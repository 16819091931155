
<div class=" md:h-screen ">
    <!-- Off-canvas menu for mobile -->
 
    <div class="md:hidden" *ngIf="show">
      <div class="h-screen flex flex-col w-[221px]">
        <div class="flex flex-col h-0 flex-1 bg-[#0B0E42]">
          <div class="flex-1 flex flex-col py-6 overflow-y-auto">
            <div class="flex flex-col justify-center items-center">
                <h1 class=" text-[#FBFBFB] font-montserrat text-[30px] leading-normal">CS MIDA</h1>
                <button (click)="restringi()" class=" mt-[45px] w-[185px] h-[45px] flex-shrink-0 rounded-[8px] bg-white flex justify-center items-center">
                <div class="mr-[12px]">Restringi Menu</div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512">
                    <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/>
                  </svg>
                </div>
                </button>
             </div>
          <!-- In your HTML file -->
          <nav class="mt-10  px-2   flex flex-col justify-center ">
            
            <div *ngFor="let item of menuItems" class="flex items-center  mr-[12px]  cursor-pointer">
              <a [routerLink]="item.route"  class=" text-white group flex items-center  text-sm font-medium rounded-md">
           
                <div class="gap-y-1.5 flex  items-center w-full">
              <div class=" flex items-center w-full mr-[20px]">
                       <div [routerLink]="item.route"  routerLinkActive="active" class="blocchetto w-5 h-[44px]  bg-[#0B0E42]"></div>
                  <div class="ml-[15px]" [innerHTML]="item.iconSvg"></div>
             
              </div>
              <a [routerLink]="item.route" routerLinkActive="active" class=" text-white group flex items-center  text-sm font-medium rounded-md">
              <div class="w-[138px] h-[44px] flex items-center justify-center">
               
                <h2 class="text-white font-montserrat text-base font-medium leading-normal whitespace-nowrap">{{ item.text }}</h2>
              
              </div>
        </a>
            </div>
          </a>
        </div>
     
        <div class="fixed bottom-5 w-[221px] h-[50px] flex justify-center items-center scale-105 cursor-pointer" (click)="navigate('/user')">
           <div class="mr-[10px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M9.9997 1.87451C5.51962 1.87451 1.87469 5.51943 1.87469 9.99951C1.87469 14.4796 5.51962 18.1245 9.9997 18.1245C14.4798 18.1245 18.1247 14.4796 18.1247 9.99951C18.1247 5.51943 14.4798 1.87451 9.9997 1.87451ZM8.03798 6.43779C8.5329 5.91318 9.22938 5.62451 9.9997 5.62451C10.77 5.62451 11.4602 5.91514 11.9571 6.44248C12.4606 6.97686 12.7056 7.69483 12.6477 8.4667C12.5321 9.99951 11.3446 11.2495 9.9997 11.2495C8.65477 11.2495 7.46493 9.99951 7.35165 8.46631C7.29423 7.68818 7.53876 6.96787 8.03798 6.43779ZM9.9997 16.8745C9.08191 16.8751 8.17335 16.6914 7.32788 16.3343C6.48242 15.9772 5.71727 15.454 5.07782 14.7956C5.44405 14.2733 5.91069 13.8293 6.45048 13.4894C7.44618 12.8511 8.70634 12.4995 9.9997 12.4995C11.2931 12.4995 12.5532 12.8511 13.5477 13.4894C14.088 13.8291 14.555 14.2732 14.9216 14.7956C14.2822 15.454 13.517 15.9773 12.6716 16.3344C11.8261 16.6916 10.9175 16.8752 9.9997 16.8745Z" fill="#FBFBFB"/>
            </svg>
          </div>
          <div>
            
            <h2 class="text-[#FBFBFB] font-lato text-base font-normal leading-normal " >
         {{nome}}  {{cognome}}
           
            </h2>
          </div>
        </div>
          </nav>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0 h-screen overflow-hidden ombra" *ngIf="expand">
      <div class="h-screen flex flex-col w-[221px]">
        <div class="flex flex-col h-0 flex-1 bg-[#0B0E42]">
          <div class="flex-1 flex flex-col py-6 overflow-y-auto">
            <div class="flex flex-col justify-center items-center">
                <h1 class=" text-[#FBFBFB] font-montserrat text-[30px] leading-normal">CS MIDA</h1>
                <button (click)="restringi()" class=" mt-[45px] w-[185px] h-[45px] flex-shrink-0 rounded-[8px] bg-white flex justify-center items-center  cursor-pointer">
                <div class="mr-[12px]">Restringi Menu</div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512">
                    <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/>
                  </svg>
                </div>
                </button>
             </div>
          <!-- In your HTML file -->
          <nav class="mt-10  px-2   flex flex-col justify-center ">
            
            <div *ngFor="let item of menuItems" class="flex items-center  mr-[12px]  cursor-pointer">
              <a [routerLink]="item.route"  class=" text-white group flex items-center  text-sm font-medium rounded-md">
           
                <div class="gap-y-1.5 flex  items-center w-full">
              <div class=" flex items-center w-full mr-[20px]">
                       <div [routerLink]="item.route"  routerLinkActive="active" class="blocchetto w-5 h-[44px]  bg-[#0B0E42]"></div>
                  <div class="ml-[15px]" [innerHTML]="item.iconSvg"></div>
             
              </div>
              <a [routerLink]="item.route" routerLinkActive="active" class=" text-white group flex items-center  text-sm font-medium rounded-md">
              <div class="w-[138px] h-[44px] flex items-center justify-center">
               
                <h2 class="text-white font-montserrat text-base font-medium leading-normal whitespace-nowrap">{{ item.text }}</h2>
              
              </div>
        </a>
            </div>
          </a>
        </div>
     
    
          </nav>
          <div class=" w-full  mt-auto flex justify-center items-center  cursor-pointer" (click)="navigate('/user')">
            <div class="mr-[10px]">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
               <path d="M9.9997 1.87451C5.51962 1.87451 1.87469 5.51943 1.87469 9.99951C1.87469 14.4796 5.51962 18.1245 9.9997 18.1245C14.4798 18.1245 18.1247 14.4796 18.1247 9.99951C18.1247 5.51943 14.4798 1.87451 9.9997 1.87451ZM8.03798 6.43779C8.5329 5.91318 9.22938 5.62451 9.9997 5.62451C10.77 5.62451 11.4602 5.91514 11.9571 6.44248C12.4606 6.97686 12.7056 7.69483 12.6477 8.4667C12.5321 9.99951 11.3446 11.2495 9.9997 11.2495C8.65477 11.2495 7.46493 9.99951 7.35165 8.46631C7.29423 7.68818 7.53876 6.96787 8.03798 6.43779ZM9.9997 16.8745C9.08191 16.8751 8.17335 16.6914 7.32788 16.3343C6.48242 15.9772 5.71727 15.454 5.07782 14.7956C5.44405 14.2733 5.91069 13.8293 6.45048 13.4894C7.44618 12.8511 8.70634 12.4995 9.9997 12.4995C11.2931 12.4995 12.5532 12.8511 13.5477 13.4894C14.088 13.8291 14.555 14.2732 14.9216 14.7956C14.2822 15.454 13.517 15.9773 12.6716 16.3344C11.8261 16.6916 10.9175 16.8752 9.9997 16.8745Z" fill="#FBFBFB"/>
             </svg>
           </div>
           <div>
             <h2 class="text-[#FBFBFB] font-lato text-base font-normal leading-normal ">{{nome}} {{cognome}}</h2>
           </div>
         </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ristretta -->
    <div class="hidden h-screen md:flex md:flex-shrink-0 ombra" *ngIf="!expand">
      <div class="flex flex-col w-[90px]">
        <div class="flex flex-col h-0 flex-1 bg-[#0B0E42]">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex flex-col justify-center items-center">
                <h1 class=" text-[#FBFBFB] font-montserrat text-[30px] leading-normal"><img src="../../../assets/images/logonav.png"></h1>
                <button (click)="restringi()" class=" mt-[45px] w-[45px] h-[45px] flex-shrink-0 rounded-[8px]  cursor-pointer bg-white flex justify-center items-center">
               
                <div>
<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512">

  <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/>
</svg>
                </div>
                </button>
             </div>
          <!-- In your HTML file -->
          <nav class="mt-10  px-2   flex flex-col justify-center ">
            
            <div *ngFor="let item of menuItems" class="flex items-center  mr-[12px]  cursor-pointer">
              <a [routerLink]="item.route"  class=" text-white group flex items-center  text-sm font-medium rounded-md">
           
                <div class="gap-y-1.5 flex  items-center w-full">
              <div class=" flex items-center w-full mr-[30px]">
                       <div [routerLink]="item.route"  routerLinkActive="active" class="blocchetto w-5 h-[44px]  bg-[#0B0E42]"></div>
                  <div class="ml-[15px]" [innerHTML]="item.iconSvg"></div>
             
              </div>
            
            </div>
          </a>
        </div>
     
        <div class="fixed bottom-5 w-[90px] h-[50px] flex justify-center items-center  cursor-pointer" (click)="navigate('/user')">
           <div class="mr-[10px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M9.9997 1.87451C5.51962 1.87451 1.87469 5.51943 1.87469 9.99951C1.87469 14.4796 5.51962 18.1245 9.9997 18.1245C14.4798 18.1245 18.1247 14.4796 18.1247 9.99951C18.1247 5.51943 14.4798 1.87451 9.9997 1.87451ZM8.03798 6.43779C8.5329 5.91318 9.22938 5.62451 9.9997 5.62451C10.77 5.62451 11.4602 5.91514 11.9571 6.44248C12.4606 6.97686 12.7056 7.69483 12.6477 8.4667C12.5321 9.99951 11.3446 11.2495 9.9997 11.2495C8.65477 11.2495 7.46493 9.99951 7.35165 8.46631C7.29423 7.68818 7.53876 6.96787 8.03798 6.43779ZM9.9997 16.8745C9.08191 16.8751 8.17335 16.6914 7.32788 16.3343C6.48242 15.9772 5.71727 15.454 5.07782 14.7956C5.44405 14.2733 5.91069 13.8293 6.45048 13.4894C7.44618 12.8511 8.70634 12.4995 9.9997 12.4995C11.2931 12.4995 12.5532 12.8511 13.5477 13.4894C14.088 13.8291 14.555 14.2732 14.9216 14.7956C14.2822 15.454 13.517 15.9773 12.6716 16.3344C11.8261 16.6916 10.9175 16.8752 9.9997 16.8745Z" fill="#FBFBFB"/>
            </svg>
          </div>
       
        </div>
          </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- Content area -->
 
  </div>