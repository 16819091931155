import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { api } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class NotificaService {
  constructor(
    private rest: RestService,
    private route: Router,
    private http: HttpClient
  ) {}

  createNotification(
    title: string,
    description: string,
    link: string,
    destination: number
  ) {
    return this.rest.post(`${api.endpoint.createNotificacion}`, {
      titolo: title,
      descrizione: description,
      link,
      destinatari: destination,
    });
  }

  paginateNotification(search = '', date = '', page = 0) {
    return this.rest.post(`notifica/paginate?page=${page}`, {
      wordKey: search,
      date: date,
    });
  }
}
