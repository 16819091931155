
<div class="accountPersonale" >
  <app-header></app-header>
<div class="outerCont">
  <div class="container" [class.container-disabled]="isContainerDisabled">
    <stdout-card>

    <stdout-card-header >{{'datas.personal' | transloco}}</stdout-card-header>
    <stdout-card-content> <div class="fields">

      <div class="nome" >
        <div class="nomeTitle">{{'nome' | transloco}}</div>
        <div class="mt-2">
        <stdout-text-input class="w-[560px]" type="text"
        value="{{ 'placeholder' | transloco }}"></stdout-text-input></div>
      </div>



      <!--<div class="titolo mt-6 ml-6 mr-6">
        <h2>{{"titolo"|transloco}}</h2>
        <div class="mt-2 ">
          <stdout-text-input type="text"></stdout-text-input>
        </div>-->


      <div class="cognome">
        <div class="cognomeTitle">{{'cognome' | transloco}}</div>
        <div class="TAContainer">
        <stdout-text-input class="w-[506px]" type="text"
        value="{{ 'placeholder' | transloco }}"></stdout-text-input></div>
      </div>



      <div class="email">
        <div class="emailTitle">{{'email' | transloco}}</div>
        <div class="TAContainer">
        <stdout-text-input class="w-[506px]" type="text"
        value="{{ 'placeholder' | transloco }}"></stdout-text-input></div>
      </div>

     </div>



     <div class="btn-section1">

        <button class="modpassword" (click)="toggleVista()">{{'pw.modifica' | transloco}}</button>

        <button class="eliminaaccount" (click)="toggleVista2()">{{'eliminaAccount.b' | transloco }}</button>
        <button class="logout">{{'maiusc.logout' | transloco}}</button>
        <button class="salva">{{'maiusc.salva' | transloco}}</button>


</div></stdout-card-content>
    </stdout-card>

      </div>


      <div class="view" *ngIf="showView">
<div class="precedente">

  <label for="oldPassword">{{'pw.precedente.insert' |transloco}}</label>
  <stdout-text-input type="text" class ="board w-[506px]" id="oldPassword" [(ngModel)]="oldPassword"
  placeholder="{{'pw.precedente.ph' |transloco}}"></stdout-text-input>


</div>


  <button class="btn-section2">
          <button class="prossimo" (click)="toggleVista3()">{{'maiusc.prossimo' | transloco}}</button>
          <button class="annullaLonger" (click)="exitFromView()">{{'maiusc.annulla' | transloco}}</button>
        </button>






      </div></div>

      <div class="view2" *ngIf="showView2"> <div class="container2"><stdout-card>
        <stdout-card-content>
          <div class="DelAccTitle">{{'eliminaAccount.conferma' | transloco}}</div>
          <div class="DelAccQuestion">{{'eliminaAccount.question' | transloco}}</div>
          <div class="DelAccBtns">
            <button class="annulla" (click)="exitFromView()">{{'maiusc.annulla' | transloco}}</button>
            <button class="eliminaAcc">{{'eliminaAccount.definitivo' | transloco}}</button>
          </div>
        </stdout-card-content>
      </stdout-card></div>
    </div>
      <div class="view3" *ngIf="showView3">
        <div class = "new">


      <div class="nuova">
        <!--<div class="nuovaTitle">{{'pw.nuova.insert' | transloco}}</div><div class="TAContainer">
        <stdout-text-input
        type="text" id="newPassword" class="board w-[506px]" value="{{ 'pw.nuova.ph' | transloco }}"></stdout-text-input></div>-->


        <label for="newPassword">{{'pw.nuova.ph' |transloco}}</label>
        <stdout-text-input type="text" class ="board w-[506px]" id="newPassword" [(ngModel)]="newPassword"
        placeholder="{{'pw.nuova.ph' |transloco}}"></stdout-text-input>

      </div>





      <div class="ripeti">

        <label for="newPassword">{{'pw.nuova.repeat' |transloco}}</label>
        <stdout-text-input type="text" class ="board w-[506px]" id="newPasswordConfirm" [(ngModel)]="newPasswordConfirm"
        placeholder="{{'pw.nuova.ph' |transloco}}"></stdout-text-input>

      </div>
</div>
      <button class = "btn-section3">
            <button class="salva" (click)="changePassword()">{{'salva'|transloco}}</button>
            <button class="annullaLonger" (click)="exitFromView()">{{'annulla'|transloco}}</button>

            <div class="successView" *ngIf="successMessage" ><stdout-card>
              <stdout-card-content>
            {{ successMessage }}
              </stdout-card-content>
            </stdout-card></div>

            <div class="errorView" *ngIf="errorMessage" ><stdout-card>
              <stdout-card-content>
            {{ errorMessage }}
              </stdout-card-content>
            </stdout-card></div>



      </button>


      </div>
</div>



