import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { RestService } from '@services/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-personale',
  templateUrl: './account-personale.component.html',
  styleUrls: ['./account-personale.component.scss'],
})
export class AccountPersonaleComponent implements OnInit {
  showView: boolean = false;
  showView2: boolean = false;
  showView3: boolean = false;
  showView4: boolean = false;
  showView5: boolean = false;
  isContainerDisabled: boolean = false;
  cardChangePassword = false;
  cardChangeSuccess = false;
  changePasswordForm!: FormGroup;
  languageForm!: FormGroup;
  oldPassword: string = '';
  newPassword: string = '';
  successMessage: string = '';
  errorMessage: string = '';
  newPasswordConfirm: string = '';
  userInfo: any = [];

  constructor(
    private auth: AuthService,
    private rest: RestService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    this.userInfo();
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['', Validators.required],
    });
    this.languageForm = this.formBuilder.group({
      language: [localStorage.getItem('language'), Validators.required],
    });
  }

  toggleVista() {
    this.showView = !this.showView;
    this.showView2 = false;
    this.showView3 = false;
    this.isContainerDisabled = !this.isContainerDisabled;
  }

  toggleVista2() {
    this.showView2 = !this.showView2;
    this.showView3 = false;
    this.showView = false;
    this.isContainerDisabled = !this.isContainerDisabled;
  }

  toggleVista3() {
    this.showView3 = !this.showView3;
    this.showView = false;
    this.showView2 = false;
    this.isContainerDisabled = this.isContainerDisabled;
  }

  exitFromView() {
    this.showView = false;
    this.showView2 = false;
    this.showView3 = false;
    this.isContainerDisabled = false;
  }

  changePassword(): void {
    this.verifyOldPasswordAndChange();
  }

  // Servizio per la gestione delle password
  private verifyOldPasswordAndChange(): void {
    if (this.newPassword === this.newPasswordConfirm) {
      this.auth
        .changePassword(
          this.oldPassword,
          this.newPassword,
          this.newPasswordConfirm
        )
        .subscribe(
          () => {
            this.successMessage = 'Password cambiata con successo.';
            this.errorMessage = '';
          },
          (error: any) => {
            if (error.status === 404 || error.status === 405) {
              this.router.navigate(['pages/user']);
            }
            if (error.status == 401) {
              this.errorMessage = `Errore durante il cambio della password`;
              this.successMessage = '';
              location.replace('auth/login');
            }
          }
        );
    } else {
      this.errorMessage =
        'La nuova password e la conferma di questa non corrispondono.';
      this.successMessage = '';
    }
  }

  exitFromCard() {
    this.cardChangePassword = false;
  }
}
