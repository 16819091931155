<div [class.opacity-50]="modal == true || loader || modalDelete" >
    <app-header></app-header>
    
    <div class=" mt-10 px-10 mb-[30vh]">
        <div class="py-10 gap-5 flex flex-wrap items-center justify-start">
            <div
            class="flex input-container justify-start items-center"
            *ngIf="!isPhone"
          >
            <stdout-icon-button
              class="lente items-center  cursor-pointer"
              style="margin: 2px 10px 0px 0px"
              size="small"
              background="secondary"
              color="white"
              borderColor="secondary"
              [icon]="faMagnifyingGlass"
            >
            </stdout-icon-button>
            <div class="w-[300px]">
              <stdout-text-input
                class="search-bar"
                placeholder="Cerca banca dati..."
                [(ngModel)]="nomeCerca"
                (keyup)="applyFilter()"
              ></stdout-text-input>
            </div>
          </div>
          </div>
     
      <div class="prt2 hi font-tab smart-tab" (click)="$event.stopPropagation()">
      <stdout2-table  class="op font-tab" [data]="profili" >
     
    
        <span stdoutTableColumn label="NOME" property="Nome" >
          <ng-template #dataTableCell let-item="item" let-index="index" >
            <div >
          
            <div >
            
              {{item.nome}}
            
            </div>
          </div>
          </ng-template>
        </span>
      
      
      
        <span stdoutTableColumn label="AZIONI" property= "icona">
          <ng-template #dataTableCell let-item="item" let-index="index">
            <div  class="flex items-center justify-center gap-x-10">
      
     
                <div  >
                  
                  <stdout-icon-button (click)="showModal(item.id, item.nome)"  class="tab-icon  cursor-pointer"  color="primary" borderColor="primary" background="white" size="small"
                    [icon]="faPencil"></stdout-icon-button>
                
                </div>
                <div  >
                  
                  <stdout-icon-button (click)="showModalDelete(item.id)"  class="tab-icon  cursor-pointer"  color="primary" borderColor="primary" background="white" size="small"
                    [icon]="faTrash"></stdout-icon-button>
                
                </div>
              </div>
        
        
          </ng-template>
    </span>
    
    
    
      </stdout2-table>
    </div>
      <div class="mt-10 flex justify-center" *ngIf="profili.length > 0">
        <stdout-pagination
          [current]="page"
          [total]="totalPages"
          (goTo)="getPage($event)"
          (next)="goNext($event)"
          (previous)="goBack($event)"
      
        ></stdout-pagination>
      </div>
    </div>
    </div>
    
    
    <div class="absolute size-full top-1/3 left-1/2" *ngIf="modal" (click)="$event.stopPropagation()">
    <div class="size-full flex items-center justify-center">
    <div class="size-full rounded-xl shadow-lg bg-white p-10">
        <div class="flex flex-col justify-start items-center size-full gap-2">
            <div class="flex items-center justify-start w-full">
            <p>Nome Profilo</p>
        </div>
        <div class="flex items-center justify-start w-full">
            <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della domanda"
            [(ngModel)]="nomeProfilo"
    
          >
          </stdout-text-input>
    </div>
    <div class="w-full mt-5 flex items-center gap-3">
        <div class="w-full">
        <stdout-button
        class="btn-blu"
    (click)="modifyEnte()"
        size="large"
        color="white"
        ><h2>{{ "modifica" | transloco }}</h2></stdout-button
      >
    </div>
    <!-- <div>
        <stdout-button
        class="btn-bianco"
    (click)="deleteEnte()"
        size="large"
        color="white"
        ><h2>{{ "elimina" | transloco }}</h2></stdout-button
      >
    </div> -->
    </div>
    </div>
    
    </div>
    </div>
    </div>
    
   
 

        <app-loader> </app-loader>
    
    <!-- modale elimina -->
<div
class="absolute top-[20%] left-[40%]"
*ngIf="modalDelete"
(click)="$event.stopPropagation()"
>
<app-modale
  title="Conferma eliminazione Profilo"
  content="Sei sicuro di volere eliminare definitivamente gli elementi selezionati?"
  [width]="504"
>
  <div class="flex justify-center items-center mt-10">
    <div class="w-full mr-[5px]  cursor-pointer">
      <stdout-button
        class="btn-bianco"
        (click)="modalDelete = false"
        size="large"
        color="white"
        ><h2>{{ "annulla" | transloco }}</h2></stdout-button
      >
    </div>
    <div class="w-full ml-[5px]  cursor-pointer">
      <stdout-button
        class="btn-blu"
        (click)="deleteProfilo()"
        size="large"
        color="white"
        ><h2>{{ "elimina_selezionati" | transloco }}</h2></stdout-button
      >
    </div>
  </div>
</app-modale>
</div>