import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  faLock,
  faLockOpen,
  faTrashCan,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { FormDetailsService } from '@services/form-details.service';
import { ImageServiceService } from '@services/image-service.service';
import { MappingService } from '@services/mapping.service';
import { MessageService } from '@services/message.service';
import { BehaviorSubject, Subscription, filter, take } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit {
  form: FormGroup;
  public imageUrl: any;
  public faUpload = faUpload;
  public faTrashCan = faTrashCan;
  public faLockOpen = faLockOpen;
  public faLock = faLock;
  private subscription: Subscription = new Subscription();
  editDataBank: any = {};
  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;
  @Input() combinedAnno: any;

  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  showPub: boolean = false;
  showPriv: boolean = false;
  combinedLib: any;
  pubblica: boolean = false;
  existPass: boolean = false;
  librerie: any;
  selectedFile: any;
  modifica: boolean = false;
  titolo: string = '';
  descrizione: string = '';
  ente: string = '';
  profilo: string = '';
  regione: string = '';
  anno: string = '';
  idToNavigate: any;
  pass: boolean = false;
  passSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() Anno: any;
  constructor(
    private fb: FormBuilder,
    private imageService: ImageServiceService,
    private banchedati: BanchedatiService,
    private formDetails: FormDetailsService,
    private mappingService: MappingService,
    private route: ActivatedRoute,
    private message: MessageService
  ) {
    this.form = this.fb.group({
      titolo: [, Validators.required],
      descrizione: [, Validators.required],
      ente: [, Validators.required],
      profilo: [{ disabled: true }, Validators.required],
      regione: [, Validators.required],
      anno: [, Validators.required],
      imageUrl: [, Validators.required],
    });
    this.form.get('imageUrl')?.valueChanges.subscribe((value) => {
      this.imageUrl = value;
    });
  }

  formChange() {
    this.form.get('titolo')?.valueChanges.subscribe((value) => {
      console.log('titolo changed:', value);
    });

    this.form.get('descrizione')?.valueChanges.subscribe((value) => {
      console.log('descrizione changed:', value);
    });

    this.form.get('ente')?.valueChanges.subscribe((value) => {
      console.log('ente changed:', value);
    });

    this.form.get('profilo')?.valueChanges.subscribe((value) => {
      console.log('profilo changed:', value);
    });
    this.form.get('anno')?.valueChanges.subscribe((value) => {
      console.log('anno changed:', value);
    });
  }

  getForm() {
    this.subscription.add(
      this.formDetails.getForm().subscribe((form) => {
        this.form.patchValue(form);
        this.formDetails.setIsChange(false);
      })
    );
  }

  ngOnInit(): void {
    this.formChange();
    this.subscription.add(
      this.formDetails.getPass().subscribe((value) => {
        this.pass = value;
        this.passSubject.next(this.pass);
      })
    );
    this.formDetails.setPass(false);
    this.subscription.add(
      this.route.queryParams.subscribe((param: any) => {
        this.idToNavigate = param.id;
        this.idBancaDati = param.id;
        this.getBanca(param.id);
        console.log(this.idToNavigate);
      })
    );

    this.subscription.add(
      this.formDetails.getModifica().subscribe((value) => {
        this.modifica = value;
      })
    );

    this.subscription.add(
      this.formDetails.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );

    this.subscription.add(
      this.formDetails.getIsChange().subscribe((value) => {
        this.formChanged = value;
      })
    );
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFile = image;
      })
    );

    this.subscription.add(
      this.formDetails.getPublic().subscribe((value) => {
        this.pubblica = value;
      })
    );
    // this.subscription.add(
    //   this.formDetails.getShowPriv().subscribe((value) => {
    //     this.showPriv = value;
    //   })
    // );
    // this.subscription.add(
    //   this.formDetails.getShowPub().subscribe((value) => {
    //     this.showPub = value;
    //   })
    // );

    this.onFormValueChanges();
    // this.form.valueChanges.subscribe((change) => {
    //   console.log(change);
    // });
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);

    this.formDetails.setError(false);
    this.formDetails.setModifica(false);
  }

  onFormValueChanges() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        this.formChanged = true;
      })
    );
  }

  resetFormChanged() {
    this.formChanged = false;
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }
  onAnnoChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const anno = target.value;

    this.combinedAnno = this.Anno.map((anno: any) => anno.nome);

    this.combinedAnno.push(anno);
  }
  onProfiliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const profilo = target.value;

    this.combinedProfili = [...this.nomiProfili];
    if (profilo) {
      this.combinedProfili.push(profilo);
    }
  }
  @Output() onSubmit = new EventEmitter<any>();
  @Output() public = new EventEmitter<any>();
  @Output() private = new EventEmitter<any>();

  submitForm(event: Event) {
    event.preventDefault();

    let updatedValues: Record<string, any> = {};

    Object.keys(this.form.controls).forEach((key) => {
      let control = this.form.get(key);
      if (control && control.dirty) {
        updatedValues[key] = control.value;
      }
    });
    console.log(updatedValues);

    this.formDetails.changeForm(updatedValues);

    this.onSubmit.emit();
  }

  show(event: Event) {
    event.preventDefault();
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage(event: Event) {
    event.preventDefault();
    this.form.get('imageUrl')?.setValue('');
    this.imageService.setCroppedImage(null);
  }

  formChanged = false;
  isFormChanged() {
    return this.formChanged;
  }

  idBancaDati: any;

  log() {
    const title = this.form.get('titolo')?.value;
    if (this.titolo.toString() !== title.toString()) {
      console.log('ciao');
    }
    console.log(this.form.value);
  }
  esempio(nome: string, value: any) {
    this.editDataBank[nome] = value;
  }
  modifyBanca(event: MouseEvent) {
    this.showModify(event);

    this.passSubject
      .pipe(
        filter((pass) => pass === true),
        take(1)
      )
      .subscribe(() => {
        LoaderComponent.isLoading = true;
        let regione = this.form.get('regione')?.value;
        let regioneId = this.listaRegioni.find(
          (region: any) => region.nome === this.form.get('regione')?.value
        )?.id;
        let nome = this.form.get('titolo')?.value;
        let descrizione = this.form.get('descrizione')?.value;
        let ente = this.form.get('ente')?.value;
        let profilo = this.form.get('profilo')?.value;
        let anno = this.form.get('anno')?.value;
        const formData: FormData = new FormData();
        if (nome.toString() !== this.titolo.toString()) {
          formData.append('nome', nome.toString());
        }
        if (descrizione.toString() !== this.descrizione) {
          formData.append('descrizione', descrizione.toString());
        }
        if (ente !== this.ente) {
          formData.append('ente', ente);
        }

        if (profilo !== this.profilo) {
          formData.append('profilo', profilo);
        }
        if (regione !== this.regione) {
          formData.append('regione', regioneId);
        }
        if (anno !== this.anno) {
          formData.append('anno', anno);
        }
        if (this.selectedFile) {
          const fileExtension = 'png';
          formData.append('cover', this.selectedFile, `cover.${fileExtension}`);
        }

        return this.banchedati
          .modifyBanca(formData, this.idBancaDati.toString())
          .subscribe(
            (response: any) => {
              this.modifica = false;
              this.imageService.setCroppedImage(null);
              this.mappingService.avvia().subscribe();
              this.getBanca(this.idToNavigate);
              // this.loader = false;
              this.formDetails.setModifica(false);
              LoaderComponent.isLoading = false;
              this.formDetails.setPass(false);
            },
            (error: any) => {
              this.showMessage(error.error.message, 'error');

              console.error(
                'Errore durante la creazione della materia:',
                error
              );
              LoaderComponent.isLoading = false;
            }
          );
      });
  }

  showModify(event: MouseEvent) {
    const mod = (this.modifica = !this.modifica);
    this.formDetails.setModifica(mod);
    this.formDetails.setIsChange(true);
    event.stopPropagation();
  }

  openPriv(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.formDetails.setShowPriv(true);
    //this.private.emit();
  }

  openPub(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.formDetails.setShowPub(true);
    //this.public.emit();
  }

  getBanca(id: any) {
    return this.banchedati.getBanca(id).subscribe(
      (response: any) => {
        this.pubblica = response.pubblicata;
        this.form.patchValue({
          titolo: response.nome,
          descrizione: response.bando.descrizione,
          ente: response.bando.enteProfilo?.ente?.nome || '-',
          profilo: response.bando.enteProfilo?.profilo?.nome || '-',
          regione: response.bando.regione.nome,
          imageUrl: response.bando.cover || '-',
          anno: response.anno,
        });

        this.titolo = response.nome;
        this.descrizione = response.bando.descrizione;
        this.ente = response.bando.enteProfilo?.ente?.nome || '-';
        this.profilo = response.bando.enteProfilo?.profilo?.nome || '-';
        this.regione = response.bando.regione.nome;
        this.anno = response.anno;

        this.formDetails.setPublic(response.pubblicata);
        this.formChanged = false;
      },

      (error: any) => {
        console.error(
          'Errore durante il recupero delle informazioni delle notifiche',
          error
        );
      }
    );
  }
}
