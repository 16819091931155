import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faChevronDown,
  faChevronUp,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FiltriDetailsService } from '@services/filtri-details.service';
import { MappingService } from '@services/mapping.service';

@Component({
  selector: 'app-filtri-details',
  templateUrl: './filtri-details.component.html',
  styleUrls: ['./filtri-details.component.scss'],
})
export class FiltriDetailsComponent implements OnInit {
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faXmark = faXmark;
  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  show4: boolean = false;
  show5: boolean = false;
  show6: boolean = false;
  show7: boolean = false;
  show8: boolean = false;
  @Input() titolo: any;
  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;
  @Input() combinedTitoli: any;
  @Input() combinedAnno: any;
  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  @Input() Anno: any;
  @Input() listaEnti: any;
  @Input() listaProfili: any;
  @Input() listaMaterie: any;
  @Input() listaArgomenti: any;
  @Input() listaDifficolta: any;
  @Input() listaTitoliStudio: any;

  constructor(
    private filtri: FiltriDetailsService,
    private mappingService: MappingService
  ) {}

  ngOnInit(): void {
    this.prendiFiltri();
  }

  initialValue = false;
  selectedNomiDifficoltaFiltro: string[] = [];
  selectedNomiProfiliFiltro: string[] = [];
  selectedAnniFiltro: string[] = [];
  selectedTitoliFiltro: number[] = [];
  selectedMaterieFiltro: number[] = [];
  selectedArgomentiFiltro: number[] = [];
  selectedDifficoltaFiltro: number[] = [];
  selectedRegione: any;
  selectedProfilo: any;
  selectedEnte: any;
  selectedProfiloRiga: any;
  selectedEnteRiga: any;
  selectedAnno: any;
  initialStateSelectedRegioni: number[] = [];
  selectedRegioni: number[] = [];
  selectedMateria: any;
  selectedArgomento: any;
  selectedDifficolta: any;
  selectedTitoli: any;
  selectedTit: number[] = [];
  selectedNomiTitFiltro: string[] = [];
  selectedAnni: string[] = [];
  selectedMaterie: number[] = [];
  selectedNomiMaterieFiltro: string[] = [];
  selectedArgomenti: number[] = [];
  selectedNomiArgomentiFiltro: string[] = [];
  selectedDiff: number[] = [];
  selectedNomiDiffFiltro: string[] = [];
  selectedEnti: number[] = [];
  selectedNomiEntiFiltro: string[] = [];
  selectedProfili: number[] = [];
  selectedEnteFil: number[] = [];
  selectedProfiloFil: string = '';
  selectedMateriaFil: string = '';
  selectedAnnoFil: string = '';
  selectedArgomentoFil: string = '';
  selectedDifficoltaFil: string = '';
  selectedTitoliFil: string = '';
  handleSwitchChange(isChecked: boolean) {}

  prendiFiltri() {
    this.filtri.getAnni().subscribe((anni) => {
      this.selectedAnni = anni;
    });
    this.filtri.getEnti().subscribe((enti) => {
      this.selectedEnti = enti;
    });
    this.filtri.getProfili().subscribe((profili) => {
      this.selectedProfili = profili;
    });
    this.filtri.getRegioni().subscribe((regioni) => {
      this.selectedRegioni = regioni;
    });
    this.filtri.getMaterie().subscribe((materie) => {
      this.selectedMaterie = materie;
    });
    this.filtri.getTitoliDiStudio().subscribe((titoliDiStudio) => {
      this.selectedTitoli = titoliDiStudio;
    });
    this.filtri.getArgomento().subscribe((argomento) => {
      this.selectedArgomenti = argomento;
    });
    this.filtri.getDifficolta().subscribe((difficolta) => {
      this.selectedDifficolta = difficolta;
    });
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  addSelectedTitoli(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedTitoliFiltro.push(selectedObject.id);
      this.selectedNomiTitFiltro.push(selectedObject.nome);
    }
    this.selectedTitoli = [...this.selectedTitoliFiltro];
  }

  addSelectedAnni(selectedObject: any) {
    if (selectedObject) {
      this.selectedAnniFiltro.push(selectedObject);
      this.selectedNomiAnniFiltro.push(selectedObject);
    }
    this.selectedAnni = [...this.selectedAnniFiltro];
  }

  addSelectedMaterie(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedMaterieFiltro.push(selectedObject.id);
      this.selectedNomiMaterieFiltro.push(selectedObject.nome);
    }
    this.selectedMaterie = [...this.selectedMaterieFiltro];
    this.mappingService.setMateriaID(selectedObject.id);
    this.mappingService.argomento().subscribe();
  }

  addSelectedArgomenti(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedArgomentiFiltro.push(selectedObject.id);
      this.selectedNomiArgomentiFiltro.push(selectedObject.nome);
    }
    this.selectedArgomenti = [...this.selectedArgomentiFiltro];
  }

  addSelectedDifficolta(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedDifficoltaFiltro.push(selectedObject.id);
      this.selectedNomiDifficoltaFiltro.push(selectedObject.nome);
    }
    this.selectedDifficolta = [...this.selectedDifficoltaFiltro];
  }
  selectedEntiFiltro: any[] = [];
  selectedProfiliFiltro: any[] = [];
  initialStateSelectedProfili: number[] = [];
  initialStateSelectedArgomenti: number[] = [];
  addSelectedEnte(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedEntiFiltro.push(selectedObject.id);
      this.selectedNomiEntiFiltro.push(selectedObject.nome);
    }
    this.mappingService.setEnteID(selectedObject.id);
    this.selectedEnti = [...this.selectedEntiFiltro];
    this.mappingService.profilo().subscribe();
  }

  addSelectedProfili(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedProfiliFiltro.push(selectedObject.id);
      this.selectedNomiProfiliFiltro.push(selectedObject.nome);
    }
    this.selectedProfili = [...this.selectedProfiliFiltro];
  }
  initialSelectedEnti: number[] = [];
  rimuoviVal(id: any) {
    const index = this.selectedEntiFiltro.indexOf(id);
    if (index > -1) {
      this.selectedEntiFiltro.splice(index, 1);
      this.selectedNomiEntiFiltro.splice(index, 1);
    }

    if (this.selectedEntiFiltro.length === 0) {
      this.selectedEnti = [...this.initialSelectedEnti];
    } else {
      this.selectedEnti = [...this.selectedEntiFiltro];
    }
  }
  rimuoviValProfilo(id: any) {
    const index = this.selectedProfiliFiltro.indexOf(id);
    if (index > -1) {
      this.selectedProfiliFiltro.splice(index, 1);
      this.selectedNomiProfiliFiltro.splice(index, 1);
    }

    if (this.selectedProfiliFiltro.length === 0) {
      this.selectedProfili = [...this.initialStateSelectedProfili];
    } else {
      this.selectedProfili = [...this.selectedProfiliFiltro];
    }
  }

  selectedNomiAnniFiltro: any[] = [];
  initialStateSelectedAnni: any[] = [];

  rimuoviAnno(id: any) {
    const index = this.selectedAnniFiltro.indexOf(id);
    if (index > -1) {
      this.selectedAnniFiltro.splice(index, 1);
      this.selectedNomiAnniFiltro.splice(index, 1);
    }

    if (this.selectedAnniFiltro.length === 0) {
      this.selectedAnni = [...this.initialStateSelectedAnni];
    } else {
      this.selectedAnni = [...this.selectedAnniFiltro];
    }
  }
  initialStateSelectedTitoli: number[] = [];
  initialStateSelectedMaterie: number[] = [];
  initialStateSelectedDifficolta: number[] = [];

  rimuoviValTit(id: any) {
    const index = this.selectedTitoliFiltro.indexOf(id);
    if (index > -1) {
      this.selectedTitoliFiltro.splice(index, 1);
      this.selectedNomiTitFiltro.splice(index, 1);
    }

    if (this.selectedTitoliFiltro.length === 0) {
      this.selectedTitoli = [...this.initialStateSelectedTitoli];
    } else {
      this.selectedTitoli = [...this.selectedTitoliFiltro];
    }
  }
  rimuoviValMaterie(id: any) {
    const index = this.selectedMaterieFiltro.indexOf(id);
    if (index > -1) {
      this.selectedMaterieFiltro.splice(index, 1);
      this.selectedNomiMaterieFiltro.splice(index, 1);
    }

    if (this.selectedTitoliFiltro.length === 0) {
      this.selectedMaterie = [...this.initialStateSelectedMaterie];
    } else {
      this.selectedMaterie = [...this.selectedMaterieFiltro];
    }
  }
  rimuoviValArgomenti(id: any) {
    const index = this.selectedArgomentiFiltro.indexOf(id);
    if (index > -1) {
      this.selectedArgomentiFiltro.splice(index, 1);
      this.selectedNomiArgomentiFiltro.splice(index, 1);
    }

    if (this.selectedArgomentiFiltro.length === 0) {
      this.selectedArgomenti = [...this.initialStateSelectedArgomenti];
    } else {
      this.selectedArgomenti = [...this.selectedArgomentiFiltro];
    }
  }

  rimuoviValDifficolta(id: any) {
    const index = this.selectedDifficoltaFiltro.indexOf(id);
    if (index > -1) {
      this.selectedDifficoltaFiltro.splice(index, 1);
      this.selectedNomiDifficoltaFiltro.splice(index, 1);
    }

    if (this.selectedDifficoltaFiltro.length === 0) {
      this.selectedDifficolta = [...this.initialStateSelectedDifficolta];
    } else {
      this.selectedDifficolta = [...this.selectedDifficoltaFiltro];
    }
  }
  @Output() onSubmit = new EventEmitter<any>();
  applicaFiltri() {
    this.filtri.setAnni(this.selectedAnni);
    this.filtri.setEnti(this.selectedEnti);
    this.filtri.setProfili(this.selectedProfili);
    this.filtri.setRegioni(this.selectedRegioni);
    this.filtri.setMaterie(this.selectedMaterie);
    this.filtri.setTitoliDiStudio(this.selectedNomiTitFiltro);
    this.filtri.setArgomento(this.selectedArgomenti);
    this.filtri.setDifficolta(this.selectedDifficolta);

    this.onSubmit.emit();
  }

  @Output() reset = new EventEmitter<any>();

  resettaFiltri() {
    this.selectedAnniFiltro = [];
    this.selectedEntiFiltro = [];
    this.selectedProfiliFiltro = [];
    this.selectedRegioni = [];
    this.selectedMaterie = [];
    this.selectedTitoliFiltro = [];
    this.selectedArgomenti = [];
    this.selectedDifficolta = [];
    this.selectedAnni = [];
    this.selectedEnti = [];
    this.selectedProfili = [];
    this.selectedRegioni = [];
    this.selectedMaterie = [];
    this.selectedTitoli = [];
    this.selectedArgomenti = [];
    this.selectedDifficolta = [];
    this.selectedNomiArgomentiFiltro = [];
    this.selectedNomiDifficoltaFiltro = [];
    this.selectedNomiEntiFiltro = [];
    this.selectedNomiMaterieFiltro = [];
    this.selectedNomiProfiliFiltro = [];
    this.selectedNomiTitFiltro = [];
    this.selectedMaterieFiltro = [];
    this.selectedArgomentiFiltro = [];
    this.selectedDifficoltaFiltro = [];

    this.reset.emit();
  }
}
