import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lm-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [
    trigger('expand', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('100ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      // transition(':leave', [animate('100ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class MessageComponent implements OnInit {
  public deviceWidth = window.innerWidth;
  @Input() message = 'ORDINE CONFERMATO';
  @Input() status = 'success';
  @Input() customClass: string | undefined;
  public faCircleXmark = faCircleXmark;
  constructor() {}

  ngOnInit(): void {}

  closeMessage() {
    this.message = 'ginoa';
  }
}
