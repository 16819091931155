import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { api } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BanchedatiService {
  constructor(
    private rest: RestService,
    private route: Router,
    private http: HttpClient
  ) {}

  // abbonamenti
  createAbbonamento(
    idTipoAbbonamento: number,
    dataInizio: String,
    dataFine: String,
    dataAcquisto: string,
    pagato: boolean,
    stato: String,
    codiceTransizione: string,
    idBancaDati: number
  ) {
    return this.rest.post(`${api.endpoint.Abbonamento}`, {
      idTipoAbbonamento,
      dataInizio,
      dataFine,
      dataAcquisto,
      pagato,
      stato,
      codiceTransizione,
      idBancaDati,
    });
  }

  attivaAbbonamento(
    userId: string[],
    idTipoAbbonamento: number,
    idBancadati: number
  ) {
    return this.rest.post(
      `${api.endpoint.abbonamento}/admin/active/${userId}`,
      { idTipoAbbonamento, idBancadati }
    );
  }

  getAllAbbonamenti() {
    return this.rest.get(`${api.endpoint.getabb}`);
  }

  getAbbonamento(id: number) {
    return this.rest.get(`${api.endpoint.abbonamento}/admin/get/${id}`);
  }

  deleteAbbonamento(id: number) {
    return this.rest.delete(`${api.endpoint.abbonamento}/admin/remove/${id}`);
  }

  // sezione crea

  createRisposta(contenuto: string, corretta: boolean, idDomanda: number) {
    return this.rest.post(`${api.endpoint.Risposta}`, {
      contenuto,
      idDomanda,
      corretta,
    });
  }

  // sezione Ente

  createEnte(nome: string) {
    return this.rest.post(`${api.endpoint.Ente}`, { nome });
  }

  getListaBanche(nome: string) {
    return this.rest.get(`bancadati?nome=${nome}`);
  }

  getProfilo(id: number, filter: string) {
    return this.rest.get(`ente/${id}/profili?filter=${filter}`);
  }

  getEnte(filter: string) {
    return this.rest.get(`${api.endpoint.Ente}?filter=${filter}`);
  }

  getAnno(filter: string) {
    return this.rest.get(`${api.endpoint.anno}?filter=${filter}`);
  }

  getTitoloStudio(filter: string) {
    return this.rest.get(`${api.endpoint.titoloDiStudio}?filter=${filter}`);
  }

  getLib() {
    return this.rest.get(`${api.endpoint.getLib}`);
  }

  getCover() {
    return this.rest.get(`${api.endpoint.getCover}`);
  }

  // sezione materia

  createMateria(nome: string) {
    return this.rest.post(`${api.endpoint.Materia}`, { nome });
  }

  getMateria(filter: string) {
    return this.rest.get(`${api.endpoint.Materia}?filter=${filter}`);
  }

  getArgomento(id: number, filter: string) {
    return this.rest.get(`materia/${id}/argomenti?filter=${filter}`);
  }

  // sezione profilo

  createProfilo(nome: string) {
    return this.rest.post(`${api.endpoint.Profilo}`, { nome });
  }

  // paginateEnte(page = 0, search?: string) {
  //   return this.rest.get(`ente/paginate?page=${page}&search=${search}`);
  // }

  // sezione domanda

  createDomanda(
    contenuto: string,
    idArgomento: number,
    idBancaDati: number,
    difficolta: number
  ) {
    return this.rest.post(`${api.endpoint.Domanda}`, {
      contenuto,
      idArgomento,
      idBancaDati,
      difficolta,
    });
  }

  getDomanda() {
    return this.rest.get(`${api.endpoint.Domanda}`);
  }

  // sezione regione
  createRegione(nome: string) {
    return this.rest.post(`${api.endpoint.regione}`, { nome });
  }

  getRegione() {
    return this.rest.get(`${api.endpoint.regione}`);
  }

  // sezione Banca Dati

  // createBancaDati(nome:string, descrizione:string, ente:string,profilo:string, idRegione:number, anno:string,  bancadatiIds:string[], cover:any) {
  //   return this.rest.post(`${api.endpoint.createBancaDati}`, { nome, descrizione, ente,profilo, idRegione, anno, bancadatiIds, cover });
  // }

  createBancaDati(params: any) {
    return this.rest.post(`${api.endpoint.createBancaDati}`, params);
  }

  createBancaDatiFromLib(params: any) {
    return this.rest.post(`${api.endpoint.createFromLib}`, params);
  }

  // createBancaDatiFromLib(
  //   libId: string,
  //   anno: string,
  //   nome: string,
  //   descrizione: string,
  //   regione: string,
  //   ente?: string,
  //   profilo?: string,
  //   idImmagine?: string
  // ) {
  //   return this.rest.post(`${api.endpoint.createFromLib}`, {
  //     libId,
  //     anno,
  //     nome,
  //     descrizione,
  //     regione,
  //     ente,
  //     profilo,
  //     idImmagine,
  //   });
  // }

  paginateEnte(page = 0, search?: string) {
    return this.rest.get(`ente/paginate?page=${page}&search=${search}`);
  }

  paginateAnno(page = 0, search?: string) {
    return this.rest.get(`anno/paginate?page=${page}&search=${search}`);
  }

  paginateProfilo(page = 0, search?: string) {
    return this.rest.get(`profilo/paginate?page=${page}&search=${search}`);
  }

  paginateBancaDati(
    page = 0,
    nome?: string,
    descrizione?: string,
    selectedAnni?: string[],
    selectedRegioni?: number[],
    selectedEnti?: number[],
    SelectedProfili?: number[],
    emptyProfilo?: boolean
  ) {
    return this.rest.post(`bancadati/paginate?page=${page}`, {
      nome,
      descrizione,
      anno: selectedAnni,
      regione: selectedRegioni,
      enti: selectedEnti,
      profili: SelectedProfili,
      emptyProfilo,
    });
  }

  getAll(search = '', page = 0, typeSubscription: Array<number>) {
    let token = localStorage.getItem('token');
    return this.rest.post(`admin/paginate/users?page=${page}`, {
      wordKey: search,
      ...(typeSubscription.length > 0 && { tipoAbbonamento: typeSubscription }),
    });
  }

  ImportaSingola(params: any, idBD: number) {
    return this.rest.post(`${api.endpoint.importSingola}/${idBD}`, params);
  }

  ImportaSingolaFromLib(idBD: number, libId: number) {
    return this.rest.post(`${api.endpoint.importSingolaFromLib}/${idBD}`, {
      libId,
    });
  }

  deleteSingolaBancaDati(id: number) {
    return this.rest.delete(`${api.endpoint.deleteBancaDati}/${id}`);
  }

  deleteBancheDati(bancadatiIds: string[]) {
    return this.rest.post(`${api.endpoint.deleteBancheDati}`, { bancadatiIds });
  }

  // exportBancaDati(bancadatiIds:number[],)  {
  //   return this.http.post<Blob>(`${api.baseUrl}${api.endpoint.exportBancaDati}`, {bancadatiIds},{ responseType: 'blob' as 'json' });
  // }
  exportBancaDati(bancadatiIds: number[]) {
    return this.http.post<Blob>(
      `${api.baseUrl}${api.endpoint.exportBancaDati}`,
      { bancadatiIds },
      { responseType: 'blob' as 'json', observe: 'response' }
    );
  }

  exportDetails(domandeIds: number[], bancadatiIds: number) {
    return this.http.post<Blob>(
      `${api.baseUrl}${api.endpoint.exportDetails}/${bancadatiIds}`,
      { domandeIds },
      { responseType: 'blob' as 'json', observe: 'response' }
    );
  }

  getDomande(
    contenuto: string,
    filters: any[],
    ordinaPer: number,
    ordinaDesc: boolean
  ) {
    return this.rest.post(`${api.endpoint.getDomande}`, {
      contenuto,
      filters,
      ordinaPer,
      ordinaDesc,
    });
  }

  getBancaDati(
    idBD: number,
    ordinaPer: number,
    ordinaDesc: boolean,
    duplicate: string,
    contenuto: string,
    anno?: string[],
    materia?: number[],
    ente?: number[],
    profilo?: number[],
    argomento?: number[],
    difficolta?: number[],
    titoloDiStudio?: string[]
  ) {
    return this.rest.post(
      `${api.endpoint.getBancaDati}/${idBD}?duplicate=false`,

      {
        ordinaPer,
        ordinaDesc,
        contenuto,
        anno,
        materia,
        ente,
        profilo,
        argomento,
        difficolta,
        titoloDiStudio,
      }
    );
  }

  ImportaCreateBancheDati(params: any) {
    return this.rest.post(`${api.endpoint.importaCreateBancaDati}`, params);
  }

  getBanca(idBD: number) {
    return this.rest.get(`${api.endpoint.banca}/${idBD}`);
  }

  // paginateBancaDati(search='',page=0, ) {
  //   return this.rest.post(`bancadati/paginate?page=${page}`, { nome:search , });
  // }

  idToNavigate!: number;

  setIdToNavigate(id: number) {
    this.idToNavigate = id;
  }

  getIdToNavigate() {
    return this.idToNavigate;
  }

  duplicaBancaDati(bancadatiIds: number[]) {
    return this.rest.post(`${api.endpoint.duplicateBancaDati}`, {
      bancadatiIds,
    });
  }

  duplicaBancaDatiDomanda(idBancadati: number, idDomande: number[]) {
    return this.rest.post(`${api.endpoint.duplicateBancaDatiDomanda}`, {
      idBancadati,
      idDomande,
    });
  }

  // deleteDomanda(idBancadati: number, idDomande:number[]) {
  //   return this.rest.delete(`${api.endpoint.deleteDomanda}` ,{ idBancadati, idDomande});
  // }

  deleteDomanda(idBancadati: number, idDomande: number[]) {
    return this.http.request(
      'delete',
      `${api.baseUrl}${api.endpoint.deleteDomanda}`,
      {
        body: { idBancadati, idDomande },
      }
    );
  }

  aggiungiDomanda(
    idBancadati: number,
    domanda: string,
    rispostaA: string,
    rispostaB: string,
    rispostaCorretta: string,
    numerazioneMida: string,
    anno: string,
    materia: string,
    immagine?: string,
    immagineSpiegazioneRisposta?: string,
    linkVideoSpiegazione?: string,
    linkPreparazione?: string,
    rispostaC?: string,
    rispostaD?: string,
    rispostaE?: string,
    rispostaF?: string,
    titoloDiStudio?: string,
    ente?: string,
    profilo?: string,
    argomento?: string,
    difficoltaDomanda?: number
  ) {
    return this.rest.post(`${api.endpoint.aggiungiDomanda}`, {
      idBancadati,
      domanda,
      rispostaA,
      rispostaB,
      rispostaC,
      rispostaD,
      rispostaE,
      rispostaF,
      rispostaCorretta,
      numerazioneMida,
      titoloDiStudio,
      anno,
      ente,
      profilo,
      materia,
      argomento,
      difficoltaDomanda,
      immagine,
      immagineSpiegazioneRisposta,
      linkVideoSpiegazione,
      linkPreparazione,
    });
  }

  // modifyBanca(nome?:string, idBD?:string, descrizione?:string, ente?:string, profilo?:string, regione?:number,anno?:string ) {
  //   return this.rest.patch(`${api.endpoint.modifyBanca}/${idBD}`, {nome, descrizione, ente, profilo, regione, anno});
  // }

  modifyBanca(params: any, idBD?: string) {
    return this.rest.patch(`${api.endpoint.modifyBanca}/${idBD}`, params);
  }
  modifyEnte(id: string, nome: string) {
    return this.rest.patch(`${api.endpoint.modEnte}/${id}`, { nome: nome });
  }

  changeIndex(idBancadati: number, idDomanda: number, indice: number) {
    return this.rest.patch(
      `${api.endpoint.banca}/${idBancadati}/domanda/position/${idDomanda}`,
      { indice }
    );
  }

  modifyProfilo(id: string, nome: string) {
    return this.rest.patch(`${api.endpoint.modProfilo}/${id}`, { nome: nome });
  }

  deleteEnte(id: number) {
    return this.rest.delete(`${api.endpoint.Ente}/${id}`);
  }

  deleteAnno(id: number) {
    return this.rest.delete(`${api.endpoint.anno}/${id}`);
  }

  deleteProfilo(id: number) {
    return this.rest.delete(`${api.endpoint.Profilo}/${id}`);
  }

  perTe(idBD?: string) {
    return this.rest.patch(`${api.endpoint.perTe}/${idBD}`, {});
  }

  publishBanca(idBD?: string) {
    return this.rest.patch(`${api.endpoint.publishBancaDati}/${idBD}`, {});
  }

  unPubblishBanca(idBD?: string) {
    return this.rest.patch(`${api.endpoint.unpublishBancaDati}/${idBD}`, {});
  }

  modifyDomanda(
    idDomanda?: number,
    contenuto?: string,
    rispostaA?: string,
    rispostaB?: string,
    rispostaC?: string,
    rispostaD?: string,
    rispostaE?: string,
    rispostaF?: string,
    rispostaCorretta?: string,
    numerazioneMida?: string,
    titoloDiStudio?: string,
    anno?: string,
    ente?: string,
    profilo?: string,
    materia?: string,
    argomento?: string,
    difficoltaDomanda?: string,
    immagine?: string,
    immagineSpiegazioneRisposta?: string,

    linkPreparazione?: string
  ) {
    return this.http.patch(`${api.baseUrl}${api.endpoint.modifyDomanda}`, {
      idDomanda,
      contenuto,
      rispostaA,
      rispostaB,
      rispostaC,
      rispostaD,
      rispostaE,
      rispostaF,
      rispostaCorretta,
      numerazioneMida,
      titoloDiStudio,
      anno,
      ente,
      profilo,
      materia,
      argomento,
      difficoltaDomanda,
      immagine,
      immagineSpiegazioneRisposta,

      linkPreparazione,
    });
  }

  exist(nome: string, descrizione: string, anno: string) {
    return this.rest.post(`${api.endpoint.exist}`, { nome, descrizione, anno });
  }

  // sezione quiz

  importaQuiz(params: any) {
    return this.rest.post(`${api.endpoint.importaQuiz}`, params);
  }

  exportQuiz(domandeIds: number[]) {
    return this.http.post<Blob>(
      `${api.baseUrl}${api.endpoint.exportQuiz}`,
      { domandeIds },
      { responseType: 'blob' as 'json', observe: 'response' }
    );
  }

  deleteQuiz(idDomande: string[]) {
    return this.http.request(
      'delete',
      `${api.baseUrl}${api.endpoint.deleteDomandaBulk}`,
      {
        body: { idDomande },
      }
    );
  }

  duplicaQuiz(idDomande: string[]) {
    return this.rest.post(`${api.endpoint.duplicateDomanda}`, {
      idDomande,
    });
  }

  createBancaDatiFromQuiz(params: any) {
    return this.rest.post(`${api.endpoint.createBancaDatiFromQuiz}`, params);
  }

  aggiungiDomandaQuiz(
    domanda: string,
    rispostaA: string,
    rispostaB: string,
    rispostaCorretta: string,
    numerazioneMida: string,
    anno: string,
    materia: string,
    immagine?: string,
    immagineSpiegazioneRisposta?: string,
    linkVideoSpiegazione?: string,
    linkPreparazione?: string,
    rispostaC?: string,
    rispostaD?: string,
    rispostaE?: string,
    rispostaF?: string,
    titoloDiStudio?: string,
    ente?: string,
    profilo?: string,
    argomento?: string,
    difficoltaDomanda?: number
  ) {
    return this.rest.post(`${api.endpoint.addDomanda}`, {
      domanda,
      rispostaA,
      rispostaB,
      rispostaC,
      rispostaD,
      rispostaE,
      rispostaF,
      rispostaCorretta,
      numerazioneMida,
      titoloDiStudio,
      anno,
      ente,
      profilo,
      materia,
      argomento,
      difficoltaDomanda,
      immagine,
      immagineSpiegazioneRisposta,
      linkVideoSpiegazione,
      linkPreparazione,
    });
  }
}
