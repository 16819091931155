import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth.component';
import {
  ButtonModule,
  CardModule,
  FormModule,
  TagModule,
} from '@stdoutsrl/ui-kit';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RegistrationComponent } from './registration/registration.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { TranslocoRootModule } from '../transloco-root.module';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'recovery', component: RecoveryComponent },

      { path: 'register', component: RegistrationComponent },
      { path: 'reset/:token', component: ResetPasswordComponent },
    ],
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    RecoveryComponent,
    ResetPasswordComponent,
  ],
  imports: [
    TranslocoRootModule,
    CommonModule,
    FormsModule,
    CardModule,
    ButtonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
