<input type="file" #fileInput (change)="onFileSelected($event)" accept=".xlsx" style="display: none" />
<form [formGroup]="form">
  <div class="bg-white p-[20px] importare">
    <div class="flex flex-col justify-center items-center">
      <div clss="titolo-modal w-full flex items-center justify-center">
        <h1>Importa file</h1>
      </div>

      <div class="flex justify-start items-center w-full mt-5 mb-[30px]">
        <div class="flex items-center mr-[25px]">
          <label class="mr-[10px]">
            <input type="radio" id="choice1" name="choice" (click)="onButtonClick($event)" class="radio" />
            <span class="custom-radio">
              <span class="inner"></span>
            </span>
          </label>
          <p>
            <b>{{ "file_est" | transloco }}</b>
          </p>
        </div>
        <div class="flex items-center">
          <label class="mr-[10px]">
            <input type="radio" id="choice1" name="choice" (click)="showLib()" class="radio" />
            <span class="custom-radio">
              <span class="inner"></span>
            </span>
          </label>
          <p>
            <b>{{ "file_lib" | transloco }}</b>
          </p>
        </div>
        <div *ngIf="importLibreria" class="justify-center items-center flex ml-20 mr-auto">
          <div class="w-[346px]">
            <stdout-select class="fix-select" [placeholder]="'seleziona il file da importare'" id="select1" type="text"
              formControlName="libreria" [clearable]="false" [value]="form.value.libreria" [data]="librerie"
              bindLabel="nome" bindValue="id" #selectElement>
            </stdout-select>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row lg:items-center w-full justify-between items-end">
        <div class="flex flex-col w-full md:w-[18.285%] mb-[10px] md:mb-0">
          <div class="mb-[10px]">
            <label class="primalabel" for="1">
              <h2 class="lg:whitespace-nowrap">
                <b [ngClass]="{'testo-errore': form.get('titolo')?.invalid && formError}">{{ "nome_nuova_banca" | transloco }}</b>
              </h2>
            </label>
          </div>
          <div class="w-full h-full bordo" [ngClass]="{'invalid-border': form.get('titolo')?.invalid && formError}">
            <input  class="w-full h-full px-[12px] py-[8px] outline-none" type="text"
              placeholder="Inserisci il nome nuova banca dati" formControlName="titolo" />
          </div>
          <div></div>
        </div>
        <div class="flex flex-col w-full md:w-[79.428%]">
          <div class="mb-[10px]">
            <label class="primalabel" for="1">
              <h2>
                <b>{{ "descrizione" | transloco }}</b>
              </h2>
            </label>
          </div>
          <div>
            <stdout-text-input class="bordo" id="1" type="text"
              placeholder="Inserisci la descrizione della nuova banca dati"
              formControlName="descrizione"></stdout-text-input>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row w-full mt-[25px] justify-between">
      <div class="md:w-[18.285%] selezione">
        <div>
          <div class="mb-[10px] primalabel">
            <label>
              <h2>
                <b [ngClass]="{'testo-errore': form.get('ente')?.invalid && formError}">{{ "ente" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select class="fix-select" [placeholder]="'Seleziona o inserisci l’ente'" id="select1" type="text"
            [clearable]="false" [data]="combinedEnti" (input)="onEnteChange($event)" formControlName="ente"
            [value]="form.value.ente" #selectElement
            [ngClass]="{'invalid-select': form.get('ente')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="selezione md:w-[18.285%] mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label>
              <h2>
                <b>{{ "profilo" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select [placeholder]="'Seleziona o inserisci il profilo'" class="fix-select" [clearable]="false"
            type="text" [data]="combinedProfili" (input)="onProfiliChange($event)" [value]="form.value.profilo"
            formControlName="profilo" #selectElement [disabled]="form.controls['profilo'].disabled">
          </stdout-select>
        </div>
      </div>
      <div class="md:w-[18.285%] selezione mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label>
              <h2>
                <b [ngClass]="{'testo-errore': form.get('regione')?.invalid && formError}">{{ "regione" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select class="fix-select" id="select1" [clearable]="false" [data]="listaRegioni"
            [placeholder]="'Seleziona la località'" #selectElement bindLabel="nome" [value]="form.value.regione"
            bindValue="id" formControlName="regione"
            [ngClass]="{'invalid-select': form.get('regione')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="md:w-[18.285%] selezione mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label>
              <h2>
                <b [ngClass]="{'testo-errore': form.get('anno')?.invalid && formError}">{{ "anno" | transloco }}</b>
              </h2>
            </label>
          </div>
          <stdout-select class="fix-select" id="select1" [clearable]="false" (input)="onAnnoChange($event)"
            [placeholder]="'Seleziona l’anno '" formControlName="anno" [value]="form.value.anno" [data]="combinedAnno"
            #selectElement
            [ngClass]="{'invalid-select': form.get('anno')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="mt-[10px] md:mt-0 md:w-[18.285%]" (click)="$event.stopPropagation()">
        <div class="mb-[10px] primalabel">
          <label>
            <h2>
              <b>{{ "icona" | transloco }}</b>
            </h2>
          </label>
        </div>
        <div class="flex w-full">
          <div class="mr-[15px] w-full">
            <stdout-text-input class="no-bordo" id="1" type="text" placeholder="immagine" formControlName="imageUrl"
              [disabled]="true"></stdout-text-input>
          </div>
          <div class=" cursor-pointer" *ngIf="imageUrl === ''">
            <stdout-icon-button (click)="show($event)" type="button" class="lente"
              [icon]="faUpload"></stdout-icon-button>
          </div>
          <div class=" cursor-pointer" *ngIf="imageUrl !== ''">
            <stdout-icon-button (click)="removeImage($event)" class="lente" [icon]="faTrashCan">
            </stdout-icon-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-center items-center">
      <div class="mt-10 w-full md:w-[15.209%]  cursor-pointer">
        <stdout-button *ngIf="importNormale" class="btn-blu cursor-pointer w-full"
          (click)="submitForm()" type="submit" size="large" color="white">
          <h2>{{ "importa" | transloco }}</h2>
        </stdout-button>
        <stdout-button *ngIf="importDaLibreria" class="btn-blu  cursor-pointer w-full"
          (click)="createFromLib()" type="submit" size="large" color="white">
          <h2>{{ "importa" | transloco }}</h2>
        </stdout-button>
      </div>
    </div>
  </div>

</form>