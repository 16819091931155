import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageServiceService {
  private selectedFileSubject: BehaviorSubject<File | null> =
    new BehaviorSubject<File | null>(null);
  private croppedImageSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private mostra: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  setSelectedFile(file: File): void {
    this.selectedFileSubject.next(file);
  }

  getSelectedFile(): Observable<File | null> {
    return this.selectedFileSubject.asObservable();
  }

  setCroppedImage(image: any): void {
    this.croppedImageSubject.next(image);
  }

  getCroppedImage(): Observable<any> {
    return this.croppedImageSubject.asObservable();
  }

  setMostra(value: boolean): void {
    this.mostra.next(value);
  }

  getMostra(): Observable<boolean> {
    return this.mostra.asObservable();
  }

  constructor() {}
}
