import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { api } from '@environments/environment';
import { ScrollService } from '@services/scroll.service';
import { SelectionService } from '@services/selection.service';
import { Subscription } from 'rxjs';

export interface Column {
  display: string;
  dataIndex: string;
}

@Component({
  selector: 'app-tabella2',
  templateUrl: './tabella2.component.html',
  styleUrls: ['./tabella2.component.scss'],
})
export class Tabella2Component implements OnInit {
  constructor(
    private selectionService: SelectionService,
    private scrollService: ScrollService,
    public el: ElementRef
  ) {}

  @Input() showCheck: boolean = false;
  check$ = this.selectionService.check$;
  displayCount = 0;
  columnsWithWidths: { display: string; width: number }[] = [];
  private subscription: Subscription = new Subscription();
  bancadatiCoverFolder = `${api.baseUrl}public/bancadati/cover/`;

  ngOnInit(): void {
    this.subscription.add(
      this.scrollService.getScrollLeft().subscribe((value) => {
        if (this.scrollableDiv && this.scrollableDiv.nativeElement) {
          this.scrollableDiv.nativeElement.scrollLeft = value;
        }
      })
    );

    this.subscription.add(
      this.scrollService.getMouseDown().subscribe((value) => {
        this.mouseDown = value;
      })
    );

    this.subscription.add(
      this.scrollService.getDisplayCount().subscribe((value) => {
        this.displayCount = value;
        setTimeout(() => {
          this.getWidth();
          this.adattaAltezza();
        });
      })
    );
  }

  @Input() data!: any[];
  @Input() dataView!: any[];
  @Input() changeId!: number[];
  @Input() perTe!: number[];
  @Input() tabText!: string;

  @Input() columns!: Column[];
  @Output() itemCliccato: EventEmitter<any> = new EventEmitter();
  @Input() isScrollable: boolean = false;
  head: boolean = false;

  clicca(item: any) {
    this.itemCliccato.emit(item);
  }
  maxHeight!: number;

  @ViewChildren('myDiv') divs!: QueryList<ElementRef>;
  @ViewChildren('tabHead') tabHead!: QueryList<ElementRef>;

  adattaAltezza() {
    let maxHeight = 0;

    this.divs.forEach((td: ElementRef) => {
      let elemento = td.nativeElement;
      if (elemento.offsetHeight > maxHeight) {
        maxHeight = elemento.offsetHeight;
      }
    });
    maxHeight = Math.max(maxHeight, 100);

    this.divs.forEach((div: ElementRef) => {
      let elemento = div.nativeElement;
      if (elemento) {
        elemento.style.height = maxHeight.toString() + 'px';
      }
    });
    this.maxHeight = maxHeight;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adattaAltezza();
      this.getWidth();
    }, 0);
  }

  // ngAfterViewChecked() {
  //   this.adattaAltezza();
  //   setTimeout(() => {
  //     this.getWidth();
  //   }, 0);
  // }

  private resizeTimeout: any;

  @HostListener('window:resize')
  getWidth() {
    let widths: number[] = [];
    this.tabHead.toArray().forEach((div: ElementRef) => {
      let width = parseFloat(
        div.nativeElement.getBoundingClientRect().width.toFixed(2)
      );
      widths.push(width);
    });

    this.scrollService.setWidths(widths);
  }

  ngOnDestroy() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.subscription.unsubscribe();
  }

  @Output() selectionChangeAll = new EventEmitter<any[]>();
  @Output() selectionChange = new EventEmitter<any[]>();

  onCheckboxChange(item: any, event: any) {
    const checked = event.target.checked;

    if (checked) {
      this.selectionService.select(item);
    } else {
      this.selectionService.deselect(item);
    }
    this.selectionChange.emit(this.selectionService.getSelectedIds());
  }

  check: boolean = false;

  onCheckboxChangeAll(event: any) {
    const checked = event.target.checked;
    if (checked) {
      this.data.forEach((item) => {
        this.selectionService.selectAll(this.data);
        this.selectionChange.emit(item);
      });
    } else {
      this.data.forEach((item) => {
        this.selectionService.deselectAll();
        this.selectionChange.emit(item);
      });
    }
    this.check = checked; // Aggiorna this.check qui

    const selectedIds = this.selectionService.getSelectedIds();
    this.selectionChangeAll.emit(selectedIds);
  }

  scrollLeft: number = 0; // Define the type for scrollLeft

  mouseDown: boolean = false;
  startX: number = 0; // Define the type for startX

  @ViewChild('parent', { static: true }) // Adjust the 'static' value as needed
  slider!: ElementRef;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;

  startDragging(e: MouseEvent, slider: HTMLElement): void {
    this.mouseDown = true;
    this.startX = e.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
    this.scrollService.setMouseDown(true);
  }

  stopDragging(): void {
    this.scrollService.setMouseDown(false);
  }

  moveEvent(e: MouseEvent, slider: HTMLElement): void {
    if (!slider) {
      return;
    }

    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - slider.offsetLeft;
    const scroll = (x - this.startX) * 0.5;
    slider.scrollLeft = this.scrollLeft - scroll;

    // Notify the scroll service of the new scroll left
    this.scrollService.setScrollLeft(slider.scrollLeft);
  }

  lastTapTime: number = 0;

  handleTap(event: TouchEvent, row: any) {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - this.lastTapTime;

    if (tapLength < 500 && tapLength > 0) {
      // Double tap detected
      this.clicca(row);
    }

    this.lastTapTime = currentTime;
  }

  @ViewChild('scrollingDiv') scrollingDiv!: ElementRef;
  @ViewChild('tableHeader') tableHeader!: ElementRef;
  @ViewChild('tableHeader2') tableHeader2!: ElementRef;

  onScroll(event: any) {
    this.scrollService.emitScrollEvent(event.target.scrollTop);
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    if (scrollTop > 50) {
      this.head = true;
    } else {
      this.head = false;
    }
    if (scrollTop + offsetHeight + 10 >= scrollHeight) {
      // this.displayCount += 10;
      this.scrollService.incrementDisplayCount();
    }
  }
}
