

<div class="grid grid-cols-1 sm:grid-cols-2 full-height-container">
  <div class="items-center grid">
    <div>
      <div class="flex justify-center h-[300px]">
        <img
          src="../../assets/images/logo.png"
          alt="Logo"
          style="width: 300px; height: 300px"
        />
      </div>

      <div class="container" style="margin-top: 100px !important">
        <div class="card-container">
          <div>
            <div>
              <span>
                <p>Recupera password</p>
              </span>
            </div>

            <form [formGroup]="recoveryForm" class="example-form grid">
              <div class="input-container">
                <div class="mb-[60px]">
                  <div class="mb-[10px] mt-10">
                    <label>
                      <p>Email</p>
                    </label>
                  </div>
                  <stdout-text-input
                    class="bordo w-[408px] mobile"
                    formControlName="email"
                    type="email"
                    placeholder="Email"
                  >
                  </stdout-text-input>
                </div>
              </div>
              <stdout-button
                class="btn-blu w-[408px] h-[52px] mobile"
                size="large"
                color="white"
                (click)="recovery()"
                type="submit"
              >
                <h2>Invia Email</h2>
              </stdout-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nascondi">
    <img
      src="../../assets/images/ret.png"
      alt=""
      style="fill: #0b0e42; opacity: 0.5 rgba(11, 14, 66); flex-shrink: 0"
    />
  </div>

  <!-- Aggiungi altre colonne se necessario -->
</div>
