import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public hide1 = true;
  public hide2 = true;
  public checked = false;

  constructor(private authService: AuthService, private router: Router) {
    this.registerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$'
        ),
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.confirmPasswordValidator,
      ]),
    });
  }

  ngOnInit(): void {}

  confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    if (!this.registerForm) {
      return null;
    }
    return control.value !== this.registerForm.controls['password']
      ? { confirmPassword: { value: control.value } }
      : null;
  };

  register() {
    let user = {
      email: this.registerForm.controls['email'].value,
      password: this.registerForm.controls['password'].value,
      passwordConfirm: this.registerForm.controls['confirmPassword'].value,
    };

    this.authService.register(user).subscribe({
      next: (v) => this.router.navigate(['auth/login']),
      error: (e) => console.error(e),
      complete: () => console.info('complete'),
    });
  }
}
