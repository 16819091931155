  <app-header></app-header>
  <div class="tutto relative mb-[30vh]">

    <div (click)="showPop = false" class="absolute left-[50%] right-[50%]  bg-black bg-opacity-50  z-[9999999] items-center justify-center flex" *ngIf="showPop">
    
      <div class="pop h-fit   " *ngIf="showPop" [ngClass]="{'pop-non-opaco': showPop}" (click)="$event.stopPropagation()">
        <div class="tit mt-5 ">
          <h3>{{"conferma_eliminazione"|transloco}}</h3>
        </div>
        <div class="frase mt-5 px-[52px] ">
          <p>{{"eliminazione_op"|transloco}}</p>
        </div >
        <div class=" flex mb-5 mt-10 px-5" index="index">
        
          <div  class=" mr-[5px]  w-[200px]  cursor-pointer  ">
      
          <stdout-button class="btn-bianco" (click)="annulla()"  size="large" color="white"><h2>{{"annulla"|transloco}}</h2></stdout-button>
        </div>
        <div  class=" ml-[5px]  w-[200px]  cursor-pointer  "  >
          
          <stdout-button class="btn-blu" (click)="deleteOperator()"   size="large" color="white"><h2>{{"elimina_account"|transloco}}</h2></stdout-button>
        </div>
  
      </div>
      </div>
  
    </div>




  <div class=" tutto-2 " [class.opacity-50]="showPop==true">
  <div class=" mt-10 px-10 grid grid-cols-2 ">
      <div class=" ">
          <div class="flex  items-stretch " *ngIf="!isPhone">
            <stdout-icon-button class="lente" style="margin: 2px 10px 0px 0px;" size="small" background="secondary" color="white"
              borderColor="secondary" [icon]="faMagnifyingGlass">
            </stdout-icon-button>
            <div class="w-[300px] h-[44px]">
            <stdout-text-input class="search-bar" placeholder="Nome collaboratore..." [(ngModel)]="search" (keyup)="applyFilter()"></stdout-text-input>
          </div>
          </div>
        </div>
        <div class="">
          <div class="flex justify-end align-baseline   items-center ">
          <div class="mr-[15px] ">
              <h1>{{"aggiungi_operatore"|transloco}}</h1>
            </div>
            <div class=" cursor-pointer">
              <stdout-icon-button class="lente" (click)="visibile()"  [icon]="faPlus"></stdout-icon-button>
          
            </div> 
            </div>
            </div>
        </div>


      


        <div  class=" ml-10 mr-10 mt-10 prt2 hi font-tab smart-tab" *ngIf="operatori.length>0">




          <stdout2-table  class="op font-tab" [data]="operatori" >
            <span stdoutTableColumn label="id" property="id" *ngIf="showId" >
              <ng-template #dataTableCell let-item="item" let-index="index" >
              
                <div class="font" *ngIf="showInput && (operatori.length -1 ) === index " >
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="firstName" placeholder="Nome"></stdout-text-input>
                </div>
                <div *ngIf="(operatori.length -1) !== index || showInput === false">
                  {{item.id}}
                  
                </div>
              </ng-template>
            </span>
        
            <span stdoutTableColumn label="NOME" property="Nome" >
              <ng-template #dataTableCell let-item="item" let-index="index" >
                <div (dblclick)="modifica(item.id)">
                <div class="font" *ngIf="showInput && (operatori.length -1 ) === index ">
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="firstName" placeholder="Nome"></stdout-text-input>
                </div>
                <div class="font" *ngIf="showInput2 && item.id == idToModify">
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="firstNameModified" placeholder="Nome"></stdout-text-input>
                </div>
                <div *ngIf="item.id !== idToModify || showData">
                
                  {{item.Nome}}
                
                </div>
              </div>
              </ng-template>
            </span>
            <span stdoutTableColumn label="COGNOME" property="Cognome"  >
              <ng-template #dataTableCell let-item="item" let-index="index" >
                <div (dblclick)="modifica(item.id)">
              
                <div *ngIf="showInput && (operatori.length -1 ) === index "  >
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="lastName" placeholder="Cognome"></stdout-text-input>
                </div>
                <div class="font" *ngIf="showInput2 && item.id == idToModify">
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="lastNameModified" placeholder="Cognome"></stdout-text-input>
                </div>
                <div *ngIf="item.id !== idToModify || showData">
                  {{item.Cognome}}
                  
                </div>
                </div>
              </ng-template>
            </span>
            <span stdoutTableColumn label="EMAIL" property="Email" >
              <ng-template #dataTableCell let-item="item" let-index="index">
              
                <div *ngIf="showInput && (operatori.length -1 ) === index ">
                  <stdout-text-input class="fix-tab-text" type="text" [(ngModel)]="email" placeholder="Email"></stdout-text-input>
                </div>
                <div *ngIf="(operatori.length -1) !== index || showInput === false">
                  {{item.Email}}
                  
                </div>
              </ng-template>
            </span>
            <span stdoutTableColumn label="RUOLO" property="Ruolo" >
              <ng-template #dataTableCell let-item="item" let-index="index">
              
                <div *ngIf="showInput && (operatori.length -1 ) === index " >
                  {{item.Ruolo}}
                </div>
                <div *ngIf="(operatori.length -1) !== index || showInput === false">
                  {{item.Ruolo}}
                  
                </div>
              </ng-template>
            </span>
            <span stdoutTableColumn label="ELIMINA" property= "icona">
              <ng-template #dataTableCell let-item="item" let-index="index">
                <div  class="flex items-center justify-center gap-x-4">
                <div *ngIf="showBtn||  (item.id !== idToModify && (!showInput || showInput2) )  ||  idToModify!==index && !showBtn2 && (operatori.length -1) !== index "
                >
              
          
                  <stdout-icon-button (click)="elimina(item.id)" class="tab-icon  cursor-pointer"  color="primary" borderColor="primary" background="white" size="small"
                    [icon]="faTrashCan"></stdout-icon-button>
                
                </div>
                <div *ngIf="  showInput && (operatori.length -1 ) === index">
                  <stdout-icon-button class="tab-icon cursor-pointer" (click)="createOperator()"  color="primary" borderColor="primary" background="white" size="small"
                  [icon]="faCheck"></stdout-icon-button>
                </div>
                
                <div *ngIf="item.id === idToModify  && (operatori.length ) !== index "  >
                  
                  <stdout-icon-button (click)="modifyOperator(index)" class="tab-icon  cursor-pointer"  color="primary" borderColor="primary" background="white" size="small"
                    [icon]="faCheck"></stdout-icon-button>
                
                </div>
              </div>
            
            
              </ng-template>
        </span>
      
        
      
          </stdout2-table>
        </div>
        

  
        
        
        <div class="mt-10 flex justify-center" *ngIf="operatori.length>0">
          <stdout-pagination [current]="page" [total]="totalPages" (goTo)="getPage($event)" (next)=" goNext($event)"
          (previous)="goBack($event)"></stdout-pagination>
        </div>
      </div>

      </div>


     