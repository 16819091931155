import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { language } from '../../../assets/constant';
import { AuthService } from '@services/auth.service';
import { Breadcrumb } from '@stdoutsrl/ui-kit';
import { Observable, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestService } from '@services/rest.service';
import { Router, RouterLink } from '@angular/router';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { StatoUtenteService } from '@services/stato-utente.service';
import { MessageService } from '@services/message.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  passwordForm: FormGroup;
  languageList = language;
  cardChangePassword = false;
  cardChangeSuccess = false;
  breadcrumbs = breadcrumbs;
  changePasswordForm!: FormGroup;
  languageForm!: FormGroup;
  oldPassword: string = '';
  newPassword: string = '';

  newPasswordConfirm: string = '';
  passwordError: string = '';
  showPop: boolean = false;
  step1: boolean = false;
  step2: boolean = false;
  public faCircleCheck = faCircleCheck;
  public faCircleXmark = faCircleXmark;
  succToast: boolean = false;
  failToast: boolean = false;
  failToastPass: boolean = false;
  public hide = true;
  public hide2 = true;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  nome: string = '';
  cognome: string = '';
  originalNome: string = '';
  originalCognome: string = '';
  email: string = '';

  userinfo: any = [];
  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private transloco: TranslocoService,
    private rest: RestService,
    private router: Router,
    private statoUtenteService: StatoUtenteService,
    private message: MessageService
  ) {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,32}$'
        ),
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.confirmPasswordValidator,
      ]),
    });
  }
  confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const password = control.root.get('password');
    const confirmPassword = control.root.get('confirmPassword');

    if (!password || !confirmPassword) {
      return null;
    }

    return password.value !== confirmPassword.value
      ? { confirmPassword: true }
      : null;
  };
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  async ngOnInit() {
    this.statoUtenteService.userInfo();

    this.statoUtenteService.id$.subscribe((id) => (this.id = id));
    this.statoUtenteService.nome$.subscribe(
      (nome) => ((this.nome = nome), (this.originalNome = nome))
    );
    this.statoUtenteService.cognome$.subscribe(
      (cognome) => ((this.cognome = cognome), (this.originalCognome = cognome))
    );
    this.statoUtenteService.email$.subscribe((email) => (this.email = email));

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['', Validators.required],
    });
    this.languageForm = this.formBuilder.group({
      language: [localStorage.getItem('language'), Validators.required],
    });
  }

  changeLanguage() {
    if (this.languageForm.value.language != '') {
      this.transloco.setActiveLang(this.languageForm.value.language);
      localStorage.setItem('language', this.languageForm.value.language);
    }
  }

  logout() {
    this.auth.logOut().subscribe({
      complete: () => {
        localStorage.removeItem('token');
        location.replace('auth/login');
      },
    });
  }

  visibile() {
    this.step1 = true;
  }

  visibile2() {
    this.step1 = false;
    this.step2 = true;
  }

  elimina() {
    this.showPop = true;
  }
  chiudiPop() {
    this.showPop = false;
  }

  id!: number;

  deleteAccount() {
    this.auth.deleteMe().subscribe({
      next: (response: any) => {
        this.showPop = false;
        this.router.navigate(['/auth/login']);
      },
      error: (error: any) => {
        console.error('Impossibile eliminare account, riprovare');
      },
    });
  }

  modifyAccount() {
    return this.auth.modifyData(this.nome, this.cognome).subscribe(
      (response: any) => {
        this.statoUtenteService.userInfo().subscribe();
      },
      (error: any) => {
        console.error('Errore durante la creazione dell ente:', error);
      }
    );
  }

  exitFromCard() {
    this.cardChangePassword = false;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.step1 === true) {
      this.step1 = false;
      this.step2 = false;
      this.oldPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.passwordForm.reset();
    }
    if (this.step2 === true) {
      this.step1 = false;
      this.step2 = false;
      this.oldPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.passwordForm.reset();
    }
  }

  /*changePassword2() {
    this.auth.changePassword(this.changePasswordForm.value).subscribe({
      error: (error: any) => {
        if (error.status == 401) {
          location.replace('auth/login');
        }
      },
      complete: () => {
        this.cardChangePassword = false;
        this.cardChangeSuccess = true;
      },
    });
  }
*/

  changePassword(): void {
    this.step1 = false;
    this.step2 = false;
    this.verifychangePassword();
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.passwordForm.reset();
  }

  verifychangePassword() {
    if (this.passwordForm.valid) {
      const newPassword = this.passwordForm.value.password;
      const confirmNewPassword = this.passwordForm.value.confirmPassword;

      if (newPassword === confirmNewPassword) {
        this.auth
          .changePassword(this.oldPassword, newPassword, confirmNewPassword)
          .subscribe(
            () => {
              this.showMessage('Password cambiata con successo', 'success');
            },
            (error: any) => {
              this.showMessage(error.error.message, 'error');
            }
          );
      } else {
        this.showMessage('Le due password non coincidono', 'error');
      }
    } else {
      this.showMessage('La password non rispetta le validazioni', 'error');
    }
  }
}

const breadcrumbs: Breadcrumb[] = [];
