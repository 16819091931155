import { Component, HostListener, OnInit } from '@angular/core';
import { BanchedatiService } from '@services/banchedati.service';
import { SelectionService } from '@services/selection.service';
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { MessageService } from '@services/message.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Component({
  selector: 'app-enti',
  templateUrl: './enti.component.html',
  styleUrls: ['./enti.component.scss'],
})
export class EntiComponent implements OnInit {
  loader = false;
  nome: string = '';
  totalPages: number = 27;
  page: number = 1;
  enti: any = [];
  isScrollable: boolean = false;
  changeId: number[] = [];
  perTeId: number[] = [];
  isPhone: any;
  public faMagnifyingGlass = faMagnifyingGlass;
  nomeCerca: string = '';
  modal: boolean = false;
  public faTrash = faTrash;
  public faPencil = faPencil;
  nomeEnte: string = '';
  idEnte: string = '';

  modalDelete: boolean = false;
  constructor(
    private banchedati: BanchedatiService,
    private selectionService: SelectionService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.paginateEnti();
  }

  applyFilter() {
    this.paginateEnti();
  }

  log() {
    console.log(this.nomeEnte);
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateEnti();
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateEnti();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateEnti();
  }

  paginateEnti() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .paginateEnte(this.page - 1, this.nomeCerca)
      .subscribe(
        (response: any) => {
          this.totalPages = response.totalPages;
          this.enti = response.data.map((item: any) => {
            return {
              id: item.id,
              nome: item.nome === '' ? 'ente vuoto' : item.nome,
            };
          });
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          console.error(
            'Errore durante il recupero delle informazioni ',
            error
          );

          LoaderComponent.isLoading = false;
        }
      );
  }

  showModal(id: number, nome: string) {
    this.modal = !this.modal;
    this.nomeEnte = nome;
    this.idEnte = id.toString();
  }

  showModalDelete(id: number) {
    this.modalDelete = !this.modalDelete;

    this.idEnte = id.toString();
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  modifyEnte() {
    LoaderComponent.isLoading = true;
    // const formData: FormData = new FormData();

    // formData.append('nome', this.nomeEnte.toString());

    return this.banchedati.modifyEnte(this.idEnte, this.nomeEnte).subscribe(
      (response: any) => {
        LoaderComponent.isLoading = false;
        this.paginateEnti();
        this.modal = !this.modal;
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error('Errore durante la creazione della materia:', error);
      }
    );
  }

  deleteEnte() {
    LoaderComponent.isLoading = true;

    this.banchedati.deleteEnte(Number(this.idEnte)).subscribe(
      (response) => {
        this.paginateEnti();
        this.modal = false;
        this.modalDelete = false;
        LoaderComponent.isLoading = false;
      },
      (error) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error(
          `Errore durante l'eliminazione del bando con ID ${this.idEnte}:`,
          error
        );
      }
    );
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.modal) {
      this.modal = false;
    }
    if (this.modalDelete) {
      this.modalDelete = false;
    }
  }
}
