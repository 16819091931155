import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { faCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BandoService } from '@services/bando.service';
import { ImageServiceService } from '@services/image-service.service';
import { Subscription } from 'rxjs';
import { api, environment } from '@environments/environment';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'app-consigliati',
  templateUrl: './consigliati.component.html',
  styleUrls: ['./consigliati.component.scss'],
})
export class ConsigliatiComponent implements OnInit {
  public faCirclePlus = faCirclePlus;
  public faPlus = faPlus;
  allTranslation: any = false;
  title: string = '';
  description: string = '';
  link: string = '';
  selectedFile: any = null;
  selectedFileModify: any = null;
  idToModify: number | null = null;
  modifichiamo: boolean = true;
  consigliati: any[] = [];
  modifyTitle: string = '';
  modifyDescription: string = '';
  modifyLink: string = '';
  modifyPosizione: string = '';
  modificato: boolean = false;
  showRiquadro = true;
  showRiquadro2 = true;
  Riquadretto = false;
  idToReset: number | null = null;
  page: number = 1;
  totalPages: number = 1;
  idToDelete: number | null = null;
  showModal: boolean = false;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput2!: ElementRef;
  showForm: boolean = false;
  public showImage: boolean = false;
  public imageUrl: any;
  public selectedCardIndices: (number | null)[] = [];
  percorso: string = `${api.baseUrl}public/consigliati/`;
  private subscription: Subscription = new Subscription();

  constructor(
    private translocoService: TranslocoService,
    private bandoService: BandoService,
    private imageService: ImageServiceService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.paginateData();
    this.updateImageUrl();
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = 'default-image-url';
        }
        this.selectedFile = image;

        this.showRiquadro = false;
      })
    );
    this.subscription.add(
      this.imageService.getMostra().subscribe((mostra) => {
        this.showModal = mostra;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);
  }

  get consigliatiOrdinati() {
    return this.consigliati.sort((a, b) => a.posizione - b.posizione);
  }

  updateImageUrl(): void {
    this.showRiquadro = false;
    this.imageUrl = this.imageService.getCroppedImage().toString();
    this.selectedFile = this.imageService.getCroppedImage();
  }

  onButtonClick() {
    if (this.fileInput) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
  }

  onButtonClickModify() {
    if (this.fileInput2) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result as string;
      };
      reader.readAsDataURL(input.files[0]);
      this.showRiquadro = false;
    }

    this.selectedFile = event.target.files[0];

    this.imageService.setSelectedFile(this.selectedFile);
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('titolo', this.title.toString());
    formData.append('descrizione', this.description.toString());
    formData.append('link', this.link.toString());
    if (this.selectedFile) {
      const fileExtension = 'png';
      formData.append('cover', this.selectedFile, `cover.${fileExtension}`);
    }
    if (
      this.title.trim() !== '' &&
      this.description.trim() !== '' &&
      this.selectedFile
    ) {
      this.bandoService.createBando(formData).subscribe(
        (response) => {
          this.showForm = false;
          this.title = '';
          this.description = '';
          this.link = '';
          this.imageUrl = '';
          this.selectedFile = null;
          this.imageService.setCroppedImage(null);
          this.imageService.setSelectedFile(new File([], ''));
          this.paginateData();
        },
        (error) => {
          this.showMessage(error.error.message, 'error');
        }
      );
    } else {
      this.showMessage('Selezionare un immagine', 'error');
    }
  }

  onFileSelectedModify(event: any) {
    this.selectedFileModify = event.target.files[0];

    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result as string;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  onSubmitModify(id: number) {
    console.log(this.selectedFile);
    const formData = new FormData();
    formData.append('titolo', this.modifyTitle.toString());
    formData.append('descrizione', this.modifyDescription.toString());
    formData.append('link', this.modifyLink.toString());
    formData.append('posizione', this.modifyPosizione);
    if (this.selectedFile) {
      const fileExtension = 'png';
      formData.append('cover', this.selectedFile, `cover.${fileExtension}`);
    }
    if (
      this.modifyTitle.trim() !== '' ||
      this.modifyDescription.trim() !== '' ||
      this.selectedFile
    ) {
      this.bandoService.modifyBando(id, formData).subscribe(
        (response) => {
          this.selectedFile = null;
          this.imageService.setCroppedImage(null);

          this.paginateData();
          this.showForm = false;
          this.showRiquadro = !this.showRiquadro;
          this.modificato = !this.modificato;
          this.modifichiamo = !this.modifichiamo;
          this.idToModify = null;
          this.showMessage('Modifica avvenuta con successo', 'error');
        },
        (error) => {
          console.error('Errore durante la modifica del bando:', error);
        }
      );
    } else {
      console.error(
        'Assicurati di compilare tutti i campi e selezionare un file.'
      );
    }
  }

  modifica(
    id: number,
    titolo: string,
    descrizione: string,
    link: string,
    url: string,
    posizione: string
  ) {
    this.showRiquadro = false;
    this.modificato = !this.modificato;
    this.idToModify = id;
    this.modifyTitle = titolo;
    this.modifyDescription = descrizione;
    this.modifyLink = link;
    this.modifyPosizione = posizione;
    this.modifichiamo = !this.modifichiamo;
  }

  areFieldsValid(): any {
    if (
      this.selectedFile === '' ||
      this.title === '' ||
      this.description === ''
    ) {
      return false;
    } else {
      return true;
    }
  }

  changeLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
  }

  public selezionaFile(idx: number) {
    const fileInput = document.getElementById(
      `fileInput-${idx}`
    ) as HTMLInputElement;
    fileInput.click();
  }

  openFileInput(idx: number) {
    const fileInput = document.getElementById(
      `fileInput-${idx}`
    ) as HTMLInputElement;
    fileInput.click();
  }

  public aggiungiNews() {
    if (this.consigliati.length === 10) {
      alert('Limite massimo di news raggiunto');
    } else {
      this.showForm = true;
      this.title = '';
      this.description = '';
      this.link = '';
      this.imageUrl = '';
      this.showRiquadro = true;
    }
  }

  isLastIndex(index: number): boolean {
    return index === this.consigliati.length - 1;
  }

  paginateData(page: number = 0) {
    this.bandoService.paginateConsigliato(page).subscribe(
      (data: any) => {
        this.consigliati = data.map((item: any) => ({
          id: item.id,
          Titolo: item.titolo,
          Cover: item.cover,
          Descrizione: item.descrizione,
          Link: item.link,
          posizione: item.posizione,
        }));
      },
      (error: any) => {
        console.error('Errore durante il recupero dei dati consigliati', error);
      }
    );
  }

  deleteBando(id: number) {
    this.idToDelete = id;
    if (this.idToDelete !== null) {
      this.bandoService.deleteBando(this.idToDelete).subscribe(
        (response) => {
          this.consigliati = this.consigliati.filter(
            (consigliati) => consigliati.id !== this.idToDelete
          );

          this.paginateData();
        },
        (error) => {
          console.error(
            `Errore durante l'eliminazione del bando con ID ${this.idToDelete}:`,
            error
          );
        }
      );
    }
  }

  show(event: MouseEvent) {
    this.showModal = true;
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateData();
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateData();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateData();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.showModal === true) {
      this.showModal = false;
      this.imageService.setMostra(false);
    }
  }
}
