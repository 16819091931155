import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { api } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const refreshToken = localStorage.getItem('refreshToken');
    const user = this.authenticationService.currentUserValue;
    const isLoggedIn = user && localStorage.getItem('token');
    const isApiUrl = request.url.startsWith(api.baseUrl);
    const isRefreshTokenUrl = request.url.endsWith('auth/refresh');
    const isLoginUrl = request.url.endsWith('user/me');

    if (isLoggedIn && isApiUrl && !isRefreshTokenUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `${api.prefixAuthorization} ${localStorage.getItem(
            'token'
          )}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          isLoggedIn &&
          !isRefreshTokenUrl &&
          isLoginUrl
        ) {
          return this.authenticationService.refreshToken().pipe(
            switchMap((response: any) => {
              const newToken = response.token;
              const newRefreshToken = response.refreshToken;

              if (newToken) {
                localStorage.setItem('token', newToken);
                localStorage.setItem('refreshToken', newRefreshToken);

                const newRequest = request.clone({
                  setHeaders: {
                    Authorization: `${api.prefixAuthorization} ${newToken}`,
                  },
                });
                window.location.reload();
                return next.handle(newRequest);
              } else {
                this.router.navigate(['/auth/login']);
                return throwError(error);
              }
            }),
            catchError((refreshError: HttpErrorResponse) => {
              if (refreshError.status) {
                this.router.navigate(['/auth/login']);
              }

              return of({} as HttpEvent<any>);
            })
          );
        } else if (error.status === 401 && isLoginUrl) {
          this.router.navigate(['/auth/login']);
          return of({} as HttpEvent<any>);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
