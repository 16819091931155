<app-header></app-header>

<div class="mt-5 md:mt-10 px-5 md:px-10 relative">
  <div (click)="showPop = false" class="absolute left-[50%] right-[50%] top-[160px] bg-black bg-opacity-50  z-[9999999] items-center justify-center flex " *ngIf="showPop">
    <div class="pop h-fit  " *ngIf="showPop" [ngClass]="{'pop-non-opaco': showPop}" (click)="$event.stopPropagation()">
      <div class=" mt-5 flex justify-center">
        <h1>{{"Conferma_el_acc"|transloco}}</h1>
      </div>
      <div class=" mt-5  flex justify-center items-center px-[52px] ">
        <p>{{"sei_sicuro_el"|transloco}} </p>
      </div >
      <div class="flex items-center ">
        <div  class="mb-5 mt-10 ml-[20px] mr-[10px]  w-[200px]    cursor-pointer">
          <stdout-button class="btn-bianco" (click)="chiudiPop()" size="large" color="white"><h2>{{"Annulla"|transloco}}</h2></stdout-button>
        </div>
        <div  class="mb-5 mt-10   w-[200px]  mr-[20px]  cursor-pointer ">
          <stdout-button class="btn-blu" size="large" (click)="deleteAccount()" color="white"><h2>{{"Conferma"|transloco}}</h2></stdout-button>
      </div>
    </div>
    </div>
    </div>
 
  <stdout-card  [class.opacity-50]="step1 || step2 || showPop==true">
    <stdout-card-header class="card-header"><h3>Dati Personali</h3></stdout-card-header>
    <stdout-card-content>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        <div class=" lab2 mr-[25px]" >
          <label><h3>Nome</h3></label>
          <div class="mt-[10px]">
          <stdout-text-input class="bordo" type="text" [(ngModel)]="nome"></stdout-text-input>
        </div>
        </div>
        <div class=" lab2 mr-[25px] mt-3 md:mt-0" >
          <label><h3>Cognome</h3></label>
          <div class="mt-[10px]">
          <stdout-text-input class="bordo" type="text" [(ngModel)]="cognome"></stdout-text-input>
        </div>
        </div>
        <div class="lab2 mr-[25px] mt-3 md:mt-0 " >
          <label><h3>Email</h3></label>
          <div class="mt-[10px]">
          <stdout-text-input class="bordo" type="text"  [value]="email" [disabled]="true"></stdout-text-input>
        </div>
        </div>
      </div>
      <div class="flex mt-[53px] justify-end items-center sott flex-wrap">
        <div class="mr-10 mb-3 md:mb-0  cursor-pointer"   >
          <div class="text" (click)="visibile()" (click)="$event.stopPropagation()">
        <label><p>Modifica Password</p></label>
      </div>
      </div>
      <div class="mr-10 mb-3 md:mb-0 ">
        <div class=" cursor-pointer text    " (click)="elimina()">
        <label ><p>Elimina Account</p></label>
      </div>
        </div>
        <div class="md:mr-[25px] w-[200px] mb-3 md:mb-0  cursor-pointer">
        <stdout-button class="btn-bianco" (click)="logout()"  size="large" color="white"><h2>{{"logout"|transloco}}</h2></stdout-button>
      </div>
      <div class="w-[200px]  cursor-pointer">
        <stdout-button class="btn-blu w-full md:w-[200px] " 
        [disabled]="nome === originalNome && cognome === originalCognome " 
        [class.opacity-50]="nome === originalNome && cognome === originalCognome " 
        size="large" color="white"  
        (click)="modifyAccount()">
        <h2>{{"salva"|transloco}}</h2>
      </stdout-button>
        </div>
      </div>
      
    </stdout-card-content>
  </stdout-card>
  <div *ngIf="step1"  class="mt-10 rec-pass lab2 " (click)="$event.stopPropagation()">
   <stdout-card-content>
    <label for="1"><h3>Inserisci vecchia password</h3></label>
    <div class="flex items-end w-full">
      
      <div class="flex ">
    <div class="mt-[10px] md:max-w-[373px] md:min-w-[300px] ">
      <stdout-text-input id="1" class="bordo" [type]="hide ? 'password':'text'" id="oldPassword" [(ngModel)]="oldPassword" [placeholder]="'Inserisci la vecchia password'"></stdout-text-input>
    </div>
    <div class="fix-login-icona ">
      <stdout-icon-button class="pass log-icon-user" [icon]="hide ? faEye : faEyeSlash "  (click)="hide = !hide" ></stdout-icon-button>
    </div>
  </div>
    <div class=" w-[200px] h-full  cursor-pointer" >
      <stdout-button class="btn-blu" (click)="visibile2()" [disabled]="oldPassword===''" [class.opacity-50]="oldPassword===''" (click)="$event.stopPropagation()"  size="large" color="white"><h2>{{"Prossimo"|transloco}}</h2></stdout-button>
    </div>
  </div>
</stdout-card-content>
</div>
<form [formGroup]="passwordForm" (ngSubmit)="changePassword()" >
<div  *ngIf="step2" class="md:flex mt-10  items-end  rec-pass lab2" (click)="$event.stopPropagation()">

  <div  >
<label><h3>Inserisci la nuova Password</h3></label>
<div class="flex w-full h-full">
  <div class="mt-[10px] md:max-w-[373px] md:min-w-[300px]">
    <stdout-text-input class="bordo " isErrorMessage="Lunghezza minima 8 caratteri. La password deve contenere almeno una
    maiuscola, una minuscola, un numero ed un carattere speciale"
        [isError]="passwordForm.controls['password'].hasError('pattern')" formControlName="password"
        [type]="hide2 ? 'password':'text'" placeholder="Inserisci la nuova password">
      </stdout-text-input>
  </div>
  <div class="fix-login-icona">
    <stdout-icon-button class="pass log-icon-user" [icon]="hide2 ? faEye : faEyeSlash "  (click)="hide2 = !hide2" ></stdout-icon-button>
  </div>
</div>
</div>

  <div  class="md:ml-[25px] mt-3 md:mt-0">
    <label><h3>Ripeti la nuova Password</h3></label>
    <div class="flex">
      <div class="mt-[10px] md:max-w-[373px] md:min-w-[300px] ">
        <stdout-text-input class="bordo" isErrorMessage="Le due password non coincidono"
        [isError]="passwordForm.controls['confirmPassword'].value !== passwordForm.controls['password'].value"
        formControlName="confirmPassword" [type]="hide2 ? 'password':'text'"
        placeholder="Ripeti la nuova password" >
      </stdout-text-input>
      </div>
      <div class="fix-login-icona">
        <stdout-icon-button class="pass log-icon-user" [icon]="hide2 ? faEye : faEyeSlash "  (click)="hide2 = !hide2" ></stdout-icon-button>
      </div>
    </div>
</div>

<div class="mt-3 md:mt-[10px] md:w-[200px]  cursor-pointer  md:ml-10">
  <stdout-button class="btn-blu md:h-max-[45px]" (click)="changePassword()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
</div>

</div>
</form>

</div>


