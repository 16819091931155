<div [class.opacity-50]="modal == true || loader || modalDelete" >
    <app-header></app-header>
    
    <div class=" mt-10 px-10 mb-[30vh]">
        <div class="py-10 gap-5 flex flex-wrap items-center justify-start">
            <div
            class="flex input-container justify-start items-center"
            *ngIf="!isPhone"
          >
            <stdout-icon-button
              class="lente items-center  cursor-pointer"
              style="margin: 2px 10px 0px 0px"
              size="small"
              background="secondary"
              color="white"
              borderColor="secondary"
              [icon]="faMagnifyingGlass"
            >
            </stdout-icon-button>
            <div class="w-[300px]">
              <stdout-text-input
                class="search-bar"
                placeholder="Cerca banca dati..."
                [(ngModel)]="nomeCerca"
                (keyup)="applyFilter()"
              ></stdout-text-input>
            </div>
          </div>
          </div>
     
      <div class="prt2 hi font-tab smart-tab" (click)="$event.stopPropagation()">
      <stdout2-table  class="op font-tab" [data]="anni" >
     
    
        <span stdoutTableColumn label="NOME" property="Nome" >
          <ng-template #dataTableCell let-item="item" let-index="index" >
            <div >
          
            <div >
            
              {{item.nome}}
            
            </div>
          </div>
          </ng-template>
        </span>
      
      
      
        <span stdoutTableColumn label="AZIONI" property= "icona">
          <ng-template #dataTableCell let-item="item" let-index="index">
            <div  class="flex items-center justify-center gap-x-10">
      
     
                <div  >
                  
                  <stdout-icon-button (click)="showModalDelete(item.id)"  class="tab-icon  cursor-pointer"  color="primary" borderColor="primary" background="white" size="small"
                    [icon]="faTrash"></stdout-icon-button>
                
                </div>
              </div>
        
        
          </ng-template>
    </span>
    
    
    
      </stdout2-table>
    </div>
      <div class="mt-10 flex justify-center" *ngIf="anni.length > 0">
        <stdout-pagination
          [current]="page"
          [total]="totalPages"
          (goTo)="getPage($event)"
          (next)="goNext($event)"
          (previous)="goBack($event)"
      
        ></stdout-pagination>
      </div>
    </div>
    </div>
    
    

    
    <div class="absolute top-[5%] w-[88.489%] flex justify-center items-center">
        <div class="w-fit h-full min-w-[500px]">
          <app-toast
            colore="#DF4343"
            [testo]="messErr"
            [showToast]="showToast"
          ></app-toast>
        </div>
      </div>
    
      <div
      class="absolute top-1/2 left-1/2 right-1/2 flex justify-center items-center"
      *ngIf="loader"
    >
      <div class="w-full h-full">
        <app-loader> </app-loader>
      </div>
    </div>

    <!-- modale elimina -->
<div
class="absolute top-[20%] left-[40%]"
*ngIf="modalDelete"
(click)="$event.stopPropagation()"
>
<app-modale
  title="Conferma eliminazione Profilo"
  content="Sei sicuro di volere eliminare definitivamente gli elementi selezionati?"
  [width]="504"
>
  <div class="flex justify-center items-center mt-10">
    <div class="w-full mr-[5px]  cursor-pointer">
      <stdout-button
        class="btn-bianco"
        (click)="modalDelete = false"
        size="large"
        color="white"
        ><h2>{{ "annulla" | transloco }}</h2></stdout-button
      >
    </div>
    <div class="w-full ml-[5px]  cursor-pointer">
      <stdout-button
        class="btn-blu"
        (click)="deleteAnno()"
        size="large"
        color="white"
        ><h2>{{ "elimina_selezionati" | transloco }}</h2></stdout-button
      >
    </div>
  </div>
</app-modale>
</div>