import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {
  faMagnifyingGlass,
  faPencil,
  faTrashCan,
  faPlus,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { constant } from '@assets/constant';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { UserComponent } from '../user/user.component';
import { PagesComponent } from '../pages.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'app-collaboratori',
  templateUrl: './collaboratori.component.html',
  styleUrls: ['./collaboratori.component.scss'],
})
export class CollaboratoriComponent implements OnInit, OnChanges {
  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private message: MessageService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.operatorInfo();
  }

  isPhone: any;
  search: string = '';
  public faMagnifyingGlass = faMagnifyingGlass;
  public faPlus = faPlus;
  arrayTest = constant.arrayTest;
  public faPencil = faPencil;
  public faTrashCan = faTrashCan;
  public faCheck = faCheck;
  tableRefresh = true;
  showPop: boolean = false;
  roleId: number = 0;
  operatorForm: any;
  showId: boolean = false;
  showInput2: boolean = false;
  modificato: boolean = false;
  messErr: string = '';

  showText: boolean = true;
  idToDelete: number | null = null;
  idToModify: number | null = null;
  userinfo: any = [];
  showInput = false;
  showBtn = true;
  usersinfo: any = [];
  operatori: any[] = [];
  page: number = 1;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  password: string = 'Operatore';
  firstNameModified: string = '';
  lastNameModified: string = '';
  showData: boolean = true;
  showEditTableUser: boolean = false;
  showBtn2: boolean = false;
  totalPages: number = 1;

  // funzione per la ricerca
  applyFilter() {
    this.operatorInfo();
  }
  // blocco paginazione
  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.operatorInfo();
  }
  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.operatorInfo();
  }
  getPage(page: number) {
    this.page = page;
    this.operatorInfo();
  }

  operatorInfo() {
    return this.auth.paginateOperator(this.search, this.page - 1).subscribe(
      (response: any) => {
        this.usersinfo = response.data;
        this.totalPages = response.totalPages;

        this.operatori = this.usersinfo.map((user: any) => ({
          id: user.id,
          Nome: user.firstName,
          Cognome: user.lastName,
          Email: user.email,
          Ruolo: 'Operatore', // oppure user.ruolo o qualsiasi altro valore corretto
        }));
        this.showInput = false;
        this.showBtn = true;
        this.idToModify = null;
        this.showData = true;
        this.showBtn2 = false;

        if (this.operatori.length === 0) {
          this.goBack(this.page);
        }
      },
      (error: any) => {
        console.error(
          'Errore durante il recupero delle informazioni degli operatori',
          error
        );
      }
    );
  }

  // blocco per la creazione, modifica ed eliminazione degli operatori
  visibile() {
    this.showInput = true;
    this.showBtn = false;
    this.operatori.push({
      Nome: this.firstName,
      Cognome: this.lastName,
      Email: this.email,
      Ruolo: this.password,
    });
    this.operatori = [...this.operatori];
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  createOperator() {
    if (!this.firstName || !this.lastName || !this.email) {
      console.error('firstName, lastName e email non possono essere vuoti');
      this.showMessage('Inserire nome cognome e email', 'error');
      return;
    }

    return this.auth
      .createOperator(this.email, this.password, this.firstName, this.lastName)
      .subscribe(
        (response: any) => {
          this.firstName = '';
          this.lastName = '';
          this.email = '';
          this.password = '';
          this.operatorInfo();
        },
        (error: any) => {
          console.error("Errore durante la creazione dell'operatore:", error);
        }
      );
  }

  elimina(id: number) {
    this.idToDelete = id;
    this.showPop = true;
  }

  modifica(id: number) {
    this.idToModify = id;
    this.showInput = false;
    this.showInput2 = true;
    this.showData = false;
    this.showBtn = false;
    this.showBtn2 = true;
  }

  deleteOperator() {
    if (this.idToDelete !== null) {
      this.auth.deleteOperator(this.idToDelete).subscribe(
        (response) => {
          this.operatori = this.operatori.filter(
            (operatore) => operatore.id !== this.idToDelete
          );
          this.operatorInfo();
        },
        (error) => {
          console.error(
            `Errore durante l'eliminazione dell'operatore con ID ${this.idToDelete}:`,
            error
          );
        }
      );
      this.showPop = false;
      this.idToDelete = null;
      this.operatori = [...this.operatori];
    }
  }

  modifyOperator(index: number) {
    if (this.idToModify !== null) {
      const currentFirstName = this.operatori[index].Nome;
      const currentLastName = this.operatori[index].Cognome;
      if (this.firstNameModified !== '' && this.lastNameModified !== '') {
        this.auth
          .modifyOperator(
            this.idToModify,
            this.firstNameModified,
            this.lastNameModified
          )
          .subscribe(
            (response) => {
              this.firstNameModified = '';
              this.lastNameModified = '';
              this.showInput2 = false;
              this.showData = true;
              this.showBtn2 = false;
              this.idToModify === null;
              this.operatorInfo();
            },
            (error) => {}
          );
      } else if (this.firstNameModified !== '') {
        this.auth
          .modifyOperator(
            this.idToModify,
            this.firstNameModified,
            currentLastName
          )
          .subscribe(
            (response) => {
              this.firstNameModified = '';
              this.lastNameModified = '';
              this.showInput2 = false;
              this.showData = true;
              this.idToModify === null;
              this.operatorInfo();
            },
            (error) => {}
          );
      } else if (this.lastNameModified !== '') {
        this.auth
          .modifyOperator(
            this.idToModify,
            currentFirstName,
            this.lastNameModified
          )
          .subscribe(
            (response) => {
              this.firstNameModified = '';
              this.lastNameModified = '';
              this.showInput2 = false;
              this.showData = true;
              this.idToModify === null;
              this.operatorInfo();
            },
            (error) => {}
          );
      } else if (
        this.firstNameModified !== '' &&
        this.lastNameModified !== ''
      ) {
        this.auth
          .modifyOperator(this.idToModify, currentFirstName, currentLastName)
          .subscribe(
            (response) => {
              this.firstNameModified = '';
              this.lastNameModified = '';
              this.showInput2 = false;
              this.showData = true;
              this.idToModify === null;
              this.operatorInfo();
            },
            (error) => {}
          );
      } else {
      }
    }
  }

  aggiungiNuovaRiga(index: number) {
    this.operatori[index] = {
      Nome: this.firstName,
      Cognome: this.lastName,
      Email: this.email,
      Ruolo: this.password,
    };
    this.operatori.push({
      Nome: '',
      Cognome: '',
      Email: '',
      Ruolo: 'Operatore',
    });
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = 'Operatore';
    this.operatorInfo();
    this.operatori = [...this.operatori];
    this.operatori.pop();
    this.operatori = [...this.operatori];
  }

  annulla() {
    this.showPop = false;
  }

  vedi(event: MouseEvent) {
    this.showPop = true;
  }

  async ngOnInit() {
    this.operatorInfo();
    this.operatorForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', Validators.required),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
    });
  }
}
