import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModaleUtentiService {
  private showModal = new BehaviorSubject<boolean>(false);
  private userId = new BehaviorSubject<string[]>([]);
  constructor() {}

  setShowModal(value: boolean) {
    this.showModal.next(value);
  }

  getShowModal() {
    return this.showModal.asObservable();
  }

  setUserId(value: string[]) {
    this.userId.next(value);
  }

  getUserId() {
    return this.userId.asObservable();
  }
}
