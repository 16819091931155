<stdout-card>
  <stdout-card-header class="card-header">
    <h1>{{ "modifica_banca_dati" | transloco }}</h1>
  </stdout-card-header>

  <stdout-card-content>
    <form  [formGroup]="form" (ngSubmit)="modifyBanca($event)">
      <div class="flex flex-wrap md:flex-nowrap justify-between lg:items-center items-end w-full">
        <div class="w-full md:w-[18.285%]">
          <div class="mb-[10px] primalabel">
            <label>
              <h2 class="lg:whitespace-nowrap">
                <b>{{ "nome_nuova_banca" | transloco }} </b>
              </h2>
            </label>
          </div>
          <div class="w-full h-full bordo">
            <input  class="w-full h-full px-[12px] py-[8px] outline-none" type="text"
              placeholder="Inserisci il nome nuova banca dati" formControlName="titolo" />
          </div>
        </div>
        <div class="mt-[10px] md:mt-0 w-full md:w-[79.428%]">
          <div class="mb-[10px] secondalabel">
            <label>
              <h2>
                <b>{{ "descrizione" | transloco }}</b>
              </h2>
            </label>
          </div>
          <stdout-text-input class="bordo" id="2" type="text"
            placeholder="Inserisci la descrizione della nuova banca dati"
            formControlName="descrizione"></stdout-text-input>
        </div>
      </div>
      <div class="flex flex-col md:flex-row items-end justify-between margine-card w-full">
        <div class="flex flex-col md:flex-row w-full justify-between">
          <div class="selezione w-full md:w-[18.285%]">
            <div>
              <div class="mb-[10px] primalabel">
                <label>
                  <h2>
                    <b>{{ "ente" | transloco }}</b>
                  </h2>
                </label>
              </div>

              <stdout-select class="fix-select" [placeholder]="'Seleziona o inserisci l’ente'" id="select1" type="text"
                [clearable]="false" [data]="combinedEnti" (input)="onEnteChange($event)" formControlName="ente"
                [value]="form.value.ente" #selectElement>
              </stdout-select>
            </div>
          </div>
          <div class="selezione mx-0 mt-[10px] md:mt-0 w-full md:w-[18.285%]">
            <div>
              <div class="mb-[10px] primalabel">
                <label>
                  <h2>
                    <b>{{ "profilo" | transloco }}</b>
                  </h2>
                </label>
              </div>

              <stdout-select [placeholder]="'Seleziona o inserisci il profilo'" class="fix-select" [clearable]="false"
                type="text" [data]="combinedProfili" (input)="onProfiliChange($event)" formControlName="profilo"
                [value]="form.value.profilo" #selectElement>
              </stdout-select>
            </div>
          </div>
          <div class="selezione mx-0 mt-[10px] md:mt-0 w-full md:w-[18.285%]">
            <div>
              <div class="mb-[10px] primalabel">
                <label>
                  <h2>
                    <b>{{ "regione" | transloco }}</b>
                  </h2>
                </label>
              </div>

              <stdout-select class="fix-select" id="select1" [clearable]="false" [data]="listaRegioni"
                [placeholder]="'Seleziona la località'" [value]="form.value.regione" #selectElement bindLabel="nome"
                bindValue="nome" formControlName="regione">
              </stdout-select>
            </div>
          </div>
          <div class="selezione mt-[10px] md:mt-0 w-full md:w-[18.285%]">
            <div>
              <div class="mb-[10px] primalabel">
                <label>
                  <h2>
                    <b>{{ "anno" | transloco }}</b>
                  </h2>
                </label>
              </div>

              <stdout-select class="fix-select" id="select1" [clearable]="false" (input)="onAnnoChange($event)"
                [placeholder]="'Seleziona l’anno '" formControlName="anno" [value]="form.value.anno"
                [data]="combinedAnno" #selectElement>
              </stdout-select>
            </div>
          </div>
          <div class="mt-[10px] md:mt-0 w-full md:w-[18.285%]" (click)="$event.stopPropagation()">
            <div class="mb-[10px] primalabel">
              <label>
                <h2>
                  <b>{{ "icona" | transloco }}</b>
                </h2>
              </label>
            </div>
            <div class="flex w-full">
              <div class="mr-[15px] w-full">
                <stdout-text-input class="no-bordo" id="1" type="text" placeholder="immagine" formControlName="imageUrl"
                  [disabled]="true"></stdout-text-input>
              </div>
              <div class=" cursor-pointer" *ngIf="imageUrl === ''">
                <stdout-icon-button (click)="show($event)" type="button" class="lente"
                  [icon]="faUpload"></stdout-icon-button>
              </div>
              <div class=" cursor-pointer" *ngIf="imageUrl !== ''">
                <stdout-icon-button (click)="removeImage($event)" class="lente"
                  [icon]="faTrashCan"></stdout-icon-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="margine w-full flex justify-between items-center">
        <div class="w-1/2 flex justify-start items-center" *ngIf="pubblica">
          <div class="flex items-center mr-[15px]">
            <stdout-icon-button class="lente-bianca" (click)="openPriv($event)"
              [icon]="faLockOpen"></stdout-icon-button>
          </div>
          <div class="flex items-center">
            <h2><b>Banca Dati pubblica</b></h2>
          </div>
        </div>
        <div class="w-1/2 flex justify-start items-center" *ngIf="!pubblica">
          <div class="flex items-center mr-[15px]">
            <stdout-icon-button class="lente-bianca" (click)="openPub($event)" [icon]="faLock"></stdout-icon-button>
          </div>
          <div class="flex items-center">
            <h2><b>Banca Dati privata</b></h2>
          </div>
        </div>
        <div class="w-[200px]">
          <stdout-button class="btn-blu w-full md:w-[200px]  cursor-pointer" size="large" color="white"
            type="submit" [disabled]="!isFormChanged()" [class.opacity-50]="!isFormChanged()">
            <p>{{ "salva" | transloco }}</p>
          </stdout-button>
        </div>
      </div>
    </form>
  </stdout-card-content>
</stdout-card>


