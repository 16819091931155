

<div class="dropdown ml-auto" (click)="$event.stopPropagation()">
  <div class="primaL px-[25px]">
    <div class="mb-[10px]">
      <label>
        <p>{{ "ente" | transloco }}</p>
      </label>
    </div>
    <div class="fix-select w-[250px]">
      <stdout-select class="fix-select" [placeholder]="'Seleziona l’ente'" id="select1" type="text" [clearable]="false"
        (onSelectedItem)="addSelectedEnte($event.event)" (input)="onEnteChange($event)" [data]="listaEnti"
        [value]="selectedEntiFiltro" #selectElement bindLabel="nome" bindValue="nome">
      </stdout-select>
    </div>

    <div class="flex flex-col w-fit" *ngFor="let nome of selectedNomiEntiFiltro; let i = index">
      <div class="lab flex items-center justify-center mt-[10px] w-full h-full">
        <div class="flex items-center justify-center w-full h-full">
          <p class="whitespace-nowrap">
            {{ nome }}
          </p>
        </div>
        <div class="icona-fil flex items-center justify-center w-full h-full">
          <fa-icon class="flex justify-center items-center w-full h-full" [icon]="faXmark"
            (click)="rimuoviVal(selectedEntiFiltro[i])"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="secondaL px-[25px]">
    <div class="mb-[10px]">
      <label>
        <p>{{ "profilo" | transloco }}</p>
      </label>
    </div>
    <div class="fix-select w-[250px]">
      <stdout-select class="fix-select" id="select1" [clearable]="false"
        (onSelectedItem)="addSelectedProfili($event.event)" [data]="listaProfili" [value]="selectedProfiliFiltro"
        [placeholder]="'Seleziona il profilo'" #selectElement bindLabel="nome" bindValue="nome">
      </stdout-select>
    </div>
    <div class="flex flex-col w-fit" *ngFor="let nome of selectedNomiProfiliFiltro; let i = index">
      <div class="lab flex items-center justify-center mt-[10px] w-full h-full">
        <div class="flex items-center justify-center w-full h-full">
          <p class="whitespace-nowrap">
            {{ nome }}
          </p>
        </div>
        <div class="icona-fil flex items-center justify-center w-full h-full">
          <fa-icon class="flex justify-center items-center w-full h-full" [icon]="faXmark"
            (click)="rimuoviValProfilo(selectedProfiliFiltro[i])"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="secondaL px-[25px]">
    <div class="mb-[10px]">
      <label>
        <p>{{ "regione" | transloco }}</p>
      </label>
    </div>
    <div class="fix-select w-[250px]">
      <stdout-select class="fix-select" id="select1" [clearable]="false"
        (onSelectedItem)="addSelectedRegioni($event.event)" [data]="listaRegioni" [value]="selectedRegioniFiltro"
        [placeholder]="'Seleziona la località'" #selectElement bindLabel="nome" bindValue="nome">
      </stdout-select>
    </div>

    <div class="flex flex-col w-fit" *ngFor="let nome of selectedNomiRegioniFiltro; let i = index">
      <div class="lab flex items-center justify-center mt-[10px] w-full h-full">
        <div class="flex items-center justify-center w-full h-full">
          <p class="whitespace-nowrap">
            {{ nome }}
          </p>
        </div>
        <div class="icona-fil flex items-center justify-center w-full h-full">
          <fa-icon class="flex justify-center items-center w-full h-full" [icon]="faXmark"
            (click)="rimuoviValRegione(selectedRegioniFiltro[i])"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="secondaL px-[25px]">
    <div class="mb-[10px]">
      <label>
        <p>{{ "seleziona_anno" | transloco }}</p>
      </label>
    </div>
    <div class="fix-select w-[250px]">
      <stdout-select class="fix-select" id="select1" [clearable]="false"
        (onSelectedItem)="addSelectedAnni($event.event)" [data]="Anno" [value]="selectedAnniFiltro"
        [placeholder]="'Seleziona l’anno '" #selectElement>
      </stdout-select>
    </div>

    <div class="flex flex-col w-fit" *ngFor="let nome of selectedNomiAnniFiltro; let i = index">
      <div class="lab flex items-center justify-center mt-[10px] w-full h-full">
        <div class="flex items-center justify-center w-full h-full">
          <p class="whitespace-nowrap">
            {{ nome }}
          </p>
        </div>
        <div class="icona-fil flex items-center justify-center w-full h-full">
          <fa-icon class="flex justify-center items-center w-full h-full" [icon]="faXmark"
            (click)="rimuoviAnno(selectedAnniFiltro[i])"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center mb-[25px] mt-10 px-[25px]">
    <div class="mr-[5px]">
      <div class="w-[120px]">
        <stdout-button class="btn-bianco  cursor-pointer" color="black" size="large"
          (click)="resettaFiltri()">
          <h2>{{ "resetta" | transloco }}</h2>
        </stdout-button>
      </div>
    </div>
    <div class="ml-[5px]">
      <div class="w-[120px]">
        <stdout-button class="btn-blu  cursor-pointer" color="white" size="large"
          (click)="applicaFiltri()">
          <h2>{{ "applica" | transloco }}</h2>
        </stdout-button>
      </div>
    </div>
  </div>
</div>