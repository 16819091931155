import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { StatoUtenteService } from '@services/stato-utente.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent2 implements OnInit {
  public faBars = faBars;
  expand: boolean = true;

  menuItems: { text: string; iconSvg: SafeHtml; route: string }[] = [];
  userinfo: any = [];
  show: boolean = false;

  mostra() {
    this.show = !this.show;
  }

  restringi() {
    this.expand = !this.expand;
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private router: Router,
    private auth: AuthService,
    public statoUtenteService: StatoUtenteService
  ) {}

  nome: string = '';
  cognome: string = '';
  private subscription: Subscription = new Subscription();
  ngOnInit() {
    this.statoUtenteService.getRoleId().subscribe((roleId) => {
      if (roleId === 3) {
        this.menuItems = [
          {
            route: '/banche_dati',
            text: 'Banche Dati',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.9938 2.52321C12.2336 1.7025 11.1719 1.25055 10 1.25055C8.82191 1.25055 7.75668 1.69977 7.00004 2.51539C6.23519 3.34 5.86254 4.46071 5.95004 5.67086C6.12348 8.05836 7.94027 10.0005 10 10.0005C12.0598 10.0005 13.8735 8.05875 14.0496 5.67164C14.1383 4.47242 13.7633 3.35406 12.9938 2.52321Z" fill="white"/>
                    <path d="M16.8752 18.7502H3.12522C2.94525 18.7525 2.76702 18.7147 2.60349 18.6395C2.43997 18.5643 2.29526 18.4536 2.17991 18.3154C1.926 18.0119 1.82366 17.5974 1.89944 17.1783C2.22913 15.3494 3.25804 13.813 4.87522 12.7345C6.31194 11.7771 8.13186 11.2502 10.0002 11.2502C11.8686 11.2502 13.6885 11.7775 15.1252 12.7345C16.7424 13.8127 17.7713 15.349 18.101 17.1779C18.1768 17.597 18.0744 18.0115 17.8205 18.315C17.7052 18.4532 17.5605 18.564 17.397 18.6393C17.2335 18.7146 17.0552 18.7525 16.8752 18.7502Z" fill="white"/>
                  </svg>`),
          },
          {
            route: '/quiz',
            text: 'Quiz',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z" fill="white"/>
          <path d="M6.25 3.12543H15.4641C15.2696 2.57748 14.9104 2.10311 14.4356 1.76741C13.9609 1.43172 13.3939 1.25113 12.8125 1.25043H4.0625C3.31658 1.25043 2.60121 1.54674 2.07376 2.07419C1.54632 2.60164 1.25 3.31701 1.25 4.06293V12.8129C1.2507 13.3944 1.43129 13.9613 1.76699 14.4361C2.10268 14.9108 2.57705 15.27 3.125 15.4645V6.25043C3.125 5.42163 3.45424 4.62677 4.04029 4.04072C4.62634 3.45467 5.4212 3.12543 6.25 3.12543Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/notifiche',
            text: 'Notifiche',

            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.375 3.74994H5.62502C5.45926 3.74994 5.30029 3.68409 5.18308 3.56688C5.06587 3.44967 5.00002 3.2907 5.00002 3.12494C5.00002 2.95918 5.06587 2.80021 5.18308 2.683C5.30029 2.56579 5.45926 2.49994 5.62502 2.49994H14.375C14.5408 2.49994 14.6998 2.56579 14.817 2.683C14.9342 2.80021 15 2.95918 15 3.12494C15 3.2907 14.9342 3.44967 14.817 3.56688C14.6998 3.68409 14.5408 3.74994 14.375 3.74994Z" fill="white"/>
          <path d="M15.625 5.62496H4.37499C4.20923 5.62496 4.05026 5.55912 3.93305 5.44191C3.81584 5.3247 3.74999 5.16572 3.74999 4.99996C3.74999 4.8342 3.81584 4.67523 3.93305 4.55802C4.05026 4.44081 4.20923 4.37496 4.37499 4.37496H15.625C15.7907 4.37496 15.9497 4.44081 16.0669 4.55802C16.1841 4.67523 16.25 4.8342 16.25 4.99996C16.25 5.16572 16.1841 5.3247 16.0669 5.44191C15.9497 5.55912 15.7907 5.62496 15.625 5.62496Z" fill="white"/>
          <path d="M16.3723 17.5H3.62773C3.16304 17.4995 2.71752 17.3146 2.38894 16.9861C2.06035 16.6575 1.87552 16.212 1.875 15.7473V8.00272C1.87552 7.53803 2.06035 7.09251 2.38894 6.76392C2.71752 6.43534 3.16304 6.25051 3.62773 6.24999H16.3723C16.837 6.25051 17.2825 6.43534 17.6111 6.76392C17.9397 7.09251 18.1245 7.53803 18.125 8.00272V15.7473C18.1245 16.212 17.9397 16.6575 17.6111 16.9861C17.2825 17.3146 16.837 17.4995 16.3723 17.5Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/consigliati',
            text: 'Consigliati',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.7598 6.61691C17.6439 6.50023 17.506 6.40769 17.3542 6.34466C17.2023 6.28163 17.0394 6.24936 16.875 6.24973H14.375V5.62473C14.375 4.4644 13.9141 3.3516 13.0936 2.53113C12.2731 1.71066 11.1603 1.24973 10 1.24973C8.83968 1.24973 7.72688 1.71066 6.90641 2.53113C6.08594 3.3516 5.625 4.4644 5.625 5.62473V6.24973H3.125C2.79348 6.24973 2.47554 6.38142 2.24112 6.61584C2.0067 6.85026 1.875 7.1682 1.875 7.49973V15.9372C1.875 17.4607 3.16406 18.7497 4.6875 18.7497H15.3125C16.0494 18.75 16.7572 18.4618 17.2844 17.947C17.5497 17.6939 17.761 17.3896 17.9055 17.0526C18.0499 16.7156 18.1246 16.3527 18.125 15.9861V7.49973C18.1255 7.33574 18.0935 7.17327 18.0308 7.02174C17.9681 6.87021 17.876 6.73261 17.7598 6.61691ZM12.9879 10.7029L9.48789 15.0779C9.43041 15.1497 9.35779 15.2079 9.27521 15.2484C9.19263 15.2889 9.10213 15.3107 9.01016 15.3122H9C8.90972 15.3122 8.82052 15.2927 8.73852 15.2549C8.65652 15.2172 8.58368 15.1621 8.525 15.0935L7.025 13.3404C6.97165 13.278 6.93111 13.2057 6.90569 13.1277C6.88027 13.0496 6.87048 12.9673 6.87686 12.8855C6.88325 12.8037 6.90568 12.7239 6.9429 12.6507C6.98011 12.5776 7.03137 12.5124 7.09375 12.4591C7.15613 12.4058 7.2284 12.3652 7.30645 12.3398C7.38449 12.3144 7.46678 12.3046 7.54861 12.311C7.63044 12.3173 7.71022 12.3398 7.78338 12.377C7.85654 12.4142 7.92165 12.4655 7.975 12.5279L8.98437 13.7071L12.0121 9.9216C12.1157 9.7921 12.2665 9.70906 12.4313 9.69075C12.5962 9.67243 12.7615 9.72034 12.891 9.82394C13.0205 9.92754 13.1036 10.0783 13.1219 10.2432C13.1402 10.408 13.0923 10.5733 12.9887 10.7029H12.9879ZM13.125 6.24973H6.875V5.62473C6.875 4.79592 7.20424 4.00107 7.79029 3.41502C8.37634 2.82897 9.1712 2.49973 10 2.49973C10.8288 2.49973 11.6237 2.82897 12.2097 3.41502C12.7958 4.00107 13.125 4.79592 13.125 5.62473V6.24973Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/utenti',
            text: 'Utenti',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M13.1253 10.0008C12.3221 10.0008 11.5454 9.64212 10.9375 8.99125C10.3464 8.35639 9.98543 7.50979 9.92175 6.6081C9.85377 5.64624 10.1472 4.76174 10.7476 4.11712C11.3481 3.47249 12.1877 3.12479 13.1253 3.12479C14.0563 3.12479 14.8982 3.47874 15.4968 4.1218C16.1011 4.77112 16.3953 5.65405 16.3273 6.60771C16.2621 7.51057 15.9015 8.35678 15.3116 8.99086C14.7052 9.64212 13.929 10.0008 13.1253 10.0008Z" fill="white"/>
          <path d="M18.2732 16.8748H7.97441C7.80883 16.8756 7.64525 16.8385 7.49626 16.7663C7.34728 16.694 7.21686 16.5885 7.11503 16.458C7.00703 16.3164 6.93244 16.1523 6.89686 15.9778C6.86128 15.8033 6.86562 15.6231 6.90957 15.4505C7.23847 14.1298 8.05332 13.0345 9.26582 12.2833C10.342 11.6169 11.7119 11.2498 13.1236 11.2498C14.5631 11.2498 15.8971 11.6013 16.9795 12.2673C18.1947 13.0146 19.0107 14.1162 19.3381 15.4529C19.3815 15.6256 19.3854 15.8058 19.3494 15.9802C19.3134 16.1545 19.2385 16.3185 19.1303 16.4599C19.0286 16.5899 18.8984 16.6948 18.7499 16.7668C18.6013 16.8387 18.4383 16.8756 18.2732 16.8748Z" fill="white"/>
          <path d="M5.74233 10.1571C4.36753 10.1571 3.15876 8.87881 3.04664 7.30788C2.99116 6.50308 3.24198 5.75883 3.74986 5.21305C4.25228 4.67274 4.96097 4.37504 5.74233 4.37504C6.52369 4.37504 7.22692 4.6743 7.73207 5.21774C8.24386 5.76782 8.49389 6.5105 8.43529 7.30866C8.32317 8.8792 7.11479 10.1571 5.74233 10.1571Z" fill="white"/>
          <path d="M8.30808 11.3852C7.62087 11.0492 6.72895 10.8812 5.74326 10.8812C4.59232 10.8812 3.47458 11.1812 2.59555 11.7258C1.59892 12.3443 0.928514 13.2448 0.657773 14.3321C0.618152 14.4884 0.614404 14.6518 0.646811 14.8098C0.679217 14.9678 0.746938 15.1165 0.844909 15.2447C0.93787 15.364 1.05696 15.4605 1.19303 15.5266C1.3291 15.5927 1.47851 15.6267 1.62979 15.626H5.96634C6.03954 15.626 6.1104 15.6003 6.16659 15.5534C6.22278 15.5065 6.26072 15.4413 6.27381 15.3693C6.2781 15.3447 6.28357 15.3201 6.28983 15.2959C6.62112 13.9652 7.39741 12.8408 8.54444 12.0212C8.58663 11.9908 8.62056 11.9503 8.64317 11.9035C8.66577 11.8566 8.67633 11.8049 8.67388 11.7529C8.67144 11.701 8.65607 11.6505 8.62917 11.606C8.60227 11.5615 8.56469 11.5244 8.51983 11.4981C8.45849 11.4621 8.38817 11.4242 8.30808 11.3852Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/collaboratori',
            text: 'Collaboratori',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z" fill="white"/>
          <path d="M6.25 3.12543H15.4641C15.2696 2.57748 14.9104 2.10311 14.4356 1.76741C13.9609 1.43172 13.3939 1.25113 12.8125 1.25043H4.0625C3.31658 1.25043 2.60121 1.54674 2.07376 2.07419C1.54632 2.60164 1.25 3.31701 1.25 4.06293V12.8129C1.2507 13.3944 1.43129 13.9613 1.76699 14.4361C2.10268 14.9108 2.57705 15.27 3.125 15.4645V6.25043C3.125 5.42163 3.45424 4.62677 4.04029 4.04072C4.62634 3.45467 5.4212 3.12543 6.25 3.12543Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/funzionalita',
            text: 'Funzionalità',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.5074 13.1093C10.3426 13.1216 10.1762 13.1277 10.0082 13.1277C9.80389 13.1277 9.60194 13.1183 9.40233 13.1008C8.41486 13.0132 7.45797 12.7133 6.59719 12.2215C5.73642 11.7297 4.99211 11.0577 4.41522 10.2515C4.40003 10.2299 4.37957 10.2124 4.35578 10.2008C4.33199 10.1892 4.30564 10.1838 4.27921 10.1852C4.25278 10.1865 4.22712 10.1946 4.20465 10.2086C4.18219 10.2226 4.16365 10.2421 4.15077 10.2652L1.33827 15.3199C1.23123 15.5152 1.21444 15.7496 1.32733 15.9402C1.38609 16.0347 1.46796 16.1127 1.56521 16.1668C1.66246 16.221 1.77189 16.2494 1.88319 16.2496H4.93006C5.0362 16.2457 5.14146 16.2701 5.23498 16.3205C5.32851 16.3708 5.40691 16.4452 5.4621 16.5359L6.97225 19.0621C7.02751 19.1558 7.10593 19.2338 7.19998 19.2885C7.29403 19.3432 7.40056 19.3729 7.50936 19.3746C7.74139 19.3617 7.98163 19.2109 8.07811 18.9996L10.6605 13.3297C10.6717 13.305 10.6763 13.2779 10.6738 13.251C10.6713 13.2241 10.6619 13.1982 10.6465 13.176C10.6311 13.1538 10.6101 13.136 10.5858 13.1243C10.5614 13.1126 10.5344 13.1075 10.5074 13.1093Z" fill="white"/>
          <path d="M18.6574 15.3076L15.8633 10.2619C15.8504 10.239 15.8319 10.2197 15.8096 10.2059C15.7872 10.192 15.7618 10.184 15.7355 10.1826C15.7093 10.1812 15.6831 10.1864 15.6594 10.1978C15.6357 10.2093 15.6153 10.2264 15.6 10.2478C14.8316 11.3245 13.7697 12.1573 12.541 12.6471C12.3902 12.7065 12.2681 12.822 12.2004 12.9693L10.7606 16.1373C10.742 16.1777 10.7324 16.2217 10.7324 16.2662C10.7324 16.3107 10.742 16.3547 10.7606 16.3951L11.943 18.9963C12.0387 19.2076 12.2785 19.3619 12.5102 19.3748C12.619 19.3721 12.7254 19.3415 12.8191 19.286C12.9128 19.2305 12.9907 19.1518 13.0453 19.0576L14.5504 16.5349C14.6633 16.3455 14.868 16.2486 15.0887 16.2498H18.1656C18.4262 16.2498 18.6234 16.1139 18.7125 15.8592C18.7438 15.7687 18.7551 15.6726 18.7456 15.5773C18.7361 15.4821 18.706 15.3901 18.6574 15.3076Z" fill="white"/>
          <path d="M10.0135 8.12761C11.05 8.12761 11.8903 7.28752 11.8903 6.25123C11.8903 5.21493 11.05 4.37485 10.0135 4.37485C8.97698 4.37485 8.13672 5.21493 8.13672 6.25123C8.13672 7.28752 8.97698 8.12761 10.0135 8.12761Z" fill="white"/>
          <path d="M10.0098 0.62439C6.90742 0.62439 4.38477 3.14744 4.38477 6.24939C4.38477 9.35134 6.90859 11.8744 10.0098 11.8744C13.1109 11.8744 15.6348 9.35095 15.6348 6.24939C15.6348 3.14783 13.1117 0.62439 10.0098 0.62439ZM10.0098 9.37439C9.3917 9.37439 8.78751 9.19111 8.27361 8.84773C7.7597 8.50435 7.35917 8.01629 7.12264 7.44528C6.88612 6.87426 6.82423 6.24592 6.94481 5.63973C7.06539 5.03354 7.36302 4.47672 7.80006 4.03968C8.2371 3.60264 8.79392 3.30501 9.40011 3.18444C10.0063 3.06386 10.6346 3.12574 11.2057 3.36227C11.7767 3.59879 12.2647 3.99933 12.6081 4.51323C12.9515 5.02714 13.1348 5.63132 13.1348 6.24939C13.1337 7.07787 12.8042 7.87213 12.2183 8.45796C11.6325 9.04378 10.8382 9.37336 10.0098 9.37439Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/enti',
            text: 'Enti',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.5074 13.1093C10.3426 13.1216 10.1762 13.1277 10.0082 13.1277C9.80389 13.1277 9.60194 13.1183 9.40233 13.1008C8.41486 13.0132 7.45797 12.7133 6.59719 12.2215C5.73642 11.7297 4.99211 11.0577 4.41522 10.2515C4.40003 10.2299 4.37957 10.2124 4.35578 10.2008C4.33199 10.1892 4.30564 10.1838 4.27921 10.1852C4.25278 10.1865 4.22712 10.1946 4.20465 10.2086C4.18219 10.2226 4.16365 10.2421 4.15077 10.2652L1.33827 15.3199C1.23123 15.5152 1.21444 15.7496 1.32733 15.9402C1.38609 16.0347 1.46796 16.1127 1.56521 16.1668C1.66246 16.221 1.77189 16.2494 1.88319 16.2496H4.93006C5.0362 16.2457 5.14146 16.2701 5.23498 16.3205C5.32851 16.3708 5.40691 16.4452 5.4621 16.5359L6.97225 19.0621C7.02751 19.1558 7.10593 19.2338 7.19998 19.2885C7.29403 19.3432 7.40056 19.3729 7.50936 19.3746C7.74139 19.3617 7.98163 19.2109 8.07811 18.9996L10.6605 13.3297C10.6717 13.305 10.6763 13.2779 10.6738 13.251C10.6713 13.2241 10.6619 13.1982 10.6465 13.176C10.6311 13.1538 10.6101 13.136 10.5858 13.1243C10.5614 13.1126 10.5344 13.1075 10.5074 13.1093Z" fill="white"/>
          <path d="M18.6574 15.3076L15.8633 10.2619C15.8504 10.239 15.8319 10.2197 15.8096 10.2059C15.7872 10.192 15.7618 10.184 15.7355 10.1826C15.7093 10.1812 15.6831 10.1864 15.6594 10.1978C15.6357 10.2093 15.6153 10.2264 15.6 10.2478C14.8316 11.3245 13.7697 12.1573 12.541 12.6471C12.3902 12.7065 12.2681 12.822 12.2004 12.9693L10.7606 16.1373C10.742 16.1777 10.7324 16.2217 10.7324 16.2662C10.7324 16.3107 10.742 16.3547 10.7606 16.3951L11.943 18.9963C12.0387 19.2076 12.2785 19.3619 12.5102 19.3748C12.619 19.3721 12.7254 19.3415 12.8191 19.286C12.9128 19.2305 12.9907 19.1518 13.0453 19.0576L14.5504 16.5349C14.6633 16.3455 14.868 16.2486 15.0887 16.2498H18.1656C18.4262 16.2498 18.6234 16.1139 18.7125 15.8592C18.7438 15.7687 18.7551 15.6726 18.7456 15.5773C18.7361 15.4821 18.706 15.3901 18.6574 15.3076Z" fill="white"/>
          <path d="M10.0135 8.12761C11.05 8.12761 11.8903 7.28752 11.8903 6.25123C11.8903 5.21493 11.05 4.37485 10.0135 4.37485C8.97698 4.37485 8.13672 5.21493 8.13672 6.25123C8.13672 7.28752 8.97698 8.12761 10.0135 8.12761Z" fill="white"/>
          <path d="M10.0098 0.62439C6.90742 0.62439 4.38477 3.14744 4.38477 6.24939C4.38477 9.35134 6.90859 11.8744 10.0098 11.8744C13.1109 11.8744 15.6348 9.35095 15.6348 6.24939C15.6348 3.14783 13.1117 0.62439 10.0098 0.62439ZM10.0098 9.37439C9.3917 9.37439 8.78751 9.19111 8.27361 8.84773C7.7597 8.50435 7.35917 8.01629 7.12264 7.44528C6.88612 6.87426 6.82423 6.24592 6.94481 5.63973C7.06539 5.03354 7.36302 4.47672 7.80006 4.03968C8.2371 3.60264 8.79392 3.30501 9.40011 3.18444C10.0063 3.06386 10.6346 3.12574 11.2057 3.36227C11.7767 3.59879 12.2647 3.99933 12.6081 4.51323C12.9515 5.02714 13.1348 5.63132 13.1348 6.24939C13.1337 7.07787 12.8042 7.87213 12.2183 8.45796C11.6325 9.04378 10.8382 9.37336 10.0098 9.37439Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/profili',
            text: 'Profili',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.5074 13.1093C10.3426 13.1216 10.1762 13.1277 10.0082 13.1277C9.80389 13.1277 9.60194 13.1183 9.40233 13.1008C8.41486 13.0132 7.45797 12.7133 6.59719 12.2215C5.73642 11.7297 4.99211 11.0577 4.41522 10.2515C4.40003 10.2299 4.37957 10.2124 4.35578 10.2008C4.33199 10.1892 4.30564 10.1838 4.27921 10.1852C4.25278 10.1865 4.22712 10.1946 4.20465 10.2086C4.18219 10.2226 4.16365 10.2421 4.15077 10.2652L1.33827 15.3199C1.23123 15.5152 1.21444 15.7496 1.32733 15.9402C1.38609 16.0347 1.46796 16.1127 1.56521 16.1668C1.66246 16.221 1.77189 16.2494 1.88319 16.2496H4.93006C5.0362 16.2457 5.14146 16.2701 5.23498 16.3205C5.32851 16.3708 5.40691 16.4452 5.4621 16.5359L6.97225 19.0621C7.02751 19.1558 7.10593 19.2338 7.19998 19.2885C7.29403 19.3432 7.40056 19.3729 7.50936 19.3746C7.74139 19.3617 7.98163 19.2109 8.07811 18.9996L10.6605 13.3297C10.6717 13.305 10.6763 13.2779 10.6738 13.251C10.6713 13.2241 10.6619 13.1982 10.6465 13.176C10.6311 13.1538 10.6101 13.136 10.5858 13.1243C10.5614 13.1126 10.5344 13.1075 10.5074 13.1093Z" fill="white"/>
          <path d="M18.6574 15.3076L15.8633 10.2619C15.8504 10.239 15.8319 10.2197 15.8096 10.2059C15.7872 10.192 15.7618 10.184 15.7355 10.1826C15.7093 10.1812 15.6831 10.1864 15.6594 10.1978C15.6357 10.2093 15.6153 10.2264 15.6 10.2478C14.8316 11.3245 13.7697 12.1573 12.541 12.6471C12.3902 12.7065 12.2681 12.822 12.2004 12.9693L10.7606 16.1373C10.742 16.1777 10.7324 16.2217 10.7324 16.2662C10.7324 16.3107 10.742 16.3547 10.7606 16.3951L11.943 18.9963C12.0387 19.2076 12.2785 19.3619 12.5102 19.3748C12.619 19.3721 12.7254 19.3415 12.8191 19.286C12.9128 19.2305 12.9907 19.1518 13.0453 19.0576L14.5504 16.5349C14.6633 16.3455 14.868 16.2486 15.0887 16.2498H18.1656C18.4262 16.2498 18.6234 16.1139 18.7125 15.8592C18.7438 15.7687 18.7551 15.6726 18.7456 15.5773C18.7361 15.4821 18.706 15.3901 18.6574 15.3076Z" fill="white"/>
          <path d="M10.0135 8.12761C11.05 8.12761 11.8903 7.28752 11.8903 6.25123C11.8903 5.21493 11.05 4.37485 10.0135 4.37485C8.97698 4.37485 8.13672 5.21493 8.13672 6.25123C8.13672 7.28752 8.97698 8.12761 10.0135 8.12761Z" fill="white"/>
          <path d="M10.0098 0.62439C6.90742 0.62439 4.38477 3.14744 4.38477 6.24939C4.38477 9.35134 6.90859 11.8744 10.0098 11.8744C13.1109 11.8744 15.6348 9.35095 15.6348 6.24939C15.6348 3.14783 13.1117 0.62439 10.0098 0.62439ZM10.0098 9.37439C9.3917 9.37439 8.78751 9.19111 8.27361 8.84773C7.7597 8.50435 7.35917 8.01629 7.12264 7.44528C6.88612 6.87426 6.82423 6.24592 6.94481 5.63973C7.06539 5.03354 7.36302 4.47672 7.80006 4.03968C8.2371 3.60264 8.79392 3.30501 9.40011 3.18444C10.0063 3.06386 10.6346 3.12574 11.2057 3.36227C11.7767 3.59879 12.2647 3.99933 12.6081 4.51323C12.9515 5.02714 13.1348 5.63132 13.1348 6.24939C13.1337 7.07787 12.8042 7.87213 12.2183 8.45796C11.6325 9.04378 10.8382 9.37336 10.0098 9.37439Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/anni',
            text: 'Anni',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.5074 13.1093C10.3426 13.1216 10.1762 13.1277 10.0082 13.1277C9.80389 13.1277 9.60194 13.1183 9.40233 13.1008C8.41486 13.0132 7.45797 12.7133 6.59719 12.2215C5.73642 11.7297 4.99211 11.0577 4.41522 10.2515C4.40003 10.2299 4.37957 10.2124 4.35578 10.2008C4.33199 10.1892 4.30564 10.1838 4.27921 10.1852C4.25278 10.1865 4.22712 10.1946 4.20465 10.2086C4.18219 10.2226 4.16365 10.2421 4.15077 10.2652L1.33827 15.3199C1.23123 15.5152 1.21444 15.7496 1.32733 15.9402C1.38609 16.0347 1.46796 16.1127 1.56521 16.1668C1.66246 16.221 1.77189 16.2494 1.88319 16.2496H4.93006C5.0362 16.2457 5.14146 16.2701 5.23498 16.3205C5.32851 16.3708 5.40691 16.4452 5.4621 16.5359L6.97225 19.0621C7.02751 19.1558 7.10593 19.2338 7.19998 19.2885C7.29403 19.3432 7.40056 19.3729 7.50936 19.3746C7.74139 19.3617 7.98163 19.2109 8.07811 18.9996L10.6605 13.3297C10.6717 13.305 10.6763 13.2779 10.6738 13.251C10.6713 13.2241 10.6619 13.1982 10.6465 13.176C10.6311 13.1538 10.6101 13.136 10.5858 13.1243C10.5614 13.1126 10.5344 13.1075 10.5074 13.1093Z" fill="white"/>
          <path d="M18.6574 15.3076L15.8633 10.2619C15.8504 10.239 15.8319 10.2197 15.8096 10.2059C15.7872 10.192 15.7618 10.184 15.7355 10.1826C15.7093 10.1812 15.6831 10.1864 15.6594 10.1978C15.6357 10.2093 15.6153 10.2264 15.6 10.2478C14.8316 11.3245 13.7697 12.1573 12.541 12.6471C12.3902 12.7065 12.2681 12.822 12.2004 12.9693L10.7606 16.1373C10.742 16.1777 10.7324 16.2217 10.7324 16.2662C10.7324 16.3107 10.742 16.3547 10.7606 16.3951L11.943 18.9963C12.0387 19.2076 12.2785 19.3619 12.5102 19.3748C12.619 19.3721 12.7254 19.3415 12.8191 19.286C12.9128 19.2305 12.9907 19.1518 13.0453 19.0576L14.5504 16.5349C14.6633 16.3455 14.868 16.2486 15.0887 16.2498H18.1656C18.4262 16.2498 18.6234 16.1139 18.7125 15.8592C18.7438 15.7687 18.7551 15.6726 18.7456 15.5773C18.7361 15.4821 18.706 15.3901 18.6574 15.3076Z" fill="white"/>
          <path d="M10.0135 8.12761C11.05 8.12761 11.8903 7.28752 11.8903 6.25123C11.8903 5.21493 11.05 4.37485 10.0135 4.37485C8.97698 4.37485 8.13672 5.21493 8.13672 6.25123C8.13672 7.28752 8.97698 8.12761 10.0135 8.12761Z" fill="white"/>
          <path d="M10.0098 0.62439C6.90742 0.62439 4.38477 3.14744 4.38477 6.24939C4.38477 9.35134 6.90859 11.8744 10.0098 11.8744C13.1109 11.8744 15.6348 9.35095 15.6348 6.24939C15.6348 3.14783 13.1117 0.62439 10.0098 0.62439ZM10.0098 9.37439C9.3917 9.37439 8.78751 9.19111 8.27361 8.84773C7.7597 8.50435 7.35917 8.01629 7.12264 7.44528C6.88612 6.87426 6.82423 6.24592 6.94481 5.63973C7.06539 5.03354 7.36302 4.47672 7.80006 4.03968C8.2371 3.60264 8.79392 3.30501 9.40011 3.18444C10.0063 3.06386 10.6346 3.12574 11.2057 3.36227C11.7767 3.59879 12.2647 3.99933 12.6081 4.51323C12.9515 5.02714 13.1348 5.63132 13.1348 6.24939C13.1337 7.07787 12.8042 7.87213 12.2183 8.45796C11.6325 9.04378 10.8382 9.37336 10.0098 9.37439Z" fill="white"/>
        </svg>`),
          },
          /*{
            route: '/test',
            text: 'Test',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.5074 13.1093C10.3426 13.1216 10.1762 13.1277 10.0082 13.1277C9.80389 13.1277 9.60194 13.1183 9.40233 13.1008C8.41486 13.0132 7.45797 12.7133 6.59719 12.2215C5.73642 11.7297 4.99211 11.0577 4.41522 10.2515C4.40003 10.2299 4.37957 10.2124 4.35578 10.2008C4.33199 10.1892 4.30564 10.1838 4.27921 10.1852C4.25278 10.1865 4.22712 10.1946 4.20465 10.2086C4.18219 10.2226 4.16365 10.2421 4.15077 10.2652L1.33827 15.3199C1.23123 15.5152 1.21444 15.7496 1.32733 15.9402C1.38609 16.0347 1.46796 16.1127 1.56521 16.1668C1.66246 16.221 1.77189 16.2494 1.88319 16.2496H4.93006C5.0362 16.2457 5.14146 16.2701 5.23498 16.3205C5.32851 16.3708 5.40691 16.4452 5.4621 16.5359L6.97225 19.0621C7.02751 19.1558 7.10593 19.2338 7.19998 19.2885C7.29403 19.3432 7.40056 19.3729 7.50936 19.3746C7.74139 19.3617 7.98163 19.2109 8.07811 18.9996L10.6605 13.3297C10.6717 13.305 10.6763 13.2779 10.6738 13.251C10.6713 13.2241 10.6619 13.1982 10.6465 13.176C10.6311 13.1538 10.6101 13.136 10.5858 13.1243C10.5614 13.1126 10.5344 13.1075 10.5074 13.1093Z" fill="white"/>
          <path d="M18.6574 15.3076L15.8633 10.2619C15.8504 10.239 15.8319 10.2197 15.8096 10.2059C15.7872 10.192 15.7618 10.184 15.7355 10.1826C15.7093 10.1812 15.6831 10.1864 15.6594 10.1978C15.6357 10.2093 15.6153 10.2264 15.6 10.2478C14.8316 11.3245 13.7697 12.1573 12.541 12.6471C12.3902 12.7065 12.2681 12.822 12.2004 12.9693L10.7606 16.1373C10.742 16.1777 10.7324 16.2217 10.7324 16.2662C10.7324 16.3107 10.742 16.3547 10.7606 16.3951L11.943 18.9963C12.0387 19.2076 12.2785 19.3619 12.5102 19.3748C12.619 19.3721 12.7254 19.3415 12.8191 19.286C12.9128 19.2305 12.9907 19.1518 13.0453 19.0576L14.5504 16.5349C14.6633 16.3455 14.868 16.2486 15.0887 16.2498H18.1656C18.4262 16.2498 18.6234 16.1139 18.7125 15.8592C18.7438 15.7687 18.7551 15.6726 18.7456 15.5773C18.7361 15.4821 18.706 15.3901 18.6574 15.3076Z" fill="white"/>
          <path d="M10.0135 8.12761C11.05 8.12761 11.8903 7.28752 11.8903 6.25123C11.8903 5.21493 11.05 4.37485 10.0135 4.37485C8.97698 4.37485 8.13672 5.21493 8.13672 6.25123C8.13672 7.28752 8.97698 8.12761 10.0135 8.12761Z" fill="white"/>
          <path d="M10.0098 0.62439C6.90742 0.62439 4.38477 3.14744 4.38477 6.24939C4.38477 9.35134 6.90859 11.8744 10.0098 11.8744C13.1109 11.8744 15.6348 9.35095 15.6348 6.24939C15.6348 3.14783 13.1117 0.62439 10.0098 0.62439ZM10.0098 9.37439C9.3917 9.37439 8.78751 9.19111 8.27361 8.84773C7.7597 8.50435 7.35917 8.01629 7.12264 7.44528C6.88612 6.87426 6.82423 6.24592 6.94481 5.63973C7.06539 5.03354 7.36302 4.47672 7.80006 4.03968C8.2371 3.60264 8.79392 3.30501 9.40011 3.18444C10.0063 3.06386 10.6346 3.12574 11.2057 3.36227C11.7767 3.59879 12.2647 3.99933 12.6081 4.51323C12.9515 5.02714 13.1348 5.63132 13.1348 6.24939C13.1337 7.07787 12.8042 7.87213 12.2183 8.45796C11.6325 9.04378 10.8382 9.37336 10.0098 9.37439Z" fill="white"/>
        </svg>`),
          },*/
        ];
      } else {
        this.menuItems = [
          {
            route: '/banche_dati',
            text: 'Banche Dati',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.9938 2.52321C12.2336 1.7025 11.1719 1.25055 10 1.25055C8.82191 1.25055 7.75668 1.69977 7.00004 2.51539C6.23519 3.34 5.86254 4.46071 5.95004 5.67086C6.12348 8.05836 7.94027 10.0005 10 10.0005C12.0598 10.0005 13.8735 8.05875 14.0496 5.67164C14.1383 4.47242 13.7633 3.35406 12.9938 2.52321Z" fill="white"/>
                    <path d="M16.8752 18.7502H3.12522C2.94525 18.7525 2.76702 18.7147 2.60349 18.6395C2.43997 18.5643 2.29526 18.4536 2.17991 18.3154C1.926 18.0119 1.82366 17.5974 1.89944 17.1783C2.22913 15.3494 3.25804 13.813 4.87522 12.7345C6.31194 11.7771 8.13186 11.2502 10.0002 11.2502C11.8686 11.2502 13.6885 11.7775 15.1252 12.7345C16.7424 13.8127 17.7713 15.349 18.101 17.1779C18.1768 17.597 18.0744 18.0115 17.8205 18.315C17.7052 18.4532 17.5605 18.564 17.397 18.6393C17.2335 18.7146 17.0552 18.7525 16.8752 18.7502Z" fill="white"/>
                  </svg>`),
          },
          {
            route: '/quiz',
            text: 'Quiz',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z" fill="white"/>
          <path d="M6.25 3.12543H15.4641C15.2696 2.57748 14.9104 2.10311 14.4356 1.76741C13.9609 1.43172 13.3939 1.25113 12.8125 1.25043H4.0625C3.31658 1.25043 2.60121 1.54674 2.07376 2.07419C1.54632 2.60164 1.25 3.31701 1.25 4.06293V12.8129C1.2507 13.3944 1.43129 13.9613 1.76699 14.4361C2.10268 14.9108 2.57705 15.27 3.125 15.4645V6.25043C3.125 5.42163 3.45424 4.62677 4.04029 4.04072C4.62634 3.45467 5.4212 3.12543 6.25 3.12543Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/notifiche',
            text: 'Notifiche',

            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.375 3.74994H5.62502C5.45926 3.74994 5.30029 3.68409 5.18308 3.56688C5.06587 3.44967 5.00002 3.2907 5.00002 3.12494C5.00002 2.95918 5.06587 2.80021 5.18308 2.683C5.30029 2.56579 5.45926 2.49994 5.62502 2.49994H14.375C14.5408 2.49994 14.6998 2.56579 14.817 2.683C14.9342 2.80021 15 2.95918 15 3.12494C15 3.2907 14.9342 3.44967 14.817 3.56688C14.6998 3.68409 14.5408 3.74994 14.375 3.74994Z" fill="white"/>
          <path d="M15.625 5.62496H4.37499C4.20923 5.62496 4.05026 5.55912 3.93305 5.44191C3.81584 5.3247 3.74999 5.16572 3.74999 4.99996C3.74999 4.8342 3.81584 4.67523 3.93305 4.55802C4.05026 4.44081 4.20923 4.37496 4.37499 4.37496H15.625C15.7907 4.37496 15.9497 4.44081 16.0669 4.55802C16.1841 4.67523 16.25 4.8342 16.25 4.99996C16.25 5.16572 16.1841 5.3247 16.0669 5.44191C15.9497 5.55912 15.7907 5.62496 15.625 5.62496Z" fill="white"/>
          <path d="M16.3723 17.5H3.62773C3.16304 17.4995 2.71752 17.3146 2.38894 16.9861C2.06035 16.6575 1.87552 16.212 1.875 15.7473V8.00272C1.87552 7.53803 2.06035 7.09251 2.38894 6.76392C2.71752 6.43534 3.16304 6.25051 3.62773 6.24999H16.3723C16.837 6.25051 17.2825 6.43534 17.6111 6.76392C17.9397 7.09251 18.1245 7.53803 18.125 8.00272V15.7473C18.1245 16.212 17.9397 16.6575 17.6111 16.9861C17.2825 17.3146 16.837 17.4995 16.3723 17.5Z" fill="white"/>
        </svg>`),
          },
          {
            route: '/consigliati',
            text: 'Consigliati',
            iconSvg: this.sanitizer
              .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.7598 6.61691C17.6439 6.50023 17.506 6.40769 17.3542 6.34466C17.2023 6.28163 17.0394 6.24936 16.875 6.24973H14.375V5.62473C14.375 4.4644 13.9141 3.3516 13.0936 2.53113C12.2731 1.71066 11.1603 1.24973 10 1.24973C8.83968 1.24973 7.72688 1.71066 6.90641 2.53113C6.08594 3.3516 5.625 4.4644 5.625 5.62473V6.24973H3.125C2.79348 6.24973 2.47554 6.38142 2.24112 6.61584C2.0067 6.85026 1.875 7.1682 1.875 7.49973V15.9372C1.875 17.4607 3.16406 18.7497 4.6875 18.7497H15.3125C16.0494 18.75 16.7572 18.4618 17.2844 17.947C17.5497 17.6939 17.761 17.3896 17.9055 17.0526C18.0499 16.7156 18.1246 16.3527 18.125 15.9861V7.49973C18.1255 7.33574 18.0935 7.17327 18.0308 7.02174C17.9681 6.87021 17.876 6.73261 17.7598 6.61691ZM12.9879 10.7029L9.48789 15.0779C9.43041 15.1497 9.35779 15.2079 9.27521 15.2484C9.19263 15.2889 9.10213 15.3107 9.01016 15.3122H9C8.90972 15.3122 8.82052 15.2927 8.73852 15.2549C8.65652 15.2172 8.58368 15.1621 8.525 15.0935L7.025 13.3404C6.97165 13.278 6.93111 13.2057 6.90569 13.1277C6.88027 13.0496 6.87048 12.9673 6.87686 12.8855C6.88325 12.8037 6.90568 12.7239 6.9429 12.6507C6.98011 12.5776 7.03137 12.5124 7.09375 12.4591C7.15613 12.4058 7.2284 12.3652 7.30645 12.3398C7.38449 12.3144 7.46678 12.3046 7.54861 12.311C7.63044 12.3173 7.71022 12.3398 7.78338 12.377C7.85654 12.4142 7.92165 12.4655 7.975 12.5279L8.98437 13.7071L12.0121 9.9216C12.1157 9.7921 12.2665 9.70906 12.4313 9.69075C12.5962 9.67243 12.7615 9.72034 12.891 9.82394C13.0205 9.92754 13.1036 10.0783 13.1219 10.2432C13.1402 10.408 13.0923 10.5733 12.9887 10.7029H12.9879ZM13.125 6.24973H6.875V5.62473C6.875 4.79592 7.20424 4.00107 7.79029 3.41502C8.37634 2.82897 9.1712 2.49973 10 2.49973C10.8288 2.49973 11.6237 2.82897 12.2097 3.41502C12.7958 4.00107 13.125 4.79592 13.125 5.62473V6.24973Z" fill="white"/>
        </svg>`),
          },
        ];
      }
    });
    this.statoUtenteService.userInfo();

    this.subscription = new Subscription();

    this.subscription.add(
      this.statoUtenteService.getNome().subscribe((nome) => {
        this.nome = nome;
      })
    );

    this.subscription.add(
      this.statoUtenteService.getCognome().subscribe((cognome) => {
        this.cognome = cognome;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
