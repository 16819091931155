import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltriBancheService {
  private anni = new BehaviorSubject<string[]>([]);
  private enti = new BehaviorSubject<any[]>([]);
  private profili = new BehaviorSubject<any[]>([]);
  private regioni = new BehaviorSubject<any[]>([]);

  constructor() {}

  setAnni(anni: string[]) {
    this.anni.next(anni);
  }
  getAnni() {
    return this.anni.asObservable();
  }
  setEnti(enti: any[]) {
    this.enti.next(enti);
  }
  getEnti() {
    return this.enti.asObservable();
  }
  setProfili(profili: any[]) {
    this.profili.next(profili);
  }
  getProfili() {
    return this.profili.asObservable();
  }
  setRegioni(regioni: any[]) {
    this.regioni.next(regioni);
  }
  getRegioni() {
    return this.regioni.asObservable();
  }
}
