import { Component, OnInit } from '@angular/core';
import { faUser, faCopy, faFolder } from '@fortawesome/free-regular-svg-icons';
import {
  faHouse,
  faBars,
  faStore,
  faFileContract,
  faUsers,
  faBasketShopping,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent implements OnInit {
  faHouse = faHouse;
  faBars = faBars;
  faUser = faUser;
  faStore = faStore;
  faCopy = faCopy;
  faFolder = faFolder;
  faFileContract = faFileContract;
  faBasketShopping = faBasketShopping;
  faWallet = faWallet;

  expandedUser: boolean = false;
  expandedFatture: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
