import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SelectionService } from '@services/selection.service';
import { max } from 'lodash';

@Component({
  selector: 'app-tabella',
  templateUrl: './tabella.component.html',
  styleUrls: ['./tabella.component.scss'],
})
export class TabellaComponent implements OnInit {
  constructor(private selectionService: SelectionService) {}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  @Input() colonne: string[] = [];
  @Input() colonneDisplayNames: { [key: string]: string } = {};
  @Input() data: any[] = [];
  @Input() larghezza: any = '';
  @Input() isScrollable: boolean = false;

  @Output() itemCliccato: EventEmitter<any> = new EventEmitter();

  clicca(item: any) {
    this.itemCliccato.emit(item);
  }

  @ViewChildren('myDiv') divs!: QueryList<ElementRef>;

  private observer!: MutationObserver;

  ngAfterViewInit() {
    this.observer = new MutationObserver(() => {
      this.funzioneBruh();
    });

    this.divs.forEach((div: ElementRef) => {
      this.observer.observe(div.nativeElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    });
  }

  ngAfterViewChecked() {
    this.funzioneBruh();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @Output() selectionChangeAll = new EventEmitter<any[]>();
  @Output() selectionChange = new EventEmitter<any[]>();

  onCheckboxChange(item: any, event: any) {
    const checked = event.target.checked;

    if (checked) {
      this.selectionService.select(item);
    } else {
      this.selectionService.deselect(item);
    }
    this.selectionChange.emit(this.selectionService.getSelectedIds());
  }

  check: boolean = false;

  onCheckboxChangeAll(event: any) {
    const checked = event.target.checked;
    if (event.target.checked) {
      this.data.forEach((item) => {
        this.selectionService.select(item);
        this.selectionChange.emit(item);
      });
    } else {
      this.data.forEach((item) => {
        this.selectionService.deselect(item);

        this.selectionChange.emit(item);
      });
    }

    const selectedIds = this.selectionService.getSelectedIds();
    this.selectionChangeAll.emit(selectedIds);
  }

  maxHeight!: number;
  maxWidht!: number;

  funzioneBruh() {
    let maxHeight = 0;
    let maxWidth = 0;

    this.divs.forEach((div: ElementRef) => {
      let elemento = div.nativeElement;

      // Imposta box-sizing a border-box
      elemento.style.boxSizing = 'border-box';

      if (elemento.offsetHeight > maxHeight && elemento.offsetHeight <= 300) {
        maxHeight = elemento.offsetHeight;
      }
      if (elemento.offsetWidth > maxWidth && elemento.offsetWidth <= 300) {
        maxWidth = elemento.offsetWidth;
      }
    });

    // Assicurati che le dimensioni non siano mai inferiori a 200
    maxHeight = Math.max(maxHeight, 100);
    maxWidth = Math.max(maxWidth, 100);

    this.divs.forEach((div: ElementRef) => {
      let elemento = div.nativeElement;
      if (elemento) {
        elemento.style.height = maxHeight.toString() + 'px';
        elemento.style.width = maxWidth.toString() + 'px';
      }
    });

    // Assegna le dimensioni massime alle variabili del componente
    this.maxHeight = maxHeight;
    this.maxWidht = maxWidth + 2;
  }

  scrollLeft: number = 0; // Define the type for scrollLeft

  mouseDown: boolean = false;
  startX: number = 0; // Define the type for startX

  @ViewChild('parent', { static: true }) // Adjust the 'static' value as needed
  slider!: ElementRef;

  startDragging(e: MouseEvent, slider: HTMLElement): void {
    this.mouseDown = true; // Corrected assignment from false to true
    this.startX = e.pageX - slider.offsetLeft;
    this.scrollLeft = slider.scrollLeft;
  }

  stopDragging(): void {
    this.mouseDown = false;
  }

  moveEvent(e: MouseEvent, slider: HTMLElement): void {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - this.startX;
    slider.scrollLeft = this.scrollLeft - scroll;
  }
}
