import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  faFilter,
  faMagnifyingGlass,
  faEye,
  faTrashCan,
  faUpload,
  faCirclePlus,
  faTrash,
  faCopy,
  faLockOpen,
  faLock,
  faChevronDown,
  faChevronUp,
  faXmark,
  faArrowDownZA,
  faArrowUp19,
  faArrowUpAZ,
  faArrowDown91,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionService } from '@services/selection.service';
import { get, set } from 'lodash';
import { BehaviorSubject, Subscription, forkJoin, map, of, take } from 'rxjs';
import { ImageServiceService } from '@services/image-service.service';
import * as moment from 'moment';
import { response } from 'express';
import { MappingService } from '@services/mapping.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ScrollService } from '@services/scroll.service';
import { FormDetailsService } from '@services/form-details.service';
import { ModalFormService } from '@services/modal-form.service';
import { FiltriDetailsService } from '@services/filtri-details.service';
import { MessageService } from '@services/message.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Component({
  selector: 'app-bancadati',
  templateUrl: './bancadati.component.html',
  styleUrls: ['./bancadati.component.scss'],
})
export class BancadatiComponent implements OnInit {
  showModal: boolean = false;
  selectedFile: any = null;
  selectedFileXl: any = null;
  showDomande: boolean = false;
  selectedNomiDifficoltaFiltro: string[] = [];
  selectedNomiProfiliFiltro: string[] = [];
  selectedAnniFiltro: string[] = [];
  selectedTitoliFiltro: number[] = [];
  selectedMaterieFiltro: number[] = [];
  selectedArgomentiFiltro: number[] = [];
  selectedDifficoltaFiltro: number[] = [];
  selectedEntiFiltro: any[] = [];
  selectedProfiliFiltro: any[] = [];
  initialStateSelectedProfili: number[] = [];
  initialStateSelectedArgomenti: number[] = [];
  initialSelectedEnti: number[] = [];
  initialStateSelectedTitoli: number[] = [];
  initialStateSelectedMaterie: number[] = [];
  initialStateSelectedDifficolta: number[] = [];
  index: string = '';
  modifyIndex: boolean = false;
  checkHeader: string = 'sopratab';
  checkBody: string = 'check-corpo';
  tabText: string = 'tab-text';
  headerText: string = 'header-text';
  private subscription: Subscription = new Subscription();
  public faArrowUpAZ = faArrowUpAZ;
  public faArrowDownZA = faArrowDownZA;
  public faArrowUp19 = faArrowUp19;
  public faArrowDown91 = faArrowDown91;
  public faSort = faSort;
  ordinaPer: number = 3;
  ordinaDesc: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private mappingService: MappingService,
    private banchedati: BanchedatiService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private selectionService: SelectionService,
    private imageService: ImageServiceService,
    private message: MessageService,
    private formDetails: FormDetailsService,
    private modalForm: ModalFormService,
    private filtri: FiltriDetailsService
  ) {
    this.subscription.add(
      this.route.queryParams.subscribe((param: any) => {
        this.idToNavigate = param.id;
      })
    );
  }

  search: string = '';
  isPhone: any;

  public faMagnifyingGlass = faMagnifyingGlass;
  public faFilter = faFilter;
  public faEye = faEye;
  public faTrashCan = faTrashCan;
  public faUpload = faUpload;
  public faCirclePlus = faCirclePlus;
  public faTrash = faTrash;
  public faCopy = faCopy;
  public faLockOpen = faLockOpen;
  public faLock = faLock;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faXmark = faXmark;
  pubblica!: boolean;
  showPub: boolean = false;
  showPriv: boolean = false;
  titolo: string = '';
  descrizione: string = '';
  ente: string = '';

  profilo: string = '';
  titoloPop: string = '';
  nomeModifica: string = '';
  immagine: string = '';
  showInput: boolean = false;
  showData: boolean = true;

  imageUrl: string = '';
  selectedRegione: any;
  selectedProfilo: any;
  selectedEnte: any;
  selectedProfiloRiga: any;
  selectedEnteRiga: any;
  selectedAnno: any;
  selectedAnnoRiga: any;
  profili: any[] = [];
  listaProfili: any[] = [];
  enti: any[] = [];
  listaEnti: any[] = [];
  changeId: number[] = [];
  perTeId: number[] = [];

  // ngModel per creare domanda

  domanda: string = '';
  rispostaA: string = '';
  rispostaB: string = '';
  rispostaC: string = '';
  rispostaD: string = '';
  rispostaE: string = '';
  rispostaF: string = '';
  rispostaCorretta: string = '';
  numerazioneMida: string = '';
  titoloDiStudio: string = '';
  annoRiga: string = '';
  enteRiga: string = '';
  profiloRiga: string = '';
  materia: string = '';
  argomento: string = '';
  difficoltaDomanda!: number;
  immagineRiga: string = '';
  immagineSpiegazioneRisposta: string = '';
  linkVideoSpiegazione: string = '';
  linkPreparazione: string = '';
  idBancaDati!: number;
  originalNome: string = '';
  originalDescrizione: string = '';
  originalEnte: string = '';
  originalProfilo: string = '';
  originalRegione: string = '';
  originalAnno: string = '';
  originalImageUrl: string = '';
  modifica: boolean = false;
  larghezza = { width: 'fit-content' };
  isScrollable: boolean = true;

  importLibreria: boolean = false;
  selectedLibreria: any;
  public fileUrl: any | null = null;
  showSlide: boolean = false;
  loader: boolean = false;
  modifica2: boolean = false;
  listaBancaDati: any[] = [];
  nome: string = '';
  regione: string = '';
  anno: string = '';
  dom: any[] = [];
  idToModify!: number;
  contenutoToModify: string = '';
  rispostaAToModify: any;
  rispostaBToModify: any;
  rispostaCToModify: any;
  rispostaDToModify: any;
  rispostaEToModify: any;
  rispostaFToModify: any;
  correttaToModify: string = '';
  numerazioneMidaToModify: string = '';
  titoloDiStudioToModify: any;
  annoToModify: any;
  enteToModify: any;
  profiloToModify: any;
  materiaToModify: any;
  argomentoToModify: any;
  difficoltaToModify: any;
  immagineToModify: string = '';
  immagineSpiegazioneRispostaToModify: string = '';
  linkVideoSpiegazioneToModify: string = '';
  linkPreparazioneToModify: string = '';
  regioni: any[] = [];
  nomiRegioni: any;
  private regioniSubject = new BehaviorSubject<any[]>([]);
  initialStateSelectedRegioni: number[] = [];
  selectedRegioni: number[] = [];
  nomiEnti: any;
  nomiProfili: any;
  bancaD: any[] = [];
  infoBanca: any[] = [];
  messErr: string = '';

  Anno: any[] = [];
  listaArgomenti: any[] = [];
  listaMaterie: any[] = [];
  nomiMaterie: any;
  selectedMateria: any;
  selectedArgomento: any;
  selectedDifficolta: any;
  bancheda: any[] = [];
  listaBancheDati: any[] = [];
  filter: any[] = [];
  idToNavigate!: number;
  selectedTitoli: any;
  listaRegioni: any[] = [];
  combinedEnti!: string[];
  combinedProfili!: string[];
  domandeIds: number[] = [];
  librerie: any[] = [];
  elimina: boolean = false;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('scrollingDiv') scrollingDiv!: ElementRef;
  banca: any[] = [];
  bancaDati: any[] = [];
  regioneInit!: number;
  duplicate: string = 'true';
  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  show4: boolean = false;
  show5: boolean = false;
  show6: boolean = false;
  show7: boolean = false;
  show8: boolean = false;
  initialValue = false;
  selectedTit: number[] = [];
  selectedNomiTitFiltro: string[] = [];
  selectedAnni: string[] = [];
  selectedMaterie: number[] = [];
  selectedNomiMaterieFiltro: string[] = [];
  selectedArgomenti: number[] = [];
  selectedNomiArgomentiFiltro: string[] = [];
  selectedDiff: number[] = [];
  selectedNomiDiffFiltro: string[] = [];
  selectedEnti: number[] = [];
  selectedNomiEntiFiltro: string[] = [];
  selectedProfili: number[] = [];
  showFilter: boolean = false;
  showVisual: boolean = false;
  selectedEnteFil: number[] = [];
  selectedProfiloFil: string = '';
  selectedMateriaFil: string = '';
  selectedAnnoFil: string = '';
  selectedArgomentoFil: string = '';
  selectedDifficoltaFil: string = '';
  existPass: boolean = false;
  selectedTitoliFil: string = '';
  contenutoCheck: string = '';
  rispostaACheck: string = '';
  rispostaBCheck: string = '';
  rispostaCCheck: string = '';
  rispostaDCheck: string = '';
  rispostaECheck: string = '';
  rispostaFCheck: string = '';
  correttaCheck: string = '';
  numerazioneMidaCheck: string = '';
  titoloDiStudioCheck: string = '';
  annoCheck: string = '';
  enteCheck: string = '';
  profiloCheck: string = '';
  materiaCheck: string = '';
  argomentoCheck: string = '';
  difficoltaCheck: number = 0;
  immagineCheck: string = '';
  immagineSpiegazioneRispostaCheck: string = '';
  linkPreparazioneCheck: string = '';
  titles: any[] = [
    { title: 'indice', value: 'indice' },
    { title: 'domanda', value: 'domanda' },
    { title: 'risposta a', value: 'rispostaA' },
    { title: 'risposta b', value: 'rispostaB' },
    { title: 'risposta c', value: 'rispostaC' },
    { title: 'risposta d', value: 'rispostaD' },
    { title: 'risposta e', value: 'rispostaE' },
    { title: 'risposta f', value: 'rispostaF' },
    { title: 'corretta', value: 'corretta' },
    { title: 'numerazione mida', value: 'numerazioneMida' },
    { title: 'titolo di studio', value: 'titoloStudio' },
    { title: 'anno', value: 'anno' },
    { title: 'ente', value: 'ente' },
    { title: 'profilo', value: 'profilo' },
    { title: 'materia', value: 'materia' },
    { title: 'argomento', value: 'argomento' },
    { title: 'difficolta', value: 'difficolta' },
    { title: 'immagine', value: 'cover' },
    {
      title: 'Testo spiegazione domanda',
      value: 'immagineSpiegazioneRisposta',
    },
    { title: 'link preparazione', value: 'linkPreparazione' },
  ];

  tab: boolean = false;
  idBanche!: number;
  idRegione!: number;
  listaDifficolta: any[] = [
    {
      id: null,
      nome: '',
    },
    {
      id: 1,
      nome: 'Facile',
    },
    {
      id: 2,
      nome: 'Medio',
    },
    {
      id: 3,
      nome: 'Difficile',
    },
  ];

  listaTitoliStudio: any[] = [];

  showFil(event: MouseEvent) {
    event.stopPropagation();
    this.showFilter = !this.showFilter;
  }

  showLib(event: MouseEvent) {
    event.stopPropagation();
    this.importLibreria = !this.importLibreria;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.modifica === true) {
      this.formDetails.setModifica(false);
    }
    if (this.showInput === true) {
      this.showInput = false;
    }
    if (this.importLibreria === true) {
      this.importLibreria = false;
    }
    if (this.elimina === true) {
      this.elimina = !this.elimina;
    }
    if (this.showDomande === true) {
      this.showDomande = !this.showDomande;
    }
    if (this.modifica2 === true) {
      this.modifica2 = !this.modifica2;
    }
    if (this.showPriv === true) {
      this.formDetails.setShowPriv(false);
    }
    if (this.showPub === true) {
      this.formDetails.setShowPub(false);
    }
    if (this.showFilter === true) {
      this.showFilter = !this.showFilter;
    }
    if (this.showVisual) {
      this.showVisual = !this.showVisual;
    }
  }

  showModify(event: MouseEvent) {
    this.modifica = !this.modifica;
    this.formDetails.setIsChange(true);
    event.stopPropagation();
  }

  showModify2(event: MouseEvent) {
    this.modifica2 = !this.modifica2;
  }

  openFilter() {
    this.showFilter = !this.showFilter;
  }

  openVisual() {
    this.showVisual = !this.showVisual;
  }

  ordinaAlfabetico() {
    this.ordinaPer = 2;
    this.getBancaDati();
  }

  ordinaNumerico() {
    this.ordinaPer = 1;
    this.getBancaDati();
  }

  ordinaIndice() {
    this.ordinaPer = 3;
    this.getBancaDati();
  }

  invertiOrdine() {
    this.ordinaDesc = !this.ordinaDesc;
    this.getBancaDati();
  }

  resetOrdine() {
    this.ordinaPer = 0;
    this.ordinaDesc = false;
    this.index = '0';
    this.selectionService.setDisplayCount(10);
    this.selectionService.setIndexView(0);
    this.getBancaDati();
  }

  resetForm2() {
    const emptyForm = {
      domanda: '',
      rispostaA: '',
      rispostaB: '',
      rispostaC: '',
      rispostaD: '',
      rispostaE: '',
      rispostaF: '',
      rispostaCorretta: '',
      numerazioneMida: '',
      titoliStudio: null,
      anno: null,
      ente: null,
      profilo: null,
      materia: null,
      argomento: null,
      difficolta: '',
      immagine: '',
      immagineSpiegazione: '',
      linkPreparazione: '',
    };
    this.modalForm.changeForm(emptyForm);
  }

  showModifyDetails(
    event: MouseEvent,
    id: number,
    indice: string,
    domanda: string,
    rispostaA: string,
    rispostaB: string,
    rispostaC: string,
    rispostaD: string,
    rispostaE: string,
    rispostaF: string,
    corretta: string,
    numerazioneMida: string,
    titoloDiStudio: string,
    anno: string,
    ente: string,
    profilo: string,
    materia: string,
    argomento: string,
    difficolta: number,
    immagine: string,
    immagineSpiegazioneRisposta: string,

    linkPreparazione: string
  ) {
    this.showDomande = !this.showDomande;
    this.idToModify = id;
    this.modifyIndex = true;
    this.modalForm.changeForm({
      indice: indice,
      domanda: domanda,
      rispostaA: rispostaA,
      rispostaB: rispostaB,
      rispostaC: rispostaC,
      rispostaD: rispostaD,
      rispostaE: rispostaE,
      rispostaF: rispostaF,
      rispostaCorretta: corretta,
      numerazioneMida: numerazioneMida,
      titoliStudio: titoloDiStudio,
      anno: anno,
      ente: ente,
      profilo: profilo,
      materia: materia,
      argomento: argomento,
      difficolta: difficolta.toString(),
      immagine: immagine,
      immagineSpiegazioneRisposta: immagineSpiegazioneRisposta,
      linkPreparazione: linkPreparazione,
    });
    this.subscription.add(
      this.modalForm.getForm().subscribe((form) => {
        this.index = form.indice;
        this.contenutoToModify = form.domanda;
        this.rispostaAToModify = form.rispostaA;
        this.rispostaBToModify = form.rispostaB;
        this.rispostaCToModify = form.rispostaC;
        this.rispostaDToModify = form.rispostaD;
        this.rispostaEToModify = form.rispostaE;
        this.rispostaFToModify = form.rispostaF;
        this.correttaToModify = form.rispostaCorretta;
        this.numerazioneMidaToModify = form.numerazioneMida;
        this.titoloDiStudioToModify = form.titoliStudio;
        this.annoToModify = form.anno;
        this.selectedEnteRiga = form.ente;
        this.selectedProfiloRiga = form.profilo;
        this.materiaToModify = form.materia;
        this.argomentoToModify = form.argomento;
        this.difficoltaToModify = form.difficolta;
        this.immagineToModify = form.immagine;
        this.immagineSpiegazioneRispostaToModify = form.immagineSpiegazione;
        this.linkPreparazioneToModify = form.linkPreparazione;
      })
    );
    this.compareFunction();
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  compareFunction() {
    this.subscription.add(
      this.modalForm
        .getForm()
        .pipe(take(1))
        .subscribe((form) => {
          this.contenutoCheck = form.domanda;
          this.rispostaACheck = form.rispostaA;
          this.rispostaBCheck = form.rispostaB;
          this.rispostaCCheck = form.rispostaC;
          this.rispostaDCheck = form.rispostaD;
          this.rispostaECheck = form.rispostaE;
          this.rispostaFCheck = form.rispostaF;
          this.correttaCheck = form.rispostaCorretta;
          this.numerazioneMidaCheck = form.numerazioneMida;
          this.titoloDiStudioCheck = form.titoliStudio;
          this.annoCheck = form.anno;
          this.enteCheck = form.ente;
          this.profiloCheck = form.profilo;
          this.materiaCheck = form.materia;
          this.argomentoCheck = form.argomento;
          this.difficoltaCheck = form.difficolta;
          this.immagineCheck = form.immagine;
          this.immagineSpiegazioneRispostaCheck = form.immagineSpiegazione;
          this.linkPreparazioneCheck = form.linkPreparazione;
        })
    );
    console.log(this.contenutoCheck);
  }

  changeIndex() {
    return this.banchedati
      .changeIndex(this.idBancaDati, this.idToModify, Number(this.index))
      .subscribe(
        (response: any) => {
          this.showMessage(response.message, 'success');
          this.getBancaDati();
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
        }
      );
  }

  applyIndex() {
    const index = Number(this.index);
    this.selectionService.setIndexView(index - 1);
  }

  modifyDomanda() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .modifyDomanda(
        this.idToModify,
        this.contenutoToModify === this.contenutoCheck
          ? undefined
          : this.contenutoToModify,
        this.rispostaAToModify === this.rispostaACheck
          ? undefined
          : this.rispostaAToModify,
        this.rispostaBToModify === this.rispostaBCheck
          ? undefined
          : this.rispostaBToModify,
        this.rispostaCToModify === this.rispostaCCheck
          ? undefined
          : this.rispostaCToModify,
        this.rispostaDToModify === this.rispostaDCheck
          ? undefined
          : this.rispostaDToModify,
        this.rispostaEToModify === this.rispostaECheck
          ? undefined
          : this.rispostaEToModify,
        this.rispostaFToModify === this.rispostaFCheck
          ? undefined
          : this.rispostaFToModify,
        this.correttaToModify === this.correttaCheck
          ? undefined
          : this.correttaToModify,
        this.numerazioneMidaToModify === this.numerazioneMidaCheck
          ? undefined
          : this.numerazioneMidaToModify,
        this.titoloDiStudioToModify === this.titoloDiStudioCheck
          ? undefined
          : this.titoloDiStudioToModify,
        this.annoToModify === this.annoCheck ? undefined : this.annoToModify,
        this.selectedEnteRiga === this.enteCheck
          ? undefined
          : this.selectedEnteRiga,
        this.selectedProfiloRiga === this.profiloCheck
          ? undefined
          : this.selectedProfiloRiga,
        this.materiaToModify === this.materiaCheck
          ? undefined
          : this.materiaToModify,
        this.argomentoToModify === this.argomentoCheck
          ? undefined
          : this.argomentoToModify,
        this.difficoltaToModify === this.difficoltaCheck
          ? undefined
          : this.difficoltaToModify,
        this.immagineToModify === this.immagineCheck
          ? undefined
          : this.immagineToModify,
        this.immagineSpiegazioneRispostaToModify ===
          this.immagineSpiegazioneRispostaCheck
          ? undefined
          : this.immagineSpiegazioneRispostaToModify,
        this.linkPreparazioneToModify === this.linkPreparazioneCheck
          ? undefined
          : this.linkPreparazioneToModify
      )
      .subscribe(
        (response: any) => {
          this.changeId.push(this.idToModify);
          this.changeIndex();
          this.modifyIndex = false;
          this.mappingService.avvia().subscribe();
          LoaderComponent.isLoading = false;
          this.showDomande = !this.showDomande;

          this.modifica2 = !this.modifica2;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          this.modifyIndex = false;
          console.error('Errore durante la creazione della materia:', error);
        }
      );
  }

  getEnte() {
    this.subscription.add(
      this.mappingService.getListaEnti().subscribe((enti) => {
        this.listaEnti = enti;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiEnti().subscribe((nomi) => {
        this.nomiEnti = nomi;
        this.combinedEnti = [...this.nomiEnti];
      })
    );
  }

  getProfili() {
    this.subscription.add(
      this.mappingService.getListaProfili().subscribe((profili) => {
        this.listaProfili = profili;
      })
    );
    this.subscription.add(
      this.mappingService.getNomiProfili().subscribe((nomi) => {
        this.nomiProfili = nomi;
        this.combinedProfili = [...this.nomiProfili];
        console.log(this.combinedProfili);
      })
    );
  }

  getRegioni() {
    this.subscription.add(
      this.mappingService.getListaRegioni().subscribe((regioni) => {
        this.listaRegioni = regioni;
      })
    );
  }

  getLib() {
    this.subscription.add(
      this.mappingService.getLibrerie().subscribe((lib) => {
        this.librerie = lib;
      })
    );
  }

  combinedMaterie: any[] = [];

  getMateria() {
    this.subscription.add(
      this.mappingService.getListaMaterie().subscribe((materie) => {
        this.listaMaterie = materie;
        this.combinedMaterie = this.listaMaterie.map((materia) => materia.nome);
      })
    );
  }

  combinedArgomenti: any[] = [];

  getArgomento() {
    this.subscription.add(
      this.mappingService.getListaArgomenti().subscribe((argomenti) => {
        this.listaArgomenti = argomenti;
        this.combinedArgomenti = this.listaArgomenti.map(
          (argomento) => argomento.nome
        );
      })
    );
  }
  combinedAnno: any[] = [];

  getAnno() {
    this.subscription.add(
      this.mappingService.getListaAnni().subscribe((anni) => {
        this.Anno = anni;
        this.combinedAnno = this.Anno.map((anno) => anno.nome);
      })
    );
  }

  combinedTitoli: any[] = [];

  getTitoli() {
    this.subscription.add(
      this.mappingService.getListaTitoliDiStudio().subscribe((titoli) => {
        this.listaTitoliStudio = titoli;
        this.combinedTitoli = this.listaTitoliStudio.map(
          (titolo) => titolo.nome
        );
      })
    );
  }

  avvia() {
    this.getLib();
    this.getEnte();
    this.getProfili();
    this.getRegioni();
    this.getMateria();
    this.getArgomento();
    this.getAnno();
    this.getTitoli();
    this.getBancaDati();
  }

  getFormModal() {
    this.subscription.add(
      this.modalForm.getForm().subscribe((form) => {
        this.domanda = form?.domanda;
        this.rispostaA = form?.rispostaA;
        this.rispostaB = form?.rispostaB;
        this.rispostaC = form?.rispostaC;
        this.rispostaD = form?.rispostaD;
        this.rispostaE = form?.rispostaE;
        this.rispostaF = form?.rispostaF;
        this.rispostaCorretta = form?.rispostaCorretta;
        this.numerazioneMida = form?.numerazioneMida;
        this.selectedTitoli = form?.titoliStudio;
        this.selectedAnnoRiga = form?.anno;
        this.selectedEnteRiga = form?.ente;
        this.selectedProfiloRiga = form?.profilo;
        this.selectedMateria = form?.materia;
        this.selectedArgomento = form?.argomento;
        this.difficoltaDomanda = form?.difficolta;
        this.immagineRiga = form?.immagine;
        this.immagineSpiegazioneRisposta = form?.immagineSpiegazione;
        this.linkVideoSpiegazione = form?.linkPreparazione;
      })
    );
  }

  scrollLeft: number = 0; // Define the type for scrollLeft

  mouseDown: boolean = false;
  startX: number = 0; // Define the type for startX

  @ViewChild('parent', { static: true })
  slider!: ElementRef;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  ngOnInit(): void {
    this.sub();
    this.avvia();
    this.getFormModal();
    this.getFitri();
  }

  pass() {
    this.formDetails.setPass(true);
    this.formDetails.setModifica(false);
  }

  sub() {
    this.subscription.add(
      this.formDetails.getShowPriv().subscribe((value) => {
        this.showPriv = value;
      })
    );
    this.subscription.add(
      this.formDetails.getShowPub().subscribe((value) => {
        this.showPub = value;
      })
    );
    this.subscription.add(
      this.formDetails.getModifica().subscribe((value) => {
        this.modifica = value;
      })
    );

    this.subscription.add(this.mappingService.profilo().subscribe());
    this.subscription.add(this.mappingService.argomento().subscribe());

    this.subscription.add(
      this.formDetails.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );
    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        this.idBancaDati = parseInt(params['id'], 10);
      })
    );

    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFile = image;
      })
    );
    this.subscription.add(
      this.imageService.getMostra().subscribe((mostra) => {
        this.showModal = mostra;
      })
    );
    this.subscription.add(
      this.formDetails.getPublic().subscribe((pubblica) => {
        this.pubblica = pubblica;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.imageService.setCroppedImage(null);
    this.selectionService.clearSelection();
    this.modalForm.setFormError(false);
    this.mappingService.setEnteID(0);
  }

  applyFilter() {
    this.getBancaDati();
  }
  check: number[] = [];

  aggiungiIdACheck(id: number) {
    this.check.push(id);
  }

  isChecked(id: number): boolean {
    return this.check.includes(id);
  }

  onSelectedLibreria(selectedValue: string) {
    if (selectedValue && selectedValue !== 'Seleziona o inserisci l’ente') {
      this.selectedLibreria = selectedValue;
    }
  }

  exportDetails(id: number[]) {
    this.domandeIds = id;
    LoaderComponent.isLoading = true;

    if (this.domandeIds !== null) {
      this.banchedati
        .exportDetails(this.domandeIds, this.idToNavigate)
        .subscribe(
          (response: any) => {
            this.selectionService.clearSelection();
            this.check = [];
            this.domandeIds = [];
            this.updateSelectionAll();

            this.downloadFile(response.body);
            LoaderComponent.isLoading = false;
          },
          (error) => {
            this.showMessage(error.error.message, 'error');
          }
        );
    }
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/zip' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'nome_del_file');
    document.body.appendChild(link);
    link.click();

    link.onload = () => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
  }

  pippo() {
    return this.check.length > 0 ? this.check.length : 0;
  }

  log() {}

  getRispostaByTag(risposte: any[], tag: string) {
    return (
      risposte.find((risposta) => risposta.tag == tag)?.contenuto ||
      'Non disponibile'
    );
  }

  getArrayOrUndefined(array: any[]): any[] | undefined {
    return array.length > 0 ? array : undefined;
  }

  reset() {
    this.filtri.setAnni([]);
    this.filtri.setEnti([]);
    this.filtri.setProfili([]);
    this.filtri.setRegioni([]);
    this.filtri.setMaterie([]);
    this.filtri.setArgomento([]);
    this.filtri.setDifficolta([]);
    this.filtri.setTitoliDiStudio([]);

    this.getBancaDati();
  }

  getFitri() {
    this.subscription.add(
      this.filtri.getAnni().subscribe((anni) => {
        this.selectedAnni = anni;
      })
    );
    this.subscription.add(
      this.filtri.getMaterie().subscribe((materie) => {
        this.selectedMaterie = materie;
      })
    );
    this.subscription.add(
      this.filtri.getEnti().subscribe((enti) => {
        this.selectedEnti = enti;
      })
    );
    this.subscription.add(
      this.filtri.getProfili().subscribe((profili) => {
        this.selectedProfili = profili;
      })
    );
    this.subscription.add(
      this.filtri.getArgomento().subscribe((argomenti) => {
        this.selectedArgomenti = argomenti;
      })
    );
    this.subscription.add(
      this.filtri.getDifficolta().subscribe((difficolta) => {
        this.selectedDifficoltaFiltro = difficolta;
      })
    );
    this.subscription.add(
      this.filtri.getTitoliDiStudio().subscribe((titoli) => {
        this.selectedNomiTitFiltro = titoli;
      })
    );
  }

  getBancaDati() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .getBancaDati(
        this.idToNavigate,
        this.ordinaPer,
        this.ordinaDesc,
        this.duplicate,
        this.search,
        this.getArrayOrUndefined(this.selectedAnni),
        this.getArrayOrUndefined(this.selectedMaterie),
        this.getArrayOrUndefined(this.selectedEnti),
        this.getArrayOrUndefined(this.selectedProfili),
        this.getArrayOrUndefined(this.selectedArgomenti),
        this.getArrayOrUndefined(this.selectedDifficoltaFiltro),
        this.getArrayOrUndefined(this.selectedNomiTitFiltro)
      )
      .subscribe(
        (response: any) => {
          this.bancaDati = response.map((response: any) => ({
            indice: response.bancadati[0].domanda_bancadati?.indice,
            id: response.id,
            domanda: response.contenuto,
            rispostaA: this.getRispostaByTag(response.risposte, 'A'),
            rispostaB: this.getRispostaByTag(response.risposte, 'B'),
            rispostaC: this.getRispostaByTag(response.risposte, 'C'),
            rispostaD: this.getRispostaByTag(response.risposte, 'D'),
            rispostaE: this.getRispostaByTag(response.risposte, 'E'),
            rispostaF: this.getRispostaByTag(response.risposte, 'F'),
            corretta: response.rispostaCorretta,
            correttaTag: response.risposte.find(
              (corretta: { corretta: boolean }) => corretta.corretta == true
            )?.tag,
            numerazioneMida: response.numerazioneMida || 'Non disponibile',
            titoloStudio: response.titoloStudio || 'Non disponibile',
            anno: response.anno || 'Non disponibile',
            materia: response.materia?.nome || 'Non disponibile',
            argomento: response.argomento?.nome || 'Non disponibile',
            difficolta: response.difficolta || 'Non disponibile',
            profilo: response?.profilo?.nome || 'Non disponibile',
            ente: response?.ente?.nome || 'Non disponibile',
            cover:
              response.immagine === 'Non disponibile'
                ? 'Non disponibile'
                : response.immagine,
            immagineSpiegazioneRisposta:
              response.immagineSpiegazioneRisposta || 'Non disponibile',
            linkPreparazione: response.linkPreparazione || 'Non disponibile',
          }));
          console.log(this.bancaDati[0].indice);
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
        }
      );
  }

  getSelectedIds() {
    return this.selectionService.getSelectedIds();
  }

  logA() {
    this.getSelectedIds();
  }

  add(event: MouseEvent) {
    this.showInput = !this.showInput;
    this.resetForm2();
    event.stopPropagation();
  }

  aggiungiDomanda() {
    LoaderComponent.isLoading = true;
    this.getFormModal();
    return this.banchedati
      .aggiungiDomanda(
        this.idBancaDati,
        this.domanda,
        this.rispostaA,
        this.rispostaB,
        this.rispostaCorretta,
        this.numerazioneMida,
        this.selectedAnnoRiga,
        this.selectedMateria,
        this.immagineRiga,
        this.immagineSpiegazioneRisposta,
        this.linkVideoSpiegazione,
        this.linkPreparazione,
        this.rispostaC,
        this.rispostaD,
        this.rispostaE,
        this.rispostaF,
        this.selectedTitoli,
        this.selectedEnteRiga,
        this.selectedProfiloRiga,
        this.selectedArgomento,
        this.difficoltaDomanda
      )
      .subscribe(
        (response: any) => {
          this.showInput = false;

          (this.domanda = ''),
            (this.rispostaA = ''),
            (this.rispostaB = ''),
            (this.rispostaC = ''),
            (this.rispostaD = ''),
            (this.rispostaE = ''),
            (this.rispostaF = ''),
            (this.rispostaCorretta = ''),
            (this.numerazioneMida = ''),
            (this.selectedTitoli = null),
            (this.selectedAnnoRiga = null),
            (this.enteRiga = ''),
            (this.profiloRiga = ''),
            (this.selectedMateria = null),
            (this.selectedArgomento = null),
            (this.difficoltaDomanda = 0),
            (this.immagineRiga = ''),
            (this.immagineSpiegazioneRisposta = ''),
            (this.linkVideoSpiegazione = ''),
            (this.linkPreparazione = '');
          this.mappingService.avvia().subscribe();
          this.getBancaDati();
          this.modalForm.setFormError(false);
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          if (error.status === 409) {
            this.showMessage(error.error.message, 'error');

            this.numerazioneMida = '';
          }
          if (error.status === 400) {
            this.showMessage('Compilare i campi correttamente', 'error');
            this.modalForm.setFormError(true);
          }

          LoaderComponent.isLoading = false;
          console.error('Errore durante la creazione dell ente:', error);
        }
      );
  }

  updateSelection() {
    const ids = [...this.selectionService.getSelectedIds()];

    let checkSet = new Set(this.check);
    let domandeIdsSet = new Set(this.domandeIds);

    ids.forEach((id) => {
      // Aggiungi l'ID al set check se non è già presente
      if (id !== 0) {
        checkSet.add(id);
        this.idBanche = id;
        domandeIdsSet.add(id);
      }
    });

    // Rimuovi l'ID da checkSet e domandeIdsSet se non è presente in ids
    this.check.forEach((id) => {
      if (!ids.includes(id)) {
        checkSet.delete(id);
        domandeIdsSet.delete(id);
      }
    });

    this.check = Array.from(checkSet);
    this.domandeIds = Array.from(domandeIdsSet);

    if (!ids.includes(this.idBanche)) {
      this.domandeIds = [];
      this.check = [];
      this.idBanche != null;
    }
    if (this.check.length === 0) {
      this.showSlide = false;
    }
    return this.selectionService.getSelectedIds();
  }

  updateSelectionAll() {
    // Svuota gli array all'inizio del metodo
    this.check = [];
    this.domandeIds = [];

    const selectedIds = this.selectionService.getSelectedIds();

    if (selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        this.check.push(id);
        this.domandeIds.push(id);
      });
    } else {
      this.selectionService.deselectAll();
      this.showSlide = false;
    }

    return selectedIds;
  }

  getForm() {
    this.subscription.add(
      this.formDetails.getForm().subscribe((form) => {
        this.nome = form?.titolo;
        this.descrizione = form?.descrizione;
        this.selectedEnte = form?.ente;
        this.selectedProfilo = form?.profilo;
        this.selectedRegione = form?.regione;
        this.selectedAnno = form?.anno;
        this.imageUrl = form?.imageUrl;
      })
    );
  }

  show(event: MouseEvent) {
    this.showModal = true;
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage() {
    this.imageService.setCroppedImage(null);
  }

  duplicateDomanda() {
    LoaderComponent.isLoading = true;
    return this.banchedati
      .duplicaBancaDatiDomanda(this.idBancaDati, this.check)
      .subscribe(
        (response: any) => {
          this.getBancaDati();
          this.selectionService.clearSelection();
          this.updateSelectionAll();
          this.check = [];
          this.showSlide = false;
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error('Errore ', error);
        }
      );
  }

  showDelete(event: MouseEvent) {
    this.elimina = !this.elimina;

    event.stopPropagation();
  }

  deleteDomanda() {
    LoaderComponent.isLoading = true;
    return this.banchedati
      .deleteDomanda(this.idBancaDati, this.check)
      .subscribe(
        (response: any) => {
          this.getBancaDati();
          this.selectionService.clearSelection();
          this.check = [];
          this.updateSelectionAll();
          this.showSlide = false;
          this.elimina = !this.elimina;
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
        }
      );
  }

  onButtonClick(event: MouseEvent) {
    if (this.fileInput) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
  }

  onFileSelected(event: any) {
    this.selectedFileXl = event.target.files[0];
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileUrl = reader.result as string;
        this.onSubmit(); // Sposta la chiamata a onSubmit() qui
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  onSubmit() {
    LoaderComponent.isLoading = true;
    if (this.selectedFileXl) {
      const formData = new FormData();

      formData.append('file', this.selectedFileXl);

      this.banchedati.ImportaSingola(formData, this.idBancaDati).subscribe(
        (response) => {
          this.getBancaDati();
          LoaderComponent.isLoading = false;
        },
        (error) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error('Errore durante la creazione del bando:', error);
        }
      );
      console.error(
        'Assicurati di compilare tutti i campi e selezionare un file.'
      );
    } else {
      this.showMessage('Seleziona un file', 'error');
      LoaderComponent.isLoading = false;
    }
  }

  createFromLibSingola() {
    LoaderComponent.isLoading = true;
    if (this.selectedLibreria) {
      this.banchedati
        .ImportaSingolaFromLib(this.idBancaDati, this.selectedLibreria.id)
        .subscribe(
          (response) => {
            this.getBancaDati();
            this.importLibreria = !this.importLibreria;
            LoaderComponent.isLoading = false;
          },
          (error) => {
            this.showMessage(error.error.message, 'error');
            LoaderComponent.isLoading = false;
            console.error('Errore durante la creazione del bando:', error);
          }
        );
    } else {
      this.showMessage('Seleziona una libreria', 'error');
      LoaderComponent.isLoading = false;
    }
  }

  selectAll(event: any) {
    if (event.target.checked) {
      // Se la checkbox "Seleziona tutto" è selezionata, seleziona tutti gli elementi
      this.bancaDati.forEach((item: any) => {
        this.selectionService.select(item); // passa l'oggetto intero, non solo l'ID
        this.check.push(item.id);
      });
    } else {
      // Se la checkbox "Seleziona tutto" non è selezionata, deseleziona tutti gli elementi
      this.selectionService.deselectAll();
      this.check = []; // Svuota this.check
    }
  }

  openPriv(event: MouseEvent) {
    this.showPriv = !this.showPriv;
  }

  openPub(event: MouseEvent) {
    this.showPub = !this.showPub;
  }

  chiudiModal() {
    this.formDetails.setShowPriv(false);
    this.formDetails.setShowPub(false);
  }

  setPublic() {
    LoaderComponent.isLoading = true;

    return this.banchedati.publishBanca(this.idBancaDati.toString()).subscribe(
      (response: any) => {
        this.formDetails.setShowPub(false);
        this.formDetails.setPublic(true);

        LoaderComponent.isLoading = false;
      },
      (error: any) => {
        this.showMessage(error.error.message, 'error');
        this.formDetails.setShowPub(false);
        LoaderComponent.isLoading = false;
        console.error('Errore durante la creazione della materia:', error);
      }
    );
  }

  setPrivate() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .unPubblishBanca(this.idBancaDati.toString())
      .subscribe(
        (response: any) => {
          this.formDetails.setShowPriv(false);
          this.formDetails.setPublic(false);

          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          this.formDetails.setShowPriv(false);
          LoaderComponent.isLoading = false;
          console.error('Errore durante la creazione della materia:', error);
        }
      );
  }

  handleSwitchChange(isChecked: boolean) {}
}
