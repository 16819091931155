<app-header></app-header>

<div class="mb-[30vh]">
  <div class="mr-10 ml-10 mt-10">
    <stdout-card>
      <stdout-card-header class="card-header">{{
        "crea notifica" | transloco
        }}</stdout-card-header>

      <stdout-card-content>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
          <div class="md:mr-[25px] mr-0">
            <div class="rounded-md w-full">
              <div class="mb-[10px] mt-1">
                <label class="primalabel" for="1"><b style="color: #0b0e42">{{
                    "titolo" | transloco
                    }}</b></label>
              </div>
              <stdout-text-input class="bordo" id="1" placeholder="Inserisci titolo" type="text"
                [(ngModel)]="title"></stdout-text-input>
            </div>
          </div>
          <div class="col-span-2 md:mt-1 mt-[10px]">
            <div class="rounded-md w-full">
              <div class="mb-[10px]">
                <label for="2" class="secondalabel">
                  <p>
                    <b style="color: #0b0e42">{{
                      "descrizione" | transloco
                      }}</b>
                  </p>
                </label>
              </div>
              <stdout-text-input class="bordo" id="2" placeholder="Inserisci descrizione" [(ngModel)]="description"
                type="text"></stdout-text-input>
            </div>
          </div>
          <div class="md:mt-1 mt-[10px] ml-0 md:ml-[25px]">
            <div class="mb-[10px]">
              <label class="terzalabel" for="1"><b style="color: #0b0e42">{{ "link" | transloco }}</b></label>
            </div>
            <stdout-text-input class="bordo" id="3" placeholder="Inserisci Link" [(ngModel)]="link"
              type="text"></stdout-text-input>
          </div>
        </div>

        <div class="flex flex-wrap md:flex-row flex-col">
          <div class="mt-[30px]">
            <div class="flex flex-wrap not">
              <label>
                <p [ngClass]="{'testo-errore': notificaErr }">{{ "dest_not" | transloco }}</p>
              </label>
            </div>
          </div>
          <div class="mt-[25px] ml-[25px]">
            <div class="flex flex-wrap fix-check-not items-center">
              <stdout-checkbox (change)="updateDestination(1, $event)" class="mr-2  cursor-pointer"
                #checkbox1></stdout-checkbox>

              <label>
                <p>{{ "tutti" | transloco }}</p>
              </label>
            </div>
          </div>
          <div class="mt-[25px] ml-[25px]">
            <div class="flex flex-wrap fix-check-not items-center">
              <stdout-checkbox (change)="updateDestination(2, $event)" class="mr-2  cursor-pointer"
                #checkbox2></stdout-checkbox>
              <label>
                <p>{{ "solo_mil" | transloco }}</p>
              </label>
            </div>
          </div>
          <div class="mt-[25px] ml-[25px]">
            <div class="flex flex-wrap fix-check-not items-center">
              <stdout-checkbox (change)="updateDestination(3, $event)" class="mr-2  cursor-pointer"
                #checkbox3></stdout-checkbox>
              <label>
                <p>{{ "solo_pub" | transloco }}</p>
              </label>
            </div>
          </div>
          <div class="w-[100%] mr-[25px]" style="text-align: -webkit-right">
            <div class="md:w-[200px]  cursor-pointer w-full margine">
              <stdout-button class="btn-blu" size="large" color="white" (click)="createNotification()">
                <p>{{ "crea_nuova" | transloco }}</p>
              </stdout-button>
            </div>
          </div>
        </div>
      </stdout-card-content>
    </stdout-card>
  </div>

  <hr />
  <div class="flex justify-between items-center mt-10 px-10">
    <div class="flex input-container" *ngIf="!isPhone">
      <stdout-icon-button class="lente" style="margin: 2px 10px 0px 0px" size="small" background="secondary"
        color="white" borderColor="secondary" [icon]="faMagnifyingGlass">
      </stdout-icon-button>
      <div class="md:w-[300px]">
        <stdout-text-input class="search-bar" placeholder="Cerca..." [(ngModel)]="search"
          (keyup)="applyFilter()"></stdout-text-input>
      </div>
    </div>
    <div class="posi">
      <div class="mr-[15px]">
        <p>Filtri</p>
      </div>

      <stdout-icon-button class="lente  cursor-pointer" [icon]="faCalendarDays"
        (click)="visibile($event)"></stdout-icon-button>

      <div *ngIf="showDropdown" class="dropdown ml-auto flex flex-col justify-center items-center px-5"
        (click)="$event.stopPropagation()">
        <div class="w-full data-text">
          <h1 class="mb-[10px]">Data</h1>
          <stdout-text-input class="date" type="date" (input)="handleDateSelection($event)" [(ngModel)]="date"
            (keyup)="applyFilter()"></stdout-text-input>
        </div>
        <div class="flex justify-between mt-10 w-full">
          <div class="mr-[10px] md:w-[120px]  cursor-pointer">
            <stdout-button (click)="date=null;applyFilter()" class="btn-bianco" size="large" color="black">
              <h2>{{ "resetta" | transloco }}</h2>
            </stdout-button>
          </div>
          <div class="md:w-[120px]  cursor-pointer">
            <stdout-button class="btn-blu" size="large" color="white" (click)="applyFilter()">
              <h2>{{ "applica" | transloco }}</h2>
            </stdout-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hi prt2 ml-10 mr-10 mt-10 font-tab" *ngIf="notifications.length > 0">
    <stdout2-table class="op font-tab" [data]="notifications">
      <span stdoutTableColumn label="DATA" property="data"></span>

      <span stdoutTableColumn label="ORA" property="ora"></span>
      <span stdoutTableColumn label="MESSAGGIO NOTIFICA" property="messaggio"></span>

      <ng-template #dataTableCell let-item="item">
        <div class="flex items-end gap-x-4">
          <stdout-icon-button style="margin-right: 5px" size="small" color="primary" borderColor="primary"
            background="white" [icon]="faPencil">
          </stdout-icon-button>
          <stdout-icon-button color="primary" borderColor="primary" background="white" size="small"
            [icon]="faTrashCan"></stdout-icon-button>
        </div>
      </ng-template>
    </stdout2-table>
  </div>

  <div class="mt-10 flex justify-center" *ngIf="notifications.length > 0">
    <stdout-pagination [current]="page" [total]="totalPages" (goTo)="getPage($event)" (next)="goNext($event)"
      (previous)="goBack($event)"></stdout-pagination>
  </div>
</div>

<div class="absolute top-[5%] w-[88.489%] flex justify-center items-center">
  <div class="w-fit h-full min-w-[500px]">
    <app-toast colore="#DF4343" [testo]="messErr" [showToast]="failToast"></app-toast>
  </div>
</div>