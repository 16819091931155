import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { FiltriBancheService } from '@services/filtri-banche.service';
import { MappingService } from '@services/mapping.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filtri-banche-dati',
  templateUrl: './filtri-banche-dati.component.html',
  styleUrls: ['./filtri-banche-dati.component.scss'],
})
export class FiltriBancheDatiComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @Input() listaRegioni: any;
  @Input() listaEnti: any;
  @Input() listaProfili: any;
  @Input() Anno: any;
  @Input() nomiEnti: any;
  @Input() combinedEnti: any;

  selectedAnni: string[] = [];
  selectedAnniFiltro: string[] = [];
  selectedNomiEntiFiltro: string[] = [];
  selectedNomiRegioniFiltro: string[] = [];
  selectedEnti: number[] = [];
  selectedEntiFiltro: any[] = [];
  initialStateSelectedProfili: number[] = [];
  selectedProfiliFiltro: number[] = [];
  selectedRegioni: number[] = [];
  initialStateSelectedRegioni: number[] = [];
  selectedRegioniFiltro: number[] = [];
  selectedNomiProfiliFiltro: string[] = [];
  selectedProfili: number[] = [];
  initialSelectedEnti: number[] = [];
  public faXmark = faXmark;
  constructor(
    private fb: FormBuilder,
    private banchedati: BanchedatiService,
    private mappingService: MappingService,
    private filtri: FiltriBancheService
  ) {}

  ngOnInit(): void {
    this.prendiFiltri();
  }

  prendiFiltri() {
    this.filtri.getAnni().subscribe((anni) => {
      this.selectedAnni = anni;
    });
    this.filtri.getEnti().subscribe((enti) => {
      this.selectedEnti = enti;
    });
    this.filtri.getProfili().subscribe((profili) => {
      this.selectedProfili = profili;
    });
    this.filtri.getRegioni().subscribe((regioni) => {
      this.selectedRegioni = regioni;
    });
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  addSelectedAnni(selectedObject: any) {
    if (selectedObject) {
      this.selectedAnniFiltro.push(selectedObject);
      this.selectedNomiAnniFiltro.push(selectedObject);
    }
    this.selectedAnni = [...this.selectedAnniFiltro];
  }

  addSelectedRegioni(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedRegioniFiltro.push(selectedObject.id);
      this.selectedNomiRegioniFiltro.push(selectedObject.nome);
    }
    this.selectedRegioni = [...this.selectedRegioniFiltro];
  }

  addSelectedEnte(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedEntiFiltro.push(selectedObject.id);
      this.selectedNomiEntiFiltro.push(selectedObject.nome);
    }
    this.mappingService.setEnteID(selectedObject.id);
    this.selectedEnti = [...this.selectedEntiFiltro];
    this.mappingService.profilo().subscribe();
  }

  addSelectedProfili(selectedObject: any) {
    if (selectedObject && selectedObject.id) {
      this.selectedProfiliFiltro.push(selectedObject.id);
      this.selectedNomiProfiliFiltro.push(selectedObject.nome);
    }
    this.selectedProfili = [...this.selectedProfiliFiltro];
  }

  rimuoviVal(id: any) {
    const index = this.selectedEntiFiltro.indexOf(id);
    if (index > -1) {
      this.selectedEntiFiltro.splice(index, 1);
      this.selectedNomiEntiFiltro.splice(index, 1);
    }

    if (this.selectedEntiFiltro.length === 0) {
      this.selectedEnti = [...this.initialSelectedEnti];
    } else {
      this.selectedEnti = [...this.selectedEntiFiltro];
    }
    this.selectedEntiFiltro = [];
  }

  rimuoviValProfilo(id: any) {
    const index = this.selectedProfiliFiltro.indexOf(id);
    if (index > -1) {
      this.selectedProfiliFiltro.splice(index, 1);
      this.selectedNomiProfiliFiltro.splice(index, 1);
    }

    if (this.selectedProfiliFiltro.length === 0) {
      this.selectedProfili = [...this.initialStateSelectedProfili];
    } else {
      this.selectedProfili = [...this.selectedProfiliFiltro];
    }
    this.selectedProfiliFiltro = [];
  }

  rimuoviValRegione(id: any) {
    const index = this.selectedRegioniFiltro.indexOf(id);
    if (index > -1) {
      this.selectedRegioniFiltro.splice(index, 1);
      this.selectedNomiRegioniFiltro.splice(index, 1);
    }

    if (this.selectedRegioniFiltro.length === 0) {
      this.selectedRegioni = [...this.initialStateSelectedRegioni];
    } else {
      this.selectedRegioni = [...this.selectedRegioniFiltro];
    }
    this.selectedRegioniFiltro = [];
  }

  selectedNomiAnniFiltro: any[] = [];
  initialStateSelectedAnni: any[] = [];

  rimuoviAnno(id: any) {
    const index = this.selectedAnniFiltro.indexOf(id);
    if (index > -1) {
      this.selectedAnniFiltro.splice(index, 1);
      this.selectedNomiAnniFiltro.splice(index, 1);
    }

    if (this.selectedAnniFiltro.length === 0) {
      this.selectedAnni = [...this.initialStateSelectedAnni];
    } else {
      this.selectedAnni = [...this.selectedAnniFiltro];
    }
  }

  @Output() onSubmit = new EventEmitter<any>();
  applicaFiltri() {
    this.filtri.setAnni(this.selectedAnni);
    this.filtri.setEnti(this.selectedEnti);
    this.filtri.setProfili(this.selectedProfili);
    this.filtri.setRegioni(this.selectedRegioni);

    this.onSubmit.emit();
  }
  @Output() reset = new EventEmitter<any>();

  resettaFiltri() {
    this.selectedAnniFiltro = [];
    this.selectedNomiEntiFiltro = [];
    this.selectedNomiRegioniFiltro = [];
    this.selectedEntiFiltro = [];
    this.selectedProfiliFiltro = [];
    this.selectedRegioniFiltro = [];
    this.selectedNomiProfiliFiltro = [];

    this.reset.emit();
  }
}
