



<div class="grid grid-cols-1 sm:grid-cols-2 full-height-container">
  <!-- Contenuto della prima colonna -->
  <div class="w-full items-center grid">
    <div class="adatta">
      <div class="flex justify-center resp">
        <img src="../../assets/images/logo.png" alt="Logo" />
      </div>

      <div class="flex justify-center mt-[60px]">
        <div class="md:w-[400px] w-full">
          <div>
            <form [formGroup]="loginForm" class="example-form grid">
              <div class="pb-10 md:pb-[60px]">
                <div class="input-container">
                  <div class="pb-[10px]">
                    <label><p>Email</p></label>
                  </div>
                  <div class="md:w-[408px] mobile">
                    <stdout-text-input
                      class="bordo"
                      formControlName="email"
                      type="email"
                      placeholder="Email"
                    >
                    </stdout-text-input>
                  </div>
                </div>
                <div class="pt-10 pb-[10px]">
                  <label><p>Password</p></label>
                </div>
                <div class="flex input-container mobile">
                  <div class="w-[408px]">
                    <stdout-text-input
                      class="bordo"
                      formControlName="password"
                      style="width: 100%"
                      [type]="hide ? 'password' : 'text'"
                      placeholder="Password"
                    >
                    </stdout-text-input>
                  </div>
                  <div class="fix-login-icona">
                    <stdout-icon-button class="log-icon" (click)="hide = !hide"
                      ><fa-icon [icon]="hide ? faEye : faEyeSlash"></fa-icon
                    ></stdout-icon-button>
                  </div>
                </div>
                <div class="forgot-password w-[408px] pt-[10px] mobile">
                  <i class="t-primary c-pointer" [routerLink]="['../recovery']"
                    ><p>Password dimenticata?</p></i
                  >
                </div>
                <div class="flex between items-center"></div>
              </div>

              <stdout-button
                class="btn-blu md:w-[408px] md:h-[52px] mobile"
                size="large"
                color="white"
                (click)="login()"
                type="submit"
              >
                <h2>Accedi</h2>
              </stdout-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Contenuto della seconda colonna -->
  <div class="nascondi">
    <img
      src="../../assets/images/ret.png"
      alt=""
      style="fill: #0b0e42; opacity: 0.5 rgba(11, 14, 66); flex-shrink: 0"
    />
  </div>

  <!-- Aggiungi altre colonne se necessario -->
</div>
