import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { api } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user: any;
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private rest: RestService,
    private route: Router,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.rest.post(`${api.endpoint.login}`, { email, password });
  }

  active(token: string) {
    return this.rest.post(`${api.endpoint.active}/${token}`, {});
  }

  refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem('refreshToken');
    const token = localStorage.getItem('token');

    if (!refreshToken || !token) {
      throw new Error('Token non disponibili');
    }
    return this.http.post(
      `${api.baseUrl}${api.endpoint.refreshToken}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          'refresh-token': refreshToken.trim(),
        },
      }
    );
  }

  getMe() {
    let token = localStorage.getItem('token');
    let roleId = localStorage.getItem('roleId');

    return this.rest.get('user/me');
  }

  deleteMe() {
    let token = localStorage.getItem('token');
    let roleId = localStorage.getItem('roleId');

    return this.rest.delete('user/me');
  }

  modifyMe() {
    let token = localStorage.getItem('token');
    let roleId = localStorage.getItem('roleId');

    return this.rest.put('user/me', { roleId: roleId });
  }

  modifyData(firstName: string, lastName: string) {
    return this.rest.patch(`${api.endpoint.modificaDati}`, {
      firstName,
      lastName,
    });
  }

  getAll(search = '', page = 0, typeSubscription: Array<number>) {
    let token = localStorage.getItem('token');
    return this.rest.post(`admin/paginate/users?page=${page}`, {
      wordKey: search,
      ...(typeSubscription.length > 0 && { tipoAbbonamento: typeSubscription }),
    });
  }

  paginateOperator(search = '', page = 0) {
    return this.rest.post(`admin/paginate/operators?page=${page}`, {
      wordKey: search,
    });
  }

  logOut() {
    return this.rest.delete(`${api.endpoint.logout}`);
  }

  register(user: any) {
    return this.rest.post(api.endpoint.register, user);
  }

  recovery(email: string, url: string) {
    return this.rest.post(api.endpoint.sendEmail, { email, url });
  }

  resetPassword(
    newPassword: string,
    confirmNewPassword: string,
    recoveryToken: string
  ) {
    return this.rest.post(`${api.endpoint.resetPassword}/${recoveryToken}`, {
      password: newPassword,
      confirmPassword: confirmNewPassword,
    });
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) {
    return this.rest.put(api.endpoint.changePassword, {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    });
  }

  logout() {
    this.route.navigate(['auth/login']);
    localStorage.clear();
    return this.rest.delete(api.endpoint.logout);
  }

  createOperator(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) {
    return this.rest.post(`${api.endpoint.createOperator}`, {
      email,
      password,
      firstName,
      lastName,
    });
  }

  deleteOperator(id: number) {
    return this.rest.delete(`${api.endpoint.deleteOperator}/${id}`);
  }

  modifyOperator(id: number, firstName: string, lastName: string) {
    return this.rest.put(`${api.endpoint.modifyOperator}/${id}`, {
      firstName,
      lastName,
    });
  }
}

export interface User {
  email: any;
  password: any;
  role: number;
}
