      <div class="absolute top-0 left-0 z-[99999] bg-white grid overflow-hidden h-screen w-screen grid-cols-1 md:grid-cols-3">
          <div class=" nascondi">
              <img src="../../../assets/images/Subtract.png" alt="" style=" fill: #0B0E42; opacity: 0.5 rgba(11, 14, 66, );  flex-shrink: 0; height:100vh; width: 100%;">
          </div>
          <div class="grid  justify-center items-center px-5 md:px-10">
              <div  class="flex justify-center resp">
                  <img src="../../../assets/images/logo.png" alt="Logo" >
                </div>
                <div class="grid items-center justify-center">
                  <h1 class="text-center font-montserrat text-2xl font-semibold leading-normal" style="color: #0B0E42;">
                  Hai attivato il tuo account correttamente
                    </h1>
                    <h3 class="text-center font-montserrat text-base font-medium leading-normal mt-5 md:mt-10 " style="color: #0B0E42;">
                      Adesso puoi tornare all’applicazione ed effettuare l'accesso
                    </h3>
                
                    </div>

          </div>
          <div class="nascondi">
              <img src="../../../assets/images/ret.png" alt="" style=" fill: #0B0E42; opacity: 0.5 rgba(11, 14, 66, );  flex-shrink: 0; height:100vh; width: 100%; ">
          </div>
      </div>
      