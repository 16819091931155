import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { api, environment } from '../../environments/environment';
import { Observable } from 'rxjs';

declare interface RequestCustomOptions {
  authorization?: boolean;
  data?: object;
  headers?: { [header: string]: string | string[] };
}

var defaultOptions: RequestCustomOptions = {
  authorization: true,
  headers: {
    Authorization: `${api.prefixAuthorization} ${localStorage.getItem(
      'token'
    )}`,
  },
  data: undefined,
};

export declare interface Response<T = null> {
  data?: T;
  success: boolean;
  code: number;
}

@Injectable({
  providedIn: 'root',
})
export class RestService {
  request: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private http: HttpClient) {
    defaultOptions = {
      authorization: true,
      headers: {
        Authorization: `${api.prefixAuthorization} ${localStorage.getItem(
          'token'
        )}`,
      },
      data: undefined,
    };
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    defaultOptions = {
      authorization: true,
      headers: {
        Authorization: `${api.prefixAuthorization} ${localStorage.getItem(
          'token'
        )}`,
      },
      data: undefined,
    };
  }

  prepareOptions = (options: RequestCustomOptions) => {
    this.request.emit(true);
    for (const optionKey of Object.keys(defaultOptions)) {
      if (options === undefined) {
        options = defaultOptions;
      }
    }
  };

  post = (
    url: string,
    data: FormData | object | string,
    options: RequestCustomOptions = defaultOptions
  ) => {
    this.prepareOptions(options);
    return this.http
      .post(
        api.baseUrl + (environment.stage || '') + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  put = (
    url: string,
    data: FormData | object | string,
    options: RequestCustomOptions = defaultOptions
  ) => {
    this.prepareOptions(options);
    return this.http
      .put(
        api.baseUrl + (environment.stage || '') + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  get = (url: string, options: RequestCustomOptions = defaultOptions) => {
    this.prepareOptions(options);
    if (options.data) {
      url += url.indexOf('?') > -1 ? '&' : '?';
      let i = 0;
      for (const key of Object.keys(options.data)) {
        url +=
          key +
          '=' +
          options.data +
          (i === Object.keys(options.data).length - 1 ? '' : '&');
        i++;
      }
    }
    return this.http
      .get(
        api.baseUrl + (environment.stage || '') + url,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  get$<T>(
    url: string,
    options: RequestCustomOptions = defaultOptions,
    directResponse = false
  ): Observable<T> {
    this.prepareOptions(options);
    if (options.data) {
      url += url.indexOf('?') > -1 ? '&' : '?';
      let i = 0;
      for (const key of Object.keys(options.data)) {
        url +=
          key +
          '=' +
          options.data +
          (i === Object.keys(options.data).length - 1 ? '' : '&');
        i++;
      }
    }

    if (directResponse) {
      return this.http
        .get<T>(
          api.baseUrl + (environment.stage || '') + url,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          catchError((err) => {
            // this.authService.logout();
            console.error(err);
            throw err;
          }),
          finalize(() => this.request.emit(false))
        );
    } else {
      return this.http
        .get<GenericResponse<T>>(
          api.baseUrl + (environment.stage || '') + url,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((src) => src.data),
          catchError((err) => {
            // this.authService.logout();
            console.error(err);
            throw err;
          }),
          finalize(() => this.request.emit(false))
        );
    }
  }

  post$<T>(
    url: string,
    data: FormData | object | string,
    options: RequestCustomOptions = defaultOptions,
    directResponse = false
  ): Observable<T> {
    this.prepareOptions(options);

    if (directResponse) {
      return this.http
        .post<T>(
          api.baseUrl + (environment.stage || '') + url,
          data,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(finalize(() => this.request.emit(false)));
    } else {
      return this.http
        .post<GenericResponse<T>>(
          api.baseUrl + (environment.stage || '') + url,
          data,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((response) => {
            if (response.success) {
              return response.data;
            } else {
              if (response.data && (response.data as any).message) {
                const dataStr: string = (response.data as any)
                  .message as string;

                throw new Error(dataStr);
              }
              throw new Error(response.additionalData);
            }
          }),

          finalize(() => this.request.emit(false))
        );
    }
  }

  options = (url: string) => {
    this.request.emit(true);
    return this.http
      .options(api.baseUrl + (environment.stage || '') + url)
      .pipe(finalize(() => this.request.emit(false)));
  };

  delete = (url: string, options: RequestCustomOptions = defaultOptions) => {
    this.request.emit(true);
    return this.http
      .delete(
        api.baseUrl + (environment.stage || '') + url,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  patch = (
    url: string,
    data: FormData | object | string,
    options: RequestCustomOptions = defaultOptions
  ) => {
    this.prepareOptions(options);
    return this.http
      .patch(
        api.baseUrl + (environment.stage || '') + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };
}

export interface GenericResponse<T> {
  data: T;
  success: boolean;
  additionalData: any;
}
