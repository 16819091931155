<div class="w-full h-full flex justify-center items-center mt-5 " *ngIf="showToast">
    <div class="w-full toast-comp flex items-center px-5" [ngStyle]="{'background-color': colore}">
<div class=" w-full flex justify-around items-center">
    <div class="w-full flex items-center ">
        <p class="whitespace-nowrap uppercase">{{testo}}</p>
    </div>
    <div class="w-full flex items-center justify-end ml-[15px] icona-toast">
        <fa-icon (click)="showToast=false"  [icon]="faCircleXmark"></fa-icon>
    </div>
</div>
    </div>
    </div>