<div class="grid grid-cols-1 sm:grid-cols-2 full-height-container">
  <div class="items-center grid">
    <div>
      <div class="flex justify-center h-[300px]">
        <img
          src="../../assets/images/logo.png"
          alt="Logo"
          style="width: 300px; height: 300px"
        />
      </div>

      <div class="container" style="margin-top: 75px !important">
        <div class="card-container">
          <div>
            <label><h3>Inserisci la nuova password</h3></label>

            <form [formGroup]="passwordForm" class="example-form grid">
              <div class="mt-10 mb-[10px]">
                <label><p>Nuova password</p></label>
              </div>
              <div class="flex input-container">
                <stdout-text-input
                  class="bordo w-[408px] mobile"
                  style="width: 100%"
                  isErrorMessage="Lunghezza minima 8 caratteri. La password deve contenere almeno una
              maiuscola, una minuscola, un numero ed un carattere speciale"
                  [isError]="
                    passwordForm.controls['password'].hasError('pattern')
                  "
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                  placeholder="Nuova password"
                >
                </stdout-text-input>

                <stdout-icon-button
                  class="fix-login-icona"
                  style="top: 13px; position: relative; right: 30px; width: 0px"
                  (click)="hide = !hide"
                  ><fa-icon [icon]="hide ? faEye : faEyeSlash"></fa-icon
                ></stdout-icon-button>
              </div>
              <div class="mt-10 mb-[10px]">
                <label><p>Ripeti la nuova password</p></label>
              </div>
              <div class="flex input-container">
                <stdout-text-input
                  class="bordo w-[408px] mobile"
                  style="width: 100%"
                  isErrorMessage="Le password devono coincidere,"
                  [isError]="
                    passwordForm.controls['confirmPassword'].value !==
                    passwordForm.controls['password'].value
                  "
                  formControlName="confirmPassword"
                  [type]="hide ? 'password' : 'text'"
                  placeholder="Conferma nuova password"
                >
                </stdout-text-input>
                <stdout-icon-button
                  class="fix-login-icona"
                  style="top: 13px; position: relative; right: 30px; width: 0px"
                  (click)="hide = !hide"
                  ><fa-icon [icon]="hide ? faEye : faEyeSlash"></fa-icon
                ></stdout-icon-button>
              </div>
              <div class="mt-[60px]"></div>
              <stdout-button
                class="btn-blu w-[408px] h-[52px] mobile-btn"
                color="white"
                size="large"
                (click)="reset()"
                type="submit"
              >
                <h2>Conferma</h2>
              </stdout-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nascondi">
    <img
      src="../../assets/images/ret.png"
      alt=""
      style="fill: #0b0e42; opacity: 0.5 rgba(11, 14, 66); flex-shrink: 0"
    />
  </div>

  <!-- Aggiungi altre colonne se necessario -->
</div>
