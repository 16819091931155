import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private formSource = new BehaviorSubject<any>(null);
  currentForm = this.formSource.asObservable();

  private formError = new BehaviorSubject<boolean>(false);
  private existPass = new BehaviorSubject<boolean>(false);
  private idBancaDati = new BehaviorSubject<string[]>([]);

  private showPopUp = new BehaviorSubject<boolean>(false);

  setShowPopUp(value: boolean) {
    this.showPopUp.next(value);
  }

  getShowPopUp() {
    return this.showPopUp.asObservable();
  }

  setIdBancaDati(value: string[]) {
    this.idBancaDati.next(value);
  }

  getIdBancaDati() {
    return this.idBancaDati.asObservable();
  }

  setExistPass(value: boolean) {
    this.existPass.next(value);
    console.log('existPass', value);
  }

  getExistPass() {
    return this.existPass.asObservable();
  }

  setFormError(value: boolean) {
    this.formError.next(value);
  }

  getFormError() {
    return this.formError.asObservable();
  }

  changeForm(form: any) {
    this.formSource.next(form);
  }

  getForm() {
    return this.formSource.asObservable();
  }
}
