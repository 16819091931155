import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModaleCreaService {
  private formSource = new BehaviorSubject<any>(null);
  currentForm = this.formSource.asObservable();

  private error = new BehaviorSubject<string>('');
  private formError = new BehaviorSubject<boolean>(false);
  private existPass = new BehaviorSubject<boolean>(false);

  private import = new BehaviorSubject<boolean>(false);
  private confirm = new BehaviorSubject<boolean>(false);

  constructor() {}

  setConfirm(value: boolean) {
    this.confirm.next(value);
  }

  getConfirm() {
    return this.confirm.asObservable();
  }

  setImport(value: boolean) {
    this.import.next(value);
  }

  getImport() {
    return this.import.asObservable();
  }

  setExistPass(value: boolean) {
    this.existPass.next(value);
  }

  getExistPass() {
    return this.existPass.asObservable();
  }

  setFormError(value: boolean) {
    this.formError.next(value);
  }

  getFormError() {
    return this.formError.asObservable();
  }

  changeForm(form: any) {
    this.formSource.next(form);
  }

  getForm() {
    return this.formSource.asObservable();
  }

  setError(value: any) {
    this.error.next(value);
  }

  getError() {
    return this.error.asObservable();
  }
}
