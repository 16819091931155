import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() isChecked!: boolean;
  @Output() change = new EventEmitter<boolean>();

  onSwitchChange(event: any) {
    this.change.emit(event.target.checked);
  }
}
