<app-header></app-header>

<div class="mb-[30vh]">
<div class=" mt-10 px-10 grid grid-cols-2 ">
  <!-- Prima colonna -->
  <div class="flex items-center ">
    <div class="flex items-end" *ngIf="!isPhone">
      <stdout-icon-button  class="lente  cursor-pointer" style="margin: 2px 10px 0px 0px;" size="small" background="secondary" color="white"
        borderColor="secondary" [icon]="faMagnifyingGlass">
      </stdout-icon-button>
      <div class="w-[300px]  ">
        <stdout-text-input class="search-bar" placeholder="Nome utente" [(ngModel)]="search" (keyup)="applyFilter()"></stdout-text-input>


    </div>
    </div>
  </div>

  <!-- Seconda colonna -->
  <div class="">
    <div class="posi">

      <div class="pippo">
        <div class="mr-[15px] ">
          <p>Filtri</p>
        </div>
            </div>
            <stdout-icon-button class="bottoneDrop lente  cursor-pointer"  [icon]="faFilter" (click)="visibile($event)"></stdout-icon-button>

            <div *ngIf="showDropdown" class="dropdown ml-auto" (click)="$event.stopPropagation()">
              <div class=" mt-[25px]">
              <div class="primaL px-[25px]">
                <label for select="1" >{{"tipo di abbonamento"|transloco}}</label>
                <div class="fix-select mt-[10px] ">
                  <stdout-select
                  id="select1"
                  [clearable]="false"
                  [(ngModel)]="typeSubscription"
                  (onSelectedItem)="selezionaVal($event.event)"
                  [data]="abb"
                  [value]="selectValue"
                  [placeholder]="place"
                  #selectElement
                  bindLabel="duration"
                  bindValue="id"
                >
                </stdout-select>
                
              </div>
           
              <div class="flex flex-col w-fit " *ngFor="let id of y">
                <div class="lab flex items-center justify-center  mt-[10px] w-full h-full" >
                  <div class="flex items-center justify-center w-full h-full">
                    <p class="whitespace-nowrap">
                      {{ getSubscriptionName(id) || 'Gratis' }}
                    </p>
                  </div>
                  <div class="icona-fil flex items-center justify-center w-full h-full">
                    <fa-icon class="flex justify-center items-center w-full h-full" [icon]="faXmark" (click)="rimuoviAbb(id)"></fa-icon>
                  </div>
                </div>
              </div>
              
              
            </div>

      <div class="secondaL px-[25px]  mt-[25px]">
        <label for="select2">{{"data_di_scadenza"|transloco}}</label>
        <div class="fix-date-picker mt-[10px]  ">
        <stdout-text-input type="date"></stdout-text-input>
        </div>
        <div class="sec"  *ngIf="selectedYears.length > 0">
        <label class="flex justify-center lab" *ngFor="let year of selectedYears">
          <p class="mt-2">{{year}}</p>
          <fa-icon class="icon  text-white" [icon]="faXmark"></fa-icon>
        </label>

       </div>

              </div>

              <div class="flex items-center justify-center bottoni mt-10 mb-[25px] px-[25px]">
                <div class="w-[120px]  cursor-pointer mr-[5px] ">
              <stdout-button class="btn-bianco" (click)="resetta()" color="black"size="large" ><h2>{{"resetta"|transloco}}</h2></stdout-button>
            </div>
            <div class="w-[120px]  cursor-pointer ml-[5px] ">
              <stdout-button (click)="usersInfo()" class="btn-blu" color="white"size="large"><h2>{{"applica"|transloco}}</h2></stdout-button>
            </div>
            </div>
            </div>
          </div>
          </div>
  </div>

  <!-- Puoi aggiungere altre celle se necessario -->
</div>

<div class="w-full h-full flex justify-end items-center px-10 mt-10">
  <div class="utenti-reg">
    <p><b>{{attivi}} utenti attivi</b></p>
  </div>

</div>
<div class="mt-[10px] px-10 w-full">
  <app-tabella2
  *ngIf="utenti.length>0"
  [data]="utenti"
  [dataView]="utenti"
  [columns]="columns"
  [showCheck]='false'
  (itemCliccato)="log($event)"
  [isScrollable]="isScrollable"
  [changeId]="changeId"
  [perTe]="perTeId"
   
>
</app-tabella2>

  
</div>



<div class="mt-10 flex justify-center" *ngIf="utenti.length>0">
  <stdout-pagination [current]="page" [total]="totalPages" (goTo)="getPage($event)" (next)=" goNext($event)"
  (previous)="goBack($event)"></stdout-pagination>
</div>
</div>



<!-- modale  -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="showModale" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto   max-h-[80vh] min-w-[500px]">
      <app-modale-utenti (click)="$event.stopPropagation()"
      [nome]="nome"
      [cognome]="cognome"
      
      ></app-modale-utenti>
  
</div> 
  </div>
</div>