<div class="w-full h-full flex items-stretch scroll">
  <!-- prima tab -->
  <div class="w-1/10 h-full head-check flex flex-col" *ngIf="showCheck">
    <table class="min-w-full table-auto flex-grow">
      <thead>
        <tr #tableHeader>
          <th class="px-[14px] py-[16px] sopratab max-w-[58px] max-h-[58px]">
            <div class="w-full h-full flex items-center justify-center  cursor-pointer">
              <stdout-checkbox [checked]="(check$ | async) || false"
                (change)="onCheckboxChangeAll($event)"></stdout-checkbox>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="w-full h-[10px] !bg-[#FCFAFA]"></tr>
      </tbody>
      <div class="corpo-check">
        <tbody>
          <tr *ngFor="let row of dataView; let rowIndex = index">
            <td #myDiv class="px-[14px] py-[16px] check-header-fix check-corpo cursor-pointer"
              [style.height.px]="maxHeight">
              <div class="w-full h-full flex items-center justify-center"
                [ngClass]="{ 'check-corpo-te': perTe.includes(row.id) }">
                <stdout-checkbox [checked]="row.selected" (change)="onCheckboxChange(row, $event)"
                  [id]="row.id"></stdout-checkbox>
              </div>
            </td>
          </tr>
        </tbody>
      </div>
    </table>
  </div>
  <!-- seconda tabella -->
  <div class="w-full h-full ml-[10px] head-dati corpo-dati flex flex-col"
    [ngClass]="{ 'scrollable-table overflow-x-auto ': isScrollable }"
    (mousedown)="isScrollable ? startDragging($event, scrollableDiv) : null"
    (mouseup)="isScrollable ? stopDragging() : null" (mouseleave)="isScrollable ? stopDragging() : null"
    (mousemove)="isScrollable ? moveEvent($event, scrollableDiv) : null" #scrollableDiv>
    <table class="min-w-full table-auto flex-grow overflow-hidden">
      <thead>
        <tr #tableHeader2>
          <th scope="col" #tabHead class="px-[14px] py-[16px] whitespace-nowrap text-center uppercase tracking-wider"
            *ngFor="let column of columns">
            <p [class]="tabText"> {{ column.display }}</p>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr class="w-full h-[10px] !bg-[#FCFAFA]"></tr>
      </tbody>

      <tbody>
        <tr *ngFor="let row of dataView; let rowIndex = index" [ngClass]="{
            highlight: changeId.includes(row.id),
            perTeDati: perTe.includes(row.id)
          }">
          <td #myDiv class="px-[14px] py-[16px] text-center  cursor-pointer" [style.height.px]="maxHeight"
            *ngFor="let column of columns; let isFirst = first" (dblclick)="clicca(row)"
            (touchend)="handleTap($event, row)">
            <div class="flex items-center justify-center">
              <p [class]="tabText" *ngIf="column.dataIndex !== 'cover'" class="w-full h-full">
                {{ row[column.dataIndex] }}
              </p>
              <img *ngIf="column.dataIndex === 'cover' && row[column.dataIndex]"
                [src]="bancadatiCoverFolder + row[column.dataIndex]" />
              <p *ngIf="column.dataIndex === 'cover' && !row[column.dataIndex]" class="w-full h-full">
                -
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>