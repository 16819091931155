import { Component, HostListener, OnInit } from '@angular/core';
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { SelectionService } from '@services/selection.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Component({
  selector: 'app-anni',
  templateUrl: './anni.component.html',
  styleUrls: ['./anni.component.scss'],
})
export class AnniComponent implements OnInit {
  loader = false;
  nome: string = '';
  totalPages: number = 27;
  page: number = 1;
  anni: any = [];
  isScrollable: boolean = false;
  changeId: number[] = [];
  perTeId: number[] = [];
  isPhone: any;
  modalDelete: boolean = false;
  public faMagnifyingGlass = faMagnifyingGlass;
  nomeCerca: string = '';
  modal: boolean = false;
  public faTrash = faTrash;
  public faPencil = faPencil;
  nomeAnno: string = '';
  idAnno: string = '';
  messErr: string = '';
  showToast: boolean = false;
  constructor(
    private banchedati: BanchedatiService,
    private selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    this.paginateAnni();
  }

  applyFilter() {
    this.paginateAnni();
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateAnni();
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateAnni();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateAnni();
  }

  paginateAnni() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .paginateAnno(this.page - 1, this.nomeCerca)
      .subscribe(
        (response: any) => {
          this.totalPages = response.totalPages;
          this.anni = response.data.map((item: any) => {
            return {
              id: item.id,
              nome: item.anno === '' ? 'anno vuoto' : item.anno,
            };
          });
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          console.error(
            'Errore durante il recupero delle informazioni ',
            error
          );

          LoaderComponent.isLoading = false;
        }
      );
  }

  showModal(id: number, nome: string) {
    this.modal = !this.modal;
    this.nomeAnno = nome;
    this.idAnno = id.toString();
  }

  deleteAnno() {
    LoaderComponent.isLoading = true;

    this.banchedati.deleteAnno(Number(this.idAnno)).subscribe(
      (response) => {
        this.paginateAnni();
        this.modal = false;
        this.modalDelete = false;
        LoaderComponent.isLoading = false;
      },
      (error) => {
        this.messErr = error.error.message;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 3000);
        LoaderComponent.isLoading = false;
        console.error(
          `Errore durante l'eliminazione del bando con ID ${this.idAnno}:`,
          error
        );
      }
    );
  }

  showModalDelete(id: number) {
    this.modalDelete = !this.modalDelete;

    this.idAnno = id.toString();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.modal) {
      this.modal = false;
    }
    if (this.modalDelete) {
      this.modalDelete = false;
    }
  }
}
