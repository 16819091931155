import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Modal } from '@stdoutsrl/ui-kit/lib/modal/models/modal';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
})
export class TextModalComponent implements Modal, OnInit {
  @Input() title!: any;
  @Input() content!: any;
  @Input() isVisible = true;

  @Output() onCloseModal = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.onCloseModal.emit(true); // Emitte un evento booleano indicando la chiusura del modal
  }

  onUndo() {
    this.onCloseModal.emit(false); // Emitte un evento booleano indicando l'azione "Undo"
  }
}
