import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import {
  BreadcrumbModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FormModule,
  IconButtonModule,
  ModalModule,
  PaginationModule,
  SelectModule,
  SidenavModule,
  SliderModule,
  StatusbarModule,
  StepperModule,
  TableModule,
  TagModule,
} from '@stdoutsrl/ui-kit';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './layout/header/header.component';
import { AuthComponent } from './auth/auth.component';
import { AuthModule } from './auth/auth.module';
import { TextModalComponent } from './modals/text-modal/text-modal.component';
import { CrudModalComponent } from './modals/crud-modal/crud-modal.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { UtenteService } from '@services/utente.service';
import { UserComponent } from './pages/user/user.component';
import { NgIconsModule } from '@ng-icons/core';
import { IonBagCheckOutline } from '@ng-icons/ionicons';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JwtInterceptor } from '@helpers/jwt.interceptor';
import { SidenavComponent2 } from './layout/sidenav/sidenav.component';
import { MessageComponent } from './components/message/message.component';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AuthComponent,
    AppComponent,
    PagesComponent,
    TextModalComponent,
    CrudModalComponent,
    SidenavComponent2,
    MessageComponent,
  ],
  imports: [
    NgIconsModule.withIcons({ IonBagCheckOutline }),

    CurrencyMaskModule,
    TranslocoModule,
    BrowserModule,
    PagesModule,
    FormsModule,
    FormModule,
    SidenavModule,
    TagModule,
    ModalModule,
    StepperModule,
    SelectModule,
    SliderModule,
    FontAwesomeModule,
    CardModule,
    IconButtonModule,
    ModalModule,
    TableModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    PaginationModule,
    StatusbarModule,
    HttpClientModule,
    ButtonModule,
    AppRoutingModule,
    TranslocoRootModule,
    AuthModule,
    HttpClientModule,
    ImageCropperModule,
    HammerModule,
  ],

  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
