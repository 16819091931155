<div class="max-w-[100vw] mobileScreen"><stdout-modal-header>{{title}}</stdout-modal-header>
<stdout-modal-content>
  <!-- <h5 class="t-dark bold">{{ subtitle }}</h5> -->
  <form *ngIf="init" class="py-3" (ngSubmit)="onSubmitForm()" [formGroup]="generatedForm" autocomplete="off">
    <div *ngFor="let group of formData.groups">
      <div *ngFor="let field of group.fields">
        <div class="mobile100" style="margin: 5px 0px ;width: 500px;" *ngIf="
                field.type === 'text' ||
                field.type === 'email' ||
                field.type === 'password' ||
                field.type === 'number'
                ">
          <stdout-text-input [label]="field.label || ''" [isError]="isFieldValid(field.field)"
            [isErrorMessage]="getFieldMessageError(field.field)" [formControlName]="field.field" [type]="field.type"
            [placeholder]="field.placeholder || ''">
          </stdout-text-input>
        </div>
        <div style="margin: 5px 0px ;width: 500px;" *ngIf="
        field.type === 'textarea'
        ">
          <stdout-text-area [label]="field.label || ''" [isError]="isFieldValid(field.field)"
            [isErrorMessage]="getFieldMessageError(field.field)" [formControlName]="field.field"
            [placeholder]="field.placeholder || ''">
          </stdout-text-area>
        </div>


        <div style="margin: 5px 0px ;width: 500px;" *ngIf="
                field.type === 'money'
                ">
          <stdout-text-input [label]="field.label || ''" [isError]="isFieldValid(field.field)"
          [currencyMaskOptions]="{ prefix: '€', thousands: '.', decimal: ',', align: 'right', allowNegative: false ,suffix:'',precision:2}"
            [isErrorMessage]="getFieldMessageError(field.field)" [formControlName]="field.field" [type]="'text'"
            [placeholder]="field.placeholder || ''">
          </stdout-text-input>
        </div>


        <div class="input-container" *ngIf="field.type === 'date'">
          <stdout-date-picker [isError]="isFieldValid(field.field)" [value]="field.value" [options]="field.options"
            [isErrorMessage]="getFieldMessageError(field.field) || ''" [formControlName]="field.field"
            [placeholder]="field.placeholder || ''">
          </stdout-date-picker>
        </div>
        <div class="input-container" *ngIf="field.type === 'radio'">
          <stdout-radio-button [groupName]="field.field" [valueName]="field.value || ''" [label]="field.label || ''">
          </stdout-radio-button>
        </div>
        <div style="margin: 5px 0px" *ngIf="field.type === 'ckeditor'">
          <stdout-checkbox [label]="field.label || ''" [formControlName]="field.field"
            [isError]="isFieldValid(field.field)"></stdout-checkbox>
        </div>

        <div class="input-container" *ngIf="field.type === 'myselect'">
          <stdout-select
            [clearable]="false"
            (onSelectedItem)="this.selectValue = $event.event"


            [data]="this.array"
            [label]="field.label || ''"
            [isError]="isFieldValid(field.field)"

            ></stdout-select>
       <div class="abc">
        <label><p>{{this.selectValue}}</p></label>
       </div>

        </div>








        <div style="margin: 5px 0px" *ngIf="field.type === 'toggle'">
          <stdout-toggle [label]="field.label || ''" [formControlName]="field.field"
            [isError]="isFieldValid(field.field)">
          </stdout-toggle>
        </div>
      </div>
    </div>
  </form>
</stdout-modal-content>
</div>
