import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { api } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BandoService {
  constructor(
    private rest: RestService,
    private route: Router,
    private http: HttpClient
  ) {}

  createBando(params: any) {
    return this.rest.post(`${api.endpoint.createBando}`, params);
  }

  paginateConsigliato(page = 0) {
    return this.rest.get(`consigliato/paginate?page=${page}`);
  }

  modifyBando(id: number, params: any) {
    return this.rest.patch(`${api.endpoint.modifyBando}/${id}`, params);
  }

  deleteBando(id: number) {
    return this.rest.delete(`${api.endpoint.deleteBando}/${id}`);
  }

  getUserActive() {
    return this.rest.get(`${api.endpoint.utentiAttivi}`);
  }
}
