<div [ngClass]="{ 'hidden null': isLoggin === false }">
  <div class="adapt-bar" (window:resize)="onResize($event)">
    <app-sidenav></app-sidenav>
    <div class="my-w-100">
      <div class="d-flex justify-content-center">
        <div class="w-100" fxLayout="column" fxFlex>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
