import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ImageCroppedEvent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HammerModule } from '@angular/platform-browser';
import { HammerGestureConfig, HammerLoader } from '@angular/platform-browser';
import {
  faTrashCan,
  faRotateRight,
  faRotateLeft,
  faRulerVertical,
  faRulerHorizontal,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from '@fortawesome/free-solid-svg-icons';
import { ImageServiceService } from '@services/image-service.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
  imageFile: any;
  croppedImage: any = '';
  @Input() width!: number;
  @Input() height!: number;
  taglia: boolean = false;
  croppingActive: boolean = false;
  public faTrashCan = faTrashCan;
  public faRotateRight = faRotateRight;
  public faRotateLeft = faRotateLeft;
  public faRulerVertical = faRulerVertical;
  public faRulerHorizontal = faRulerHorizontal;
  public faMagnifyingGlassPlus = faMagnifyingGlassPlus;
  public faMagnifyingGlassMinus = faMagnifyingGlassMinus;
  show: boolean = true;
  private subscription: Subscription = new Subscription();
  @Input() imageChangedEvent: any;

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private imageService: ImageServiceService
  ) {
    this.imageFile = this.imageService.getSelectedFile();
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.imageChangedEvent = this.imageService.getSelectedFile();

    this.subscription.add(
      this.imageService.getMostra().subscribe((mostra) => {
        this.show = mostra;
      })
    );
  }

  ngAfterViewInit() {
    this.fileInput.nativeElement.click();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @ViewChild('fileInput', { static: false }) fileInput2!: ElementRef;
  onButtonClickModify() {
    if (this.fileInput2) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.taglia = true;
  }

  fileChangeEventMod(event: any): void {
    this.imageChangedEvent = event;
    this.taglia = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    if (event && event.objectUrl) {
      fetch(event.objectUrl)
        .then((res) => res.blob()) // ottieni il blob dall'URL
        .then((blob) => {
          const file = new File([blob], 'cover', { type: 'image/jpeg' }); // crea un oggetto File dal blob
          const reader = new FileReader();
          reader.onload = () => {
            this.croppedImage = reader.result as string;
          };
          this.croppedImage = file; // assegna l'oggetto File a croppedImage
        });
    }
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ritaglioConfermato = false;
  confirmCrop(): void {
    // Mostra una finestra modale o un messaggio di conferma
    // Quando l'utente conferma il ritaglio, setta il flag a true
    this.ritaglioConfermato = true;
  }
  modifiche: string[] = [];
  saveCroppedImage(): void {
    if (this.ritaglioConfermato && this.croppedImage) {
      // Aggiungi l'immagine ritagliata all'array 'modifiche'
      this.modifiche.push(this.croppedImage);

      // Salva l'immagine ritagliata nel servizio
      this.imageService.setCroppedImage(this.croppedImage);
    } else {
      // Gestisci il caso in cui il ritaglio non è stato confermato
    }
  }
  transform: ImageTransform = {};

  rotateLeft(): void {
    this.transform = {
      ...this.transform,
      rotate: this.transform.rotate ? this.transform.rotate - 45 : -45,
    };
  }

  rotateRight(): void {
    this.transform = {
      ...this.transform,
      rotate: this.transform.rotate ? this.transform.rotate + 45 : 45,
    };
  }

  flipHorizontal(): void {
    this.transform = { ...this.transform, flipH: !this.transform.flipH };
  }

  flipVertical(): void {
    this.transform = { ...this.transform, flipV: !this.transform.flipV };
  }

  scaleUp(): void {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale ? this.transform.scale + 0.1 : 1.1,
    };
  }

  scaleDown(): void {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale ? this.transform.scale - 0.1 : 0.9,
    };
  }

  resetTransform(): void {
    this.transform = {};
  }

  conferma(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.confirmCrop();
    this.saveCroppedImage();
    this.imageService.setMostra(false);
    console.log(event);
  }

  arrayTest = [
    {
      id: 0,
      Numerodomande: 5,
      Ente: 'Ente1',
      Regione: 'Regione1',
      NomeBancaDati: 'BancaDati1',
      Anno: '2023',
    },
    {
      id: 1,
      Numerodomande: 8,
      Ente: 'Ente2',
      Regione: 'Regione2',
      NomeBancaDati: 'BancaDati2',
      Anno: '2022',
    },
    {
      id: 2,
      Numerodomande: 3,
      Ente: 'Ente3',
      Regione: 'Regione3',
      NomeBancaDati: 'BancaDati3',
      Anno: '2024',
    },
    {
      id: 3,
      Numerodomande: 6,
      Ente: 'Ente4',
      Regione: 'Regione4',
      NomeBancaDati: 'BancaDati4',
      Anno: '2021',
    },
  ];
}
