<div
  class="z-50 element min-h-700 mt-5 w-[300px] bg-white rounded-[15px] p-[25px]"
>
  <!-- titolo studio -->
  <div class="flex flex-col w-full h-full justify-start items-center">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "Tit_stud" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show1 = !show1"
          [icon]="show1 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show1">
      <stdout-select
        class="fix-select"
        id="select1"
        [placeholder]="'Seleziona o inserisci l argomento'"
        [clearable]="false"
        [data]="listaTitoliStudio"
        type="text"
        (onSelectedItem)="addSelectedTitoli($event.event)"
        [value]="selectedTitoliFiltro"
        bindLabel="nome"
        bindValue="nome"
        #selectElement
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiTitFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviValTit(selectedTitoliFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- anno -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "anno" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show2 = !show2"
          [icon]="show2 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show2">
      <stdout-select
        class="fix-select"
        id="select1"
        [clearable]="false"
        (onSelectedItem)="addSelectedAnni($event.event)"
        [data]="combinedAnno"
        [value]="selectedAnniFiltro"
        [placeholder]="'Seleziona l’anno '"
        #selectElement
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiAnniFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviAnno(selectedAnniFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ente -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "ente" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show3 = !show3"
          [icon]="show3 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show3">
      <stdout-select
        class="fix-select"
        [placeholder]="'Seleziona l’ente'"
        id="select1"
        type="text"
        [clearable]="false"
        (onSelectedItem)="addSelectedEnte($event.event)"
        (input)="onEnteChange($event)"
        [data]="listaEnti"
        [value]="selectedEntiFiltro"
        #selectElement
        bindLabel="nome"
        bindValue="nome"
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiEntiFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviVal(selectedEntiFiltro[i])"
              >></fa-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- profilo -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "profilo" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show4 = !show4"
          [icon]="show4 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show4">
      <stdout-select
        class="fix-select"
        id="select1"
        [clearable]="false"
        (onSelectedItem)="addSelectedProfili($event.event)"
        [data]="listaProfili"
        [value]="selectedProfiliFiltro"
        [placeholder]="'Seleziona il profilo'"
        #selectElement
        bindLabel="nome"
        bindValue="nome"
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiProfiliFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviValProfilo(selectedProfiliFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- materia -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "materia" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show5 = !show5"
          [icon]="show5 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show5">
      <stdout-select
        class="fix-select"
        id="select1"
        [placeholder]="'Seleziona o inserisci la materia'"
        [clearable]="false"
        [data]="listaMaterie"
        type="text"
        (onSelectedItem)="addSelectedMaterie($event.event)"
        [value]="selectedMaterieFiltro"
        bindLabel="nome"
        bindValue="nome"
        #selectElement
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiMaterieFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviValMaterie(selectedMaterieFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- argomento -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "argomento" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show6 = !show6"
          [icon]="show6 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show6">
      <stdout-select
        class="fix-select"
        id="select1"
        [placeholder]="'Seleziona o inserisci l argomento'"
        [clearable]="false"
        [data]="listaArgomenti"
        type="text"
        (onSelectedItem)="addSelectedArgomenti($event.event)"
        [value]="selectedArgomentiFiltro"
        bindLabel="nome"
        bindValue="nome"
        #selectElement
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiArgomentiFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviValArgomenti(selectedArgomentiFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--difficolta -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "difficolta" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show7 = !show7"
          [icon]="show7 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show7">
      <stdout-select
        class="fix-select"
        id="select1"
        [placeholder]="'Seleziona o inserisci l argomento'"
        [clearable]="false"
        [data]="listaDifficolta"
        type="text"
        (onSelectedItem)="addSelectedDifficolta($event.event)"
        [value]="selectedDifficoltaFiltro"
        bindLabel="nome"
        bindValue="nome"
        #selectElement
      >
      </stdout-select>
      <div
        class="flex flex-col w-fit"
        *ngFor="let nome of selectedNomiDifficoltaFiltro; let i = index"
      >
        <div
          class="lab flex items-center justify-center mt-[10px] w-full h-full"
        >
          <div class="flex items-center justify-center w-full h-full">
            <p class="whitespace-nowrap">
              {{ nome }}
            </p>
          </div>
          <div class="icona-fil flex items-center justify-center w-full h-full">
            <fa-icon
              class="flex justify-center items-center w-full h-full"
              [icon]="faXmark"
              (click)="rimuoviValDifficolta(selectedDifficoltaFiltro[i])"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--immagini -->
  <div class="flex flex-col w-full h-full justify-start items-center mt-5">
    <div class="flex justify-start items-center w-full h-full">
      <div class="mr-[5px]">
        <p
          class="text-[#0B0E42] font-montserrat text-base font-semibold leading-normal"
        >
          {{ "immagini" | transloco }}
        </p>
      </div>
      <div>
        <fa-icon
          (click)="show8 = !show8"
          [icon]="show8 ? faChevronUp : faChevronDown"
        ></fa-icon>
      </div>
    </div>
    <div class="w-full h-full mt-[10px]" *ngIf="show8">
      <div class="flex items-center justify-start mt-5">
        <div class="mr-[10px]">
          <app-switch
            [isChecked]="initialValue"
            (change)="handleSwitchChange($event)"
          ></app-switch>
        </div>
        <div>
          <p
            class="text-[#0B0E42] font-montserrat text-sm font-normal leading-normal"
          >
            mostra immagini
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- switch -->
  <div class="flex items-center justify-start mt-5">
    <div class="mr-[10px]">
      <app-switch
        [isChecked]="initialValue"
        (change)="handleSwitchChange($event)"
      ></app-switch>
    </div>
    <div>
      <p
        class="text-[#0B0E42] font-montserrat text-sm font-normal leading-normal"
      >
        mostra domande uguali
      </p>
    </div>
  </div>
  <!-- bottoni -->
  <div class="flex justify-between items-center margine">
    <div class="w-[48.5%]">
      <stdout-button
        class="btn-bianco"
        size="large"
        color="black"
        (click)="resettaFiltri()"
        ><h2>{{ "resetta" | transloco }}</h2></stdout-button
      >
    </div>
    <div class="w-[48.5%]">
      <stdout-button
        class="btn-blu"
        size="large"
        color="black"
        (click)="applicaFiltri()"
        ><h2>{{ "applica" | transloco }}</h2></stdout-button
      >
    </div>
  </div>
</div>
