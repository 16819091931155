import { faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";

export const constant = {

  //Qua andranno inserite la variabili fisse da riutilizzare nel progetto
  //La prima variabile è solo per testare e visualizzare i componenti
  arrayTest: [
    {
      id: 1,
      mail_suffix: 'intesasanpaolo.com',
      name: 'INTESASANPAOLO',
      description: 'BANCA INTESASANPAOLO S.p.A.',
      short_description: 'INTESA',
      cong: false,
      fidi: false,
      conf: true,
      trev: true,
      cofi: false,
      icrea: false,
      med: false,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      checkbox: false,
      icon: faTrashCan,
    },
    {
      id: 2,
      mail_suffix: 'mps.it',
      name: 'MONTE PASCHI DI SIENA',
      description: 'BANCA MONTE PASCHI DI SIENA S.p.A.',
      short_description: 'MPS',
      cong: true,
      fidi: false,
      conf: true,
      trev: true,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 3,
      mail_suffix: 'unicredit.eu',
      name: 'UNICREDIT',
      description: 'UNICREDIT BANCA  S.p.A.',
      short_description: 'UNICREDIT',
      cong: false,
      fidi: false,
      conf: true,
      trev: true,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 4,
      mail_suffix: 'unipolbanca.it',
      name: 'UNIPOL',
      description: 'UNIPOL BANCA  S.p.A.',
      short_description: 'UNIPOL',
      cong: true,
      fidi: false,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 5,
      mail_suffix: 'bnlmail.com',
      name: 'B.N.L.',
      description: 'BANCA NAZIONALE DEL LAVORO  S.p.A.',
      short_description: 'BNL',
      cong: true,
      fidi: false,
      conf: false,
      trev: true,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 6,
      mail_suffix: 'bpm.it',
      name: 'POPOLARE DI MILANO',
      description: 'BANCO BPM  S.p.A.',
      short_description: 'BANCO BPM',
      cong: true,
      fidi: true,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 7,
      mail_suffix: 'cradiborgo.it',
      name: 'C.R.A. DI BORGO SAN GIACOMO',
      description: 'BANCA DI CREDITO COOPERATIVO BORGO SAN GIACOMO',
      short_description: 'BORGO S.G.',
      cong: true,
      fidi: false,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 8,
      mail_suffix: 'bancasistema.it',
      name: 'BANCA SISTEMA',
      description: 'BANCA SISTEMA  S.p.A.',
      short_description: 'SISTEMA',
      cong: true,
      fidi: false,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: false,
      fact: true,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 9,
      mail_suffix: 'paceco.bcc.it',
      name: 'BCC SENATORE PIETRO GRAMMATICO',
      description: null,
      short_description: null,
      cong: false,
      fidi: false,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: false,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
    {
      id: 10,
      mail_suffix: 'bancopopolare.it',
      name: 'BANCO POPOLARE',
      description: 'BANCO BPM  S.p.A.',
      short_description: 'BANCO BPM',
      cong: false,
      fidi: false,
      conf: false,
      trev: false,
      cofi: false,
      icrea: false,
      med: true,
      fact: false,
      agno: null,
      createdAt: '2020-11-11T06:59:29.000Z',
      updatedAt: '2020-11-11T06:59:29.000Z',
      icon: faTrashCan,
    },
  ],
  
};

export const language = [
  { key: 'Italiano', value: 'it' },
  { key: 'English', value: 'en' },
  { key: 'Français', value: 'fr' },
  { key: 'Deutsch', value: 'de' },
  { key: '中国人', value: 'zh' },
];
