import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { RestService } from '@services/rest.service';
import { Observable, from, throwError } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;

  recoveryToken: string = '';
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public hide1 = true;
  public hide2 = true;
  public hide = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.route.params.subscribe((params: any) => {
      this.recoveryToken = params.token;
    });
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,32}$'
        ),
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.confirmPasswordValidator,
      ]),
    });
  }

  ngOnInit(): void {}

  confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const password = control.root.get('password');
    const confirmPassword = control.root.get('confirmPassword');

    if (!password || !confirmPassword) {
      return null;
    }

    return password.value !== confirmPassword.value
      ? { confirmPassword: true }
      : null;
  };

  reset() {
    const newPassword = this.passwordForm.value.password;
    const confirmNewPassword = this.passwordForm.value.confirmPassword;

    this.authService
      .resetPassword(newPassword, confirmNewPassword, this.recoveryToken)
      .subscribe({
        next: () => {
          this.router.navigate(['auth/login']); // Reindirizza dopo il reset della password
        },
        error: (error) => {
          console.error('Errore durante il reset della password:', error);
          // Gestisci gli errori, ad esempio mostrando un messaggio all'utente
        },
        complete: () => {
          console.info('Reset della password completato');
        },
      });
  }
}
