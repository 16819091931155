<div class="content" (window:resize)="onResize($event)">
  <app-header></app-header>
  <div class="container">
    <div class="search-buttons flex">
      <div class="subtitle"> </div>
      <div class="filters" >
        <stdout-button color="white" background="primary" [icon]="faCirclePlus" mat-stroked-button>
          {{'aggiungi cliente'|transloco}}
        </stdout-button>
      </div>
      <div class="flex input-container" *ngIf="!isPhone">
        <stdout-icon-button style="margin: 2px 10px 0px 0px;" size="small" background="secondary" color="white"
          borderColor="secondary" [icon]="faMagnifyingGlass">
        </stdout-icon-button>
        <stdout-text-input placeholder="Cerca" [(ngModel)]="search" (keyup)="applyFilter()"></stdout-text-input>
      </div>
    </div>

    <stdout2-table [data]="arrayTest">
      <span stdoutTableColumn label="ID" property="id"></span>
      <span stdoutTableColumn label="NOME" property="name"></span>
      <span stdoutTableColumn label="Descrizione" property="description"></span>
      <span stdoutTableColumn width="110" label="AZIONI">
        <ng-template #dataTableCell let-item="item">
          <div class="flex items-end gap-x-4">
            <stdout-icon-button style="margin-right: 5px;" size="small" color="primary" borderColor="primary"
              background="white" [icon]="faPencil">
            </stdout-icon-button>
            <stdout-icon-button color="primary" borderColor="primary" background="white" size="small"
              [icon]="faTrashCan"></stdout-icon-button>
          </div>
        </ng-template>
      </span>
    </stdout2-table>
    <stdout-table [data]="arrayTest">
      <span stdoutTableColumn label="ID" property="id"></span>
      <span stdoutTableColumn label="NOME" property="name"></span>
      <span stdoutTableColumn label="Descrizione" property="description"></span>
      <span stdoutTableColumn width="110" label="AZIONI">
        <ng-template #dataTableCell let-item="item">
          <div class="flex items-end gap-x-4">
            <stdout-icon-button style="margin-right: 5px;" size="small" color="primary" borderColor="primary"
              background="white" [icon]="faPencil">
            </stdout-icon-button>
            <stdout-icon-button color="primary" borderColor="primary" background="white" size="small"
              [icon]="faTrashCan"></stdout-icon-button>
          </div>
        </ng-template>
      </span>
    </stdout-table>
    <br>
    <stdout-table [data]="[]"><span stdoutTableColumn label="ID" property="id"></span></stdout-table>
    <br>
    <stdout2-table [data]="[]"><span stdoutTableColumn label="ID" property="id"></span></stdout2-table>
    <div class="flex justify-center" *ngIf="!isPhone">
      <stdout-pagination [current]="page" [total]="totalPages" (goTo)="getPage($event)" (next)=" goNext($event)"
        (previous)="goBack($event)"></stdout-pagination>
    </div>
    <stdout-mobile-table *ngIf="isPhone" [data]="arrayTest" [actions]="actions" [columns]="columns"
      (actionClick)="onActionClicked($event)" color="secondary">
    </stdout-mobile-table>







    <stdout-button (click)="message('bottone')" size="small" color="black">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="small" color="white">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="small">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="medium" color="black">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="medium" color="white">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="medium">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="large" color="black">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="large" color="white">Ciao</stdout-button><br>
    <stdout-button (click)="message('bottone')" size="large">Ciao</stdout-button><br>

    <stdout-tabs type="chips">
      <!-- con [active] puoi selezionare la tab attiva inizialmente oppure piu schermate. -->
      <stdout-tab title="Tab1" [active]="true">
        <div>Sono la tab Tab1</div>
      </stdout-tab>
      <stdout-tab title="Tab2">
        <div>Sono la tab Tab2</div>
      </stdout-tab>
      <stdout-tab title="Tab3">
        <div>Sono la tab Tab3</div>
      </stdout-tab>
      <stdout-tab title="Tab4">
        <div>Sono la tab Tab4</div>
      </stdout-tab>
      <stdout-tab title="Tab5">
        <div>Sono la tab Tab5</div>
      </stdout-tab>
    </stdout-tabs>
    <br>
    <stdout-tabs type="underline">
      <!-- con [active] puoi selezionare la tab attiva inizialmente oppure piu schermate. -->
      <stdout-tab title="Tab1" [active]="true">
        <div>Sono la tab Tab1</div>
      </stdout-tab>
      <stdout-tab title="Tab2">
        <div>Sono la tab Tab2</div>
      </stdout-tab>
      <stdout-tab title="Tab3">
        <div>Sono la tab Tab3</div>
      </stdout-tab>
      <stdout-tab title="Tab4">
        <div>Sono la tab Tab4</div>
      </stdout-tab>
      <stdout-tab title="Tab5">
        <div>Sono la tab Tab5</div>
      </stdout-tab>
    </stdout-tabs>
    <br>
    <stdout-card>
      <stdout-card-header>Titolo</stdout-card-header>
      <stdout-card-content>Contenuto</stdout-card-content>
    </stdout-card>
    <!-- Le card di base prendono tutta la largezza ed è possibile usare solo il contenuto senza l'header
eliminare la stdout-card levera il box-shadow in caso non servisse lo shadow-->
    <br>
    <stdout-card>
      <stdout-card-content>Contenuto</stdout-card-content>
    </stdout-card>
    <br>

    <stdout-card-header>Titolo</stdout-card-header>
    <stdout-card-content>Contenuto</stdout-card-content>
    <br>
    <stdout-checkbox></stdout-checkbox>
    <br>
    <stdout-text-area
      value="Text area, in basso a destra puoi trascinare per ingrandire o rimpicciolire"></stdout-text-area>
    <br>
    <stdout-breadcrumbs [breadcrumbs]="[
{ url: '/pages', label: 'Home' },{ url: '/pages', label: 'Home' },
]"></stdout-breadcrumbs>
    <br>
<stdout-icon-button [icon]="faMagnifyingGlass"></stdout-icon-button>
<br>
<br>
<stdout-stepper [counter]="3">
<stdout-step></stdout-step>
<stdout-step></stdout-step>
<stdout-step></stdout-step>
</stdout-stepper>
<br>
<div>
<stdout-horizontal-stepper [counter]="2" style="display: flex;">
  <stdout-horizontal-step></stdout-horizontal-step>
  <stdout-horizontal-step></stdout-horizontal-step>
  <stdout-horizontal-step></stdout-horizontal-step>
</stdout-horizontal-stepper>
</div>
<br>
<stdout-select [clearable]="false" [data]="[{value:'ciao',key:'ciao'},{value:'ciao2',key:'ciao2'},{value:'ciao3',key:'ciao3'}]"></stdout-select>
<stdout-select  [clearable]="true" [multiple]="true" [value]="'ciao2'" [data]="[{value:'ciao',key:'ciao'},{value:'ciao2',key:'ciao2'},{value:'ciao3',key:'ciao3'}]"></stdout-select>
<stdout-text-input type="text"></stdout-text-input>
<stdout-text-input type="number"></stdout-text-input>
<stdout-text-input type="date"></stdout-text-input>
<stdout-text-input type="text" value="testo base"></stdout-text-input>
<stdout-text-input type="text" placeholder="placeholder"></stdout-text-input>

  </div>
</div>
