import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from '@stdoutsrl/ui-kit/lib/modal/models/modal';
import { FormGenerator } from './form-generator.interface';

@Component({
  selector: 'app-crud-modal',
  templateUrl: './crud-modal.component.html',
  styleUrls: ['./crud-modal.component.scss'],
})
export class CrudModalComponent implements Modal, OnInit {
  bankID = 0;
  @Input() title!: any;
  @Input() subtitle!: any;
  referentQualityArray = [];
  selectValue? = '';
  array = ['ciao', 'ciao2', 'ciao3', 'ciao4', 'ciao5'];
  init: boolean = false;
  public generatedForm!: FormGroup | any;
  public formControls: any;
  @Input() formData!: FormGenerator;

  constructor() {}

  ngOnInit(): void {
    if (!this.formData) {
      throw new Error('Passa i dati');
    }
    this.formControls = {};
    this.formData.groups.forEach((group) => {
      group.fields.map((field) => {
        this.formControls[field.field] = new FormControl(
          field.default,
          // field.validation ? this._getValidations(field.validation) : null,
          field.validators ? field.validators : null
        );
      });
    });

    this.generatedForm = new FormGroup(this.formControls);
    this.init = true;
  }

  updateFile(event: any) {
    const formData = new FormData();
    formData.append('cimiteroId', 'ciao');
    formData.append('tipo', 'ciao');
    formData.append('file', event.target.files[0]);
    this.generatedForm.patchValue({ file: formData });
  }

  public isFieldValid(field: string) {
    return (
      !this.generatedForm.get(field).valid &&
      this.generatedForm.get(field).touched
    );
  }

  public getFieldMessageError(field: string) {
    if (
      this.generatedForm.get(field).errors &&
      Object.prototype.hasOwnProperty.call(
        this.generatedForm.get(field).errors,
        'required'
      )
    ) {
      return 'Compila il campo';
    } else if (
      this.generatedForm.get(field).errors &&
      Object.prototype.hasOwnProperty.call(
        this.generatedForm.get(field).errors,
        'email'
      )
    ) {
      return 'Il campo non è una mail valida';
    } else {
      return;
    }
  }

  public getSubmitLabel(): string {
    if (this.formData.submitLabel) {
      return this.formData.submitLabel;
    }

    return 'Registra';
  }

  public onSubmitForm(): any {
    if (this.generatedForm.valid) {
      return this.generatedForm;
    } else {
      Object.keys(this.generatedForm.controls).forEach((field) => {
        const control = this.generatedForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return false;
    }
  }

  private _getValidations(validation: any) {
    if (validation === 'required') {
      return Validators.required;
    } else if (validation === 'email') {
      return Validators.email;
    } else {
      return;
    }
  }

  onClose() {
    return this.onSubmitForm();
  }

  onUndo() {
    return false;
  }

  selectedItems: any[] = [];

  onItemSelected(item: any) {
    this.selectedItems.push(item.event.value);
  }

  removeSelectedItem(index: number) {
    this.selectedItems.splice(index, 1);
  }
}
