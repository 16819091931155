<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="w-full h-full bg-white p-[20px] importare">
    <div class="flex flex-col justify-center items-center">
      <div clss="titolo-modal">
        <h1>{{ "crea_nuova_banca_dati" | transloco }}</h1>
      </div>

      <div class="flex flex-col md:flex-row items-end w-full justify-between">
        <div class="flex flex-col w-full md:w-[18.285%] mb-[10px] md:mb-0">
          <div class="mb-[10px]">
            <label class="primalabel" for="1">
              <h2 class="" >
                <b [ngClass]="{'testo-errore': form.get('titolo')?.invalid && formError}">{{ "nome_nuova_banca" | transloco }}</b>
              </h2>
            </label>
          </div>
          <div class="w-full h-full bordo"   [ngClass]="{'invalid-border': form.get('titolo')?.invalid && formError}">
            <input  class="w-full h-full px-[12px] py-[8px] outline-none" type="text"
              placeholder="Inserisci il nome nuova banca dati" formControlName="titolo"
            
              />
          </div>
          <div></div>
        </div>
        <div class="flex flex-col w-full md:w-[79.428%]">
          <div class="mb-[10px]">
            <label class="primalabel" for="1">
              <h2>
                <b>{{ "descrizione" | transloco }}</b>
              </h2>
            </label>
          </div>
          <div>
            <stdout-text-input class="bordo" id="2" type="text"
              placeholder="Inserisci la descrizione della nuova banca dati"
              formControlName="descrizione"></stdout-text-input>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row w-full mt-[25px] justify-between">
      <div class="md:w-[18.285%] selezione">
        <div>
          <div class="mb-[10px] primalabel">
            <label for="1">
              <h2>
                <b [ngClass]="{'testo-errore': form.get('ente')?.invalid && formError}">{{ "ente" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select class="fix-select" [placeholder]="'Seleziona o inserisci l’ente'" id="select1" type="text"
            [clearable]="false" [data]="combinedEnti" (input)="onEnteChange($event)" formControlName="ente"
            [value]="form.value.ente" #selectElement
            [ngClass]="{'invalid-select': form.get('ente')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="selezione md:w-[18.285%] mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label for="1">
              <h2>
                <b>{{ "profilo" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select [placeholder]="'Seleziona o inserisci il profilo'" class="fix-select" [clearable]="false"
            type="text" [data]="combinedProfili" (input)="onProfiliChange($event)" formControlName="profilo"
            [value]="form.value.profilo" #selectElement [disabled]="form.controls['profilo'].disabled">
          </stdout-select>
        </div>
      </div>
      <div class="md:w-[18.285%] selezione mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label for="1">
              <h2>
                <b [ngClass]="{'testo-errore': form.get('regione')?.invalid && formError}">{{ "regione" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select class="fix-select" id="select1" [clearable]="false" [data]="listaRegioni"
            [placeholder]="'Seleziona la località'" [value]="form.value.regione" #selectElement bindLabel="nome"
            bindValue="id" formControlName="regione"
            [ngClass]="{'invalid-select': form.get('regione')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="md:w-[18.285%] selezione mt-[10px] md:mt-0">
        <div>
          <div class="mb-[10px] primalabel">
            <label for="1">
              <h2>
                <b [ngClass]="{'testo-errore': form.get('anno')?.invalid && formError}">{{ "anno" | transloco }}</b>
              </h2>
            </label>
          </div>

          <stdout-select class="fix-select" id="select1" [clearable]="false" (input)="onAnnoChange($event)"
            [placeholder]="'Seleziona l’anno '" formControlName="anno" [value]="form.value.anno" [data]="combinedAnno"
            #selectElement
            [ngClass]="{'invalid-select': form.get('anno')?.invalid && formError}"
            >
          </stdout-select>
        </div>
      </div>
      <div class="mt-[10px] md:mt-0 md:w-[18.285%]" (click)="$event.stopPropagation()">
        <div class="mb-[10px] primalabel">
          <label for="1">
            <h2>
              <b>{{ "icona" | transloco }}</b>
            </h2>
          </label>
        </div>
        <div class="flex w-full">
          <div class="mr-[15px] w-full">
            <stdout-text-input class="no-bordo" id="1" type="text" placeholder="immagine" formControlName="imageUrl"
              [disabled]="true"></stdout-text-input>
          </div>
          <div class=" cursor-pointer" *ngIf="imageUrl === ''">
            <stdout-icon-button (click)="show($event)" type="button" class="lente"
              [icon]="faUpload"></stdout-icon-button>
          </div>
          <div class=" cursor-pointer" *ngIf="imageUrl !== ''">
            <stdout-icon-button (click)="removeImage($event)" class="lente" [icon]="faTrashCan"></stdout-icon-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-center items-center">
      <div class="mt-10 w-full md:w-[15.209%]  cursor-pointer">
        <stdout-button class="btn-blu w-full md:w-[200px]  cursor-pointer" size="large" color="white"
          type="submit" [disabled]="isFormEmpty()" [class.opacity-50]="isFormEmpty()">
          <p>{{ "crea_nuova" | transloco }}</p>
        </stdout-button>
      </div>
    </div>
  </div>
</form>