import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  selectedItems: any[] = [];
  private indexView = new BehaviorSubject<number>(0);
  private displayCount = new BehaviorSubject<number>(10);

  setDisplayCount(value: number) {
    this.displayCount.next(value);
  }

  getDisplayCount() {
    return this.displayCount.asObservable();
  }

  setIndexView(value: number) {
    this.indexView.next(value);
  }

  getIndexView() {
    return this.indexView.asObservable();
  }

  private checkSubject = new BehaviorSubject<boolean>(false);

  get check$() {
    return this.checkSubject.asObservable();
  }

  updateCheck(value: boolean) {
    this.checkSubject.next(value);
  }

  selectAll(data: any[]) {
    this.selectedItems = data;
    this.selectedItems.forEach((item) => (item.selected = true));

    this.updateCheck(true);
  }

  deselectAll() {
    this.selectedItems.forEach((item) => (item.selected = false));
    this.selectedItems = [];
    this.updateCheck(false);
  }

  getSelectedIds() {
    return this.selectedItems.map((item) => item.id);
  }

  clearSelection() {
    this.deselectAll();
  }

  select(item: any) {
    item.selected = true;
    this.selectedItems.push(item);
  }

  deselect(item: any) {
    item.selected = false;
    this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
  }
}
