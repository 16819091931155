<div class="w-full h-full flex items-center justify-center">
<form [formGroup]="form" (ngSubmit)="submitForm($event)" class="w-full h-full flex items-center justify-center">
  <div class="flex-col justify-center testo ">
    <div class="h-full p-[25px] add-rig w-full">
      <div class="w-full flex items-center justify-center">
        <h1>
          <b>{{ titolo }}</b>
        </h1>
      </div>
      <div class="w-full mt-[25px] flex items-center justify-center gap-3">
        <div class="w-1/4 h-full" *ngIf="modifyIndex" >
          <h2 class="mb-[10px]">
            <b >{{ "indice" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della domanda"
            formControlName="indice"
       
          >
          </stdout-text-input>
        </div>
        <div class="w-full h-full">
        <h2 class="mb-[10px]">
          <b [ngClass]="{'testo-errore': form.get('domanda')?.invalid && formError}">{{ "domanda" | transloco }}</b>
        </h2>
        <stdout-text-input
          class="bordo"
          id="1"
          type="text"
          placeholder="Inserisci il testo della domanda"
          formControlName="domanda"
          [ngClass]="{'invalid-border': form.get('domanda')?.invalid && formError}"
        >
        </stdout-text-input>
      </div>
      </div>
      <!-- seconda riga -->
      <div class="flex justify-between items-center mt-[25px] w-full gap-3">
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('rispostaA')?.invalid && formError}">{{ "rispostaA" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta A"
            formControlName="rispostaA"
            [ngClass]="{'invalid-border': form.get('rispostaA')?.invalid && formError}"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('rispostaB')?.invalid && formError}">{{ "rispostaB" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta B"
            formControlName="rispostaB"
            [ngClass]="{'invalid-border': form.get('rispostaB')?.invalid && formError}"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "rispostaC" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta C"
            formControlName="rispostaC"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "rispostaD" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta D"
            formControlName="rispostaD"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "rispostaE" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta E"
            formControlName="rispostaE"
          >
          </stdout-text-input>
        </div>
      </div>
      <!-- terza riga -->
      <div class="flex items-center justify-between mt-[25px] gap-3">
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "rispostaF" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il testo della risposta F"
            formControlName="rispostaF"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('rispostaCorretta')?.invalid && formError}">{{ "Corretta" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci la risposta corretta"
            formControlName="rispostaCorretta"
            [ngClass]="{'invalid-border': form.get('rispostaCorretta')?.invalid && formError}"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('numerazioneMida')?.invalid && formError}">{{ "NMida" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il n° Mida"
            formControlName="numerazioneMida"
            [ngClass]="{'invalid-border': form.get('numerazioneMida')?.invalid && formError}"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('titoliStudio')?.invalid && formError}">{{ "Tit_stud" | transloco }}</b>
          </h2>
          <stdout-select
            class="fix-select"
            id="select1"
            [placeholder]="'Seleziona o inserisci l argomento'"
            [clearable]="false"
            [data]="combinedTitoli"
            (input)="onTitoliChange($event)"
            type="text"
            [value]="form.value.titoliStudio"
            formControlName="titoliStudio"
            #selectElement
            [ngClass]="{'invalid-select': form.get('titoliStudio')?.invalid && formError}"
          >
          </stdout-select>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('anno')?.invalid && formError}">{{ "anno" | transloco }}</b>
          </h2>
          <stdout-select
            class="fix-select"
            id="select1"
            [clearable]="false"
            (input)="onAnnoChange($event)"
            [placeholder]="'Seleziona l’anno '"
            formControlName="anno"
            [value]="form.value.anno"
            [data]="combinedAnno"
            #selectElement
            [ngClass]="{'invalid-select': form.get('anno')?.invalid && formError}"
          >
          </stdout-select>
        </div>
      </div>
      <!-- quarta riga -->
      <div class="flex justify-between items-center mt-[25px] w-full gap-3">
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('ente')?.invalid && formError}">{{ "ente" | transloco }}</b>
          </h2>
          <stdout-select
            class="fix-select"
            [placeholder]="'Seleziona o inserisci l’ente'"
            id="select1"
            type="text"
            [clearable]="false"
            [data]="combinedEnti"
            (input)="onEnteChange($event)"
            formControlName="ente"
            [value]="form.value.ente"
            #selectElement
            [ngClass]="{'invalid-select': form.get('ente')?.invalid && formError}"
          >
          </stdout-select>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "profilo" | transloco }}</b>
          </h2>
          <stdout-select
            [placeholder]="'Seleziona o inserisci il profilo'"
            class="fix-select"
            [clearable]="false"
            type="text"
            [data]="combinedProfili"
            (input)="onProfiliChange($event)"
            formControlName="profilo"
            [value]="form.value.profilo"
            #selectElement
            [disabled]="form.controls['profilo'].disabled"
          >
          </stdout-select>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('materia')?.invalid && formError}">{{ "materia" | transloco }}</b>
          </h2>
          <stdout-select
            class="fix-select"
            id="select1"
            [placeholder]="'Seleziona o inserisci la materia'"
            [clearable]="false"
            [data]="combinedMaterie"
            (input)="onMaterieChange($event)"
            type="text"
            [value]="form.value.materia"
            formControlName="materia"
            bindValue="nome"
            bindLabel="nome"
            #selectElement
            [ngClass]="{'invalid-select': form.get('materia')?.invalid && formError}"
          >
          </stdout-select>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('argomento')?.invalid && formError}">{{ "argomento" | transloco }}</b>
          </h2>
          <stdout-select
            class="fix-select"
            id="select1"
            [placeholder]="'Seleziona o inserisci l argomento'"
            [clearable]="false"
            [data]="combinedArgomenti"
            (input)="onArgomentiChange($event)"
            type="text"
            [value]="form.value.argomento"
            bindValue="nome"
            bindLabel="nome"
            formControlName="argomento"
            #selectElement
            [ngClass]="{'invalid-select': form.get('argomento')?.invalid && formError}"
          >
          </stdout-select>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b [ngClass]="{'testo-errore': form.get('difficolta')?.invalid && formError}">{{ "difficolta" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il livello di difficoltà (1, 2, o 3)"
            formControlName="difficolta"
            [ngClass]="{'invalid-border': form.get('difficolta')?.invalid && formError}"
          >
          </stdout-text-input>
        </div>
      </div>
      <!-- quinta riga -->

      <div class="flex items-end justify-between mt-[25px] w-full gap-3">
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "immagini" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il link dell’immagine associata alla domanda"
            formControlName="immagine"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "spiega_risp" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci la spiegazione alla risposta corretta"
            formControlName="immagineSpiegazione"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]">
          <h2 class="mb-[10px]">
            <b>{{ "preparazione" | transloco }}</b>
          </h2>
          <stdout-text-input
            class="bordo"
            id="1"
            type="text"
            placeholder="Inserisci il link alla preparazione"
            formControlName="linkPreparazione"
          >
          </stdout-text-input>
        </div>
        <div class="w-[15vw]"></div>

        <div class="w-[15vw]">
          <div class=" cursor-pointer">
            <stdout-button
              class="btn-blu w-full"
              size="large"
              color="white"
              type="submit"
            >
              <h2>{{ "conferma" | transloco }}</h2>
            </stdout-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>