import { Component, OnInit, Input } from '@angular/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  constructor() {}

  public faCircleXmark = faCircleXmark;
  ngOnInit(): void {}

  @Input() showToast!: boolean;
  @Input() colore: string = '';
  @Input() testo: string = '';
}
