import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '@services/rest.service';
import { AuthService } from '@services/auth.service';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: any;
  public hide = true;
  public checked = true;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;

  public faCircleCheck = faCircleCheck;
  public faCircleXmark = faCircleXmark;
  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private rest: RestService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', Validators.required),
    });
  }
  messErr: any;
  login() {
    this.authService
      .login(
        this.loginForm.controls['email'].getRawValue(),
        this.loginForm.controls['password'].getRawValue()
      )
      .subscribe({
        error: (error: any) => {
          console.log(error);
          this.messErr = error.error.message;
          this.showMessage(error.error.message, 'error');
        },
        next: (response: any) => {
          localStorage.setItem('roleId', response.roleId);

          if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.setItem(
              'refreshToken',
              response.tokens[0].refreshToken
            );

            this.rest.setToken(response.tokens[0].token);
          } else {
            localStorage.setItem(
              'refreshToken',
              response.tokens[0].refreshToken
            );
            localStorage.setItem('role', response.role);
            this.rest.setToken(response.tokens[0].token);
          }
          this.autorized();
        },
      });
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  autorized() {
    this.router.navigate(['/banche_dati']);
  }
}
