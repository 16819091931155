import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
} from '@angular/core';
import { MessageComponent } from '../components/message/message.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    private resolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  showMessage(message: string, status: string, customClass?: string) {
    const messageC = this.resolver.resolveComponentFactory(MessageComponent);
    const componentRef = messageC.create(this.injector);
    componentRef.instance.message = message;
    componentRef.instance.status = status;
    if (customClass) {
      componentRef.instance.customClass = customClass;
    }
    this.applicationRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    setTimeout(() => {
      document.body.removeChild(componentRef.location.nativeElement);
      componentRef.destroy();
    }, 2500);
  }
}
