import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollSubject = new Subject<number>();
  private displayCount = new BehaviorSubject<number>(0);

  scrollObservable = this.scrollSubject.asObservable();

  emitScrollEvent(scrollTop: number) {
    this.scrollSubject.next(scrollTop);
  }

  private widths = new BehaviorSubject<number[]>([]);

  setWidths(widths: number[]) {
    this.widths.next(widths);
  }

  getWidths() {
    return this.widths.asObservable();
  }

  private scrollLeft = new BehaviorSubject<number>(0);
  private mouseDown = new BehaviorSubject<boolean>(false);

  setScrollLeft(value: any) {
    this.scrollLeft.next(value);
  }

  getScrollLeft() {
    return this.scrollLeft.asObservable();
  }

  setMouseDown(value: boolean) {
    this.mouseDown.next(value);
  }

  getMouseDown() {
    return this.mouseDown.asObservable();
  }

  reset() {
    this.scrollSubject.next(0);
    this.widths.next([]);
    this.scrollLeft.next(0);
    this.mouseDown.next(false);
    this.displayCount.next(0);
  }

  incrementDisplayCount() {
    this.displayCount.getValue();
    let currentCount = this.displayCount.getValue();

    this.displayCount.next(currentCount + 10);
  }

  getDisplayCount() {
    return this.displayCount.asObservable();
  }
}
