import { Component, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-funzionalita',
  templateUrl: './funzionalita.component.html',
  styleUrls: ['./funzionalita.component.scss'],
})
export class FunzionalitaComponent implements OnInit {
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;

  showPrima = false;
  showSeconda = false;
  showTerza = false;
  showQuarta = false;
  showQuinta = false;
  showSesta = false;
  showSettima = false;

  v1() {
    this.showPrima = !this.showPrima;
  }

  v2() {
    this.showSeconda = !this.showSeconda;
  }

  v3() {
    this.showTerza = !this.showTerza;
  }

  v4() {
    this.showQuarta = !this.showQuarta;
  }

  v5() {
    this.showQuinta = !this.showQuinta;
  }

  v6() {
    this.showSesta = !this.showSesta;
  }

  v7() {
    this.showSettima = !this.showSettima;
  }

  attività: string[] = [];
  isCheckedAppLib: boolean = false;
  isCheckedAppGuid: boolean = false;
  isCheckedSim: boolean = false;

  ResetAttivita() {
    this.isCheckedAppLib = false;
    this.isCheckedAppGuid = false;
    this.isCheckedSim = false;
    this.attività.forEach((attivita) => {
      const index = this.Tutto.indexOf(attivita);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.attività = []; // Svuota completamente l'array attività
  }

  gestisciCheckboxApprendimentoLibero(event: Event) {
    this.isCheckedAppLib = !this.isCheckedAppLib;

    if (this.isCheckedAppLib) {
      this.attività.push('Apprendimento libero');
    } else {
      const index = this.attività.indexOf('Apprendimento libero');
      if (index !== -1) {
        this.attività.splice(index, 1);
      }
    }
    console.log('Attività :', this.attività); // Stampa l'array nel console
  }

  gestisciCheckboxApprendimentoGuidato(event: Event) {
    this.isCheckedAppGuid = !this.isCheckedAppGuid;

    if (this.isCheckedAppGuid) {
      this.attività.push('Apprendimento guidato');
    } else {
      const index = this.attività.indexOf('Apprendimento guidato');
      if (index !== -1) {
        this.attività.splice(index, 1);
      }
    }
    console.log('Attività :', this.attività); // Stampa l'array nel console
  }

  gestisciCheckboxSimulazioneDesame(event: Event) {
    this.isCheckedSim = !this.isCheckedSim;

    if (this.isCheckedSim) {
      this.attività.push("Simulazione d'esame");
    } else {
      const index = this.attività.indexOf("Simulazione d'esame");
      if (index !== -1) {
        this.attività.splice(index, 1);
      }
    }
    console.log('Attività :', this.attività); // Stampa l'array nel console
  }

  ordine: string[] = [];
  isCheckedAlfabetico: boolean = false;
  isCheckedCasuale: boolean = false;
  isCheckedUfficiale: boolean = false;

  resetOrdine() {
    this.isCheckedAlfabetico = false;
    this.isCheckedCasuale = false;
    this.isCheckedUfficiale = false;

    this.ordine.forEach((opzione) => {
      const index = this.Tutto.indexOf(opzione);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });

    this.ordine = []; // Svuota completamente l'array ordine
    console.log(this.Tutto);
    console.log(this.ordine);
  }

  gestisciCheckboxAlfabetico(event: Event) {
    this.isCheckedAlfabetico = !this.isCheckedAlfabetico;

    if (this.isCheckedAlfabetico) {
      this.ordine.push('Alfabetico');
    } else {
      const index = this.ordine.indexOf('Alfabetico');
      if (index !== -1) {
        this.ordine.splice(index, 1);
      }
    }
    console.log('Ordine quiz :', this.ordine); // Stampa l'array nel console
  }

  gestisciCheckboxCasuale(event: Event) {
    this.isCheckedCasuale = !this.isCheckedCasuale;

    if (this.isCheckedCasuale) {
      this.ordine.push('Casuale');
    } else {
      const index = this.ordine.indexOf('Casuale');
      if (index !== -1) {
        this.ordine.splice(index, 1);
      }
    }
    console.log('Ordine quiz :', this.ordine); // Stampa l'array nel console
  }

  gestisciCheckboxUfficiale(event: Event) {
    this.isCheckedUfficiale = !this.isCheckedUfficiale;

    if (this.isCheckedUfficiale) {
      this.ordine.push('Ufficiale');
    } else {
      const index = this.ordine.indexOf('Ufficiale');
      if (index !== -1) {
        this.ordine.splice(index, 1);
      }
    }
    console.log('Ordine quiz :', this.ordine); // Stampa l'array nel console
  }

  filtri: string[] = [];
  isCheckMat: boolean = false;
  isCheckPar: boolean = false;
  isCheckInt: boolean = false;
  isCheckDif: boolean = false;
  isCheckQuiz: boolean = false;
  isCheckImg: boolean = false;

  resetFiltri() {
    this.isCheckMat = false;
    this.isCheckPar = false;
    this.isCheckInt = false;
    this.isCheckDif = false;
    this.isCheckQuiz = false;
    this.isCheckImg = false;

    this.filtri.forEach((filtro) => {
      const index = this.Tutto.indexOf(filtro);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.filtri = [];
    console.log(this.filtri);
    console.log(this.Tutto);
  }

  gestisciCheckboxSelezionaMateria(event: Event) {
    this.isCheckMat = !this.isCheckMat;

    if (this.isCheckMat) {
      this.filtri.push('Seleziona materia');
    } else {
      const index = this.filtri.indexOf('Seleziona materia');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  gestisciCheckboxParolaChiave(event: Event) {
    this.isCheckPar = !this.isCheckPar;

    if (this.isCheckPar) {
      this.filtri.push('Parola chiave');
    } else {
      const index = this.filtri.indexOf('Parola chiave');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  gestisciCheckboxIntervalloNumerico(event: Event) {
    this.isCheckInt = !this.isCheckInt;

    if (this.isCheckInt) {
      this.filtri.push('Intervallo numerico');
    } else {
      const index = this.filtri.indexOf('Intervallo numerico');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  gestisciCheckboxDifficoltaQuiz(event: Event) {
    this.isCheckDif = !this.isCheckDif;

    if (this.isCheckDif) {
      this.filtri.push('Difficoltà quiz');
    } else {
      const index = this.filtri.indexOf('Difficoltà quiz');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  gestisciCheckboxQuizConSpiegazione(event: Event) {
    this.isCheckQuiz = !this.isCheckQuiz;

    if (this.isCheckQuiz) {
      this.filtri.push('Quiz con spiegazione');
    } else {
      const index = this.filtri.indexOf('Quiz con spiegazione');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  gestisciCheckboxQuizConImmagini(event: Event) {
    this.isCheckImg = !this.isCheckImg;

    if (this.isCheckImg) {
      this.filtri.push('Quiz con immagini');
    } else {
      const index = this.filtri.indexOf('Quiz con immagini');
      if (index !== -1) {
        this.filtri.splice(index, 1);
      }
    }
    console.log('Filtri :', this.filtri); // Stampa l'array nel console
  }

  svolgi: string[] = [];
  isCheckTutti: boolean = false;
  isCheckSvolti: boolean = false;
  isCheckSospesi: boolean = false;
  isCheckSalvati: boolean = false;
  isCheckNuovi: boolean = false;
  isCheckErrati: boolean = false;
  isCheckCorretti: boolean = false;

  resetSvolgi() {
    this.isCheckTutti = false;
    this.isCheckSvolti = false;
    this.isCheckSospesi = false;
    this.isCheckSalvati = false;
    this.isCheckNuovi = false;
    this.isCheckErrati = false;
    this.isCheckCorretti = false;

    this.svolgi.forEach((svolgimento) => {
      const index = this.Tutto.indexOf(svolgimento);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.svolgi = [];
    console.log(this.svolgi);
    console.log(this.Tutto);
  }

  gestisciCheckboxTutti(event: Event) {
    this.isCheckTutti = !this.isCheckTutti;

    if (this.isCheckTutti) {
      this.svolgi.push('Tutti');
    } else {
      const index = this.svolgi.indexOf('Tutti');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxSvolti(event: Event) {
    this.isCheckSvolti = !this.isCheckSvolti;

    if (this.isCheckSvolti) {
      this.svolgi.push('Svolti');
    } else {
      const index = this.svolgi.indexOf('Svolti');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxSospesi(event: Event) {
    this.isCheckSospesi = !this.isCheckSospesi;
    if (this.isCheckSospesi) {
      this.svolgi.push('Sospesi');
    } else {
      const index = this.svolgi.indexOf('Sospesi');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxSalvati(event: Event) {
    this.isCheckSalvati = !this.isCheckSalvati;

    if (this.isCheckSalvati) {
      this.svolgi.push('Salvati');
    } else {
      const index = this.svolgi.indexOf('Salvati');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxNuovi(event: Event) {
    this.isCheckNuovi = !this.isCheckNuovi;

    if (this.isCheckNuovi) {
      this.svolgi.push('Nuovi');
    } else {
      const index = this.svolgi.indexOf('Nuovi');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxErrati(event: Event) {
    this.isCheckErrati = !this.isCheckErrati;

    if (this.isCheckErrati) {
      this.svolgi.push('Errati');
    } else {
      const index = this.svolgi.indexOf('Errati');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  gestisciCheckboxCorretti(event: Event) {
    this.isCheckCorretti = !this.isCheckCorretti;

    if (this.isCheckCorretti) {
      this.svolgi.push('Corretti');
    } else {
      const index = this.svolgi.indexOf('Corretti');
      if (index !== -1) {
        this.svolgi.splice(index, 1);
      }
    }
    console.log('Svolgi quiz : ', this.svolgi); // Stampa l'array nel console
  }

  opzioni: string[] = [];
  isCheckRispCorr: boolean = false;
  isCheckMostCont: boolean = false;
  isCheckAutoNext: boolean = false;

  resetOpzioni() {
    this.isCheckRispCorr = false;
    this.isCheckMostCont = false;
    this.isCheckAutoNext = false;

    this.opzioni.forEach((opzione) => {
      const index = this.Tutto.indexOf(opzione);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.opzioni = [];
    console.log(this.opzioni);
    console.log(this.Tutto);
  }

  gestisciCheckboxMostraRispostaCorretta(event: Event) {
    this.isCheckRispCorr = !this.isCheckRispCorr;

    if (this.isCheckRispCorr) {
      this.opzioni.push('Mostra risposta corretta');
    } else {
      const index = this.opzioni.indexOf('Mostra risposta corretta');
      if (index !== -1) {
        this.opzioni.splice(index, 1);
      }
    }
    console.log('Opzioni:', this.opzioni); // Stampa l'array nel console
  }

  gestisciCheckboxMostraContatore(event: Event) {
    this.isCheckMostCont = !this.isCheckMostCont;

    if (this.isCheckMostCont) {
      this.opzioni.push('Mostra contatore risposte corrette/errate');
    } else {
      const index = this.opzioni.indexOf(
        'Mostra contatore risposte corrette/errate'
      );
      if (index !== -1) {
        this.opzioni.splice(index, 1);
      }
    }
    console.log('Opzioni:', this.opzioni); // Stampa l'array nel console
  }

  gestisciCheckboxAbilita(event: Event) {
    this.isCheckAutoNext = !this.isCheckAutoNext;

    if (this.isCheckAutoNext) {
      this.opzioni.push('Abilita autonext');
    } else {
      const index = this.opzioni.indexOf('Abilita autonext');
      if (index !== -1) {
        this.opzioni.splice(index, 1);
      }
    }
    console.log('Opzioni:', this.opzioni); // Stampa l'array nel console
  }

  modalita: string[] = [];
  isCheckStampaSep: boolean = false;
  isCheckStampaAff: boolean = false;
  ischeckStampCorr: boolean = false;

  resetModalita() {
    this.ischeckStampCorr = false;
    this.isCheckStampaAff = false;
    this.isCheckStampaSep = false;

    this.modalita.forEach((modalita) => {
      const index = this.Tutto.indexOf(modalita);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.modalita = [];
    console.log(this.modalita); // Stampa l'array nel console'
    console.log(this.Tutto); // Stampa l'array nel console'
  }

  gestisciCheckboxStampaCorr(event: Event) {
    this.isCheckStampaSep = !this.isCheckStampaSep;

    if (this.isCheckStampaSep) {
      this.modalita.push('Stampa quiz con correttore separato');
    } else {
      const index = this.modalita.indexOf(
        'Stampa quiz con correttore separato'
      );
      if (index !== -1) {
        this.modalita.splice(index, 1);
      }
    }
    console.log('Modalità di stampa:', this.modalita); // Stampa l'array nel console
  }

  gestisciCheckboxStampaCorrAff(event: Event) {
    this.isCheckStampaAff = !this.isCheckStampaAff;

    if (this.isCheckStampaAff) {
      this.modalita.push('Stampa quiz con correttore al fianco');
    } else {
      const index = this.modalita.indexOf(
        'Stampa quiz con correttore al fianco'
      );
      if (index !== -1) {
        this.modalita.splice(index, 1);
      }
    }
    console.log('Modalità di stampa:', this.modalita); // Stampa l'array nel console
  }

  gestisciCheckboxStampaCorrSolo(event: Event) {
    this.ischeckStampCorr = !this.ischeckStampCorr;

    if (this.ischeckStampCorr) {
      this.modalita.push('Stampa quiz con la sola risposta corretta');
    } else {
      const index = this.modalita.indexOf(
        'Stampa quiz con la sola risposta corretta'
      );
      if (index !== -1) {
        this.modalita.splice(index, 1);
      }
    }
    console.log('Modalità di stampa:', this.modalita); // Stampa l'array nel console
  }

  opzeser: string[] = [];
  isCheckInfo: boolean = false;
  isCheckTesto: boolean = false;
  isCheckSalva: boolean = false;
  isCheckCondividi: boolean = false;

  resetOpzeser() {
    this.isCheckInfo = false;
    this.isCheckTesto = false;
    this.isCheckSalva = false;
    this.isCheckCondividi = false;

    this.opzeser.forEach((opzser) => {
      const index = this.Tutto.indexOf(opzser);
      if (index !== -1) {
        this.Tutto.splice(index, 1);
      }
    });
    this.opzeser = [];
    console.log(this.opzeser);
    console.log(this.Tutto);
  }

  gestisciCheckboxInfo(event: Event) {
    this.isCheckInfo = !this.isCheckInfo;

    if (this.isCheckInfo) {
      this.opzeser.push('Info sulla domanda');
    } else {
      const index = this.opzeser.indexOf('Info sulla domanda');
      if (index !== -1) {
        this.opzeser.splice(index, 1);
      }
    }
    console.log('Opzioni esercitazioni :', this.opzeser); // Stampa l'array nel console
  }

  gestisciCheckboxTesto(event: Event) {
    this.isCheckTesto = !this.isCheckTesto;

    if (this.isCheckTesto) {
      this.opzeser.push('Testo');
    } else {
      const index = this.opzeser.indexOf('Testo');
      if (index !== -1) {
        this.opzeser.splice(index, 1);
      }
    }
    console.log('Opzioni esercitazioni :', this.opzeser); // Stampa l'array nel console
  }

  gestisciCheckboxSalva(event: Event) {
    this.isCheckSalva = !this.isCheckSalva;

    if (this.isCheckSalva) {
      this.opzeser.push('Salva domanda');
    } else {
      const index = this.opzeser.indexOf('Salva domanda');
      if (index !== -1) {
        this.opzeser.splice(index, 1);
      }
    }
    console.log('Opzioni esercitazioni :', this.opzeser); // Stampa l'array nel console
  }

  gestisciCheckboxCondividi(event: Event) {
    this.isCheckCondividi = !this.isCheckCondividi;

    if (this.isCheckCondividi) {
      this.opzeser.push('Condividi');
    } else {
      const index = this.opzeser.indexOf('Condividi');
      if (index !== -1) {
        this.opzeser.splice(index, 1);
      }
    }
    console.log('Opzioni esercitazioni :', this.opzeser);
  }

  Tutto: string[] = [];

  salvaAttivita() {
    this.attività.forEach((attivita) => {
      this.Tutto.push(attivita);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaOrdine() {
    this.ordine.forEach((ord) => {
      this.Tutto.push(ord);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaFiltri() {
    this.filtri.forEach((filtro) => {
      this.Tutto.push(filtro);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaSvolgi() {
    this.svolgi.forEach((svolgimento) => {
      this.Tutto.push(svolgimento);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaOpzioni() {
    this.opzioni.forEach((opzione) => {
      this.Tutto.push(opzione);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaModalita() {
    this.modalita.forEach((modalita) => {
      this.Tutto.push(modalita);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  salvaOpzeser() {
    this.opzeser.forEach((opzser) => {
      this.Tutto.push(opzser);
    });
    console.log('Filtri selezionati :', this.Tutto);
  }

  constructor() {}

  ngOnInit(): void {}
}
