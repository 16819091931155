<app-header></app-header>

<div [class.opacity-50]="showModal == true" class="mb-[30vh]">
  <div class="flex justify-end align-baseline mt-10 mr-10 items-center">
    <div class="mr-[15px]">
      <h1>{{ "aggiungi_news" | transloco }}</h1>
    </div>
    <div class=" cursor-pointer">
      <stdout-icon-button class="lente" (click)="aggiungiNews()" [icon]="faPlus"></stdout-icon-button>
    </div>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
    <div *ngIf="showForm" class="mt-5 md:mt-10 px-10">
      <stdout-card>
        <stdout-card-header class="card-header">
          <h2>News</h2>
        </stdout-card-header>
        <stdout-card-content>
          <div class=" ">
            <div class="flex justify-between mb-[11px]">
              <label>
                <h3>Immagine</h3>
              </label>
              <div *ngIf="showImage">
                <label for="fileInput" class=" cursor-pointer">
                  <p>{{ "cambia_immagine" | transloco }}</p>
                </label>
              </div>
              <input type="file" (change)="onFileSelected($event)" accept=".png, .jpeg, .jpg" [id]="'fileInput'"
                style="display: none" />
            </div>
            <!-- <div class="img  flex justify-center ">
       
        <ng-container >
          <img [src]="" alt="Immagine">
        </ng-container>
        <div *ngIf="showImage && imageUrl">
          <img [src]="imageUrl" alt="Immagine caricata">
        </div>
      </div> -->

            <div class="riquadro flex items-center justify-center aspect-[463/115]" *ngIf="showRiquadro">
              <input type="file" (change)="onFileSelected($event)" accept=".png, .jpeg, .jpg" [id]="'fileInput'"
                style="display: none" />

              <div class="flex items-center justify-center">
                <input type="file" #fileInput (change)="onFileSelected($event)" accept=".png, .jpeg, .jpg"
                  style="display: none" />

                <stdout-icon-button class="btn-img  cursor-pointer" [icon]="faCirclePlus" (click)="show($event)">
                  <fa-icon [icon]="faCirclePlus"></fa-icon>
                </stdout-icon-button>

                <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image" />
              </div>
            </div>

            <div *ngIf="!showRiquadro" class="aspect-[463/115]">
              <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image" style="width: 100%; height: 115px" />
            </div>

            <div class="titolo mt-[25px]">
              <h3>{{ "titolo" | transloco }}</h3>
              <div class="mt-[10px]">
                <stdout-text-input placeholder="Inserisci titolo" [(ngModel)]="title" class="bordo"
                  type="text"></stdout-text-input>
              </div>
            </div>
            <div class="Descrizione mt-[25px]">
              <h3>{{ "descrizione" | transloco }}</h3>
              <div class="mt-[10px]">
                <stdout-text-area class="fix" [(ngModel)]="description"
                  placeholder="Inserisci descrizione"></stdout-text-area>
              </div>
            </div>
            <div class="link mt-[25px]">
              <h3>{{ "link" | transloco }}</h3>
              <div class="mt-[10px]">
                <stdout-text-input placeholder="Inserisci link" [(ngModel)]="link" class="bordo"
                  type="text"></stdout-text-input>
              </div>
            </div>
            <div class="flex justify-center bottoni mt-10">
              <div class="w-[200px] mr-[10px]  cursor-pointer">
                <stdout-button class="btn-bianco" size="large">
                  <h2>{{ "elimina" | transloco }}</h2>
                </stdout-button>
              </div>
              <div class="w-[200px] ml-[10px]  cursor-pointer">
                <stdout-button class="btn-blu" color="white" size="large" (click)="onSubmit()"
                [ngClass]="{ opaco: !areFieldsValid() }" [disabled]="!areFieldsValid()">
                <h2>{{ "salva" | transloco }}</h2>
</stdout-button>
              </div>
            </div>
          </div>
        </stdout-card-content>
      </stdout-card>
    </div>
    <!-- Visualizazione consigliati creati -->
    <div class="px-10 mt-10" *ngFor="let consigliato of consigliatiOrdinati; let i = index"
      (dblclick)="modifica(consigliato.id, consigliato.Titolo, consigliato.Descrizione, consigliato.Link, consigliato.Cover, consigliato.posizione)">
      <stdout-card>
        <stdout-card-header class="card-header">
          <h2>
            News {{ consigliato.posizione }}
          </h2>
        </stdout-card-header>
        <stdout-card-content>
          <div class=" ">
            <div class="mb-[11px] flex justify-between items-center">
              <label>
                <h3>Immagine</h3>
              </label>
              <label (click)="show($event)" *ngIf="showRiquadro2 && consigliato.id === idToModify" for="fileInput"
                class="cursor-pointer transition duration-300 text-blue-500">
                <p>Cambia immagine</p>
              </label>
              <!-- <input type="file" (change)="onFileSelectedModify($event)" accept=".png, .jpeg, .jpg" id="fileInput" style="display:none"> -->
            </div>

            <div class="flex items-center justify-center aspect-[463/115]"
              *ngIf="showRiquadro2 && consigliato.id !== idToModify">
              <img src="{{ percorso }}{{ consigliato.Cover }}" alt="Immagine consigliata"
                style="width: 100%; height: 100%" />
            </div>

            <div class="flex items-center justify-center aspect-[463/115]"
              *ngIf="showRiquadro2 && consigliato.id === idToModify">
              <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image" style="width: 100%; height: 100%" />
            </div>

            <div class="titolo mt-[25px]">
              <h3>{{ "titolo" | transloco }}</h3>
              <div class="mt-[10px]" *ngIf="idToModify !== consigliato.id">
                <stdout-text-input [(ngModel)]="consigliato.Titolo" class="bordo" type="text" [disabled]="true">
                </stdout-text-input>
              </div>
              <div class="mt-[10px]" *ngIf="idToModify === consigliato.id">
                <stdout-text-input [(ngModel)]="modifyTitle" class="bordo" type="text" [disabled]="false">
                </stdout-text-input>
              </div>
            </div>
            <div class="Descrizione mt-[25px]">
              <h3>{{ "descrizione" | transloco }}</h3>
              <div class="mt-[10px]" *ngIf="idToModify !== consigliato.id">
                <stdout-text-area class="fix" [(ngModel)]="consigliato.Descrizione"
                  [disabled]="true"></stdout-text-area>
              </div>
              <div class="mt-[10px]" *ngIf="idToModify === consigliato.id">
                <stdout-text-area class="fix" [(ngModel)]="modifyDescription" [disabled]="false"></stdout-text-area>
              </div>
            </div>
            <div class="link mt-[25px]">
              <h3>{{ "link" | transloco }}</h3>
              <div class="mt-[10px]" *ngIf="idToModify !== consigliato.id">
                <stdout-text-input [(ngModel)]="consigliato.Link" class="bordo" type="text"
                  [disabled]="true"></stdout-text-input>
              </div>
              <div class="mt-[10px]" *ngIf="idToModify === consigliato.id">
                <stdout-text-input [(ngModel)]="modifyLink" class="bordo" type="text"
                  [disabled]="false"></stdout-text-input>
              </div>
            </div>
            <div class="titolo mt-[25px]">
              <h3>{{ "posizione" | transloco }}</h3>
              <div class="mt-[10px]" *ngIf="idToModify !== consigliato.id">
                <stdout-text-input [(ngModel)]="consigliato.posizione" class="bordo" type="text" [disabled]="true">
                </stdout-text-input>
              </div>
              <div class="mt-[10px]" *ngIf="idToModify === consigliato.id">
                <stdout-text-input [(ngModel)]="modifyPosizione" class="bordo" type="text" [disabled]="false">
                </stdout-text-input>
              </div>
            </div>
            <div class="flex justify-center bottoni mt-10">
              <div class="w-[200px] mr-[10px] cursor-pointer">
                <stdout-button class="btn-bianco" size="large" (click)="deleteBando(consigliato.id)">
                  <h2>{{ "elimina" | transloco }}</h2>
                </stdout-button>
              </div>
              <div class="w-[200px] ml-[10px]  cursor-pointer">
                <stdout-button class="btn-blu" color="white" size="large" (click)="onSubmitModify(consigliato.id)">
                  <h2>{{ "salva" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
          </div>
        </stdout-card-content>
      </stdout-card>
    </div>
  </div>
</div>



<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="showModal"  >
  <div class="max-w-3xl   m-auto w-full  ">
    <div class="my-10 mx-auto flex items-center justify-center  shadow-2xl  max-h-[80vh] media rounded-xl ">
<div class=" flex items-center justify-center">
  <app-image-cropper   (click)="$event.stopPropagation()"
    class="z-10 bg-white rounded-xl shadow-2xl"
    [width]="463"
    [height]="115"
  ></app-image-cropper>
</div>
</div>
</div>
</div>

