import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  faTrash,
  faTrashCan,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { FormService } from '@services/form.service';
import { ImageServiceService } from '@services/image-service.service';
import { MappingService } from '@services/mapping.service';
import { MessageService } from '@services/message.service';
import { SelectionService } from '@services/selection.service';
import { set } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit {
  form: FormGroup;
  public imageUrl: any;
  public faUpload = faUpload;
  public faTrashCan = faTrashCan;
  private subscription: Subscription = new Subscription();

  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;

  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  @Input() combinedAnno: any;

  combinedLib: any;
  existPass: boolean = false;

  librerie: any;
  @Input() Anno: any;
  @Input() check: any;
  @Input() selectedFile: any;

  formError = true;

  constructor(
    private fb: FormBuilder,
    private banchedati: BanchedatiService,
    private mappingService: MappingService,
    private formService: FormService,
    private imageService: ImageServiceService,
    private selectionService: SelectionService,
    private message: MessageService
  ) {
    this.form = this.fb.group({
      titolo: [, Validators.required],
      descrizione: [, Validators.required],
      ente: [, Validators.required],
      profilo: [{ disabled: true }, Validators.required],
      regione: [, Validators.required],
      anno: [, Validators.required],
      imageUrl: [, Validators.required],
    });
    this.form.get('imageUrl')?.valueChanges.subscribe((value) => {
      this.imageUrl = value;
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.formService.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );
    this.subscription.add(
      this.formService.getFormError().subscribe((value) => {
        this.formError = value;
      })
    );
    this.subscription.add(
      this.formService.getForm().subscribe((form) => {
        if (form) {
          this.form.patchValue(form);
        }
      })
    );
    // this.avvia();
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.form.get('imageUrl')?.setValue(event.target.result);
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
      })
    );

    this.form.get('profilo')?.disable();
    this.form.get('ente')?.valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('profilo')?.enable();
      } else {
        this.form.get('profilo')?.disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);
  }

  exist() {
    const nome = this.form.get('titolo')?.value;
    const anno = this.form.get('anno')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    if (nome && nome.trim() !== '') {
      return this.banchedati.exist(nome, descrizione, anno).subscribe(
        (response: any) => {
          this.formService.setExistPass(true);
          this.createBanca();
        },
        (error: any) => {
          console.error('Errore durante la creazione della materia:', error);
          this.showMessage(error.error.message, 'error');

          this.formService.setExistPass(false);
        }
      );
    }
    return null;
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;
    console.log(event);

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  onAnnoChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const anno = target.value;

    this.combinedAnno = this.Anno.map((anno: any) => anno.nome);

    this.combinedAnno.push(anno);
  }

  onProfiliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const profilo = target.value;

    this.combinedProfili = [...this.nomiProfili];
    if (profilo) {
      this.combinedProfili.push(profilo);
    }
  }
  @Output() onSubmit = new EventEmitter<any>();

  //   submitForm(event: Event) {
  //  event.preventDefault();
  //   event.stopPropagation();
  //   this.createBanca();
  //  setTimeout(() => {
  //     this.onSubmit.emit();
  //   }, 300);
  //   }

  show(event: Event) {
    event.preventDefault();
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage(event: Event) {
    event.preventDefault();
    this.form.get('imageUrl')?.setValue('');
    this.imageService.setCroppedImage(null);
  }

  submitForm(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.formService.changeForm(this.form.value);
    if (
      this.check.length === 0 ||
      this.form.get('titolo')?.value === '' ||
      this.form.get('ente')?.value === null ||
      this.form.get('regione')?.value === null ||
      this.form.get('anno')?.value === null
    ) {
      this.showMessage('Selezionare almeno una banca dati', 'error');

      this.formService.setFormError(true);
    } else {
      this.exist();
    }
  }

  gestisciDati(event: MouseEvent) {
    if (
      this.check.length === 0 ||
      this.form.get('titolo')?.value === '' ||
      this.form.get('ente')?.value === null ||
      this.form.get('regione')?.value === null ||
      this.form.get('anno')?.value === null
    ) {
      this.showMessage(
        'Selezionare almeno una banca dati e compilare i campi evidenziati',
        'error'
      );
      this.formService.setFormError(true);
    } else {
      this.exist();
    }
  }

  resetForm() {
    const emptyForm = {
      titolo: '',
      descrizione: '',
      ente: null,
      profilo: null,
      regione: null,
      anno: null,
      imageUrl: '',
    };

    this.formService.changeForm(emptyForm);
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  createBanca() {
    const titolo = this.form.get('titolo')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    const ente = this.form.get('ente')?.value;
    const profilo = this.form.get('profilo')?.value;
    const regione = this.form.get('regione')?.value;

    console.log(regione);
    const anno = this.form.get('anno')?.value;
    LoaderComponent.isLoading = true;
    const formData: FormData = new FormData();
    for (let id of this.check) {
      formData.append('bancadatiIds[]', id.toString());
    }

    formData.append('nome', titolo);
    formData.append('descrizione', descrizione);
    if (ente) {
      formData.append('ente', ente);
    }
    if (profilo) {
      formData.append('profilo', profilo);
    }
    formData.append('idRegione', regione);
    formData.append('anno', anno);

    if (this.selectedFile) {
      const fileExtension = 'png';
      formData.append('cover', this.selectedFile, `cover.${fileExtension}`);
    }
    return this.banchedati.createBancaDati(formData).subscribe(
      (response: any) => {
        this.mappingService.avvia().subscribe();
        // this.paginateBancheDati()
        this.formService.setFormError(false);
        this.selectionService.clearSelection();
        this.selectionService.updateCheck(false);
        // this.updateSelectionAll();
        this.imageService.setCroppedImage(null);
        LoaderComponent.isLoading = false;
        this.formService.setShowPopUp(true);
        this.resetForm();
        // this.showPop = !this.showPop;
      },
      (error: any) => {
        console.error('Errore durante la creazione della materia:', error);
        this.formService.setFormError(true);
        this.showMessage(error.error.message, 'error');

        LoaderComponent.isLoading = false;
      }
    );
  }
}
