<app-header></app-header>

<div
  [class.opacity-50]="
    modifica == true ||
    showInput ||
    importLibreria ||
    loader ||
    elimina ||
    showDomande ||
    modifica2 ||
    showPriv ||
    showPub
  "
  [class.non-clickable]="
    modifica == true ||
    showInput ||
    importLibreria ||
    loader ||
    elimina ||
    showDomande ||
    modifica2 ||
    showPriv ||
    showPub"
  class="w-full h-full"
  [ngClass]="{ 'overflow-hidden': !showFilter }"
  [ngClass]="{ 'mb-[30vh]': showFilter }"
>
  <div class="mt-10">
    <!-- card -->
    <div class="px-10">
      <app-card-details
        [combinedAnno]="combinedAnno"
        [nomiEnti]="nomiEnti"
        [nomiProfili]="nomiProfili"
        [combinedEnti]="combinedEnti"
        [combinedProfili]="combinedProfili"
        [listaRegioni]="listaRegioni"
        [Anno]="Anno"
        [listaRegioni]="listaRegioni"
 
    
      ></app-card-details>
    </div>
    <!-- prima parte -->
    <div class="flex justify-between flex-col mt-10 md:flex-row px-10">
      <div
        class="flex input-container justify-start items-center"
        *ngIf="!isPhone"
      >
        <stdout-icon-button
          class="lente items-center  cursor-pointer"
          style="margin: 2px 10px 0px 0px"
          size="small"
          background="secondary"
          color="white"
          borderColor="secondary"
          [icon]="faMagnifyingGlass"
        >
        </stdout-icon-button>
        <div class="w-[300px]">
          <stdout-text-input
            class="search-bar"
            placeholder="Cerca banca dati..."
            [(ngModel)]="search"
            (keyup)="applyFilter()"
          ></stdout-text-input>
        </div>
      </div>
      <div class="flex lg:flex-row flex-col lg:gap-0 gap-5 lg:items-center lg:justify-end  w-1/2 ">
        <div class=" flex items-center w-full justify-end relative  mr-5">
          <p *ngIf="!showVisual" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
            <b>Ordine Visualizzazione </b>
          </p>
  
          <p *ngIf="showVisual" class="text-[#0B0E42] text-right font-montserrat text-base font-medium leading-normal">
            <b>Chiudi </b>
          </p>
          <div class="ml-[15px]  cursor-pointer" (click)="$event.stopPropagation()">
            <stdout-icon-button class="lente" (click)="openVisual()" [icon]="ordinaPer === 2 ? (ordinaDesc ? faArrowDownZA : faArrowUpAZ) :
            ordinaPer === 1 ? (ordinaDesc ? faArrowDown91 : faArrowUp19) :
            ordinaPer === 0 && !ordinaDesc ? faSort : faSort"></stdout-icon-button>
          </div>
           <!-- dropdown ordinamento -->
          <div class="absolute z-50 element min-h-700  top-full mt-5 w-[300px] bg-white rounded-[15px] p-[25px]"
          *ngIf="showVisual" (click)="$event.stopPropagation()">
          <div class="flex flex-col gap-4 items-center justify-center">
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">
      
                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaAlfabetico()" [icon]="faD"></stdout-icon-button> -->
                <stdout-button  (click)="ordinaAlfabetico()" size="large" color="black" [class]="ordinaPer==2 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_alf" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">
      
                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaNumerico()" [icon]="faN"></stdout-icon-button> -->
                <stdout-button  (click)="ordinaNumerico()" size="large" color="black" [class]="ordinaPer==1 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_num" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">
      
                <!-- <stdout-icon-button class="lente-bianca" (click)="ordinaNumerico()" [icon]="faN"></stdout-icon-button> -->
                <stdout-button  (click)="ordinaIndice()" size="large" color="black" [class]="ordinaPer==3 ? 'btn-blu' : 'btn-bianco' ">
                  <h2>{{ "ordine_ind" | transloco }}</h2>
                </stdout-button>
              </div>
            </div>
            <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
              <div class=" w-full  cursor-pointer">
    
                  <stdout-button  (click)="invertiOrdine()" size="large" color="black" [class]="ordinaDesc===true ? 'btn-blu' : 'btn-bianco' ">
                    <h2>{{ "inverti_ord" | transloco }}</h2>
                  </stdout-button>
              </div>
            </div>
      
            <div class="w-full h-full flex items-center justify-center">
              <div class="flex input-container justify-center items-center mt-4 md:max-w-[200px]" *ngIf="!isPhone">
                <stdout-icon-button class="lente items-center  cursor-pointer" style="margin: 2px 10px 0px 0px" size="small"
                  background="secondary" color="white" borderColor="secondary" [icon]="faMagnifyingGlass">
                </stdout-icon-button>
                <div class="">
                  <stdout-text-input class="search-bar" placeholder="vai a indice" [(ngModel)]="index"
                    (keyup)="applyIndex()"></stdout-text-input>
                </div>
              </div>
            </div>
            <div class="w-full h-full flex items-center justify-center mt-4">
              <div class="md:max-w-[200px] w-full md:mt-0">
                <div class=" w-full  cursor-pointer">
      
                    <stdout-button class="btn-bianco" (click)="resetOrdine()" size="large" color="black">
                      <h2>{{ "resetta" | transloco }}</h2>
                    </stdout-button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="flex w-full items-center testo relative justify-end" (click)="$event.stopPropagation()">
          <p *ngIf="showFilter==false">
            <b>{{ "filtri_visualizzazione" | transloco }}</b>
          </p>
          <p *ngIf="showFilter==true">
            <b>{{ "chiudi" | transloco }}</b>
          </p>
          <stdout-icon-button
            class="lente ml-[15px]  cursor-pointer"
            (click)="showFil($event)"
            [icon]="showFilter ? faXmark: faEye"
          ></stdout-icon-button>
            <!-- dropdown -->
        <div
        class="absolute z-50   min-h-700 top-full mt-5 w-[300px]"
        *ngIf="showFilter"
        (click)="$event.stopPropagation()"
      >
        <app-filtri-details
          [Anno]="Anno"
          [combinedAnno]="combinedAnno"
          [combinedTitoli]="combinedTitoli"
          [listaRegioni]="listaRegioni"
          [listaDifficolta]="listaDifficolta"
          [listaMaterie]="listaMaterie"
          [listaArgomenti]="listaArgomenti"
          [listaTitoliStudio]="listaTitoliStudio"
          [listaProfili]="listaProfili"
          [listaEnti]="listaEnti"
          [combinedEnti]="combinedEnti"
          [combinedProfili]="combinedProfili"
          (onSubmit)="getBancaDati()"
          (reset)="reset()"
        >
        </app-filtri-details>
      </div>
        </div>
      
      </div>
    </div>

    <!-- parte bottoni -->
    <div
      class="margine flex items-center justify-between flex-col md:flex-row px-10"
    >
      <div class="w-full">
        <div class="flex w-full justify-between flex-col md:flex-row">
          <div
            class="md:max-w-[140px] lg:max-w-[231px]  cursor-pointer w-full"
          >
            <stdout-button
              class="btn-bianco"
              (click)="showLib($event)"
              size="large"
              color="black"
              ><h2>{{ "importa_daLib" | transloco }}</h2></stdout-button
            >
          </div>
          <div
            class="md:max-w-[105px] lg:max-w-[188px]  cursor-pointer mx-[15px] w-full"
          >
            <input
              type="file"
              #fileInput
              (change)="onFileSelected($event)"
              accept=".xlsx"
              style="display: none"
            />
            <stdout-button
              class="btn-bianco"
              (click)="onButtonClick($event)"
              size="large"
              color="black"
              ><h2>{{ "importa_daEst" | transloco }}</h2></stdout-button
            >
          </div>
          <div
            class="md:max-w-[90px] lg:max-w-[119px] mr-[15px] max-w-max- w-full mt-5 md:mt-0  cursor-pointer"
          >
            <stdout-button
              class="btn-bianco"
              size="large"
              color="black"
              [disabled]="pippo() === 0"
              [class.opacity-50]="pippo() === 0"
              (click)="exportDetails(domandeIds)"
              ><h2>{{ "esporta" | transloco }}</h2></stdout-button
            >
          </div>
          <div class="md:max-w-[200px] w-full mt-5 md:mt-0">
            <div class="md:max-w-[45px]  cursor-pointer w-full">
              <!-- <stdout-button class="btn-bianco  " size="large" color="black" (click)="add($event)" ><h2>{{"aggiungi riga"|transloco}}</h2></stdout-button> -->

              <stdout-icon-button
                class="lente-bianca"
                (click)="add($event)"
                [icon]="faCirclePlus"
              ></stdout-icon-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex mt-5 md:mt-0 w-full justify-end items-center flex-col md:flex-row"
      >
        <div class="w-full flex items-center justify-between">
          <div class="w-full flex justify-end mx-[15px]">
            <div class="w-full flex justify-end">
              <p class="whitespace-nowrap">
                {{ pippo() }} {{ "quiz_selezionati" | transloco }}
              </p>
            </div>
          </div>
          <div
            class="mt-5 md:mt-0 flex items-center justify-end"
            [ngClass]="
              showSlide === false
                ? 'md:min-w-[80px] lg:min-w-[150px]'
                : 'w-full'
            "
          >
            <stdout-button
              [ngClass]="
                showSlide === false
                  ? 'btn-bianco'
                  : 'btn-blu  cursor-pointer'
              "
              class="md:min-w-[80px] lg:min-w-[150px]"
              [disabled]="pippo() === 0"
              [class.opacity-50]="pippo() === 0"
              (click)="showSlide = !showSlide"
              size="large"
              color="black"
              ><h2>{{ "modifica" | transloco }}</h2></stdout-button
            >
            <div *ngIf="showSlide" class="">
              <div
                class="flex justify-between items-center flex-col md:flex-row"
              >
                <div class="mx-[10px]  cursor-pointer">
                  <stdout-icon-button
                    class="bottoni-icon"
                    (click)="duplicateDomanda()"
                    color="rgba(11,14,66,1)"
                    [icon]="faCopy"
                  ></stdout-icon-button>
                </div>
                <div class=" cursor-pointer">
                  <stdout-icon-button
                    class="bottoni-icon"
                    (click)="showDelete($event)"
                    color="rgba(11,14,66,1)"
                    [icon]="faTrash"
                  ></stdout-icon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full px-10 py-5 control " *ngIf="bancaDati.length>0">
      <app-tabella-def [overstack]="true"  [titles]="titles" [showCheckbox]="true" 
      [data]="bancaDati" 
      [checkboxHeaderClass]="checkHeader"
      [checkboxBody]="checkBody"
      [tabText]="tabText"
      [headerText]="headerText"
      tableContainerClass="show-bar"
      (selectionChangeAll)="updateSelectionAll()"
      (selectionChange)="updateSelection()"
      (clickedItem)="
        showModifyDetails(
          $event,
          $event.id,
          $event.indice,
          $event.domanda,
          $event.rispostaA,
          $event.rispostaB,
          $event.rispostaC,
          $event.rispostaD,
          $event.rispostaE,
          $event.rispostaF,
          $event.correttaTag,
          $event.numerazioneMida,
          $event.titoloStudio,
          $event.anno,
          $event.ente,
          $event.profilo,
          $event.materia,
          $event.argomento,
          $event.difficolta,
          $event.immagine,
          $event.immagineSpiegazioneRisposta,
          $event.linkPreparazione
        )
      
      ">
   </app-tabella-def>
  </div>
  </div>
</div>

<!-- modale modifica -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="modifica" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale   (click)="$event.stopPropagation()"
    title="Conferma il salvataggio"
    content="Sei sicuro di volere salvare le modifiche effettuate?"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="showModify($event)"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px] cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="pass()"
          size="large"
          color="white"
          ><h2>{{ "salva" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div> 
  </div>
</div>

<!-- modale aggiungi riga -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"   *ngIf="showInput" [class.opacity-20]="loader === true" >
  <div class=" m-auto  ">
    <div class="my-10 mx-auto     ">

  <app-modal-form class=""   (click)="$event.stopPropagation()"
    [titolo]="'Aggiungi Riga'"
    [combinedAnno]="combinedAnno"
    [nomiEnti]="nomiEnti"
    [nomiProfili]="nomiProfili"
    [combinedEnti]="combinedEnti"
    [combinedTitoli]="combinedTitoli"
    [combinedProfili]="combinedProfili"
    [listaRegioni]="listaRegioni"
    [combinedMaterie]="combinedMaterie"
    [combinedArgomenti]="combinedArgomenti"
    [Anno]="Anno"
    [listaRegioni]="listaRegioni"
    [listaDifficolta]="listaDifficolta"
    [listaMaterie]="listaMaterie"
    [listaArgomenti]="listaArgomenti"
    [listaTitoliStudio]="listaTitoliStudio"
    [listaProfili]="listaProfili"
    [listaEnti]="listaEnti"
    (onSubmit)="aggiungiDomanda()"
  ></app-modal-form>
</div>
  </div>
</div>

<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="showModal" [class.opacity-20]="loader === true" >
  <div class="max-w-3xl   m-auto w-full  ">
    <div class="my-10 mx-auto flex items-center justify-center  shadow-2xl  max-h-[80vh] media rounded-xl ">
<div class=" flex items-center justify-center">
  <app-image-cropper   (click)="$event.stopPropagation()"
    class="z-10 bg-white rounded-xl shadow-2xl"
    [width]="50"
    [height]="50"
  ></app-image-cropper>
</div>
</div>
</div>
</div>

<!-- modale importa libreria -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="importLibreria" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale 
  (click)="$event.stopPropagation()"
    title="Importa dalla libreria"
    content="Scegli il file da importare per aggiungere quiz alla banca dati"
    [width]="386"
  >
    <div class="w-full justify-center items-center flex mt-[30px]">
      <div class="w-[346px]">
        <stdout-select
          class="fix-select"
          [placeholder]="'seleziona il file da importare'"
          id="select1"
          type="text"
          [clearable]="false"
          (onSelectedItem)="onSelectedLibreria($event.event)"
          [data]="librerie"
          [value]="selectedLibreria?.nome"
          bindLabel="nome"
          bindValue="nome"
          #selectElement
        >
        </stdout-select>
      </div>
    </div>
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="showLib($event)"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="createFromLibSingola()"
          size="large"
          color="white"
          ><h2>{{ "importa" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>



<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"   *ngIf="elimina" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale   (click)="$event.stopPropagation()"
    title="Conferma eliminazione Quiz"
    content="Sei sicuro di volere eliminare definitivamente gli elementi selezionati?"
    [width]="504"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="showDelete($event)"
          size="large"
          color="white"
          ><h2>{{ "annulla" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px] cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="deleteDomanda()"
          size="large"
          color="white"
          ><h2>{{ "elimina_selezionati" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>

<!-- modale modifica riga -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"     *ngIf="showDomande" [class.opacity-20]="loader === true"   [class.opacity-50]="modifica2 === true" >
  <div class=" m-auto  ">
    <div class="my-10 mx-auto     ">

  <app-modal-form   (click)="$event.stopPropagation()"
    [titolo]="'Modifica Riga'"
    [combinedAnno]="combinedAnno"
    [nomiEnti]="nomiEnti"
    [nomiProfili]="nomiProfili"
    [combinedEnti]="combinedEnti"
    [combinedTitoli]="combinedTitoli"
    [combinedProfili]="combinedProfili"
    [combinedMaterie]="combinedMaterie"
    [combinedArgomenti]="combinedArgomenti"
    [listaRegioni]="listaRegioni"
    [Anno]="Anno"
    [listaRegioni]="listaRegioni"
    [listaDifficolta]="listaDifficolta"
    [listaMaterie]="listaMaterie"
    [listaArgomenti]="listaArgomenti"
    [listaTitoliStudio]="listaTitoliStudio"
    [listaProfili]="listaProfili"
    [listaEnti]="listaEnti"
    [modifyIndex]="modifyIndex"
    (onSubmit)="showModify2($event)"
  ></app-modal-form>
</div>
  </div>
</div>

<!-- modale modifica 2 -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"     *ngIf="modifica2" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale   (click)="$event.stopPropagation()"
    title="Conferma il salvataggio"
    content="Sei sicuro di volere salvare le modifiche effettuate?"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="showModify2($event)"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="modifyDomanda()"
          size="large"
          color="white"
          ><h2>{{ "salva" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>

<!-- modale privata -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="showPriv" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale  (click)="$event.stopPropagation()"
    title="Vuoi rendere la Banca Dati privata?"
    content="Sei sicuro di volere salvare le modifiche effettuate?"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="chiudiModal()"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="setPrivate()"
          size="large"
          color="white"
          ><h2>{{ "salva" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>

<!-- modale pubblica -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"   *ngIf="showPub" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale   (click)="$event.stopPropagation()"
    title="Vuoi rendere la Banca Dati pubblica?"
    content="Sei sicuro di volere salvare le modifiche effettuate?"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="chiudiModal()"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="setPublic()"
          size="large"
          color="white"
          ><h2>{{ "salva" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
</div>  
</div>


<app-loader></app-loader>