import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private transloco: TranslocoService) {}
  userlogin = false;
  title = 'Gestionale Base';
  baseLanguage: any = 'it';
  ngOnInit() {
    if (
      localStorage.getItem('language') != null ||
      localStorage.getItem('language') != undefined
    ) {
      this.baseLanguage = localStorage.getItem('language');
    } else {
      localStorage.setItem('language', 'it');
    }
    this.transloco.setActiveLang(this.baseLanguage);
  }
}
