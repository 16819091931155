<div class="flex flex-col w-full  h-full ">
  <div class="header flex w-full h-full items-center ">
    <!-- sezione check -->
    <div class="">
      <div class="bg-[#0B0E42] px-[14px] py-[16px] head-check flex justify-center items-center sopratab">
        <stdout-checkbox [checked]="check" (change)="onCheckboxChangeAll($event)"></stdout-checkbox>
      </div>
      <div class="w-full mt-[10px] bg-transparent"></div>
      <div class="flex flex-col ">
        <div class="bg-white check-body ">
          <div (click)="funzioneBruh()" class="py-[10px] px-[14px] flex justify-center items-center check-header-fix check-corpo " [id]="'ciccio-check-'+index" [style.height.px]="maxHeight" *ngFor="let item of data,let index = index">
            <stdout-checkbox [checked]="item.selected" (change)="onCheckboxChange(item, $event)"  [id]="item.id"></stdout-checkbox>
          </div>
        </div>
      </div>
    </div>
    <!-- sezione dati -->
    <div class="mx-[5px] h-full bg-transparent"></div>
    <div  #parent class="w-full " [ngClass]="{'scrollable-table overflow-x-auto ': isScrollable}"
    (mousedown)="isScrollable ? startDragging($event, parent) : null" 
    (mouseup)="isScrollable ? stopDragging() : null" 
    (mouseleave)="isScrollable ? stopDragging() : null"
    (mousemove)="isScrollable ? moveEvent($event, parent) : null">
    <div [ngStyle]="larghezza" class="bg-[#0B0E42] h-[58px]  head-dati flex items-center justify-around "
    [ngClass]="{'scrollable-table overflow-x-auto ': isScrollable}"
    >
      <div *ngFor="let colonna of colonne; let index = index" class="colonna-box flex flex-col w-full h-full  ">
        <div class="items-center justify-center flex  w-full h-full " [id]="'header-' + index" [style.width.px]="maxWidht">
         
          <p >{{colonneDisplayNames[colonna]}}</p>
      
        </div>
      </div>
    </div>
      <div class="w-full h-[10px] bg-transparent"></div>
      
      <div  [ngStyle]="larghezza" class="corpo flex " *ngIf="data.length >0">
        <div  *ngFor="let colonna of colonne;  let id = index,let isFirst=first" class="colonna-box flex flex-col w-full bordo-col"  >
          <div  #myDiv class="flex justify-center items-center overflow-hidden p-4 bordo" 
          [id]="'bruh-'+index"
          *ngFor="let item of data; let index = index; let isLast = last, let isFirst = first" 
          [ngClass]="{'no-bordo': isLast}" [ngClass]="{'no-bordo-first': isFirst}">
            <div  class="" [id]="'item-' + item.id + '-' + colonna" (dblclick)="clicca(item)">
              <div class=" flex justify-center items-center" [style.width.px]="maxWidht"  [style.height.px]="maxHeight">
              <p class=" ">{{item[colonna]}}</p>
            </div>
            </div>
           
          </div>
        </div>
      </div>
    
    </div>
  
  </div>
</div>