import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModaleImportService {
  private selectedFile = new BehaviorSubject<any>(null);

  private formSource = new BehaviorSubject<any>(null);
  private erroreImport = new BehaviorSubject<string>('');
  private formError = new BehaviorSubject<boolean>(false);
  private existImport = new BehaviorSubject<boolean>(false);
  private showPopImport = new BehaviorSubject<boolean>(false);
  private import = new BehaviorSubject<boolean>(false);

  currentForm = this.formSource.asObservable();

  constructor() {}

  setImport(value: boolean) {
    this.import.next(value);
  }

  getImport() {
    return this.import.asObservable();
  }

  setShowPopImport(value: boolean) {
    this.showPopImport.next(value);
  }

  getShowPopImport() {
    return this.showPopImport.asObservable();
  }

  setExistImport(value: boolean) {
    this.existImport.next(value);
  }

  getExistImport() {
    return this.existImport.asObservable();
  }

  setFormError(value: boolean) {
    this.formError.next(value);
  }

  getFormError() {
    return this.formError.asObservable();
  }

  setErroreImport(value: any) {
    this.erroreImport.next(value);
  }

  getErroreImport() {
    return this.erroreImport.asObservable();
  }

  setSelectedFile(file: any) {
    this.selectedFile.next(file);
  }

  getSelectedFile() {
    return this.selectedFile.asObservable();
  }

  changeForm(form: any) {
    this.formSource.next(form);
  }

  getForm() {
    return this.formSource.asObservable();
  }
}
