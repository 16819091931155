import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { ImageServiceService } from '@services/image-service.service';
import { MappingService } from '@services/mapping.service';
import { ModaleCreaService } from '@services/modale-crea.service';
import { Subscription } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'app-modale-crea',
  templateUrl: './modale-crea.component.html',
  styleUrls: ['./modale-crea.component.scss'],
})
export class ModaleCreaComponent implements OnInit {
  form: FormGroup;
  public imageUrl: any;
  public faUpload = faUpload;
  public faTrashCan = faTrashCan;
  private subscription: Subscription = new Subscription();

  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;
  @Input() combinedAnno: any;
  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  existPass: boolean = false;
  combinedLib: any;
  formError = false;
  librerie: any;
  @Input() Anno: any;
  @Input() id: any;
  @Input() selectedFile: any;

  constructor(
    private fb: FormBuilder,
    private banchedati: BanchedatiService,
    private mappingService: MappingService,
    private formService: ModaleCreaService,
    private imageService: ImageServiceService,
    private message: MessageService
  ) {
    this.form = this.fb.group({
      titolo: [, Validators.required],
      descrizione: [, Validators.required],
      ente: [, Validators.required],
      profilo: [, Validators.required],
      regione: [, Validators.required],
      anno: [, Validators.required],
      imageUrl: [, Validators.required],
    });
    this.form.get('imageUrl')?.valueChanges.subscribe((value) => {
      this.imageUrl = value;
    });
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  ngOnInit(): void {
    this.subscription.add(
      this.formService.getExistPass().subscribe((value) => {
        this.existPass = value;
      })
    );
    this.subscription.add(
      this.formService.getFormError().subscribe((value) => {
        this.formError = value;
      })
    );
    this.subscription.add(
      this.formService.getForm().subscribe((form) => {
        if (form) {
          this.form.patchValue(form);
        }
      })
    );
    // this.avvia();
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.form.get('imageUrl')?.setValue(event.target.result);
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
      })
    );

    this.form.get('profilo')?.disable();

    // Subscribe to the value changes of the 'ente' field
    this.form.get('ente')?.valueChanges.subscribe((value) => {
      if (value) {
        // If 'ente' has a value, enable the 'profilo' field
        this.form.get('profilo')?.enable();
      } else {
        // If 'ente' does not have a value, disable the 'profilo' field
        this.form.get('profilo')?.disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);

    this.formService.setError(false);
  }

  isFormEmpty() {
    for (let control in this.form.controls) {
      if (this.form.controls[control].value) {
        return false;
      }
    }
    return true;
  }

  exist() {
    const nome = this.form.get('titolo')?.value;
    const anno = this.form.get('anno')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    if (nome && nome.trim() !== '') {
      return this.banchedati.exist(nome, descrizione, anno).subscribe(
        (response: any) => {
          this.formService.setExistPass(true);
          this.createBancaQuiz();
        },
        (error: any) => {
          console.error('Errore durante la creazione della materia:', error);
          this.showMessage(error.error.message, 'error');
        }
      );
    }
    return null;
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  onProfiliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const profilo = target.value;

    this.combinedProfili = [...this.nomiProfili];
    if (profilo) {
      this.combinedProfili.push(profilo);
    }
  }

  submitForm() {
    this.formService.changeForm(this.form.value);
    this.exist();
  }

  show(event: Event) {
    event.preventDefault();
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage(event: Event) {
    event.preventDefault();
    this.form.get('imageUrl')?.setValue('');
    this.imageService.setCroppedImage(null);
  }

  onAnnoChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const anno = target.value;

    this.combinedAnno = this.Anno.map((anno: any) => anno.nome);

    this.combinedAnno.push(anno);
  }

  resetForm() {
    const emptyForm = {
      titolo: '',
      descrizione: '',
      ente: null,
      profilo: null,
      regione: null,
      anno: null,
      imageUrl: '',
    };

    this.formService.changeForm(emptyForm);
  }

  createBancaQuiz() {
    const titolo = this.form.get('titolo')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    const ente = this.form.get('ente')?.value;
    const profilo = this.form.get('profilo')?.value;
    const regione = this.form.get('regione')?.value;
    const anno = this.form.get('anno')?.value;
    LoaderComponent.isLoading = true;

    const formData = new FormData();
    for (let id of this.id) {
      formData.append('idDomande[]', id.toString());
    }
    formData.append('nome', titolo);
    if (descrizione?.trim() !== '') {
      formData.append('descrizione', descrizione);
    }
    if (ente) {
      formData.append('ente', ente);
    }
    if (profilo) {
      formData.append('profilo', profilo);
    }
    if (regione) {
      formData.append('idRegione', regione);
    }
    if (anno) {
      formData.append('anno', anno);
    }
    if (this.selectedFile) {
      formData.append('cover', this.selectedFile);
    }

    this.banchedati.createBancaDatiFromQuiz(formData).subscribe(
      (response) => {
        this.formService.setImport(false);

        LoaderComponent.isLoading = false;
        this.mappingService.avvia().subscribe();
        this.formService.setConfirm(true);
        this.resetForm();
      },
      (error) => {
        console.error('Errore durante la creazione della banca dati:', error);
        if (error.status === 400) {
          this.showMessage('Compilare i campi correttamente', 'error');
          this.formService.setFormError(true);
        }

        LoaderComponent.isLoading = false;
      }
    );
    console.error(
      'Assicurati di compilare tutti i campi e selezionare un file.'
    );
  }
}
