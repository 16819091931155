
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modale',
  templateUrl: './modale.component.html',
  styleUrls: ['./modale.component.scss']
})
export class ModaleComponent implements OnInit {

  constructor() { }

  @Input() title!: string;

  
  @Input() content!: string;

  @Input() width!: number;

  ngOnInit(): void {
  }

}
