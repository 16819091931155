import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BanchedatiService } from '@services/banchedati.service';
import { MappingService } from '@services/mapping.service';
import { MessageService } from '@services/message.service';
import { ModaleUtentiService } from '@services/modale-utenti.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modale-utenti',
  templateUrl: './modale-utenti.component.html',
  styleUrls: ['./modale-utenti.component.scss'],
})
export class ModaleUtentiComponent implements OnInit {
  @Input() nome: string = '';
  @Input() cognome: string = '';
  userId: string[] = [];
  listaBancheDati: any[] = [];

  selectedBancaDati: any;

  abbonamenti: any[] = [];

  banchedati: any[] = [
    { id: 1, nome: 'prova' },
    { id: 2, nome: 'prov2' },
    { id: 3, nome: 'prova3' },
  ];

  form: FormGroup;
  showIdBancaDatiSection: boolean = false;
  showNome: boolean = false;
  private subscription: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private bancheDati: BanchedatiService,
    private modale: ModaleUtentiService,
    private mapping: MappingService,
    private message: MessageService
  ) {
    this.form = this.fb.group({
      idTipoAbbonamento: [, Validators.required],
      idBancaDati: [, Validators.required],
    });

    // Monitora i cambiamenti nel campo idTipoAbbonamento
    this.form.get('idTipoAbbonamento')?.valueChanges.subscribe((value) => {
      this.showIdBancaDatiSection = value === 3;
      if (!this.showIdBancaDatiSection) {
        this.form.get('idBancaDati')?.reset();
      }
    });
    this.form.get('idBancaDati')?.valueChanges.subscribe((value) => {
      // Mostra la parte con showNome solo se il valore selezionato è diverso da null o undefined
      this.showNome = value !== null && value !== undefined;
      // Seleziona la banca dati corrispondente
      this.selectedBancaDati = this.listaBancheDati.find(
        (bancaDati) => bancaDati.id === value
      );
    });
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.modale.getUserId().subscribe((userId: string[]) => {
        console.log(userId);
        this.userId = userId;
      })
    );
    this.mapping.banche().subscribe();
    this.mapping.abbonamenti().subscribe();
    this.subscription.add(
      this.mapping.getListaBancheDati().subscribe((value: any[]) => {
        this.listaBancheDati = value;
        console.log(this.listaBancheDati);
      })
    );
    this.subscription.add(
      this.mapping.getListaAbbonamenti().subscribe((value: any[]) => {
        this.abbonamenti = value;
        console.log(this.abbonamenti);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.modale.setShowModal(false);
  }

  attivaAbbonamento() {
    const idTipoAbbonamento = Number(this.form.get('idTipoAbbonamento')?.value);
    const idBancadati = Number(this.form.get('idBancaDati')?.value);
    this.bancheDati
      .attivaAbbonamento(this.userId, idTipoAbbonamento, idBancadati)
      .subscribe(
        (response) => {
          console.log('Abbonamento attivato con successo:', response);
          this.showMessage('Abbonamento attivato', 'success');
          this.modale.setShowModal(false);
        },
        (error) => {
          console.error(
            "Errore durante l'attivazione dell'abbonamento:",
            error
          );
          this.showMessage(error.error.message, 'error');
          // Aggiungi qui la logica per gestire l'errore
        }
      );
  }

  onSubmit() {
    console.log('Form inviato con successo!', this.form.value);
    this.modale.setShowModal(false);
  }
}
