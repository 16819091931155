<div class="header" (window:resize)="onResize($event)">
  <div class="t-dark semibold title" *ngIf="!phone && titleProject == false">
    {{ endpoint | transloco }}
  </div>
  <div class="t-dark semibold title" *ngIf="!phone && titleProject == true">
    {{ endpoint }}
  </div>
  <stdout-breadcrumbs
    class="fix-head"
    *ngIf="!phone"
    [breadcrumbs]="breadcrumbs"
    color="black"
  ></stdout-breadcrumbs>
  <div *ngIf="phone" class="flex justify-between items-baseline">
    <stdout-icon-button
      background="primary"
      borderColor="primary"
      color="white"
      [icon]="faBars"
      (click)="showSidenav = !showSidenav"
    ></stdout-icon-button>
    <app-sidebar
      [isPhone]="true"
      *ngIf="showSidenav"
      [expanded]="false"
    ></app-sidebar>
    <div class="t-dark semibold title">Gestionale</div>
    <stdout-icon-button
      background="primary"
      borderColor="primary"
      color="white"
      [icon]="faGlass"
    ></stdout-icon-button>
  </div>
</div>
