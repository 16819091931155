import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';

import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  BreadcrumbModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FormModule,
  IconButtonModule,
  ModalModule,
  PaginationModule,
  SelectModule,
  SidenavModule,
  SliderModule,
  StatusbarModule,
  StepperModule,
  TableModule,
  TabsModule,
  TagModule,
} from '@stdoutsrl/ui-kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { BottomBarComponent } from '../layout/bottom-bar/bottom-bar.component';
import { HeaderComponent } from '../layout/header/header.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { UserComponent } from './user/user.component';
import { ExampleUiKitComponent } from './example-ui-kit/example-ui-kit.component';

import { BancheDatiComponent } from './banche-dati/banche-dati.component';
import { ConsigliatiComponent } from './consigliati/consigliati.component';
import { UtentiComponent } from './utenti/utenti.component';
import { QuizComponent } from './quiz/quiz.component';
import { NotificheComponent } from './notifiche/notifiche.component';
import { FunzionalitaComponent } from './funzionalita/funzionalita.component';
import { AccountPersonaleComponent } from './account-personale/account-personale.component';
import { CollaboratoriComponent } from './collaboratori/collaboratori.component';
import { AtterraggioComponent } from './atterraggio/atterraggio.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from '../components/image-cropper/image-cropper.component';
import { BancadatiComponent } from './bancadati/bancadati.component';
import { ModaleComponent } from '../components/modale/modale.component';
import { TabellaComponent } from '../components/tabella/tabella.component';
import { ToastComponent } from './toast/toast.component';
import { SwitchComponent } from '../components/switch/switch.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { Tabella2Component } from '../components/tabella2/tabella2.component';
import { IconButtonComponent } from '../components/icon-button/icon-button.component';
import { Modale2Component } from '../components/modale2/modale2.component';
import { CardFormComponent } from '../components/card-form/card-form.component';
import { ImportModalComponent } from '../components/import-modal/import-modal.component';
import { FiltriBancheDatiComponent } from '../components/filtri-banche-dati/filtri-banche-dati.component';
import { CardDetailsComponent } from '../components/card-details/card-details.component';
import { ModalFormComponent } from '../components/modal-form/modal-form.component';
import { FiltriDetailsComponent } from '../components/filtri-details/filtri-details.component';
import { ModaleCreaComponent } from '../components/modale-crea/modale-crea.component';
import { EntiComponent } from './enti/enti.component';
import { TabellaDefComponent } from '../components/tabella-def/tabella-def.component';
import { ProfiliComponent } from './profili/profili.component';
import { AnniComponent } from './anni/anni.component';
import { ModaleUtentiComponent } from '../components/modale-utenti/modale-utenti.component';
import { MessageComponent } from '../components/message/message.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', redirectTo: '/banche_dati', pathMatch: 'full' },

      { path: 'user', component: UserComponent },

      { path: 'esempi', component: ExampleUiKitComponent },

      { path: 'banche_dati', component: BancheDatiComponent },
      { path: 'consigliati', component: ConsigliatiComponent },
      { path: 'utenti', component: UtentiComponent },
      { path: 'quiz', component: QuizComponent },
      { path: 'notifiche', component: NotificheComponent },
      { path: 'funzionalita', component: FunzionalitaComponent },
      { path: 'account-personale', component: AccountPersonaleComponent },
      { path: 'collaboratori', component: CollaboratoriComponent },
      { path: 'atterraggio/:token', component: AtterraggioComponent },
      { path: 'bancadati', component: BancadatiComponent },
      { path: 'enti', component: EntiComponent },
      { path: 'profili', component: ProfiliComponent },
      { path: 'anni', component: AnniComponent },
    ],
  },
];

@NgModule({
  declarations: [
    SidebarComponent,
    CardFormComponent,
    BottomBarComponent,
    HeaderComponent,
    UserComponent,
    ExampleUiKitComponent,
    Modale2Component,
    ImportModalComponent,
    QuizComponent,
    NotificheComponent,
    FunzionalitaComponent,
    Tabella2Component,
    IconButtonComponent,
    BancheDatiComponent,
    ConsigliatiComponent,
    UtentiComponent,
    AccountPersonaleComponent,
    CollaboratoriComponent,
    AtterraggioComponent,
    ImageCropperComponent,
    BancadatiComponent,
    ModaleComponent,
    TabellaComponent,
    ToastComponent,
    SwitchComponent,
    LoaderComponent,
    FiltriBancheDatiComponent,
    CardDetailsComponent,
    ModalFormComponent,
    FiltriDetailsComponent,
    ModaleCreaComponent,
    EntiComponent,
    TabellaDefComponent,
    ProfiliComponent,
    AnniComponent,
    ModaleUtentiComponent,
  ],
  imports: [
    TranslocoRootModule,
    CommonModule,
    FormsModule,
    FormModule,
    SidenavModule,
    TagModule,
    StepperModule,
    SliderModule,
    CardModule,
    IconButtonModule,
    ModalModule,
    TableModule,
    BreadcrumbModule,
    DropdownModule,
    ReactiveFormsModule,
    TabsModule,
    PaginationModule,
    SelectModule,
    StatusbarModule,
    ButtonModule,
    FontAwesomeModule,
    RouterModule.forChild(routes),
    ImageCropperModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    RouterModule,
    HeaderComponent,
    SidebarComponent,
    BottomBarComponent,
  ],
})
export class PagesModule {}
