import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTrashCan, faUpload } from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { FormService } from '@services/form.service';
import { ImageServiceService } from '@services/image-service.service';
import { MappingService } from '@services/mapping.service';
import { MessageService } from '@services/message.service';
import { ModaleImportService } from '@services/modale-import.service';
import { Subscription } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  import: boolean = false;
  importDaLibreria: boolean = false;
  importNormale: boolean = true;
  importLibreria: boolean = false;
  form: FormGroup;
  public imageUrl: any;
  public faUpload = faUpload;
  public faTrashCan = faTrashCan;
  private subscription: Subscription = new Subscription();
  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;
  @Input() librerie: any;
  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  @Input() combinedAnno: any;
  @Input() Anno: any;
  @Input() selectedFileImg: any;
  @Input() selectedFile: any;

  public fileUrl: any | null = null;
  titoloModal: string = '';
  formError = false;
  existImport = false;

  constructor(
    private fb: FormBuilder,
    private banchedati: BanchedatiService,
    private mappingService: MappingService,
    private imageService: ImageServiceService,
    private importService: ModaleImportService,
    private formService: FormService,
    private message: MessageService
  ) {
    this.form = this.fb.group({
      libreria: [, Validators.required],
      titolo: ['', Validators.required],
      descrizione: [, Validators.required],
      ente: [, Validators.required],
      profilo: [, Validators.required],
      regione: [, Validators.required],
      anno: [, Validators.required],
      imageUrl: [, Validators.required],
    });
    this.form.get('imageUrl')?.valueChanges.subscribe((value) => {
      this.imageUrl = value;
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.importService.getExistImport().subscribe((value) => {
        if (value) {
          this.existImport = value;
        }
      })
    );
    this.subscription.add(
      this.importService.getFormError().subscribe((value) => {
        this.formError = value;
      })
    );
    this.subscription.add(
      this.importService.getForm().subscribe((form) => {
        if (form) {
          this.form.patchValue(form);
        }
      })
    );

    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.form.get('imageUrl')?.setValue(event.target.result);
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
      })
    );
  }

  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }

  exist() {
    const nome = this.form.get('titolo')?.value;
    const anno = this.form.get('anno')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    if (nome && nome.trim() !== '') {
      return this.banchedati.exist(nome, descrizione, anno).subscribe(
        (response: any) => {
          this.importService.setExistImport(true);
          this.importNormale
            ? this.onSubmitImport()
            : this.createFromLibImport();
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          this.importService.setExistImport(false);
        }
      );
    }

    return this.importService.getErroreImport();
  }
  onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.fileInput) {
      const inputElement: HTMLInputElement = this.fileInput.nativeElement;
      inputElement.click();
    }
    this.import = true;
    this.importDaLibreria = false;
    this.importNormale = true;
  }

  showLib() {
    this.importLibreria = !this.importLibreria;
    this.importDaLibreria = true;
    this.importNormale = false;
  }

  onProfiliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const profilo = target.value;

    this.combinedProfili = [...this.nomiProfili];
    if (profilo) {
      this.combinedProfili.push(profilo);
    }
  }
  onAnnoChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const anno = target.value;

    this.combinedAnno = this.Anno.map((anno: any) => anno.nome);

    this.combinedAnno.push(anno);
  }
  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  show(event: Event) {
    event.preventDefault();
    this.imageService.setMostra(true);
    event.stopPropagation();
  }

  removeImage(event: Event) {
    event.preventDefault();
    this.form.get('imageUrl')?.setValue('');
    this.imageService.setCroppedImage(null);
  }

  submitForm() {
    this.importService.changeForm(this.form.value);
    this.importService.setSelectedFile(this.selectedFile);
    this.exist();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.imageService.setCroppedImage(null);

    this.importService.setFormError(false);
  }

  createFromLib() {
    this.importService.changeForm(this.form.value);
    this.exist();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileUrl = reader.result as string;
      };
      reader.readAsDataURL(input.files[0]);
    }

    this.titoloModal = this.selectedFile.name;
    this.form.get('titolo')?.setValue(this.titoloModal);
  }

  resetFormImport() {
    const emptyForm = {
      libreria: null,
      titolo: '',
      descrizione: '',
      ente: null,
      profilo: null,
      regione: null,
      anno: null,
      imageUrl: '',
    };

    this.importService.changeForm(emptyForm);
  }

  setImage() {
    this.subscription.add(
      this.imageService.getCroppedImage().subscribe((image) => {
        if (image instanceof File) {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(image);
        } else {
          this.imageUrl = '';
        }
        this.selectedFileImg = image;
      })
    );
  }

  onSubmitImport() {
    this.setImage();
    const titolo = this.form.get('titolo')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    const ente = this.form.get('ente')?.value;
    const profilo = this.form.get('profilo')?.value;
    const regione = this.form.get('regione')?.value;
    const anno = this.form.get('anno')?.value;

    LoaderComponent.isLoading = true;

    const formData = new FormData();
    formData.append('nome', titolo);
    if (descrizione) {
      formData.append('descrizione', descrizione);
    }
    if (ente) {
      formData.append('ente', ente);
    }
    if (profilo) {
      formData.append('profilo', profilo);
    }
    if (regione) {
      formData.append('regione', regione);
    }
    if (anno) {
      formData.append('anno', anno);
    }
    formData.append('file', this.selectedFile);
    if (this.selectedFileImg) {
      formData.append('cover', this.selectedFileImg);
    }

    this.banchedati.ImportaCreateBancheDati(formData).subscribe(
      (response) => {
        this.resetFormImport();
        this.importService.setFormError(false);
        this.mappingService.avvia().subscribe();
        this.imageService.setCroppedImage(null);
        //this.paginateBancheDati();
        this.import = false;
        LoaderComponent.isLoading = false;
        this.importService.setImport(false);
        this.importService.setShowPopImport(true);
      },
      (error) => {
        console.error('Errore durante la creazione della banca dati:', error);

        this.showMessage(error.error.message, 'error');

        LoaderComponent.isLoading = false;
      }
    );
    console.error(
      'Assicurati di compilare tutti i campi e selezionare un file.'
    );
  }

  createFromLibImport() {
    const titolo = this.form.get('titolo')?.value;
    const descrizione = this.form.get('descrizione')?.value;
    const ente = this.form.get('ente')?.value;
    const profilo = this.form.get('profilo')?.value;
    const regione = this.form.get('regione')?.value;
    const anno = this.form.get('anno')?.value;
    const libreria = this.form.get('libreria')?.value;
    LoaderComponent.isLoading = true;
    this.setImage();
    const formData = new FormData();
    formData.append('nome', titolo);
    if (descrizione) {
      formData.append('descrizione', descrizione);
    }
    if (ente) {
      formData.append('ente', ente);
    }
    if (profilo) {
      formData.append('profilo', profilo);
    }
    if (regione) {
      formData.append('regione', regione);
    }
    if (anno) {
      formData.append('anno', anno);
    }
    formData.append('libId', libreria);
    if (this.selectedFileImg) {
      formData.append('cover', this.selectedFileImg);
    }
    if (libreria && titolo !== '') {
      this.banchedati.createBancaDatiFromLib(formData).subscribe(
        (response) => {
          this.resetFormImport();
          this.mappingService.avvia().subscribe();
          this.imageService.setCroppedImage(null);
          // this.paginateBancheDati();
          this.importService.setImport(false);
          LoaderComponent.isLoading = false;
          this.importService.setShowPopImport(true);
        },
        (error) => {
          console.error('Errore durante la creazione del bando:', error);
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
        }
      );
    } else {
      this.showMessage('Seleziona una libreria', 'error');
      this.importService.setFormError(true);

      LoaderComponent.isLoading = false;
    }
  }
}
