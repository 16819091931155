import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modale2',
  templateUrl: './modale2.component.html',
  styleUrls: ['./modale2.component.scss'],
})
export class Modale2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
