<!-- image cropper -->
<div *ngIf="show">
  <div class="w-full flex flex-col justify-center items-center">
    <div class="w-full">
      <stdout-card>
        <input
          type="file"
          #fileInput
          (change)="fileChangeEvent($event)"
          accept="image/*"
          style="display: none"
        />
        <div class="w-full h-full flex justify-center items-center">
          <div class="w-full h-full p-[25px]">
            <div class="titolo mb-10">
              <b
                ><h1
                  class="text-[#0B0E42] text-center font-montserrat text-[18px] leading-normal"
                >
                  Immagine consigliati
                </h1></b
              >
            </div>
            <div
              class="w-full h-full rounded-[12px] flex flex-col items-center justify-center"
            >
              <div class="flex justify-start w-full">
                <input
                  type="file"
                  #fileInput2
                  (change)="fileChangeEventMod($event)"
                  style="display: none"
                />
                <label
                  class="ml-[5px]  cursor-pointer"
                  (click)="onButtonClickModify()"
                >
                  <p
                    class="text-right text-[#0B0E42] underline font-medium text-base font-montserrat"
                  >
                    cambia immagine
                  </p>
                </label>
              </div>
              <div class="w-full h-full rounded-xl flex justify-center">
                <div>
                  <image-cropper
                    [imageFile]="imageFile"
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="width / height"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
                    [onlyScaleDown]="true"
                    [resizeToWidth]="width"
                    [resizeToHeight]="height"
                    [(transform)]="transform"
                    [allowMoveImage]="true"
                    [autoCrop]="true"
                    [alignImage]="'center'"
                  ></image-cropper>
                </div>
              </div>
            </div>
            <div class="flex justify-between items-center mt-5">
              <div class="flex mr-10">
                <div class="mr-5">
                  <stdout-icon-button
                    class="lente"
                    [icon]="faRotateLeft"
                    (click)="rotateLeft()"
                  ></stdout-icon-button>
                </div>
                <div>
                  <stdout-icon-button
                    class="lente"
                    [icon]="faRotateRight"
                    (click)="rotateRight()"
                  ></stdout-icon-button>
                </div>
              </div>
              <div class="flex mr-10">
                <div class="mr-5">
                  <stdout-icon-button
                    class="lente"
                    [icon]="faRulerHorizontal"
                    (click)="flipHorizontal()"
                  ></stdout-icon-button>
                </div>
                <div>
                  <stdout-icon-button
                    class="lente"
                    [icon]="faRulerVertical"
                    (click)="flipVertical()"
                  ></stdout-icon-button>
                </div>
              </div>
              <div class="flex mr-10">
                <div class="mr-5">
                  <stdout-icon-button
                    class="lente"
                    [icon]="faMagnifyingGlassPlus"
                    (click)="scaleUp()"
                  ></stdout-icon-button>
                </div>
                <div>
                  <stdout-icon-button
                    class="lente"
                    [icon]="faMagnifyingGlassMinus"
                    (click)="scaleDown()"
                  ></stdout-icon-button>
                </div>
              </div>
              <div class="flex items-center">
                <div class="mr-5" (click)="resetTransform()">
                  <p
                    class="whitespace-nowrap text-[#0B0E42] text-center font-montserrat text-base font-medium leading-normal underline cursor-pointer"
                  >
                    Ripristina modifiche
                  </p>
                </div>
                <div class="w-[160px]">
                  <stdout-button
                    class="btn-blu"
                    color="white"
                    size="large"
                    (click)="conferma($event)"
                    ><h2>{{ "salva" | transloco }}</h2></stdout-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </stdout-card>
    </div>
  </div>
</div>
