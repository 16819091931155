import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor() {}
  static isLoading = false;
  LoaderComponent = LoaderComponent;
  location = window.location;
  ngOnInit(): void {}
}
