<div>
  <app-header></app-header>

  <div
  [class.opacity-50]="import == true || elimina || loader || showPop || showPopImport"
  [class.non-clickable]="import == true || elimina || loader || showPop || showPopImport"
  class="mb-[30vh]"
>
    <div class="crt margine mx-10">


      <div >
        <app-card-form
 [check]="check"
          [nomiEnti]="nomiEnti"
          [nomiProfili]="nomiProfili"
          [combinedEnti]="combinedEnti"
          [combinedAnno]="combinedAnno"
          [combinedProfili]="combinedProfili"
          [listaRegioni]="listaRegioni"
          [Anno]="Anno"
          [selectedFile]="selectedFile"
        ></app-card-form>
      </div>
    </div>

    <hr class="margine mx-10" />

    <div class="margine grid grid-cols-2 gap-4">
      <!-- Prima colonna -->
      <div class="pl-10">
        <div
          class="flex input-container justify-start items-center"
          *ngIf="!isPhone"
        >
          <stdout-icon-button
            class="lente items-center cursor-pointer"
            style="margin: 2px 10px 0px 0px"
            size="small"
            background="secondary"
            color="white"
            borderColor="secondary"
            [icon]="faMagnifyingGlass"
          >
          </stdout-icon-button>
          <div class="w-[300px]">
            <stdout-text-input
              class="search-bar"
              placeholder="Cerca banca dati..."
              [(ngModel)]="nomeCerca"
              (keyup)="applyFilter()"
            ></stdout-text-input>
          </div>
        </div>
      </div>

      <div class="pr-10">
        <div class="posi">
          <div class="mr-[15px]">
            <p>Filtri</p>
          </div>

          <stdout-icon-button
            class="lente  cursor-pointer"
            [icon]="showDropdown ? faXmark : faFilter"
            (click)="visibile($event)"
          ></stdout-icon-button>

          <div
            *ngIf="showDropdown"
            class="absolute top-full left-auto z-10 ml-auto"
            (click)="$event.stopPropagation()"
          >
            <app-filtri-banche-dati
              (onSubmit)="paginateBancheDati()"
              (reset)="reset()"
              [combinedEnti]="combinedEnti"
              [combinedProfili]="combinedProfili"
              [listaRegioni]="listaRegioni"
              [Anno]="combinedAnno"
              [nomiEnti]="nomiEnti"
              [nomiProfili]="nomiProfili"
              [listaEnti]="listaEnti"
              [listaProfili]="listaProfili"
            >
            </app-filtri-banche-dati>
          </div>
        </div>
      </div>
    </div>

    <div
      class="margine-fix flex-col md:flex-row flex items-baseline justify-between w-full px-10"
    >
      <!-- Prima colonna -->
      <div
        class="ml-0 w-[80%] md:w-[8.24%] h-full flex justify-center items-baseline"
      >
        <div class="boh w-full h-full flex items-center justify-center">
          <h2 class="whitespace-nowrap w-full">
            {{ "items.banche_dati" | transloco }}
          </h2>
        </div>
      </div>

      <!-- Seconda colonna -->
      <div class="mr-0 mt-10 w-full md:w-[91.765%]">
        <div
          class="place flex-col md:flex-row justify-start flex md:justify-end flex-wrap items-center content-center"
        >
          <div class="placeholder md:mr-[10px] md:mb-5 mb-[10px]">
            <label class="w-full md:w-[13.411%]"
              ><p>
                {{ pippo() }} {{ "banche_selezionati" | transloco }}
              </p></label
            >
          </div>

          <div class="btn2 md:ml-[10px] ml-0 mt-[10px] md:mt-0">
            <stdout-button
              [ngClass]="
                showSlide === false
                  ? 'btn-bianco'
                  : 'btn-blu  cursor-pointer'
              "
              class="w-[80%] md:w-[75px] lg:w-[150px] mr-[10px] mb-[10px] md:mb-0"
              size="large"
              color="black"
              [color]="showSlide ? 'white' : 'black'"
              (click)="vedi()"
              [disabled]="pippo() === 0"
              [class.opacity-50]="pippo() === 0"
              ><h2>{{ "modifica" | transloco }}</h2></stdout-button
            >

            <div *ngIf="showSlide" class="slide">
              <div class="flex lg:mr-[35px]">
                <div class="px-[5px]  cursor-pointer">
                  <stdout-icon-button
                    class="bottoni-icon-star"
                    (click)="setPerTe()"
                    color="rgba(11,14,66,1)"
                    [icon]="faStar"
                  ></stdout-icon-button>
                </div>
                <div class="px-[5px]  cursor-pointer">
                  <stdout-icon-button
                    class="bottoni-icon"
                    color="rgba(11,14,66,1)"
                    (click)="duplicateBancaDati()"
                    [icon]="faCopy"
                  ></stdout-icon-button>
                </div>
                <div class="px-[5px]  cursor-pointer">
                  <stdout-icon-button
                    class="bottoni-icon"
                    (click)="showDelete($event)"
                    color="rgba(11,14,66,1)"
                    [icon]="faTrash"
                  ></stdout-icon-button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-[80%] md:w-[75px] lg:w-[150px]  cursor-pointer"
            *ngIf="isAdmin"
          >
            <input
              type="file"
              #fileInput
              (change)="onFileSelected($event)"
              accept=".xlsx"
              style="display: none"
            />
            <stdout-button
              class="btn-bianco"
              (click)="importaBanca($event)"
              color="black"
              size="large"
              ><h2>{{ "importa" | transloco }}</h2></stdout-button
            >
          </div>
          <div
            class="md:ml-[10px] mt-[10px] md:mt-0 ml-0 w-[80%] md:w-[75px] lg:w-[150px]  cursor-pointer"
            *ngIf="isAdmin"
          >
            <stdout-button
              class="btn-bianco"
              (click)="exportBancaDati(idToExport!)"
              [disabled]="pippo() === 0"
              [class.opacity-50]="pippo() === 0"
              color="black"
              size="large"
              ><h2>{{ "esporta" | transloco }}</h2></stdout-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="mt-[10px] px-10 w-full">
      <app-tabella2
        *ngIf="listaBancheDati.length > 0"
        [data]="listaBancheDati"
        [dataView]="listaBancheDati"
        [columns]="columns"
        [showCheck]='true'
        (itemCliccato)="
            goToBanca(
              $event.id,
              $event.nome
            )
          "
        (selectionChangeAll)="updateSelectionAll()"
        (selectionChange)="updateSelection()"
        [isScrollable]="isScrollable"
        [changeId]="changeId"
        [perTe]="perTeId"
      >
      </app-tabella2>

      <div class="mt-10 flex justify-center" *ngIf="listaBancheDati.length > 0">
        <stdout-pagination
          [current]="page"
          [total]="totalPages"
          (goTo)="getPage($event)"
          (next)="goNext($event)"
          (previous)="goBack($event)"
        ></stdout-pagination>
      </div>
    </div>
  </div>
</div>
<!-- modale importa -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"   *ngIf="import" [class.opacity-20]="loader === true" >
  <div class="   m-auto w-full  ">
    <div class="my-10 mx-auto w-[77.352%]  shadow-2xl ">

  <app-import-modal   (click)="$event.stopPropagation()"

    [combinedEnti]="combinedEnti"
    [combinedProfili]="combinedProfili"
    [listaRegioni]="listaRegioni"
    [Anno]="Anno"
    [combinedAnno]="combinedAnno"
    [nomiEnti]="nomiEnti"
    [nomiProfili]="nomiProfili"
    [librerie]="librerie"

  ></app-import-modal>
</div>
  </div>
</div>


<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"  *ngIf="showModal" [class.opacity-20]="loader === true" >
  <div class="max-w-3xl   m-auto w-full  ">
    <div class="my-10 mx-auto flex items-center justify-center  shadow-2xl  max-h-[80vh] media rounded-xl ">
<div class=" flex items-center justify-center">
  <app-image-cropper   (click)="$event.stopPropagation()"
    class="z-10 bg-white rounded-xl shadow-2xl"
    [width]="50"
    [height]="50"
  ></app-image-cropper>
</div>
</div>
</div>
</div>

<!-- modale elimina -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"     *ngIf="elimina" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale   (click)="$event.stopPropagation()"
    title="Conferma eliminazione banche dati"
    content="Sei sicuro di volere eliminare definitivamente gli elementi selezionati?"
    [width]="504"
  >
    <div class="flex justify-center items-center mt-10">
      <div class="w-full mr-[5px]  cursor-pointer">
        <stdout-button
          class="btn-bianco"
          (click)="showDelete($event)"
          size="large"
          color="white"
          ><h2>{{ "annulla" | transloco }}</h2></stdout-button
        >
      </div>
      <div class="w-full ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="deleteBancheDati(bancadatiIds)"
          size="large"
          color="white"
          ><h2>{{ "elimina_selezionati" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>




<!-- toast Form -->



 
    <app-loader> </app-loader>




<!-- modale conferma -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"       *ngIf="showPop" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale (click)="$event.stopPropagation()"
    title="Nuova banca dati creata"
    content="La nuova banca dati {{titolo}} è stata creata correttamente"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
 
      <div class="w-[200px] ml-[5px]  cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="chiudiPop()"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
  </div>
</div>

<!-- modale conferma import  -->
<div class="overlay fixed flex items-center justify-center top-0 left-0 w-full h-full z-[2000] bg-black/40"   *ngIf="showPopImport" [class.opacity-20]="loader === true" >
  <div class="   m-auto  ">
    <div class="my-10 mx-auto  overflow-y-auto max-h-[80vh]">

  <app-modale 
  (click)="$event.stopPropagation()"
    title="Nuova banca dati creata"
    content="Banca Dati importata correttamente"
    [width]="386"
  >
    <div class="flex justify-center items-center mt-10">
 
      <div class="w-[200px] ml-[5px] cursor-pointer">
        <stdout-button
          class="btn-blu"
          (click)="chiudiPopImport()"
          size="large"
          color="white"
          ><h2>{{ "chiudi" | transloco }}</h2></stdout-button
        >
      </div>
    </div>
  </app-modale>
</div>
</div>
</div>