import { Component, OnInit } from '@angular/core';
import { constant } from '../../../assets/constant';
import {
  faChevronRight,
  faChevronLeft,
  faAnglesRight,
  faTrashCan,
  faCirclePlus,
  faPencil,
  faAnglesLeft,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-example-ui-kit',
  templateUrl: './example-ui-kit.component.html',
  styleUrls: ['./example-ui-kit.component.scss'],
})
export class ExampleUiKitComponent implements OnInit {
  public faChevronRight = faChevronRight;
  public faChevronLeft = faChevronLeft;
  public faAnglesRight = faAnglesRight;
  public faTrashCan = faTrashCan;
  public faCirclePlus = faCirclePlus;
  public faPencil = faPencil;
  columns = columns;
  public faAnglesLeft = faAnglesLeft;
  public faMagnifyingGlass = faMagnifyingGlass;
  totalPages: number = 27;
  page: number = 1;
  search: string = '';
  allTranslation: any = false;
  actions = ['Modifica', 'Elimina'];
  isPhone: boolean = false;
  arrayTest = constant.arrayTest;
  constructor() {}

  ngOnInit(): void {}

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
  }

  goBack(page: number) {
    page == 1 ? '' : (this.page = page - 1);
  }

  getPage(page: number) {
    this.page = page;
  }

  onActionClicked(event: any) {
    console.log(event);
  }

  message(alert: any) {
    console.log(alert);
  }

  applyFilter() {
    // this.getCustomers(this.page, this.search);
  }

  onResize(event: any) {
    if (event.target.innerWidth < 500) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
  }
}

const columns = [
  { key: 'firstname', value: 'Nome' },
  { key: 'lastname', value: 'Cognome' },
  { key: 'username', value: 'Username' },
  //Qua vanno inseriti valori che saranno presi dalla tabella mobile
];
