<div class="hamburger">
    <div class="flex items-center justify-around">
        <div class="route" (click)="expandedUser=!expandedUser" routerLink="anagrafiche" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faUser"></stdout-icon-button>
            <div class="line-bottom"></div>
            <div *ngIf="expandedUser" class="submenu">
                <div class="item" routerLink="anagrafiche/customers">
                    Clienti
                </div>
                <div class="item" routerLink="anagrafiche/providers">
                    Fornitori
                </div>
                <div class="square">

                </div>
            </div>
        </div>
        <div class="route" routerLink="prova" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faCopy"></stdout-icon-button>
            <div class="line-bottom"></div>
        </div>
        <div class="route" routerLink="products" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faFolder"></stdout-icon-button>
            <div class="line-bottom"></div>
        </div>
        <div class="route" routerLink="orders" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faBasketShopping">
            </stdout-icon-button>
            <div class="line-bottom"></div>
        </div>
        <div class="route" routerLink="prova" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faWallet"></stdout-icon-button>
            <div class="line-bottom"></div>
        </div>
        <div class="route" routerLink="prova" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faStore"></stdout-icon-button>
            <div class="line-bottom"></div>
        </div>
        <div class="route" (click)="expandedFatture=!expandedFatture" routerLink="invoices" routerLinkActive="isFocus">
            <stdout-icon-button background="secondary" borderColor="secondary" [icon]="faFileContract">
            </stdout-icon-button>
            <div class="line-bottom"></div>
            <div *ngIf="expandedFatture" class="submenu" style="right:3px">
                <div class="item" routerLink="invoices/sent">
                    Inviate
                </div>
                <div class="item" routerLink="invoices/receipts">
                    Ricevute
                </div>
                <div class="flex" style="justify-content: flex-end">
                    <div class="square" style="transform: rotate(45deg) translate(3px, 12px);">

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>