import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  faMagnifyingGlass,
  faPencil,
  faTrashCan,
  faFilter,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { constant } from '@assets/constant';
import { AuthService } from '@services/auth.service';
import User from '@models/user.model';
import { SidebarComponent } from 'src/app/layout/sidebar/sidebar.component';
import { BandoService } from '@services/bando.service';
import { response } from 'express';
import { ModaleUtentiService } from '@services/modale-utenti.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss'],
})
export class UtentiComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private transloco: TranslocoService,
    private bandoService: BandoService,
    private modale: ModaleUtentiService
  ) {}

  changeLanguage(lang: string) {
    this.translocoService.setActiveLang(lang);
  }

  public faMagnifyingGlass = faMagnifyingGlass;
  public faXmark = faXmark;
  isPhone: any;
  public faFilter = faFilter;
  public faPencil = faPencil;
  public faTrashCan = faTrashCan;
  tipoAbbonamentoNumerico: any;
  translocoService: any;
  showDropdown = false;
  utenti: any[] = [];
  userId: string[] = [];
  nome: string = '';
  cognome: string = '';
  abb = [
    { duration: 'Mensile', id: 1 },
    { duration: 'Annuale', id: 2 },
    { duration: 'Singolo Acquisto', id: 3 },
    { duration: 'Gratis', id: 4 },
  ];
  selectedYears: string[] = [];

  // selectValue: string[] = [];
  selectValue = 1;

  search: string = '';
  typeSubscription: number = 0;
  selectedItem: any;
  showTabella = true;
  showTabella2 = false;
  totalPages: number = 1;
  page: number = 1;
  selectedAbbonamento: string = 'Seleziona abbonamento';
  selectedDuration: string | undefined;
  place: string = 'Seleziona abbonamento';
  y: Array<number> = [];
  type: number[] = [1, 2, 3, 4];
  selectedSub: number[] = [];
  changeId: number[] = [];
  perTeId: number[] = [];
  isScrollable: boolean = false;
  showModale: boolean = false;
  private subscription: Subscription = new Subscription();
  columns: any[] = [
    { display: 'Nome', dataIndex: 'nome' },
    { display: 'Cognome', dataIndex: 'cognome' },
    { display: 'Email', dataIndex: 'email' },
    { display: 'Abbonamento', dataIndex: 'abbonamento' },
    { display: 'Data scadenza', dataIndex: 'dataFine' },
  ];

  // funzione per la chiusura del drop per qualsiasi click nel body
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
    if (this.showModale) {
      this.modale.setShowModal(false);
    }
  }

  chiudi() {
    if (this.showDropdown == true) {
      this.showDropdown = false;
    }
  }

  // funzione per prendere i dati della paginate dall'authservice
  usersInfo() {
    const abb = this.y.length > 0 ? this.y : this.type;
    this.auth.getAll(this.search, this.page - 1, abb).subscribe(
      (response: any) => {
        const data = response.data;
        this.totalPages = response.totalPages;
        this.utenti = data.map((user: any) => {
          const abbonamentoId =
            user.userTipoAbbonamento.length > 0
              ? user.userTipoAbbonamento[0].idTipoAbbonamento
              : null;
          const abbonamento = this.mapAbbonamento(abbonamentoId);
          const dataFine =
            user.userTipoAbbonamento.length > 0
              ? moment(user.userTipoAbbonamento[0].dataFine).format(
                  'DD/MM/YYYY'
                )
              : 'Non disponibile';
          return {
            id: user.id,
            nome: user.firstName,
            cognome: user.lastName,
            email: user.email,
            abbonamento,
            dataFine,
          };
        });
        if (this.utenti.length === 0) {
          this.goBack(this.page);
        }
        console.log(this.utenti);
      },
      (error: any) => {
        console.error(
          'Errore durante il recupero delle informazioni degli utenti',
          error
        );
      }
    );
  }

  mapAbbonamento(idTipoAbbonamento: number | null): string {
    switch (idTipoAbbonamento) {
      case 1:
        return 'Mensile';
      case 2:
        return 'Annuale';
      case 3:
        return 'Singolo acquisto';
      case 4:
        return 'Gratis';
      default:
        return 'Nessun abbonamento';
    }
  }

  applyFilter() {
    this.usersInfo();
  }

  log(item: any) {
    const id = item.id.toString();
    this.nome = item.nome;
    this.cognome = item.cognome;
    this.modale.setShowModal(true);
    this.modale.setUserId(id);
  }

  visibile(event: MouseEvent) {
    this.showDropdown = !this.showDropdown;

    event.stopPropagation();
  }

  // funzione per selezionare il tipo di abbonamento da filtrare
  selezionaVal(event: any) {
    const selectedId = event.id;

    if (selectedId !== undefined) {
      this.y.push(selectedId);
    }
  }

  getSubscriptionName(id: number): string {
    const subscription = this.abb.find((item) => item.id === id);
    return subscription ? subscription.duration : '';
  }

  rimuoviAbb(id: number) {
    const index = this.y.indexOf(id);
    if (index > -1) {
      this.y.splice(index, 1);
    }
    this.selectValue = 0;
    this.y = [...this.y];
  }

  resetta() {
    this.selectedSub = [];
    this.selectValue = 0;
    this.typeSubscription = 0;
    this.y = [];
    this.usersInfo();
  }

  goNext(page: number) {
    if (page < this.totalPages && this.page !== page + 1) {
      this.page = page + 1;
      this.usersInfo();
    }
  }

  goBack(page: number) {
    if (page > 1 && this.page !== page - 1) {
      this.page = page - 1;
      this.usersInfo();
    }
  }

  getPage(page: number) {
    if (this.page !== page) {
      this.page = page;
      this.usersInfo();
    }
  }
  ngOnInit() {
    this.subscription.add(
      this.modale.getShowModal().subscribe((value: boolean) => {
        this.showModale = value;
        if (value === false) {
          console.log('ciao');
          this.usersInfo();
        }
      })
    );

    this.getUserActive();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  attivi: any;
  getUserActive() {
    this.bandoService.getUserActive().subscribe(
      (response: any) => {
        this.attivi = response.nOfActiveUsers;
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
