    import { Component, Input, OnInit} from '@angular/core';
    import { Router } from '@angular/router';
    import { AuthService } from '@services/auth.service';
    import { MenuItem } from '@stdoutsrl/ui-kit';
    import {
      faArrowRightFromBracket,
      faCalendarDay,
      faCalendarDays,
      faCity,
      faDollar,
      faHashtag,
      faIdCardClip,
      faUser,
      faCircleUser,
      faPeopleGroup,
      faPhone,
      faScrewdriverWrench,
      faScroll,
      faBolt,
      faCableCar,
      faIdBadge,
      faIdCard,
      faClipboardCheck,
      faIdCardAlt,
      faTicket,
      faVrCardboard,
      faUserGroup,
      faCopy,
      faBell,
      faAward,
      faFolder,
      faBriefcase,
      faBagShopping
    } from '@fortawesome/free-solid-svg-icons';

    import { TranslocoService } from '@ngneat/transloco';
    import { UserComponent } from 'src/app/pages/user/user.component';
    import { BancheDatiComponent } from 'src/app/pages/banche-dati/banche-dati.component';
    import { PagesComponent } from 'src/app/pages/pages.component';
    // import { IonicModule } from '@ionic/angular';

    @Component({
      selector: 'app-sidebar',
      templateUrl: './sidebar.component.html',
      styleUrls: ['./sidebar.component.scss'],
    })
    export class SidebarComponent implements OnInit{
      constructor(
        private router: Router,
        private transloco: TranslocoService,
        private authApi: AuthService,
        private auth: AuthService,
      private pagesComponent: PagesComponent
      ) {}

      static userInfo = false;
      User: any = {};
      categoryIcon = faHashtag;
      teamIcon = faPeopleGroup;
      moneyIcon = faDollar;
      project = faScroll;
      tools = faScrewdriverWrench;
      employee = faIdCardClip;
      client = faCity;
      month = faCalendarDays;
      week = faCalendarDay;
      userIcon = faUser;
      phoneIcon = faPhone;
      logoutIcon = faArrowRightFromBracket;


      itemsAll: any = [];
      items: any = [];
      userName = '';
      public faBell = faBell;
      public faAward = faAward;
      public faFolder = faFolder;
      public faBriefcase = faBriefcase;
      public   faBagShopping=  faBagShopping; 
      static userType = 0



      



      ngOnInit() {
        this.getMenu();
      }

      getMenu() {
        this.authApi.getMe().subscribe({
          next: (response: any) => {
            this.userName = response.firstName + ' ' + response.lastName;
            this.getTranslation();
          },
          error: (error: any) => {
            if (error.status == 401) {
              location.replace('auth/login');
            }
            this.getTranslation();
          },
        });
      }

        log(log:any){
       
        }

      getTranslation() {
        this.transloco.selectTranslation().subscribe({
          next: (translation: any) => {
            const clienti = translation['clienti'];
            const utente = this.userName;
            const fornitori = translation['fornitori'];
            const anagrafiche = translation['anagrafiche'];
            const consigliati = translation['consigliati'];
            /*const esempi = translation['esempi'];*/
            const banchedati = translation['banche_dati'];
            const utenti = translation['utenti'];
            const Abbonamenti = translation['Abbonamenti'];
            const quiz = translation['quiz'];
            const notifiche = translation['notifiche'];
            const funzionalità = translation['funzionalità'];
            const accountpersonale = translation['items.account-personale'];
            const collaboratori = translation['collaboratori'];

            this.User = {
              label: utente,
              action() {
                SidebarComponent.userInfo = true;
              },
            };
    if(localStorage.getItem('roleId')==='3'){
            this.items =  [
              {
                label: 'Banche Dati',
                url: 'banche_dati',
                icon: faUser,
              },
              {
                label: quiz,
                url: 'quiz',
                icon: faFolder,
              },
              {
                label: notifiche,
                url: 'notifiche',
                icon: faBell,
              },
              {
                label: consigliati,
                url: 'consigliati',
                icon: faBagShopping,
              },

              /*{
                label: esempi,
                url: 'esempi',
                icon: faBolt,
              },*/

              {
                label: utenti,
                url: 'utenti',
                icon: faUserGroup,
              },
              {
                label: collaboratori,
                url: 'collaboratori',
                icon: faBriefcase,
              },
              {
                label: funzionalità,
                url: 'funzionalita',
                icon: faAward,
              },

            
            ] }
            else{
          this.items=  [
              {
                label: 'Banche Dati',
                url: 'banche_dati',
                icon: faUser,
              },
              {
                label: quiz,
                url: 'quiz',
                icon: faFolder,
              },
              {
                label: notifiche,
                url: 'notifiche',
                icon: faBell,
              },
              {
                label: consigliati,
                url: 'consigliati',
                icon: faClipboardCheck,
              },

              /*{
                label: esempi,
                url: 'esempi',
                icon: faBolt,
              },*/
              
            
             
            
            ];
          }
        }
        });
      }
      

      sidenavClick() {
        if (SidebarComponent.userInfo === true) {
          SidebarComponent.userInfo = false;
          this.router.navigate(['/user']);
        } else {
          SidebarComponent.userInfo = false;
        }
      }
    }
