

    <div class=" h-full p-[25px] add-rig w-[75.808%]" (click)="$event.stopPropagation()">
      <div class="w-full flex items-center justify-center">
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="w-full mt-[25px]">
        <ng-content select="[content1]"></ng-content>
      </div>
      <!-- seconda riga -->
      <div class="flex items-center justify-between mt-[25px] w-full ">
        <div class="w-[18.285%]">
            <ng-content select="[r1-c1]"></ng-content>
      </div>
      <div class="w-[18.285%]">
        <ng-content select="[r1-c2]"></ng-content>
    </div>
    <div class="w-[18.285%]">
        <ng-content select="[r1-c3]"></ng-content>
  </div>
  <div class="w-[18.285%]">
    <ng-content select="[r1-c4]"></ng-content>
</div>
<div class=" w-[18.285%]">
    <ng-content select="[r1-c5]"></ng-content>
</div>

      </div>
      <!-- terza riga -->
      <div class="flex items-center justify-between mt-[25px] ">
        <div class="w-[18.285%]">
            <ng-content select="[r2-c1]"></ng-content>
      </div>
        <div class="w-[18.285%]">
            <ng-content select="[r2-c2]"></ng-content>
        </div>
        <div class="w-[18.285%]">
            <ng-content select="[r2-c3]"></ng-content>
      </div>
      <div class="w-[18.285%]">
        <ng-content select="[r2-c4]"></ng-content>
    </div>
    <div class=" w-[18.285%]">
        <ng-content select="[r2-c5]"></ng-content>
  </div>


      </div>
      <!-- quarta riga -->
      <div class="flex items-center justify-between mt-[25px] w-full ">
        <div class=" w-[18.285%]">
            <ng-content select="[r3-c1]"></ng-content>
      </div>
        <div class="w-[18.285%] ">
            <ng-content select="[r3-c2]"></ng-content>
        </div>
        <div class="w-[18.285%]">
            <ng-content select="[r3-c3]"></ng-content>
      </div>
      <div class="w-[18.285%]">
        <ng-content select="[r3-c4]"></ng-content>
    </div>
    <div class=" w-[18.285%]">
        <ng-content select="[r3-c5]"></ng-content>
  </div>
 

      </div>
      <!-- quinta riga -->
     
      <div class="flex items-end justify-between mt-[25px] w-full ">
        <div class="w-[18.285%]">
            <ng-content select="[r4-c1]"></ng-content>
      </div>
        <div class="w-[18.285%]">
            <ng-content select="[r4-c2]"></ng-content>
        </div>
        <div class="w-[18.285%]">
            <ng-content select="[r4-c3]"></ng-content>
      
      </div>
      <div class="w-[18.285%]">
        <ng-content select="[r4-c4]"></ng-content>
    </div>
  
  <div class="w-[18.285%] ">
    <div class=" cursor-pointer">
        <ng-content select="[r4-c5]"></ng-content>
    </div>
      </div>
    </div>
 
    </div>
