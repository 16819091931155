import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-atterraggio',
  templateUrl: './atterraggio.component.html',
  styleUrls: ['./atterraggio.component.scss'],
})
export class AtterraggioComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}
  token!: string;
  ngOnInit(): void {
    this.token = encodeURIComponent(this.router.url.split('/')[2]);
    this.attiva();
  }

  attiva() {
    this.auth.active(this.token).subscribe((res: any) => {});
  }
  autorized() {
    this.router.navigate(['/auth/login']);
  }
}
