import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { Breadcrumb } from '@stdoutsrl/ui-kit';
import { Observable, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestService } from '@services/rest.service';
import { Router } from '@angular/router';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from '@services/message.service';
@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
})
export class RecoveryComponent implements OnInit {
  recoveryForm: any;
  public faCircleCheck = faCircleCheck;
  public faCircleXmark = faCircleXmark;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  recovery() {
    let url = window.location.origin + '/auth/reset/';
    const userEmail = this.recoveryForm.get('email')?.value;
    this.auth.recovery(userEmail, url).subscribe({
      next: (v) => console.log(v),
      error: (e) => {
        console.error(e);
        this.showMessage(e.message, 'error');
      },
      complete: () => {
        this.showMessage('Controllare la mail', 'success');
      },
    });
  }
}
