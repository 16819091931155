<div class="w-full h-full p-5 flex-col justify-center items-center ">
    <div [style.width.px]="width" class="h-full corpo p-5">
    <div class="flex-col justify-center items-center">
        <div>
       <h1 class="titolo"><b>{{title}}</b></h1>
    </div>
    <div class=" content mt-5">
    <b>{{content}}</b>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
  
    </div>
</div>
</div>