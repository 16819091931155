import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalFormService {
  private formSource = new BehaviorSubject<any>(null);
  currentForm = this.formSource.asObservable();
  private formError = new BehaviorSubject<boolean>(false);
  private id = new BehaviorSubject<string[]>([]);
  constructor() {}

  setId(value: string[]) {
    this.id.next(value);
  }

  getId() {
    return this.id.asObservable();
  }

  setFormError(value: boolean) {
    this.formError.next(value);
  }

  getFormError() {
    return this.formError.asObservable();
  }

  changeForm(form: any) {
    this.formSource.next(form);
  }

  getForm() {
    return this.formSource.asObservable();
  }
}
