import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalFormService } from '@services/modal-form.service';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
})
export class ModalFormComponent implements OnInit {
  form: FormGroup;
  @Input() titolo: any;
  @Input() listaRegioni: any;
  @Input() nomiEnti: any;
  @Input() nomiProfili: any;
  @Input() combinedTitoli: any;

  @Input() combinedAnno: any;
  @Input() combinedEnti: any;
  @Input() combinedProfili: any;
  @Input() Anno: any;
  @Input() listaMaterie: any;
  @Input() listaArgomenti: any;
  @Input() listaDifficolta: any;
  @Input() listaTitoliStudio: any;
  @Input() combinedMaterie: any;
  @Input() combinedArgomenti: any;
  @Input() modifyIndex: boolean = false;
  formError = false;
  constructor(private fb: FormBuilder, private modalForm: ModalFormService) {
    this.form = this.fb.group({
      indice: [, Validators.required],
      domanda: [, Validators.required],
      rispostaA: [, Validators.required],
      rispostaB: [, Validators.required],
      rispostaC: [, Validators.required],
      rispostaD: [, Validators.required],
      rispostaE: [, Validators.required],
      rispostaF: [, Validators.required],
      rispostaCorretta: [, Validators.required],
      numerazioneMida: [, Validators.required],
      titoliStudio: [, Validators.required],
      anno: [, Validators.required],
      ente: [, Validators.required],
      profilo: [, Validators.required],
      materia: [, Validators.required],
      argomento: [, Validators.required],
      difficolta: [, Validators.required],
      immagine: [, Validators.required],
      immagineSpiegazione: [, Validators.required],
      linkPreparazione: [, Validators.required],
    });
  }

  onEnteChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const ente = target.value;

    this.combinedEnti = [...this.nomiEnti];

    this.combinedEnti.push(ente);
  }

  onProfiliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const profilo = target.value;

    this.combinedProfili = [...this.nomiProfili];
    if (profilo) {
      this.combinedProfili.push(profilo);
    }
  }

  onMaterieChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const materie = target.value;

    this.combinedMaterie = this.listaMaterie.map(
      (materie: any) => materie.nome
    );

    this.combinedMaterie.push(materie);
  }

  onArgomentiChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const argomenti = target.value;

    this.combinedArgomenti = this.listaArgomenti.map(
      (argomenti: any) => argomenti.nome
    );

    this.combinedArgomenti.push(argomenti);
  }

  onAnnoChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const anno = target.value;

    this.combinedAnno = this.Anno.map((anno: any) => anno.nome);

    this.combinedAnno.push(anno);
  }

  onTitoliChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const titoli = target.value;

    this.combinedTitoli = this.listaTitoliStudio.map(
      (titoli: any) => titoli.nome
    );

    this.combinedTitoli.push(titoli);
  }
  @Output() onSubmit = new EventEmitter<any>();
  submitForm(event: Event) {
    event.preventDefault();
    this.modalForm.changeForm(this.form.value);
    this.onSubmit.emit();
  }

  ngOnInit(): void {
    this.modalForm.getFormError().subscribe((error) => {
      this.formError = error;
    });
    this.modalForm.getForm().subscribe((form) => {
      this.form.patchValue(form);
    });
  }
}
