import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormDetailsService {
  private formSource = new BehaviorSubject<any>(null);
  currentForm = this.formSource.asObservable();

  private error = new BehaviorSubject<string>('');
  private public = new BehaviorSubject<boolean>(false);
  private isChange = new BehaviorSubject<boolean>(false);
  private existPass = new BehaviorSubject<boolean>(false);

  private modifica = new BehaviorSubject<boolean>(false);
  private pass = new BehaviorSubject<boolean>(false);
  private showPriv = new BehaviorSubject<boolean>(false);
  private showPub = new BehaviorSubject<boolean>(false);

  constructor() {}

  setShowPriv(value: boolean) {
    this.showPriv.next(value);
  }

  getShowPriv() {
    return this.showPriv.asObservable();
  }

  setShowPub(value: boolean) {
    this.showPub.next(value);
  }

  getShowPub() {
    return this.showPub.asObservable();
  }

  setPass(value: boolean) {
    this.pass.next(value);
  }

  getPass() {
    return this.pass.asObservable();
  }

  setModifica(value: boolean) {
    this.modifica.next(value);
  }

  getModifica() {
    return this.modifica.asObservable();
  }

  setExistPass(value: boolean) {
    this.existPass.next(value);
  }

  getExistPass() {
    return this.existPass.asObservable();
  }

  setIsChange(value: any) {
    this.isChange.next(value);
  }

  getIsChange() {
    return this.isChange.asObservable();
  }

  changeForm(form: any) {
    this.formSource.next(form);
  }

  getForm() {
    return this.formSource.asObservable();
  }

  setError(value: any) {
    this.error.next(value);
  }

  getError() {
    return this.error.asObservable();
  }

  setPublic(value: any) {
    this.public.next(value);
  }

  getPublic() {
    return this.public.asObservable();
  }
}
