


<app-header></app-header>
<div class="mb-[30vh]">
<div class="carta ml-10 mt-10 mr-10">
    <stdout-card>
     <stdout-card-header class="card-header"><h2>{{"lim_dom"|transloco}}</h2></stdout-card-header>
     
     <stdout-card-content>
        <div class=" flex justify-between items-center  flex-wrap  ">
      <div class="w-[333px] m-[1px] ">
        <stdout-text-input class="bordo" placeholder="Inserisci limite quiz" type="text"></stdout-text-input>

    </div>
        <div class=" mt-[1px] w-[200px]">
     <stdout-button class="bottone-banca"  size="large" color="white"><p>{{"salva"|transloco}}</p></stdout-button>
    </div>
</div>
     </stdout-card-content>
    
    </stdout-card>
</div>

<div class=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
    <div class=" px-10 mt-10 ">
      <div class=" rounded-md w-full bg-white">
        <div class="crt1">
            <stdout-card>
                <stdout-card-header >
                    <div class="flex justify-between">{{"attivita"|transloco}}
                    <fa-icon (click)="v1()" [icon]="showPrima ? faChevronUp : faChevronDown"></fa-icon>
                </div>
                </stdout-card-header>
                <stdout-card-content class *ngIf="showPrima">
                 <div class="pc flex  items-center ">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedAppLib"   (change)="gestisciCheckboxApprendimentoLibero($event)"></stdout-checkbox> 
                </div>
                <div class="ml-[10px]">
                <p>{{"app_lib"|transloco}}</p>
            </div>
                </div>
                <div class="sc  mt-[20px] flex items-center">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedAppGuid"  (change)="gestisciCheckboxApprendimentoGuidato($event)"></stdout-checkbox>  
              
                    </div>
                <div class="ml-[10px]">
                <p>{{"app_guid"|transloco}}</p>
            </div>
                </div>
                <div class="tc mt-[20px] flex items-center">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedSim"   (change)="gestisciCheckboxSimulazioneDesame($event)"></stdout-checkbox> 
                </div>
                <div class="ml-[10px]">
                <p>{{"sim_esa"|transloco}}</p>
            </div>
                </div>
                
                <div class="flex justify-center bottoni  mt-10 ">
                    <div class="w-[200px] h-[45px] mr-[10px]">
                    <stdout-button (click)="ResetAttivita()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                </div>
                <div class="ml-[10px] w-[200px] h-[45px]">
                    <stdout-button class="btn-blu" (click)="salvaAttivita()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                </div>
                </div>
                </stdout-card-content>
              </stdout-card>
            </div>
      </div>
    </div>
  
    
    <div class=" px-10 mt-10 ">
      <div class="  rounded-md w-full bg-white">
        <div class="crt2 ">
            <stdout-card>
                <stdout-card-header >
                    <div class="flex justify-between">{{"ord_quiz"|transloco}}
                    <fa-icon (click)="v2()" [icon]="showSeconda ? faChevronUp : faChevronDown"></fa-icon>
                </div>
                </stdout-card-header>
                <stdout-card-content class *ngIf="showSeconda">
                    <div class="pc  flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedAlfabetico"  (change)="gestisciCheckboxAlfabetico($event)"></stdout-checkbox>
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"alf"|transloco}}</p>
               </div>
                   </div>
                   <div class="sc  mt-[20px] flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedCasuale"  (change)="gestisciCheckboxCasuale($event)"></stdout-checkbox>
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"cas"|transloco}}</p>
               </div>
                   </div>
                   <div class="tc  mt-[20px] flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckedUfficiale"  (change)="gestisciCheckboxUfficiale($event)"></stdout-checkbox>
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"uff"|transloco}}</p>
               </div>
                   </div>
                   
                   <div class="flex justify-center bottoni  mt-10">
                    <div class="w-[200px] h-[45px] mr-[10px]">
                    <stdout-button (click)="resetOrdine()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                </div>
                <div class="ml-[10px] w-[200px] h-[45px]">
                    <stdout-button class="btn-blu" (click)="salvaOrdine()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                </div>
                </div>
                
                   </stdout-card-content>
              </stdout-card>
        </div>
    
      </div>
    </div>
  
   
    <div class=" px-10 mt-10 ">
      <div class=" rounded-md w-full bg-white">
        <div class="crt3">
            <stdout-card>
                <stdout-card-header >
                    <div class="flex justify-between">{{"filtri"|transloco}}
                    <fa-icon (click)="v3()" [icon]="showTerza ? faChevronUp : faChevronDown"></fa-icon>
                </div>
                </stdout-card-header>
                <stdout-card-content class *ngIf="showTerza">
                    <div class="pc   flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckMat" (change)="gestisciCheckboxSelezionaMateria($event)"></stdout-checkbox>
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"sel_mat"|transloco}}</p>
               </div>
                   </div>
                   <div class="sc  mt-[20px] flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckPar" (change)="gestisciCheckboxParolaChiave($event)"></stdout-checkbox> 
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"prl_chv"|transloco}}</p>
               </div>
                   </div>
                   <div class="tc  mt-[20px] flex items-center">
                       <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckInt" (change)="gestisciCheckboxIntervalloNumerico($event)"></stdout-checkbox>
                   </div>
                   <div class="ml-[10px]">
                   <p>{{"int_num"|transloco}}</p>
               </div>
                   </div>
                   <div class="qc  mt-[20px] flex items-center">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckDif" (change)="gestisciCheckboxDifficoltaQuiz($event)"></stdout-checkbox>
                </div>
                <div class="ml-[10px]">
                <p>{{"dif_quiz"|transloco}}</p>
            </div>
                </div>
                <div class="qqc  mt-[20px] flex items-center">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckQuiz" (change)="gestisciCheckboxQuizConSpiegazione($event)"></stdout-checkbox>
                </div>
                <div class="ml-[10px]">
                <p>{{"quiz_con_spieg"|transloco}}</p>
            </div>
                </div>
                <div class="sc  mt-[20px] flex items-center">
                    <div class="fix-check-funz">
                        <stdout-checkbox [value]="isCheckImg" (change)="gestisciCheckboxQuizConImmagini($event)"></stdout-checkbox>
                </div>
                <div class="ml-[10px]">
                <p>{{"quiz_con_img"|transloco}}</p>
            </div>
                </div>
                   
                <div class="flex justify-center bottoni  mt-10">
                    <div class="w-[200px] h-[45px] mr-[10px]">
                    <stdout-button (click)="resetFiltri()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                </div>
                <div class="ml-[10px] w-[200px] h-[45px]">
                    <stdout-button class="btn-blu" (click)="salvaFiltri()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                </div>
                </div>
                   </stdout-card-content>
              </stdout-card>
        </div>
      </div>
    </div>
    <div class=" px-10 mt-10">
        <div class="  rounded-md w-full bg-white">
            <div class="crt4">
                <stdout-card>
                    <stdout-card-header >
                        <div class="flex justify-between">{{"svlg_quiz"|transloco}}
                        <fa-icon (click)="v4()" [icon]="showQuarta ? faChevronUp : faChevronDown"></fa-icon>
                    </div>
                    </stdout-card-header>
                    <stdout-card-content class *ngIf="showQuarta">
                        <div class="grid grid-cols-1 md:grid-cols-2 ">
                            <!-- Elemento 1 -->
                            <div class=" ">
                                <div class="pc   flex items-center">
                                    <div class="fix-check-funz">
                                     <stdout-checkbox [value]="isCheckTutti" (change)="gestisciCheckboxTutti($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"tutti"|transloco}}</p>
                            </div>
                                </div>
                                <div class="sc mt-[20px]  flex items-center">
                                    <div class="fix-check-funz">
                                     <stdout-checkbox [value]="isCheckSvolti" (change)="gestisciCheckboxSvolti($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"svolti"|transloco}}</p>
                            </div>
                                </div>
                                <div class="tc mt-[20px]  flex items-center">
                                    <div class="fix-check-funz">
                                     <stdout-checkbox [value]="isCheckSospesi" (change)="gestisciCheckboxSospesi($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"sospesi"|transloco}}</p>
                            </div>
                                </div>
                                <div class="qc mt-[20px]  flex items-center">
                                 <div class="fix-check-funz">
                                     <stdout-checkbox [value]="isCheckSalvati" (change)="gestisciCheckboxSalvati($event)"></stdout-checkbox> 
                             </div>
                             <div class="ml-[10px]">
                             <p>{{"salvati"|transloco}}</p>
                         </div>
                             </div>
                            </div>
                            
                            <!-- Elemento 2 -->
                            <div class="">
                                <div class="qqc  flex items-center">
                                    <div class="fix-check-funz">
                                        <stdout-checkbox [value]="isCheckNuovi" (change)="gestisciCheckboxNuovi($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"nuovi"|transloco}}</p>
                            </div>
                                </div>
                                <div class="sc mt-[20px]  flex items-center">
                                    <div class="fix-check-funz">
                                        <stdout-checkbox [value]="isCheckErrati" (change)="gestisciCheckboxErrati($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"errati"|transloco}}</p>
                            </div>
                                </div>
                                <div class="se mt-[20px]  flex items-center">
                                    <div class="fix-check-funz">
                                        <stdout-checkbox [value]="isCheckCorretti" (change)="gestisciCheckboxCorretti($event)"></stdout-checkbox>
                                </div>
                                <div class="ml-[10px]">
                                <p>{{"corretti"|transloco}}</p>
                            </div>
                                </div>
                            </div>
                          </div>
                             
                          
                       
                 
                       
                       
                    <div class="flex justify-center bottoni  mt-10">
                        <div class="w-[200px] h-[45px] mr-[10px]">
                        <stdout-button (click)="resetSvolgi()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                    </div>
                    <div class="ml-[10px] w-[200px] h-[45px]">
                        <stdout-button class="btn-blu" (click)="salvaSvolgi()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                    </div>
                    </div>
                       </stdout-card-content>
                  </stdout-card>
                </div>
            </div>
            </div>
            <div class=" px-10 mt-10 ">
                <div class="  rounded-md w-full bg-white">
                    <div class="crt5 ">
                        <stdout-card>
                            <stdout-card-header >
                                <div class="flex justify-between">{{"opzioni"|transloco}}
                                <fa-icon (click)="v5()" [icon]="showQuinta ? faChevronUp : faChevronDown"></fa-icon>
                            </div>
                            </stdout-card-header>
                            <stdout-card-content class *ngIf="showQuinta">
                                <div class="pc   flex items-center">
                                   <div class="fix-check-funz">
                                    <stdout-checkbox [value]="isCheckRispCorr" (change)="gestisciCheckboxMostraRispostaCorretta($event)"></stdout-checkbox>
                               </div>
                               <div class="ml-[10px]">
                               <p>{{"ms_corr"|transloco}}</p>
                           </div>
                               </div>
                               <div class="sc mt-[20px]  flex items-center">
                                   <div class="fix-check-funz">
                                    <stdout-checkbox [value]="isCheckMostCont" (change)="gestisciCheckboxMostraContatore($event)"></stdout-checkbox>
                               </div>
                               <div class="ml-[10px]">
                               <p>{{"ms_cont"|transloco}}</p>
                           </div>
                               </div>
                               <div class="tc mt-[20px]  flex items-center">
                                   <div class="fix-check-funz">
                                    <stdout-checkbox [value]="isCheckAutoNext" (change)="gestisciCheckboxAbilita($event)"></stdout-checkbox>
                               </div>
                               <div class="ml-[10px]">
                               <p>{{"ab_atxt"|transloco}}</p>
                           </div>
                               </div>
                               
                               <div class="flex justify-center bottoni  mt-10">
                                <div class="w-[200px] h-[45px] mr-[10px]">
                                <stdout-button (click)="resetOpzioni()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                            </div>
                            <div class="ml-[10px] w-[200px] h-[45px]">
                                <stdout-button class="btn-blu" (click)="salvaOpzioni()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                            </div>
                            </div>
                               </stdout-card-content>
                          </stdout-card>
                    </div>
                    </div>
                    </div>
                    <div class="px-10 mt-10">
                        <div class="  rounded-md w-full bg-white">
                            <div class="crt6 ">
                                <stdout-card>
                                    <stdout-card-header >
                                        <div class="flex justify-between">{{"mod_stampa"|transloco}}
                                        <fa-icon (click)="v6()" [icon]="showSesta ? faChevronUp : faChevronDown"></fa-icon>
                                    </div>
                                    </stdout-card-header>
                                    <stdout-card-content class *ngIf="showSesta">
                                        <div class="pc   flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="isCheckStampaSep" (change)="gestisciCheckboxStampaCorr($event)"></stdout-checkbox>
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"stampa_sep"|transloco}}</p>
                                   </div>
                                       </div>
                                       <div class="sc mt-[20px]  flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="isCheckStampaAff" (change)="gestisciCheckboxStampaCorrAff($event)"></stdout-checkbox>
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"stampa_aff"|transloco}}</p>
                                   </div>
                                       </div>
                                       <div class="tc mt-[20px]  flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="ischeckStampCorr" (change)="gestisciCheckboxStampaCorrSolo($event)"></stdout-checkbox> 
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"stampa_corr"|transloco}}</p>
                                   </div>
                                       </div>
                                       
                                       <div class="flex justify-center  bottoni mt-10 ">
                                        <div class="w-[200px] h-[45px] mr-[10px]">
                                        <stdout-button (click)="resetModalita()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                                    </div>
                                    <div class="ml-[10px] w-[200px] h-[45px]">
                                        <stdout-button class="btn-blu" (click)="salvaModalita()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                                    </div>
                                    </div>
                                       </stdout-card-content>
                                  </stdout-card>
                            </div>
                            </div>
                            </div>

                            <div class="px-10 mt-10 ">
                                <div class="  rounded-md w-full bg-white">
                            <div class="crt7">
                                <stdout-card>
                                    <stdout-card-header >
                                        <div class="flex justify-between">{{"opzioni_ese"|transloco}}
                                        <fa-icon (click)="v7()" [icon]="showSettima ? faChevronUp : faChevronDown"></fa-icon>
                                    </div>
                                    </stdout-card-header>
                                    <stdout-card-content class *ngIf="showSettima">
                                        <div class="pc   flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="isCheckInfo" (change)="gestisciCheckboxInfo($event)"></stdout-checkbox> 
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"info_dom"|transloco}}</p>
                                   </div>
                                       </div>
                                       <div class="sc mt-[20px]  flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="isCheckTesto" (change)="gestisciCheckboxTesto($event)"></stdout-checkbox> 
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"testo"|transloco}}</p>
                                   </div>
                                       </div>
                                       <div class="tc mt-[20px]  flex items-center">
                                           <div class="fix-check-funz">
                                            <stdout-checkbox [value]="isCheckSalva" (change)="gestisciCheckboxSalva($event)"></stdout-checkbox> 
                                       </div>
                                       <div class="ml-[10px]">
                                       <p>{{"salva_dom"|transloco}}</p>
                                   </div>
                                       </div>
                                       <div class="qc mt-[20px]  flex items-center">
                                        <div class="fix-check-funz">
                                            <stdout-checkbox (change)="gestisciCheckboxCondividi($event)"></stdout-checkbox>  
                                    </div>
                                    <div class="ml-[10px]">
                                    <p>{{"condividi"|transloco}}</p>
                                </div>
                                    </div>
                                       
                                    <div class="flex justify-center  bottoni mt-10">
                                        <div class="w-[200px] h-[45px] mr-[10px]">
                                        <stdout-button (click)="resetOpzeser()" class="btn-bianco" size="large" color="black"><h2>{{"elimina"|transloco}}</h2></stdout-button>
                                    </div>
                                    <div class="ml-[10px] w-[200px] h-[45px]">
                                        <stdout-button class="btn-blu" (click)="salvaOpzeser()"  size="large" color="white"><h2>{{"salva"|transloco}}</h2></stdout-button>
                                    </div>
                                    </div>
                                       </stdout-card-content>
                                  </stdout-card>
                                </div>
                            
                            </div>
                            </div>
  </div>
  
  
</div>








