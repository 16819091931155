import { Component, HostListener, OnInit } from '@angular/core';
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { BanchedatiService } from '@services/banchedati.service';
import { MessageService } from '@services/message.service';
import { SelectionService } from '@services/selection.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';

@Component({
  selector: 'app-profili',
  templateUrl: './profili.component.html',
  styleUrls: ['./profili.component.scss'],
})
export class ProfiliComponent implements OnInit {
  loader = false;
  nome: string = '';
  totalPages: number = 27;
  page: number = 1;
  profili: any = [];
  isScrollable: boolean = false;
  changeId: number[] = [];
  perTeId: number[] = [];
  isPhone: any;
  modalDelete: boolean = false;
  public faMagnifyingGlass = faMagnifyingGlass;
  nomeCerca: string = '';
  modal: boolean = false;
  public faTrash = faTrash;
  public faPencil = faPencil;
  nomeProfilo: string = '';
  idProfilo: string = '';

  constructor(
    private banchedati: BanchedatiService,
    private selectionService: SelectionService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.paginateProfili();
  }

  applyFilter() {
    this.paginateProfili();
  }

  log() {
    console.log(this.nomeProfilo);
  }

  goNext(page: number) {
    page < this.totalPages ? (this.page = page + 1) : '';
    this.paginateProfili();
  }

  goBack(page: number) {
    page === 1 ? '' : (this.page = page - 1);
    this.paginateProfili();
  }

  getPage(page: number) {
    this.page = page;
    this.paginateProfili();
  }
  showMessage(message: string, status: string) {
    this.message.showMessage(message, status);
  }
  paginateProfili() {
    LoaderComponent.isLoading = true;

    return this.banchedati
      .paginateProfilo(this.page - 1, this.nomeCerca)
      .subscribe(
        (response: any) => {
          this.totalPages = response.totalPages;
          this.profili = response.data.map((item: any) => {
            return {
              id: item.id,
              nome: item.nome === '' ? 'profilo vuoto' : item.nome,
            };
          });
          LoaderComponent.isLoading = false;
        },
        (error: any) => {
          console.error(
            'Errore durante il recupero delle informazioni ',
            error
          );

          LoaderComponent.isLoading = false;
        }
      );
  }

  showModal(id: number, nome: string) {
    this.modal = !this.modal;
    this.nomeProfilo = nome;
    this.idProfilo = id.toString();
  }

  modifyEnte() {
    LoaderComponent.isLoading = true;
    // const formData: FormData = new FormData();

    // formData.append('nome', this.nomeEnte.toString());

    return this.banchedati
      .modifyProfilo(this.idProfilo, this.nomeProfilo)
      .subscribe(
        (response: any) => {
          LoaderComponent.isLoading = false;
          this.paginateProfili();
          this.modal = !this.modal;
        },
        (error: any) => {
          this.showMessage(error.error.message, 'error');
          LoaderComponent.isLoading = false;
          console.error('Errore durante la creazione della materia:', error);
        }
      );
  }

  deleteProfilo() {
    LoaderComponent.isLoading = true;

    this.banchedati.deleteProfilo(Number(this.idProfilo)).subscribe(
      (response) => {
        this.paginateProfili();
        this.modal = false;
        this.modalDelete = false;
        LoaderComponent.isLoading = false;
      },
      (error) => {
        this.showMessage(error.error.message, 'error');
        LoaderComponent.isLoading = false;
        console.error(
          `Errore durante l'eliminazione del bando con ID ${this.idProfilo}:`,
          error
        );
      }
    );
  }

  showModalDelete(id: number) {
    this.modalDelete = !this.modalDelete;

    this.idProfilo = id.toString();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (this.modal) {
      this.modal = false;
    }
    if (this.modalDelete) {
      this.modalDelete = false;
    }
  }
}
