<form [formGroup]="form" (ngSubmit)="attivaAbbonamento()">
<div class="w-full h-full p-5 flex-col justify-center items-center ">
    <div  class="h-full corpo p-5">
    <div class="flex-col justify-center items-center">
        <div>
       <h1 class="titolo">Abbonamento {{nome}}   {{cognome}} </h1>
    </div>
    <div class="px-5  mt-10">
<div class="flex flex-col justify-start items-start w-full h-full gap-4">
    <!-- id abbonamento -->
    <div class="w-full h-full flex flex-col gap-3 items-center justify-start">
        <div class="w-full h-full flex items-start justify-start content">
<h2 >Tipo abbonamento</h2>
</div>
<div class="w-full h-full">
    <stdout-select class="fix-select" [placeholder]="'Seleziona o inserisci l’abbonamento'" id="select1" type="text"
    [clearable]="false" [data]="abbonamenti" formControlName="idTipoAbbonamento" bindLabel="nome" bindValue="id"
    #selectElement
   
    >
  </stdout-select>
</div>

</div>
<!-- id banca dati -->
<div class="w-full h-full flex flex-col gap-3 items-center justify-start" *ngIf="showIdBancaDatiSection">
    <div class="w-full h-full flex items-start justify-start content">
<h2>Banca Dati</h2>
</div>
<div class="w-full  h-full">
<stdout-select class="fix-select" [placeholder]="'Seleziona o inserisci l’abbonamento'" id="select1" type="text"
[clearable]="false" [data]="listaBancheDati" formControlName="idBancaDati" bindLabel="nome" bindValue="id"
#selectElement

>
</stdout-select>
</div>
<div class="w-full h-full mt-5 content flex items-center justify-center flex-col gap-2" *ngIf="showNome && selectedBancaDati">
    <p class="!text-black !font-extrabold">Hai selezionato la seguente banca dati:</p>
    <div class="w-full h-full flex items-center justify-start mt-2 gap-3">
    <p class="!text-black !font-extrabold "> Nome: </p><p class="whitespace-nowrap">{{ selectedBancaDati.nome }}</p>
</div>
<div class="w-full h-full flex items-center justify-start gap-3">
    <p class="!text-black !font-extrabold">Descrizione: </p><p class="whitespace-nowrap">{{ selectedBancaDati.descrizione }}</p>
</div>
<div class="w-full h-full flex items-center justify-start gap-3">
    <p class="!text-black !font-extrabold">Anno: </p><p>{{ selectedBancaDati.anno }}</p>
</div>
  </div>

</div>

</div>
<div class="w-full mt-5 flex items-center justify-center gap-5">

<div class="w-1/2">
    <stdout-button
    class="btn-blu w-full"
    size="large"
    color="white"
    (click)="close()"
    >
    <h2>{{ "chiudi" | transloco }}</h2>
    </stdout-button>
    </div>
    <div class="w-1/2">
        <stdout-button
        class="btn-blu w-full"
        size="large"
        color="white"
        type="submit"
        >
        <h2>{{ "conferma" | transloco }}</h2>
        </stdout-button>
        </div>
    </div>
</div>
    <div>

    </div>
  
    </div>
</div>
</div>
</form>