<div class="container">
  <div class="card-container">
    <stdout-card>
      <stdout-card-header> Registrati </stdout-card-header>
      <stdout-card-content>
        <form [formGroup]="registerForm" class="example-form grid">
          <div class="input-container">
            <stdout-text-input
              formControlName="email"
              isErrorMessage="Inserisci una email valida"
              [isError]="registerForm.controls['email'].hasError('email')"
              type="email"
              placeholder="Email"
            >
            </stdout-text-input>
          </div>
          <div class="flex input-container">
            <stdout-text-input
              style="width: 100%"
              isErrorMessage="Lunghezza minima 8 caratteri. La password deve contenere almeno una
          maiuscola, una minuscola, un numero ed un carattere speciale"
              [isError]="registerForm.controls['password'].hasError('pattern')"
              formControlName="password"
              [type]="hide1 ? 'password' : 'text'"
              placeholder="Password"
            >
            </stdout-text-input>
            <stdout-icon-button
              style="top: 8px; position: relative; right: 20px; width: 0px"
              (click)="hide1 = !hide1"
              ><fa-icon [icon]="hide1 ? faEye : faEyeSlash"></fa-icon
            ></stdout-icon-button>
          </div>
          <div class="flex input-container">
            <stdout-text-input
              style="width: 100%"
              isErrorMessage="Le password devono coincidere"
              [isError]="
                registerForm.controls['confirmPassword'].value !==
                registerForm.controls['password'].value
              "
              formControlName="confirmPassword"
              [type]="hide2 ? 'password' : 'text'"
              placeholder="Nuova password"
            >
            </stdout-text-input>
            <stdout-icon-button
              style="top: 8px; position: relative; right: 20px; width: 0px"
              (click)="hide2 = !hide2"
              ><fa-icon [icon]="hide2 ? faEye : faEyeSlash"></fa-icon
            ></stdout-icon-button>
          </div>
          <div class="flex between items-center">
            <div class="flex">
              <stdout-checkbox
                [checked]="checked"
                label="Ricordami"
              ></stdout-checkbox>
            </div>
          </div>
          <stdout-button color="white" (click)="register()" type="submit">
            Registrati
          </stdout-button>
          <div class="flex" style="justify-content: center; margin: 10px">
            <i class="t-primary">oppure</i>
          </div>
          <stdout-button
            color="white"
            class="t-primary"
            mat-raised-button
            [routerLink]="['../login']"
          >
            Accedi
          </stdout-button>
        </form>
      </stdout-card-content>
    </stdout-card>
  </div>
</div>
