import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltriDetailsService {
  private anni = new BehaviorSubject<string[]>([]);
  private enti = new BehaviorSubject<any[]>([]);
  private profili = new BehaviorSubject<any[]>([]);
  private regioni = new BehaviorSubject<any[]>([]);
  private materie = new BehaviorSubject<any[]>([]);
  private titoliDiStudio = new BehaviorSubject<any[]>([]);
  private argomento = new BehaviorSubject<any[]>([]);
  private difficolta = new BehaviorSubject<any[]>([]);
  private immagini = new BehaviorSubject<any[]>([]);
  constructor() {}

  setAnni(anni: string[]) {
    this.anni.next(anni);
  }
  getAnni() {
    return this.anni.asObservable();
  }
  setEnti(enti: any[]) {
    this.enti.next(enti);
  }
  getEnti() {
    return this.enti.asObservable();
  }
  setProfili(profili: any[]) {
    this.profili.next(profili);
  }
  getProfili() {
    return this.profili.asObservable();
  }
  setRegioni(regioni: any[]) {
    this.regioni.next(regioni);
  }
  getRegioni() {
    return this.regioni.asObservable();
  }
  setMaterie(materie: any[]) {
    this.materie.next(materie);
  }
  getMaterie() {
    return this.materie.asObservable();
  }
  setTitoliDiStudio(titoliDiStudio: any[]) {
    this.titoliDiStudio.next(titoliDiStudio);
  }
  getTitoliDiStudio() {
    return this.titoliDiStudio.asObservable();
  }
  setArgomento(argomento: any[]) {
    this.argomento.next(argomento);
  }
  getArgomento() {
    return this.argomento.asObservable();
  }
  setDifficolta(difficolta: any[]) {
    this.difficolta.next(difficolta);
  }
  getDifficolta() {
    return this.difficolta.asObservable();
  }
  setImmagini(immagini: any[]) {
    this.immagini.next(immagini);
  }
  getImmagini() {
    return this.immagini.asObservable();
  }
}
